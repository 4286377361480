import {
  GET_DATA_NOTIF_SUCCESS,
  GET_DATA_NOTIF_FAIL,
  UPDATE_NOTIF_SUCCESS,
  UPDATE_NOTIF_FAIL,
  // DELETE_NOTIF_SUCCESS,
  // DELETE_NOTIF_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataNotif: [],
  error: {},
}

const DataNotifReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_NOTIF_SUCCESS:
      return {
        ...state,
        dataNotif: action.payload,
      }

    case GET_DATA_NOTIF_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_NOTIF_SUCCESS:
      return {
        ...state,
        dataNotif: state.dataNotif.map(notif =>
          notif._id === action.payload._id
            ? { notif, ...action.payload }
            : notif
        ),
      }

    case UPDATE_NOTIF_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // case DELETE_NOTIF_SUCCESS:
    //   return {
    //     ...state,
    //     dataNotif: state.dataNotif.filter(
    //       notif => notif._id !== action.payload._id
    //     ),
    //   }

    // case DELETE_NOTIF_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }
    default:
      return state
  }
}

export default DataNotifReducer
