import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_PAKET_KHITAN,
  DELETE_PAKET_KHITAN,
  // GET_CATEGORIES,
  GET_DATA_PAKET_KHITAN,
  UPDATE_PAKET_KHITAN,
} from "./actionTypes"
import {
  getDataPaketKhitanSuccess,
  getDataPaketKhitanFail,
  addPaketKhitanFail,
  addPaketKhitanSuccess,
  updatePaketKhitanSuccess,
  updatePaketKhitanFail,
  deletePaketKhitanSuccess,
  deletePaketKhitanFail,
  // getCategoriesSuccess,
  // getCategoriesFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataPaketKhitan,
  addNewPaketKhitan,
  updatePaketKhitan,
  deletePaketKhitan,
  // getCategories,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"

function* fetchDataPaketKhitan() {
  yield put(startLoading());
  try {
    const response = yield call(getDataPaketKhitan)
    yield put(getDataPaketKhitanSuccess(response))
  } catch (error) {
    yield put(getDataPaketKhitanFail(error))
  }
  yield put(stopLoading());
}

function* onAddNewPaketKhitan({ payload: paket }) {
  yield put(startLoading());
  try {
    const response = yield call(addNewPaketKhitan, paket)
    yield put(addPaketKhitanSuccess(response))
  } catch (error) {
    yield put(addPaketKhitanFail(error))
  }
  yield put(stopLoading());
}

function* onUpdatePaketKhitan({ payload: paket }) {
  yield put(startLoading());
  try {
    // console.log(paket)
    const response = yield call(updatePaketKhitan, paket)
    yield put(updatePaketKhitanSuccess(response))
  } catch (error) {
    yield put(updatePaketKhitanFail(error))
  }
  yield put(stopLoading());
}

function* onDeletePaketKhitan({ payload: paket }) {
  yield put(startLoading());
  try {
    const response = yield call(deletePaketKhitan, paket)
    yield put(deletePaketKhitanSuccess(response))
  } catch (error) {
    yield put(deletePaketKhitanFail(error))
  }
  yield put(stopLoading());
}

// function* onGetCategories() {
//   try {
//     const response = yield call(getCategories)
//     yield put(getCategoriesSuccess(response))
//   } catch (error) {
//     yield put(getCategoriesFail(error))
//   }
// }

function* DataPaketKhitanSaga() {
  yield takeEvery(GET_DATA_PAKET_KHITAN, fetchDataPaketKhitan)
  yield takeEvery(ADD_NEW_PAKET_KHITAN, onAddNewPaketKhitan)
  yield takeEvery(UPDATE_PAKET_KHITAN, onUpdatePaketKhitan)
  yield takeEvery(DELETE_PAKET_KHITAN, onDeletePaketKhitan)
  // yield takeEvery(GET_CATEGORIES, onGetCategories)
}

export default DataPaketKhitanSaga
