/* DATA_REKENING */
export const GET_DATA_REKENING = "GET_DATA_REKENING"
export const GET_DATA_REKENING_SUCCESS = "GET_DATA_REKENING_SUCCESS"
export const GET_DATA_REKENING_FAIL = "GET_DATA_REKENING_FAIL"

export const ADD_NEW_REKENING = "ADD_NEW_REKENING"
export const ADD_REKENING_SUCCESS = "ADD_REKENING_SUCCESS"
export const ADD_REKENING_FAIL = "ADD_REKENING_FAIL"

export const UPDATE_REKENING = "UPDATE_REKENING"
export const UPDATE_REKENING_SUCCESS = "UPDATE_REKENING_SUCCESS"
export const UPDATE_REKENING_FAIL = "UPDATE_REKENING_FAIL"

export const DELETE_REKENING = "DELETE_REKENING"
export const DELETE_REKENING_SUCCESS = "DELETE_REKENING_SUCCESS"
export const DELETE_REKENING_FAIL = "DELETE_REKENING_FAIL"
