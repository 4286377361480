import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { env } from "../../helpers/config"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

import { getDataConfig, updateConfig } from "../../store/actions"

const AdditionalConfig = props => {
  const { onGetDataConfig, onUpdateConfig, dataConfig } = props

  const [biayaDalamKota, setBiayaDalamKota] = useState(0)
  const [biayaLuarKota, setBiayaLuarKota] = useState(0)
  const [panicNumber, setPanicNumber] = useState("")
  const [botNumber, setBotNumber] = useState("")
  const [adminNumber, setAdminNumber] = useState("")

  useEffect(() => {
    onGetDataConfig([
      "biaya_dalam_kota",
      "biaya_luar_kota",
      "panic_number",
      "admin_number",
      "bot_number",
    ])
  }, [])

  useEffect(() => {
    if (!isEmpty(dataConfig)) {
      setBiayaDalamKota(dataConfig.biaya_dalam_kota)
      setBiayaLuarKota(dataConfig.biaya_luar_kota)
      setPanicNumber(dataConfig.panic_number)
      setAdminNumber(dataConfig.admin_number)
      setBotNumber(dataConfig.bot_number)
    }
  }, [dataConfig])

  const biayaTambahanSubmit = () => {
    onUpdateConfig({
      biaya_dalam_kota: parseInt(biayaDalamKota),
      biaya_luar_kota: parseInt(biayaLuarKota),
      panic_number: panicNumber.toString(),
      admin_number: adminNumber.toString(),
      bot_number: botNumber.toString(),
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Pengaturan Tambahan | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs breadcrumbItem="Pengaturan Tambahan" />

                  <AvForm onValidSubmit={biayaTambahanSubmit}>
                    <Col className="col-12 mb-3">
                      <AvField
                        name="panic_number"
                        label="Nomor Panic Button App (Ganti 0 dengan 62 , ex: 62123456789)"
                        type="number"
                        errorMessage="Invalid nomor"
                        onChange={e => setPanicNumber(e.target.value)}
                        validate={{
                          required: { value: true },
                          number: true,
                        }}
                        minLength={8}
                        value={panicNumber}
                      />
                    </Col>
                    <Col className="col-12 mb-3">
                      <AvField
                        name="admin_number"
                        label="Nomor Admin CS App (Ganti 0 dengan 62 , ex: 62123456789)"
                        type="number"
                        errorMessage="Invalid nomor"
                        onChange={e => setAdminNumber(e.target.value)}
                        validate={{
                          required: { value: true },
                          number: true,
                        }}
                        minLength={8}
                        value={adminNumber}
                      />
                    </Col>
                    <Col className="col-12 mb-3">
                      <AvField
                        name="bot_number"
                        label="Nomor Bot (Tatan) App (Ganti 0 dengan 62 , ex: 62123456789)"
                        type="number"
                        errorMessage="Invalid nomor"
                        onChange={e => setBotNumber(e.target.value)}
                        validate={{
                          required: { value: true },
                          number: true,
                        }}
                        minLength={8}
                        value={botNumber}
                      />
                    </Col>
                    <Col className="col-12 mb-3">
                      <AvField
                        name="biaya_dalam_kota"
                        label="Biaya Tambahan dalam Kota"
                        type="number"
                        errorMessage="Invalid biaya tambahan"
                        onChange={e => setBiayaDalamKota(e.target.value)}
                        validate={{
                          required: { value: true },
                          number: true,
                        }}
                        min={1}
                        value={biayaDalamKota}
                      />
                    </Col>
                    <Col className="col-12 mb-3">
                      <AvField
                        name="biaya_luar_kota"
                        label="Biaya Luar dalam Kota"
                        type="number"
                        errorMessage="Invalid biaya tambahan"
                        onChange={e => setBiayaLuarKota(e.target.value)}
                        validate={{
                          required: { value: true },
                          number: true,
                        }}
                        min={1}
                        value={biayaLuarKota}
                      />
                    </Col>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-outline-success simpan-biaya"
                        >
                          Simpan
                        </button>
                      </div>
                    </Col>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

AdditionalConfig.propTypes = {
  dataConfig: PropTypes.object,
  onGetDataConfig: PropTypes.func,
  onUpdateConfig: PropTypes.func,
}

const mapStateToProps = ({ dataConfigReducer }) => ({
  dataConfig: dataConfigReducer.dataConfig,
})

const mapDispatchToProps = dispatch => ({
  onGetDataConfig: config => dispatch(getDataConfig(config)),
  onUpdateConfig: config => dispatch(updateConfig(config)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalConfig)
