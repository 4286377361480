import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//dataBarang
import dataBarangReducer from "./barang/reducer"
import dataPaketKhitanReducer from "./paketKhitan/reducer"
import dataArtikelReducer from "./artikel/reducer"
import dataBookingReducer from "./booking/reducer"
import dataUserReducer from "./user/reducer"
import dataNotifReducer from "./notif/reducer"
import ioNotifReducer from "./ioNotif/reducer"
import dataDashboardReducer from "./dashboard/reducer"
import dataCarouselReducer from "./carousel/reducer"
import konsultasiReducer from "./konsultasi/reducer"
import dataRekeningReducer from "./rekening/reducer"
import dataPengeluaranReducer from "./pengeluaran/reducer"
import dataAffiliasiReducer from "./affiliasi/reducer"
import dataLevelAffiliasiReducer from "./levelAffiliasi/reducer"
import dataTarikDanaReducer from "./tarikDana/reducer"
import dataPresensiReducer from "./presensi/reducer"
import dataVideoCallReducer from "./videoCall/reducer"
import dataListTaskReducer from "./listTask/reducer"
import dataTemukanMetodeReducer from "./temukanMetode/reducer"
import dataNeracaReducer from "./neraca/reducer"
import dataGajiReducer from "./gaji/reducer"
import dataConfigReducer from "./additionalConfig/reducer"
import dataVoucherReducer from "./voucher/reducer"
import loadingReducer from "./loading/reducer"
import dataReviewReducer from "./review/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  Profile,
  dataBarangReducer,
  dataPaketKhitanReducer,
  dataArtikelReducer,
  dataBookingReducer,
  dataUserReducer,
  dataNotifReducer,
  ioNotifReducer,
  dataDashboardReducer,
  dataCarouselReducer,
  konsultasiReducer,
  dataRekeningReducer,
  dataPengeluaranReducer,
  dataAffiliasiReducer,
  dataLevelAffiliasiReducer,
  dataTarikDanaReducer,
  dataPresensiReducer,
  dataVideoCallReducer,
  dataListTaskReducer,
  dataTemukanMetodeReducer,
  dataNeracaReducer,
  dataGajiReducer,
  dataConfigReducer,
  dataVoucherReducer,
  loadingReducer,
  dataReviewReducer,
})

export default (state, action) =>
  rootReducer(action.type === "LOGOUT_USER" ? undefined : state, action)
// export default rootReducer
