import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"

class PlatformChart extends Component {
  constructor(props) {
    super(props)
  }
  getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        x: "left",
        data: ["BackOffice", "Aplikasi"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#f8b425", "#3c4ccf"],
      series: [
        {
          name: "Total Booking",
          type: "pie",
          radius: ["35%", "55%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [
            { value: this.props.data[0], name: "BackOffice" },
            { value: this.props.data[1], name: "Aplikasi" },
          ],
        },
      ],
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "300px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}
export default PlatformChart
