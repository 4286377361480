import {
  GET_KONSULTASI,
  GET_KONSULTASI_SUCCESS,
  GET_KONSULTASI_FAIL,
  GET_UNREAD_KONSULTASI,
  GET_UNREAD_KONSULTASI_SUCCESS,
  GET_UNREAD_KONSULTASI_FAIL,
  GET_LIST_KONSULTASI,
  GET_LIST_KONSULTASI_SUCCESS,
  GET_LIST_KONSULTASI_FAIL,
  UPDATE_LIST_KONSULTASI,
  UPDATE_LIST_KONSULTASI_SUCCESS,
  UPDATE_LIST_KONSULTASI_FAIL,
  ADD_NEW_KONSULTASI,
  ADD_KONSULTASI_SUCCESS,
  ADD_KONSULTASI_FAIL,
  READ_KONSULTASI,
  READ_KONSULTASI_SUCCESS,
  READ_KONSULTASI_FAIL,
} from "./actionTypes"

export const getKonsultasi = () => ({
  type: GET_KONSULTASI,
})

export const getKonsultasiSuccess = konsultasi => ({
  type: GET_KONSULTASI_SUCCESS,
  payload: konsultasi,
})

export const getKonsultasiFail = error => ({
  type: GET_KONSULTASI_FAIL,
  payload: error,
})

export const getUnreadKonsultasi = () => ({
  type: GET_UNREAD_KONSULTASI,
})

export const getUnreadKonsultasiSuccess = konsultasi => ({
  type: GET_UNREAD_KONSULTASI_SUCCESS,
  payload: konsultasi,
})

export const getUnreadKonsultasiFail = error => ({
  type: GET_UNREAD_KONSULTASI_FAIL,
  payload: error,
})

export const getListKonsultasi = () => ({
  type: GET_LIST_KONSULTASI,
})

export const getListKonsultasiSuccess = listKonsultasi => ({
  type: GET_LIST_KONSULTASI_SUCCESS,
  payload: listKonsultasi,
})

export const getListKonsultasiFail = error => ({
  type: GET_LIST_KONSULTASI_FAIL,
  payload: error,
})

export const updateListKonsultasi = () => ({
  type: UPDATE_LIST_KONSULTASI,
})

export const updateListKonsultasiSuccess = listKonsultasi => ({
  type: UPDATE_LIST_KONSULTASI_SUCCESS,
  payload: listKonsultasi,
})

export const updateListKonsultasiFail = error => ({
  type: UPDATE_LIST_KONSULTASI_FAIL,
  payload: error,
})

export const addNewKonsultasi = konsultasi => ({
  type: ADD_NEW_KONSULTASI,
  payload: konsultasi,
})

export const addKonsultasiSuccess = konsultasi => ({
  type: ADD_KONSULTASI_SUCCESS,
  payload: konsultasi,
})

export const addKonsultasiFail = error => ({
  type: ADD_KONSULTASI_FAIL,
  payload: error,
})

export const readKonsultasi = konsultasi => ({
  type: READ_KONSULTASI,
  payload: konsultasi,
})

export const readKonsultasiSuccess = konsultasi => ({
  type: READ_KONSULTASI_SUCCESS,
  payload: konsultasi,
})

export const readKonsultasiFail = error => ({
  type: READ_KONSULTASI_FAIL,
  payload: error,
})
