import {
  GET_DATA_TARIK_DANA_SUCCESS,
  GET_DATA_TARIK_DANA_FAIL,
  UPDATE_TARIK_DANA_SUCCESS,
  UPDATE_TARIK_DANA_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataTarikDana: [],
  error: {},
}

const DataTarikDanaReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_TARIK_DANA_SUCCESS:
      return {
        ...state,
        dataTarikDana: action.payload,
      }

    case GET_DATA_TARIK_DANA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TARIK_DANA_SUCCESS:
      return {
        ...state,
        dataTarikDana: state.dataTarikDana.map(tarikDana =>
          tarikDana._id === action.payload._id
            ? {
                ...tarikDana,
                status: action.payload.status,
                nominal: action.payload.nominal,
                image: action.payload.image,
              }
            : tarikDana
        ),
      }

    case UPDATE_TARIK_DANA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default DataTarikDanaReducer
