import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
// import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const { dataNotif, unreadKonsultasi } = props
  const ref = useRef()

  let userRole = JSON.parse(localStorage.getItem("authUser")).role
  let dataAdmin =
    JSON.parse(localStorage.getItem("authUser")).data_karyawan || ""
  const [totalNotifBooking, SetTotalNotifBooking] = useState(0)
  const [totalNotifVideoCall, SetTotalNotifVideoCall] = useState(0)
  const [totalNotifTarikDana, SetTotalNotifTarikDana] = useState(0)
  const [totalNotifBarang, SetTotalNotifBarang] = useState(0)

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  useEffect(() => {
    SetTotalNotifBooking(
      dataNotif.filter(item => item.type === "booking" && !item.isRead).length
    )
    SetTotalNotifVideoCall(
      dataNotif.filter(item => item.type === "video-call" && !item.isRead)
        .length
    )
    SetTotalNotifTarikDana(
      dataNotif.filter(item => item.type === "tarik-dana" && !item.isRead)
        .length
    )
    SetTotalNotifBarang(
      dataNotif.filter(item => item.type === "barang_habis" && !item.isRead)
        .length
    )
  }, [dataNotif])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {(userRole === "superAdmin" || userRole === "admin") && (
              <>
                <li className="menu-title">Menu Utama </li>
                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="fas fa-home"></i>
                    {/* <span className="badge rounded-pill bg-primary float-end">
                  2
                </span> */}
                    <span>Dashboard</span>
                  </Link>
                </li>
              </>
            )}

            {userRole === "superAdmin" && (
              <>
                <li>
                  <Link to="/neraca" className=" waves-effect">
                    <i className="fas fa-balance-scale"></i>
                    <span>Neraca Laba Rugi</span>
                  </Link>
                </li>
                <li>
                  <Link to="/paketKhitan" className=" waves-effect">
                    <i className="fas fa-list-ul"></i>
                    <span>Paket Khitan</span>
                  </Link>
                </li>
                <li>
                  <Link to="voucher" className=" waves-effect">
                    <i className="fas fa-ticket-alt"></i>
                    <span>Voucher</span>
                  </Link>
                </li>
                <li>
                  <Link to="/levelAffiliasi" className=" waves-effect">
                    <i className="fas fa-trophy"></i>
                    <span>Level Affiliasi</span>
                  </Link>
                </li>
                {/* enable transfer */}
                {/* <li>
                  <Link to="/rekening" className=" waves-effect">
                    <i className="ti-credit-card"></i>
                    <span>Data Rekening")}</span>
                  </Link>
                </li> */}
              </>
            )}

            {(userRole === "superAdmin" || userRole === "admin") && (
              <>
                <li className="menu-title">Konsultasi </li>
                <li>
                  <Link to="/konsultasi" className="waves-effect">
                    <i className="fas fa-comments"></i>
                    {/* <span className="badge rounded-pill bg-danger float-end">
                  2
                </span> */}
                    {unreadKonsultasi && unreadKonsultasi > 0 ? (
                      <span className="badge rounded-pill bg-danger float-end">
                        {unreadKonsultasi}
                      </span>
                    ) : (
                      <span className="badge rounded-pill bg-primary float-end">
                        {unreadKonsultasi}
                      </span>
                    )}
                    <span>Chat Konsultasi</span>
                  </Link>
                </li>
              </>
            )}

            {(userRole === "gudang" ||
              userRole === "superAdmin" ||
              userRole === "admin") && (
              <>
                <li className="menu-title">Data Klinik</li>
                {(userRole === "superAdmin" || userRole === "admin") && (
                  <>
                    <li>
                      <Link to="/user" className=" waves-effect">
                        <i className="fas fa-user"></i>
                        <span>Data User</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/booking" className=" waves-effect">
                        <i className="fas fa-receipt"></i>
                        {totalNotifBooking && totalNotifBooking > 0 ? (
                          <span className="badge rounded-pill bg-danger float-end">
                            {totalNotifBooking}
                          </span>
                        ) : (
                          <span className="badge rounded-pill bg-primary float-end">
                            {totalNotifBooking}
                          </span>
                        )}
                        <span>Booking</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/videoCall" className=" waves-effect">
                        <i className="fas fa-video"></i>
                        {totalNotifVideoCall && totalNotifVideoCall > 0 ? (
                          <span className="badge rounded-pill bg-danger float-end">
                            {totalNotifVideoCall}
                          </span>
                        ) : (
                          <span className="badge rounded-pill bg-primary float-end">
                            {totalNotifVideoCall}
                          </span>
                        )}
                        <span>Janji Video Call</span>
                      </Link>
                    </li>
                  </>
                )}
                {userRole === "superAdmin" && (
                  <>
                    <li>
                      <Link to="/tarikDana" className=" waves-effect">
                        <i className="fas fa-donate"></i>
                        {totalNotifTarikDana && totalNotifTarikDana > 0 ? (
                          <span className="badge rounded-pill bg-danger float-end">
                            {totalNotifTarikDana}
                          </span>
                        ) : (
                          <span className="badge rounded-pill bg-primary float-end">
                            {totalNotifTarikDana}
                          </span>
                        )}
                        <span>Tarik Dana Affiliasi</span>
                      </Link>
                    </li>
                  </>
                )}

                {(userRole === "gudang" || userRole === "superAdmin") && (
                  <>
                    <li>
                      <Link to="/barang" className=" waves-effect">
                        <i className="fas fa-box"></i>
                        {totalNotifBarang && totalNotifBarang > 0 ? (
                          <span className="badge rounded-pill bg-danger float-end">
                            {totalNotifBarang}
                          </span>
                        ) : (
                          <span className="badge rounded-pill bg-primary float-end">
                            {totalNotifBarang}
                          </span>
                        )}
                        <span>Data Barang</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/pengeluaran" className=" waves-effect">
                        <i className="fas fa-dollar-sign"></i>
                        <span>Data Pengeluaran</span>
                      </Link>
                    </li>
                  </>
                )}
              </>
            )}

            {(userRole === "admin" &&
              dataAdmin?.akses_klinik === "singosari") ||
            userRole === "superAdmin" ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-hospital"></i>
                    <span>Klinik Singosari</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link to="/barang-singosari" className=" waves-effect">
                        <span>Data Barang</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/pengeluaran-singosari"
                        className=" waves-effect"
                      >
                        <span>Data Pengeluaran</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {(userRole === "admin" &&
              dataAdmin?.akses_klinik === "joyoagung") ||
            userRole === "superAdmin" ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="far fa-hospital"></i>
                    <span>Klinik Joyoagung</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link to="/barang-joyoagung" className=" waves-effect">
                        <span>Data Barang</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/pengeluaran-joyoagung"
                        className=" waves-effect"
                      >
                        <span>Data Pengeluaran</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {userRole === "dokter" && (
              <>
                <li>
                  <Link to="/konsultasi" className="waves-effect">
                    <i className="fas fa-comments"></i>
                    {/* <span className="badge rounded-pill bg-danger float-end">
                  2
                </span> */}
                    {unreadKonsultasi && unreadKonsultasi > 0 ? (
                      <span className="badge rounded-pill bg-danger float-end">
                        {unreadKonsultasi}
                      </span>
                    ) : (
                      <span className="badge rounded-pill bg-primary float-end">
                        {unreadKonsultasi}
                      </span>
                    )}
                    <span>Chat Konsultasi</span>
                  </Link>
                </li>
                <li>
                  <Link to="/booking" className=" waves-effect">
                    <i className="fas fa-receipt"></i>
                    {totalNotifBooking && totalNotifBooking > 0 ? (
                      <span className="badge rounded-pill bg-danger float-end">
                        {totalNotifBooking}
                      </span>
                    ) : (
                      <span className="badge rounded-pill bg-primary float-end">
                        {totalNotifBooking}
                      </span>
                    )}
                    <span>Booking</span>
                  </Link>
                </li>
                <li>
                  <Link to="/videoCall" className=" waves-effect">
                    <i className="fas fa-video"></i>
                    {totalNotifVideoCall && totalNotifVideoCall > 0 ? (
                      <span className="badge rounded-pill bg-danger float-end">
                        {totalNotifVideoCall}
                      </span>
                    ) : (
                      <span className="badge rounded-pill bg-primary float-end">
                        {totalNotifVideoCall}
                      </span>
                    )}
                    <span>Janji Video Call</span>
                  </Link>
                </li>
              </>
            )}

            {userRole === "superAdmin" && (
              <>
                <li className="menu-title">Karyawan</li>

                <li>
                  <Link to="/presensi" className=" waves-effect">
                    <i className="fas fa-users"></i>
                    <span>Presensi & Task</span>
                  </Link>
                </li>
                <li>
                  <Link to="/review" className=" waves-effect">
                    <i className="fas fa-heart"></i>
                    <span>Review</span>
                  </Link>
                </li>
                <li>
                  <Link to="/gaji" className=" waves-effect">
                    <i className="fas fa-money-check-alt"></i>
                    <span>Gaji</span>
                  </Link>
                </li>
              </>
            )}

            {(userRole === "superAdmin" || userRole === "admin") && (
              <>
                <li className="menu-title">Konten Aplikasi</li>

                <li>
                  <Link to="/artikel" className=" waves-effect">
                    <i className="fas fa-file-alt"></i>
                    <span>Artikel</span>
                  </Link>
                </li>

                <li>
                  <Link to="/carousel" className=" waves-effect">
                    <i className="fas fa-images"></i>
                    <span>Carousel Banner</span>
                  </Link>
                </li>
              </>
            )}

            {userRole === "superAdmin" && (
              <>
                <li>
                  <Link to="/listTask" className=" waves-effect">
                    <i className="fas fa-clipboard-check"></i>
                    <span>Checklist Task</span>
                  </Link>
                </li>
                <li>
                  <Link to="/temukanMetode" className=" waves-effect">
                    <i className="fas fa-search"></i>
                    <span>Temukan Metode</span>
                  </Link>
                </li>

                <li className="menu-title">Lainnya</li>

                <li>
                  <Link to="/pengaturanTambahan" className=" waves-effect">
                    <i className="fas fa-cog"></i>
                    <span>Pengaturan Tambahan</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  dataNotif: PropTypes.array,
  unreadKonsultasi: PropTypes.any,
}

const mapStateToProps = ({
  dataNotifReducer,
  ioNotifReducer,
  konsultasiReducer,
}) => ({
  dataNotif: dataNotifReducer.dataNotif,
  unreadKonsultasi: konsultasiReducer.unread,
})

const mapDispatchToProps = dispatch => ({})

// export default withRouter(withTranslation()(SidebarContent))
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SidebarContent))
