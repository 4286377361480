import {
  GET_DATA_NERACA_SUCCESS,
  GET_DATA_NERACA_FAIL,
  FILTER_DATA_NERACA_SUCCESS,
  FILTER_DATA_NERACA_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataNeraca: {},
  error: {},
}

const DataNeracaReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_NERACA_SUCCESS:
      return {
        ...state,
        dataNeraca: action.payload,
      }

    case GET_DATA_NERACA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case FILTER_DATA_NERACA_SUCCESS:
      return {
        ...state,
        dataNeraca: action.payload,
      }

    case FILTER_DATA_NERACA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default DataNeracaReducer
