import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  addNewTemukanMetode,
  deleteTemukanMetode,
  getDataTemukanMetode,
  updateTemukanMetode,
  getDataPaketKhitan,
} from "../../store/actions"

const TemukanMetode = props => {
  const {
    onGetDataTemukanMetode,
    dataTemukanMetode,
    onGetDataPaketKhitan,
    dataPaketKhitan,
  } = props
  const [modal, setModal] = useState(false)
  const [temukanMetode, setTemukanMetode] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  useEffect(() => {
    if (dataTemukanMetode && dataPaketKhitan) {
      dataTemukanMetode.map((item, index) => {
        item.action = (
          <Button
            color="primary"
            outline
            className="font-16 btn-block btn-sm"
            onClick={e => handleOpenModal(item)}
          >
            Ubah
          </Button>
        )
        item.metode_primary = dataPaketKhitan.map(el => {
          return el._id === item.data_metode[0] && el.metode + " - " + el.nama
        })
        item.metode_secondary = dataPaketKhitan.map(el => {
          return el._id === item.data_metode[1] && el.metode + " - " + el.nama
        })
      })
    }

    if (!modal && !isEmpty(temukanMetode) && !!isEdit) {
      // setTimeout(() => {
      setTemukanMetode({})
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, temukanMetode, dataTemukanMetode, dataPaketKhitan])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOpenModal = data => {
    // console.log("data", data)
    if (data) {
      // console.log("data exist")
      setTemukanMetode({
        _id: data._id,
        usia: data.usia,
        data_metode: data.data_metode,
        data_penyulit: data.data_penyulit,
      })
      setIsEdit(true)
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  useEffect(() => {
    onGetDataTemukanMetode()
    onGetDataPaketKhitan()
  }, [])

  const handleValidTemukanMetodeSubmit = (e, values) => {
    const { onAddNewTemukanMetode, onUpdateTemukanMetode } = props

    if (isEdit) {
      const updateTemukanMetode = {
        id: temukanMetode._id,
        data_metode: [values.metodePrimary, values.metodeSecondary],
        data_penyulit: {
          fimosis: values.fimosis,
          autis: values.autis,
          gemuk: values.gemuk,
        },
      }
      onUpdateTemukanMetode(updateTemukanMetode)
    } else {
      const newTemukanMetode = {
        usia: values.usia,
        data_metode: [values.metodePrimary, values.metodeSecondary],
        data_penyulit: {
          fimosis: values.fimosis,
          autis: values.autis,
          gemuk: values.gemuk,
        },
      }
      onAddNewTemukanMetode(newTemukanMetode)
    }
    toggle()
  }

  const handleDeleteTemukanMetode = () => {
    const { onDeleteTemukanMetode } = props
    onDeleteTemukanMetode(temukanMetode._id)
    setDeleteModal(false)
    toggle()
  }

  const data = {
    columns: [
      {
        label: "Usia",
        field: "usia",
        sort: "asc",
        width: 150,
      },
      {
        label: "Metode Primary",
        field: "metode_primary",
        sort: "asc",
        width: 150,
      },
      {
        label: "Metode Secondary",
        field: "metode_secondary",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "data_penyulit",
      //   field: "data_penyulit",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: dataTemukanMetode,
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTemukanMetode}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Form Metode | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs
                    breadcrumbItem="Form Metode"
                    handleOpenModal={handleOpenModal}
                    buttonModal="Form Metode"
                  />
                  {/* <CardTitle className="h4">Data TemukanMetode</CardTitle>
                  <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable modal-lg"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Form Metode" : "Tambah Form Metode"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidTemukanMetodeSubmit}>
                <Row form>
                  <Col className="col-12 d-flex mb-3">
                    <Col className="col-4">
                      <AvField
                        name="usia"
                        placeholder="Ex: 1 Bulan / 1-3 Tahun"
                        label="Usia"
                        type="text"
                        errorMessage="Invalid usia"
                        validate={{
                          required: { value: true },
                        }}
                        value={temukanMetode ? temukanMetode.usia : ""}
                      />
                    </Col>
                    <Col className="col-4 ps-4 ">
                      <AvField
                        type="select"
                        name="metodePrimary"
                        label="Metode Primary"
                        validate={{
                          required: { value: true },
                        }}
                        value={
                          temukanMetode && temukanMetode.data_metode
                            ? temukanMetode.data_metode[0]
                            : ""
                        }
                      >
                        <option hidden value="">
                          -- Pilih Metode Primary --
                        </option>
                        {dataPaketKhitan &&
                          dataPaketKhitan.map((item, i) => (
                            <option key={item._id} value={item._id}>
                              {item.metode} - {item.nama}
                            </option>
                          ))}
                      </AvField>
                    </Col>
                    <Col className="col-4 ps-4 ">
                      <AvField
                        type="select"
                        name="metodeSecondary"
                        label="Metode Secondary"
                        validate={{
                          required: { value: true },
                        }}
                        value={
                          temukanMetode && temukanMetode.data_metode
                            ? temukanMetode.data_metode[1]
                            : ""
                        }
                      >
                        <option hidden value="">
                          -- Pilih Metode Secondary --
                        </option>
                        {dataPaketKhitan &&
                          dataPaketKhitan.map((item, i) => (
                            <option key={item._id} value={item._id}>
                              {item.metode} - {item.nama}
                            </option>
                          ))}
                      </AvField>
                    </Col>
                  </Col>
                  <br />
                  <br />
                  <h4>Data Penyulit</h4>
                  <br />
                  <Col className="col-12 d-flex mb-3">
                    <Col className="col-4 ">
                      <AvField
                        type="select"
                        name="fimosis"
                        label="Fimosis"
                        value={
                          temukanMetode && temukanMetode.data_penyulit
                            ? temukanMetode.data_penyulit.fimosis
                            : ""
                        }
                      >
                        <option value="">-- Ikut Usia --</option>
                        {dataPaketKhitan &&
                          dataPaketKhitan.map((item, i) => (
                            <option key={item._id} value={item._id}>
                              {item.metode} - {item.nama}
                            </option>
                          ))}
                      </AvField>
                    </Col>
                    <Col className="col-4 ps-4 ">
                      <AvField
                        type="select"
                        name="autis"
                        label="ABK / Autis"
                        value={
                          temukanMetode && temukanMetode.data_penyulit
                            ? temukanMetode.data_penyulit.autis
                            : ""
                        }
                      >
                        <option value="">-- Ikut Usia --</option>
                        {dataPaketKhitan &&
                          dataPaketKhitan.map((item, i) => (
                            <option key={item._id} value={item._id}>
                              {item.metode} - {item.nama}
                            </option>
                          ))}
                      </AvField>
                    </Col>
                    <Col className="col-4 ps-4 ">
                      <AvField
                        type="select"
                        name="gemuk"
                        label="Gemuk"
                        value={
                          temukanMetode && temukanMetode.data_penyulit
                            ? temukanMetode.data_penyulit.gemuk
                            : ""
                        }
                      >
                        <option value="">-- Ikut Usia --</option>
                        {dataPaketKhitan &&
                          dataPaketKhitan.map((item, i) => (
                            <option key={item._id} value={item._id}>
                              {item.metode} - {item.nama}
                            </option>
                          ))}
                      </AvField>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={toggle}
                      >
                        Tutup
                      </button>
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-outline-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        type="submit"
                        className="btn btn-outline-success save-temukanMetode"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

TemukanMetode.propTypes = {
  dataTemukanMetode: PropTypes.array,
  onGetDataTemukanMetode: PropTypes.func,
  onAddNewTemukanMetode: PropTypes.func,
  onUpdateTemukanMetode: PropTypes.func,
  onDeleteTemukanMetode: PropTypes.func,
  dataPaketKhitan: PropTypes.array,
  onGetDataPaketKhitan: PropTypes.func,
}

const mapStateToProps = ({
  dataPaketKhitanReducer,
  dataTemukanMetodeReducer,
}) => ({
  dataTemukanMetode: dataTemukanMetodeReducer.dataTemukanMetode,
  dataPaketKhitan: dataPaketKhitanReducer.dataPaketKhitan,
})

const mapDispatchToProps = dispatch => ({
  onGetDataTemukanMetode: () => dispatch(getDataTemukanMetode()),
  onGetDataPaketKhitan: () => dispatch(getDataPaketKhitan()),
  onAddNewTemukanMetode: temukanMetode =>
    dispatch(addNewTemukanMetode(temukanMetode)),
  onUpdateTemukanMetode: temukanMetode =>
    dispatch(updateTemukanMetode(temukanMetode)),
  onDeleteTemukanMetode: temukanMetode =>
    dispatch(deleteTemukanMetode(temukanMetode)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TemukanMetode)
