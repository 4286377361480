import {
  GET_DATA_REKENING_SUCCESS,
  GET_DATA_REKENING_FAIL,
  ADD_REKENING_SUCCESS,
  ADD_REKENING_FAIL,
  UPDATE_REKENING_SUCCESS,
  UPDATE_REKENING_FAIL,
  DELETE_REKENING_SUCCESS,
  DELETE_REKENING_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataRekening: [],
  error: {},
}

const DataRekeningReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_REKENING_SUCCESS:
      return {
        ...state,
        dataRekening: action.payload,
      }

    case GET_DATA_REKENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_REKENING_SUCCESS:
      return {
        ...state,
        dataRekening: [...state.dataRekening, action.payload],
      }

    case ADD_REKENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_REKENING_SUCCESS:
      return {
        ...state,
        dataRekening: state.dataRekening.map(rekening =>
          rekening._id === action.payload._id
            ? { rekening, ...action.payload }
            : rekening
        ),
      }

    case UPDATE_REKENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_REKENING_SUCCESS:
      return {
        ...state,
        dataRekening: state.dataRekening.filter(
          rekening => rekening._id !== action.payload._id
        ),
      }

    case DELETE_REKENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default DataRekeningReducer
