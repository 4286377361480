import { post, del, get, put, postPdf } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

//AUTH
const postLogin = data => post(url.POST_LOGIN, data)

//DATA USER
export const getDataUser = () => get(url.GET_DATA_USER)
export const getDataKaryawan = () => get(url.GET_DATA_KARYAWAN)
export const addNewUser = user =>
  post(url.ADD_NEW_USER, user, {
    headers: { "content-type": "multipart/form-data" },
  })
export const searchUser = user =>
  post(url.SEARCH_USER, user)
export const updateUser = user =>
  put(url.UPDATE_USER, user, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })
export const deleteUser = user =>
  del(url.DELETE_USER, { headers: { "X-Request-ID": user } })

//DATA BARANG
export const getDataBarang = () => get(url.GET_DATA_BARANG)
export const addNewBarang = barang => post(url.ADD_NEW_BARANG, barang)
export const updateBarang = barang => put(url.UPDATE_BARANG, barang)
export const deleteBarang = barang =>
  del(url.DELETE_BARANG, { headers: { "X-Request-ID": barang } })
export const getKategoriBarang = () => get(url.GET_KATEGORI_BARANG)

//DATA PAKET KHITAN
export const getDataPaketKhitan = () => get(url.GET_DATA_PAKET_KHITAN)

export const addNewPaketKhitan = paket =>
  post(url.ADD_NEW_PAKET_KHITAN, paket, {
    headers: { "content-type": "multipart/form-data" },
  })
export const updatePaketKhitan = paket =>
  put(url.UPDATE_PAKET_KHITAN, paket, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })
export const deletePaketKhitan = paket =>
  del(url.DELETE_PAKET_KHITAN, { headers: { "X-Request-ID": paket } })

//DATA ARTIKEL
export const getDataArtikel = () => get(url.GET_DATA_ARTIKEL)
export const addNewArtikel = artikel =>
  post(url.ADD_NEW_ARTIKEL, artikel, {
    headers: { "content-type": "multipart/form-data" },
  })
export const updateArtikel = artikel =>
  put(url.UPDATE_ARTIKEL, artikel, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })
export const deleteArtikel = artikel =>
  del(url.DELETE_ARTIKEL, { headers: { "X-Request-ID": artikel } })
export const getKategoriArtikel = () => get(url.GET_KATEGORI_ARTIKEL)

//DATA Booking
export const getDataBooking = () => get(url.GET_DATA_BOOKING)
export const addNewBooking = booking =>
  post(url.ADD_NEW_BOOKING, booking, {
    headers: { "content-type": "multipart/form-data" },
  })
export const getDataBookingFilter = booking =>
  post(url.GET_DATA_BOOKING_FILTER, booking)
export const updateBooking = booking =>
  put(url.UPDATE_BOOKING, booking, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })
export const exportBooking = booking => postPdf(url.EXPORT_BOOKING, booking)
export const exportSertifikat = booking =>
  postPdf(url.EXPORT_SERTIFIKAT, booking)
// export const exportBooking = booking =>
//   postflat(url.EXPORT_BOOKING, booking, {
//     headers: {
//       "responseType": "blob",
//     },
//   })

export const deleteBooking = booking =>
  del(url.DELETE_BOOKING, { headers: { "X-Request-ID": booking } })


//DATA NOTIF
export const getDataNotif = () => get(url.GET_DATA_NOTIF)
export const updateNotif = notif => put(url.UPDATE_NOTIF, notif)

//DATA DASHBOARD
export const getDataDashboard = () => get(url.GET_DATA_DASHBOARD)
export const filterDataDashboard = data => post(url.FILTER_DATA_DASHBOARD, data)

//DATA CAROUSEL
export const getDataCarousel = () => get(url.GET_DATA_CAROUSEL)
export const addNewCarousel = carousel =>
  post(url.ADD_NEW_CAROUSEL, carousel, {
    headers: { "content-type": "multipart/form-data" },
  })
export const updateCarousel = carousel =>
  put(url.UPDATE_CAROUSEL, carousel, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })
export const deleteCarousel = carousel =>
  del(url.DELETE_CAROUSEL, { headers: { "X-Request-ID": carousel } })

//DATA KONSULTASI
export const getListKonsultasi = () => get(url.GET_LIST_KONSULTASI)
export const getUnreadKonsultasi = () => get(url.GET_UNREAD_KONSULTASI)
export const addNewKonsultasi = konsultasi =>
  post(url.ADD_NEW_KONSULTASI, konsultasi, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })
export const readKonsultasi = konsultasi => put(url.READ_KONSULTASI, konsultasi)

//DATA REKENING
export const getDataRekening = () => get(url.GET_DATA_REKENING)
export const addNewRekening = rekening => post(url.ADD_NEW_REKENING, rekening)
export const updateRekening = rekening => put(url.UPDATE_REKENING, rekening)
export const deleteRekening = rekening =>
  del(url.DELETE_REKENING, { headers: { "X-Request-ID": rekening } })

//DATA PENGELUARAN
export const getDataPengeluaran = () => get(url.GET_DATA_PENGELUARAN)
export const addNewPengeluaran = pengeluaran =>
  post(url.ADD_NEW_PENGELUARAN, pengeluaran, {
    headers: { "content-type": "multipart/form-data" },
  })
export const updatePengeluaran = pengeluaran =>
  put(url.UPDATE_PENGELUARAN, pengeluaran, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })
export const deletePengeluaran = pengeluaran =>
  del(url.DELETE_PENGELUARAN, { headers: { "X-Request-ID": pengeluaran } })

//DATA AFFILIASI
export const getDataAffiliasi = () => get(url.GET_DATA_AFFILIASI)
//DATA LEVEL AFFILISI
export const getDataLevelAffiliasi = () => get(url.GET_DATA_LEVEL_AFFILIASI)
export const addNewLevelAffiliasi = levelAffiliasi =>
  post(url.ADD_NEW_LEVEL_AFFILIASI, levelAffiliasi)
export const updateLevelAffiliasi = levelAffiliasi =>
  put(url.UPDATE_LEVEL_AFFILIASI, levelAffiliasi)
export const deleteLevelAffiliasi = levelAffiliasi =>
  del(url.DELETE_LEVEL_AFFILIASI, {
    headers: { "X-Request-ID": levelAffiliasi },
  })
//DATA TARIK DANA
export const getDataTarikDana = () => get(url.GET_DATA_TARIK_DANA)
export const updateTarikDana = tarikDana =>
  put(url.UPDATE_TARIK_DANA, tarikDana, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })
//DATA PRESENSI
// export const getDataPresensi = (keyword) => post(url.GET_DATA_PRESENSI, keyword)
export const getDataPresensi = keyword => post(url.GET_DATA_PRESENSI, keyword)
//DATA VIDEO CALL
export const getDataVideoCall = () => get(url.GET_DATA_VIDEO_CALL)
export const addNewVideoCall = videoCall =>
  post(url.ADD_NEW_VIDEO_CALL, videoCall)
export const updateVideoCall = videoCall =>
  put(url.UPDATE_VIDEO_CALL, videoCall, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })

//DATA LIST TASK
export const getDataListTask = () => get(url.GET_DATA_LIST_TASK)
export const addNewListTask = task => post(url.ADD_NEW_LIST_TASK, task)
export const updateListTask = task => put(url.UPDATE_LIST_TASK, task)
export const deleteListTask = task =>
  del(url.DELETE_LIST_TASK, { headers: { "X-Request-ID": task } })

//DATA TEMUKAN METODE
export const getDataTemukanMetode = () => get(url.GET_DATA_TEMUKAN_METODE)
export const addNewTemukanMetode = metode =>
  post(url.ADD_NEW_TEMUKAN_METODE, metode)
export const updateTemukanMetode = metode =>
  put(url.UPDATE_TEMUKAN_METODE, metode)
export const deleteTemukanMetode = metode =>
  del(url.DELETE_TEMUKAN_METODE, { headers: { "X-Request-ID": metode } })

//DATA NERACA
export const getDataNeraca = () => get(url.GET_DATA_NERACA)
export const filterDataNeraca = data => post(url.FILTER_DATA_NERACA, data)

//DATA GAJI
export const getDataGaji = () => get(url.GET_DATA_GAJI)
export const addNewGaji = gaji =>
  post(url.ADD_NEW_GAJI, gaji, {
    headers: { "content-type": "multipart/form-data" },
  })
export const updateGaji = gaji =>
  put(url.UPDATE_GAJI, gaji, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })
export const deleteGaji = gaji =>
  del(url.DELETE_GAJI, { headers: { "X-Request-ID": gaji } })

//DATA CONFIG
export const getDataConfig = config => post(url.GET_DATA_CONFIG, config)
export const updateConfig = config => put(url.UPDATE_CONFIG, config)

//DATA VOUCHER
export const getDataVoucher = () => get(url.GET_DATA_VOUCHER)
export const addNewVoucher = voucher =>
  post(url.ADD_NEW_VOUCHER, voucher, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })
export const updateVoucher = voucher =>
  put(url.UPDATE_VOUCHER, voucher, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })
export const checkVoucher = voucher => post(url.CHECK_VOUCHER, voucher)
export const deleteVoucher = voucher =>
  del(url.DELETE_VOUCHER, { headers: { "X-Request-ID": voucher } })

//DATA REVIEW
export const getDataReview = keyword => post(url.GET_DATA_REVIEW, keyword)

////
////
// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)
// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)
const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)
// Login Method
// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtForgetPwd,
  postJwtProfile,
}
