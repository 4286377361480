import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { env } from "../../helpers/config"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import moment from "moment"
//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import {
  getDataPengeluaran,
  getDataBarang,
  getKategoriBarang,
  addNewPengeluaran,
  updatePengeluaran,
  deletePengeluaran,
} from "../../store/actions"

const Pengeluaran = props => {
  const {
    onGetDataPengeluaran,
    dataPengeluaran,
    onGetDataBarang,
    dataBarang,
    onGetKategoriBarang,
    kategoriBarang,
  } = props
  const [modal, setModal] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [pengeluaran, setPengeluaran] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [invoice, setInvoice] = useState([])
  const [errImage, setErrImage] = useState({})
  const [tipePengeluaran, setTipePengeluaran] = useState()
  const [restockLainnya, setRestockLainnya] = useState(false)

  const [dataBarangSelect, setDataBarangSelect] = useState([])
  const [showSelectedBarang, setShowSelectedBarang] = useState({
    label: "-- Cari & Pilih Barang --",
  })

  const [dataForm, setDataForm] = useState([])
  const [selectedBarang, setSelectedBarang] = useState({})
  const [namaBarangLainnya, setNamaBarangLainnya] = useState()
  const [jumlah, setJumlah] = useState()
  const [harga, setHarga] = useState()
  const [isKatBarangLainnya, setIsKatBarangLainnya] = useState(false)
  const [katBarang, setKatBarang] = useState("")
  const [katBarangLainnya, setKatBarangLainnya] = useState("")
  const [catatan, setCatatan] = useState("")

  const [photoIndex, setphotoIndex] = useState(0)
  const [isGallery, setisGallery] = useState(false)

  const [dataPengeluaranSingosari, setdataPengeluaranSingosari] = useState([])

  useEffect(() => {
    if (dataPengeluaran) {
      dataPengeluaran.map((item, index) => {
        let total_pemasukan_single = 0
        let total_pengeluaran_single = 0

        item.data_barang.reduce((acc, obj) => {
          let subtotal = obj.harga * obj.jumlah
          if (obj.tipe === "pemasukan") {
            total_pemasukan_single += subtotal
          } else {
            total_pengeluaran_single += subtotal
          }
        }, 0)

        let total_single = total_pemasukan_single - total_pengeluaran_single

        item.action = (
          <>
            <Button
              key={`detail-${index}`}
              color="success"
              outline
              className="font-16 btn-block btn-sm"
              onClick={e => handleOpenModalDetail(item)}
            >
              Detail
            </Button>{" "}
            {/* &nbsp;&nbsp;
            <Button
              key={index}
              color="primary"
              className="font-16 btn-block"
              onClick={e => handleOpenModal(item)}
            >
              <i className="mdi mdi-grease-pencil"></i>
            </Button> */}
          </>
        )
        item.invoice2 = item.invoice.map((img, i) => [
          <CardImg
            key={i}
            src={env.IMG_URL + img}
            alt="image-invoice"
            className="avatar-sm"
          />,
        ])
        item.total =
          "Rp." + total_single.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        item.tanggal = new Date(item.createdAt).toLocaleString("ID", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })
        item.note = item.catatan || "-"
      })

      setdataPengeluaranSingosari(
        dataPengeluaran.filter(item => item.klinik === "singosari")
      )
      // console.log(dataPengeluaran)
    }

    if (!modal && !isEmpty(pengeluaran) && !!isEdit) {
      // setTimeout(() => {
      setPengeluaran({})
      setDataForm([])
      setShowSelectedBarang({ label: "-- Cari & Pilih Barang --" })
      setSelectedBarang("")
      setNamaBarangLainnya("")
      setJumlah("")
      setHarga("")
      setCatatan("")
      setKatBarang("")
      setKatBarangLainnya("")
      setIsKatBarangLainnya(false)
      setRestockLainnya(false)
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, pengeluaran, dataPengeluaran, dataBarang, kategoriBarang])

  // useEffect(() => {
  // console.log(dataPengeluaran)
  // }, [dataPengeluaran])

  useEffect(() => {
    let optionSelect = []
    dataBarang.reduce((item, { _id, klinik, nama }) => {
      optionSelect.push({
        label: klinik,
        options: [{ value: _id, label: nama }],
      })
    }, {})

    optionSelect = Object.values(
      optionSelect.reduce((c, { label, options }) => {
        c[label] = c[label] || { label, options: [] }
        c[label].options = c[label].options.concat(
          Array.isArray(options) ? options : [options]
        )
        return c
      }, {})
    )

    if (tipePengeluaran === "restock") {
      optionSelect.push({
        label: "Lainnya",
        options: [
          {
            value: "lainnya",
            label: "Tambah Barang Lainnya",
          },
        ],
      })
    }
    setDataBarangSelect(optionSelect)
  }, [dataBarang, tipePengeluaran])

  const toggle = close => {
    if (close) {
      setInvoice([])
      setErrImage({})
      setDataForm([])
      setPengeluaran({})
      setSelectedBarang("")
      setShowSelectedBarang({ label: "-- Cari & Pilih Barang --" })
      setNamaBarangLainnya("")
      setJumlah("")
      setHarga("")
      setCatatan("")
      setKatBarang("")
      setKatBarangLainnya("")
      setIsKatBarangLainnya(false)
      setRestockLainnya(false)
    }
    setModal(!modal)
  }

  const toggleDetail = close => {
    // console.log(booking)
    setModalDetail(!modalDetail)
    if (close) {
      setInvoice([])
      setPengeluaran({})
    }
  }

  const handleOpenModal = data => {
    // console.log("data", data)
    if (data) {
      // console.log("data exist")
      setPengeluaran({
        _id: data._id,
        nama_barang: data.nama_barang,
        invoice: data.invoice,
        createdAt: data.createdAt,
      })
      setInvoice(data.image)
      setIsEdit(true)
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  const handleOpenModalDetail = data => {
    if (data) {
      setPengeluaran({
        _id: data._id,
        data_barang: data.data_barang,
        invoice: data.invoice,
        klinik: data.klinik,
        createdAt: data.createdAt,
      })

      setInvoice(data.invoice)
    }
    toggleDetail()
  }

  useEffect(() => {
    onGetDataPengeluaran()
    onGetDataBarang()
  }, [])

  useEffect(() => {
    onGetKategoriBarang()
  }, [isKatBarangLainnya, dataPengeluaran])

  const handleValidPengeluaranSubmit = (e, values) => {
    const { onAddNewPengeluaran, onUpdatePengeluaran } = props

    const formData = new FormData()
    formData.append("data_barang", JSON.stringify(dataForm))
    formData.append("klinik", "singosari")
    formData.append("catatan", catatan || "")
    invoice.map(img => {
      formData.append("invoice", img)
    })

    // if (Object.keys(invoice).length === 0 && !isEdit) {
    //   setErrImage({ ...errImage, invoice: "Gambar Invoice harus di upload.." })
    // } else {
    if (isEdit) {
      formData.append("_id", pengeluaran._id)
      onUpdatePengeluaran(formData)
    } else {
      onAddNewPengeluaran(formData)
    }
    // console.log(dataForm)
    setInvoice([])
    setDataForm([])
    toggle()
    // }
  }

  const handleDeletePengeluaran = () => {
    const { onDeletePengeluaran } = props
    onDeletePengeluaran(pengeluaran._id)
    setDeleteModal(false)
    setInvoice([])
    toggle()
  }

  function handleInvoice(files) {
    if (files.length > 5) {
      setErrImage({ invoice: "Maksimal upload 5 gambar" })
    } else {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setInvoice(files)
      setErrImage(delete errImage.invoice)
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleRestockLainnya = val => {
    setShowSelectedBarang(val)
    if (val.value === "lainnya") {
      setRestockLainnya(true)
      setSelectedBarang({ _id: val.value })
      setKatBarang("")
    } else {
      setRestockLainnya(false)
      let data = dataBarang.find(item => item._id === val.value)
      setSelectedBarang(data)
      setKatBarang(data.kategori)
      setKatBarangLainnya("")
    }
    // var prevSelectedBarang = { ...selectedBarang }
    // prevSelectedBarang = val.target.value
    // prevSelectedBarang.stock = val.target.value.stock
  }

  const handleTipePengeluaran = e => {
    setTipePengeluaran(e.target.value)
    setSelectedBarang({})
    setShowSelectedBarang({ label: "-- Cari & Pilih Barang --" })

    setRestockLainnya(false)
    setIsKatBarangLainnya(false)
    setKatBarangLainnya("")
    setNamaBarangLainnya("")
  }

  const handleAddData = () => {
    const data = {
      id_barang: selectedBarang._id === "lainnya" ? "" : selectedBarang._id,
      nama_barang:
        selectedBarang._id === "lainnya" ||
        tipePengeluaran === "pengeluaran" ||
        tipePengeluaran === "pemasukan"
          ? selectedBarang._id
          : dataBarang.find(item => item._id === selectedBarang._id).nama,

      nama_barang_lainnya:
        selectedBarang._id === "lainnya" ? namaBarangLainnya : "",

      kategori_barang:
        tipePengeluaran === "restock" &&
        selectedBarang._id === "lainnya" &&
        isKatBarangLainnya === true
          ? katBarangLainnya
          : tipePengeluaran === "restock" && isKatBarangLainnya === false
          ? katBarang
          : "",

      tipe: tipePengeluaran,
      jumlah: tipePengeluaran === "pemasukan" ? 1 : jumlah,
      harga: harga.toString().replace(/\./g, ""),
    }
    setDataForm([...dataForm, data])
    setSelectedBarang("")
    setShowSelectedBarang({ label: "-- Cari & Pilih Barang --" })
    setNamaBarangLainnya("")
    setJumlah("")
    setHarga("")
    setKatBarang("")
    setKatBarangLainnya("")
    setIsKatBarangLainnya(false)
    setRestockLainnya(false)
    setTipePengeluaran()
  }

  const handleKatBarang = val => {
    if (val.target.value === "lainnya") {
      setIsKatBarangLainnya(true)
    } else {
      setIsKatBarangLainnya(false)
    }
    setKatBarang(val.target.value)
  }

  const data = {
    columns: [
      {
        label: "Tanggal",
        field: "tanggal",
        sort: "asc",
      },
      {
        label: "Total",
        field: "total",
        sort: "asc",
      },
      {
        label: "Invoice",
        field: "invoice2",
        sort: "asc",
      },
      {
        label: "Catatan",
        field: "note",
        sort: "asc",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: dataPengeluaranSingosari,
  }

  const openLighboxInvoice = i => {
    setphotoIndex(i)
    setisGallery(true)
    setModalDetail(!modalDetail)
  }

  const closeLighboxInvoice = async () => {
    setphotoIndex(0)
    await setModalDetail(!modalDetail)
    setisGallery(false)
  }

  return (
    <React.Fragment>
      {isGallery && !isEmpty(invoice) ? (
        <Lightbox
          mainSrc={env.IMG_URL + invoice[photoIndex]}
          nextSrc={env.IMG_URL + invoice[(photoIndex + 1) % invoice.length]}
          prevSrc={
            env.IMG_URL +
            invoice[(photoIndex + invoice.length - 1) % invoice.length]
          }
          enableZoom={true}
          onCloseRequest={() => {
            closeLighboxInvoice()
          }}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + invoice.length - 1) % invoice.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % invoice.length)
          }
          imageCaption={"Invoice " + parseFloat(photoIndex + 1)}
        />
      ) : null}

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePengeluaran}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Pengeluaran | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs
                    breadcrumbItem="Pengeluaran Khitan"
                    handleOpenModal={handleOpenModal}
                    buttonModal="Pengeluaran"
                  />
                  {/* <CardTitle className="h4">Data Pengeluaran</CardTitle> */}
                  {/* <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable modal-xl"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Pengeluaran" : "Tambah Data Pengeluaran"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleAddData}>
                <Row form>
                  <div data-repeater-list="group-a">
                    <div data-repeater-item className="row">
                      {/* // */}

                      {!isEdit ? (
                        <>
                          <div className="mb-3 col-2">
                            <AvField
                              type="select"
                              name="tipe_pengeluaran"
                              label="Tipe "
                              // validate={{
                              //   required: { value: true },
                              // }}
                              value={tipePengeluaran ? tipePengeluaran : ""}
                              onChange={e => handleTipePengeluaran(e)}
                            >
                              <option hidden value="">
                                -- Pilih Tipe --
                              </option>
                              <option value="pengeluaran">Pengeluaran</option>
                              <option value="pemakaian">Pemakaian</option>
                              <option value="restock">Restock</option>
                              <option value="pemasukan">Pemasukan</option>
                            </AvField>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {tipePengeluaran === "restock" ||
                      tipePengeluaran === "pemakaian" ? (
                        <div className="mb-3 col-3">
                          <Label>Barang</Label>
                          <Select
                            value={showSelectedBarang ? showSelectedBarang : ""}
                            onChange={e => handleRestockLainnya(e)}
                            options={dataBarangSelect}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      ) : (
                        <div className="mb-3 col-3">
                          <AvField
                            name="id_barang"
                            label="Barang / Jasa"
                            type="text"
                            errorMessage="Invalid barang / jasa"
                            validate={{
                              // required: { value: true },
                              minLength: { value: 4 },
                            }}
                            onChange={e =>
                              setSelectedBarang({ _id: e.target.value })
                            }
                            value={
                              pengeluaran && pengeluaran.data_barang
                                ? pengeluaran.data_barang.id_barang
                                : selectedBarang._id
                                ? selectedBarang._id
                                : ""
                            }
                          />
                        </div>
                      )}

                      {restockLainnya && (
                        <>
                          <div className="mb-3 col-2">
                            <AvField
                              name="nama_barang_lainnya"
                              label="Barang Baru"
                              type="text"
                              errorMessage="Invalid barang / jasa"
                              onChange={e =>
                                setNamaBarangLainnya(e.target.value)
                              }
                              value={namaBarangLainnya ? namaBarangLainnya : ""}
                              validate={{
                                // required: { value: true },
                                minLength: { value: 4 },
                              }}
                            />
                          </div>
                          <div className="mb-3 col-2">
                            <AvField
                              type="select"
                              name="kategori"
                              label="Kategori Barang"
                              // validate={{
                              //   required: { value: true },
                              // }}
                              value={katBarang ? katBarang : ""}
                              onChange={value => handleKatBarang(value)}
                            >
                              <option hidden value="">
                                -- Pilih Kategori --
                              </option>
                              {kategoriBarang &&
                                kategoriBarang.map((item, i) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              <option value="lainnya">Lainnya</option>
                            </AvField>
                          </div>
                          {isKatBarangLainnya && (
                            <div className="mb-3 col-2">
                              <AvField
                                name="kat_barang_lainnya"
                                label="Kategori Barang"
                                type="text"
                                errorMessage="Invalid kategori barang"
                                onChange={e =>
                                  setKatBarangLainnya(e.target.value)
                                }
                                validate={{
                                  // required: { value: true },
                                  minLength: { value: 3 },
                                }}
                                value={katBarangLainnya ? katBarangLainnya : ""}
                              />
                            </div>
                          )}
                        </>
                      )}

                      {tipePengeluaran !== "pemasukan" && (
                        <div className="mb-3 col-2">
                          <AvField
                            name="jumlah"
                            label="Quantity"
                            type="number"
                            errorMessage="Invalid quantity"
                            validate={{
                              // required: { value: true },
                              number: { value: true },
                              max: {
                                value:
                                  tipePengeluaran === "pemakaian" &&
                                  selectedBarang.stock
                                    ? selectedBarang.stock
                                    : 99999,
                                errorMessage: `Stock barang hanya ${selectedBarang.stock}`,
                              },
                            }}
                            min={1}
                            onChange={e => setJumlah(e.target.value)}
                            value={
                              pengeluaran && pengeluaran.data_barang
                                ? pengeluaran.data_barang.jumlah
                                : jumlah
                                ? jumlah
                                : ""
                            }
                          />
                        </div>
                      )}

                      <div
                        className={
                          tipePengeluaran === "pemasukan"
                            ? "mb-3 col-6"
                            : "mb-3 col-2"
                        }
                      >
                        <AvField
                          name="harga"
                          label={
                            tipePengeluaran === "pemasukan"
                              ? "Total Pemasukan"
                              : "Harga / Pcs"
                          }
                          type="number"
                          errorMessage="Invalid harga barang"
                          validate={{
                            // required: { value: true },
                            number: true,
                          }}
                          min={1}
                          onChange={e => setHarga(e.target.value)}
                          value={
                            pengeluaran && pengeluaran.data_barang
                              ? pengeluaran.data_barang.harga
                              : harga
                              ? harga
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="text-center">
                    <button
                      type="submit"
                      color="primary"
                      className="btn btn-outline-primary btn-block mt-3 mt-lg-0"
                      disabled={
                        ((tipePengeluaran === "restock" &&
                          selectedBarang._id === "lainnya" &&
                          namaBarangLainnya &&
                          isKatBarangLainnya === false &&
                          katBarang) ||
                          (tipePengeluaran === "restock" &&
                            selectedBarang._id === "lainnya" &&
                            namaBarangLainnya &&
                            isKatBarangLainnya === true &&
                            katBarangLainnya) ||
                          (tipePengeluaran === "restock" &&
                            selectedBarang._id !== "lainnya")) &&
                        !isEmpty(selectedBarang) &&
                        tipePengeluaran &&
                        jumlah &&
                        harga
                          ? false
                          : tipePengeluaran !== "restock" &&
                            selectedBarang._id !== "lainnya" &&
                            !isEmpty(selectedBarang) &&
                            tipePengeluaran &&
                            harga &&
                            (tipePengeluaran === "pemasukan" ? true : jumlah)
                          ? false
                          : true
                      }
                    >
                      Tambah
                    </button>
                  </div>
                  <br />
                  <br />
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">(#) No</th>
                          <th scope="col">Nama Barang</th>
                          <th scope="col">Tipe</th>
                          <th scope="col">Jumlah</th>
                          <th scope="col">Harga</th>
                          <th scope="col">Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isEmpty(dataForm) ? (
                          <>
                            {dataForm.map((item, index) => (
                              <tr key={index}>
                                <th scope="row">#{index + 1}</th>
                                <td>
                                  {/* {item.tipe !== "pengeluaran" &&
                                item.nama_barang === "lainnya"
                                  ? item.nama_barang_lainnya
                                  : item.tipe !== "pengeluaran"
                                  ? dataBarang.map(barang =>
                                      barang._id === item.id_barang
                                        ? barang.nama
                                        : ""
                                    )
                                  : item.id_barang} */}
                                  {item.tipe !== "pengeluaran" &&
                                  item.nama_barang === "lainnya"
                                    ? item.nama_barang_lainnya
                                    : item.tipe !== "pengeluaran"
                                    ? item.nama_barang
                                    : item.id_barang}
                                </td>
                                <td>{item.tipe}</td>
                                <td>{item.jumlah}</td>
                                <td>
                                  Rp.{item.tipe === "pemasukan" ? "" : "-"}
                                  {item.harga
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                </td>
                                <td>
                                  Rp.{item.tipe === "pemasukan" ? "" : "-"}
                                  {(
                                    parseInt(item.jumlah) * parseInt(item.harga)
                                  )
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <th
                                scope="row"
                                colSpan={5}
                                className="text-center"
                              >
                                T O T A L
                              </th>
                              <th scope="row">
                                Rp.
                                {dataForm
                                  .reduce((acc, obj) => {
                                    if (obj.tipe === "pemasukan") {
                                      acc += obj.harga * obj.jumlah
                                    } else {
                                      acc -= obj.harga * obj.jumlah
                                    }
                                    return acc
                                  }, 0)
                                  // .reduce(
                                  //   (acc, obj) =>
                                  //     (acc += obj.harga * obj.jumlah),
                                  //   0
                                  // )
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                              </th>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <th scope="row" colSpan={7} className="text-center">
                              Belum ada transaksi
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <br />

                  {/* <Col className="col-12 mb-3">
                    <AvField
                      name="nama"
                      label="Nama Pengeluaran"
                      type="text"
                      errorMessage="Invalid nama pengeluaran"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={pengeluaran ? pengeluaran.nama : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="harga"
                      label="Harga Pengeluaran"
                      type="number"
                      errorMessage="Invalid pengeluaran"
                      validate={{
                        required: { value: true },
                        number: true,
                      }}
                      min={1}
                      value={pengeluaran ? pengeluaran.harga : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="fasilitas"
                      label="Fasilitas Pengeluaran"
                      type="textarea"
                      errorMessage="Invalid fasilitas"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={pengeluaran ? pengeluaran.fasilitas : ""}
                    />
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      name="deskripsi"
                      label="Deskripsi Pengeluaran"
                      type="textarea"
                      errorMessage="Invalid name"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={pengeluaran ? pengeluaran.deskripsi : ""}
                    />
                  </Col> */}
                  <Col className="col-12 mb-3 form-row">
                    <AvField
                      name="catatan"
                      label="Catatan"
                      type="textarea"
                      onChange={e => setCatatan(e.target.value)}
                    />
                  </Col>
                  <Col className="col-12 mb-3 form-row">
                    <Dropzone
                      accept="image/jpeg, image/png"
                      onDrop={img => {
                        handleInvoice(img)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                            </div>
                            <h6>Seret Gambar Paket atau Klik untuk Upload</h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {invoice.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name || f.toString().split("-")[1]}
                                    src={f.preview || env.IMG_URL + f}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name || f.toString().split("-")[1]}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                    <div className=" is-invalid"></div>
                    <div className="invalid-feedback">{errImage.invoice}</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={() => toggle("close")}
                      >
                        Tutup
                      </button>
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-outline-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        onClick={() => {
                          handleValidPengeluaranSubmit()
                        }}
                        disabled={isEmpty(dataForm) ? true : false}
                        className="btn btn-outline-success save-pengeluaran"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={modalDetail}
            className="modal-dialog modal-dialog-scrollable modal-lg"
          >
            <ModalHeader toggle={toggleDetail} tag="h4">
              Detail Pengeluaran
            </ModalHeader>
            <ModalBody>
              <div>
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="col" width="200">
                        List Barang
                      </th>
                      <td scope="col"></td>
                    </tr>
                    <tr>
                      <td scope="col" colSpan={2}>
                        <table className="table table-hover table-centered table-nowrap mb-0">
                          <thead>
                            <tr>
                              <th scope="col">(#) No</th>
                              <th scope="col">Nama Barang</th>
                              <th scope="col">Tipe</th>
                              <th scope="col">Jumlah</th>
                              <th scope="col">Harga</th>
                              <th scope="col">Subtotal</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isEmpty(pengeluaran.data_barang) ? (
                              <>
                                {pengeluaran.data_barang.map((item, index) => (
                                  <tr key={index}>
                                    <th scope="row">#{index + 1}</th>
                                    <td>
                                      {/* {item.tipe !== "pengeluaran" &&
                                item.nama_barang === "lainnya"
                                  ? item.nama_barang_lainnya
                                  : item.tipe !== "pengeluaran"
                                  ? dataBarang.map(barang =>
                                      barang._id === item.id_barang
                                        ? barang.nama
                                        : ""
                                    )
                                  : item.id_barang} */}
                                      {item.tipe !== "pengeluaran" &&
                                      item.nama_barang === "lainnya"
                                        ? item.nama_barang_lainnya
                                        : item.tipe !== "pengeluaran"
                                        ? item.nama_barang
                                        : item.id_barang}
                                    </td>
                                    <td>{item.tipe}</td>
                                    <td>{item.jumlah}</td>
                                    <td>
                                      Rp.{item.tipe === "pemasukan" ? "" : "-"}
                                      {item.harga
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                    </td>
                                    <td>
                                      Rp.{item.tipe === "pemasukan" ? "" : "-"}
                                      {(
                                        parseInt(item.jumlah) *
                                        parseInt(item.harga)
                                      )
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                  <th
                                    scope="row"
                                    colSpan={5}
                                    className="text-center"
                                  >
                                    T O T A L
                                  </th>
                                  <th scope="row">
                                    Rp.
                                    {pengeluaran.data_barang
                                      .reduce((acc, obj) => {
                                        if (obj.tipe === "pemasukan") {
                                          acc += obj.harga * obj.jumlah
                                        } else {
                                          acc -= obj.harga * obj.jumlah
                                        }
                                        return acc
                                      }, 0)
                                      // .reduce(
                                      //   (acc, obj) =>
                                      //     (acc += obj.harga * obj.jumlah),
                                      //   0
                                      // )
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                  </th>
                                </tr>
                              </>
                            ) : (
                              <tr>
                                <th
                                  scope="row"
                                  colSpan={6}
                                  className="text-center"
                                >
                                  Belum ada transaksi
                                </th>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Klinik
                      </th>
                      <td scope="col" className="text-capitalize">
                        {pengeluaran.klinik}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Tanggal
                      </th>
                      <td scope="col">
                        {new Date(pengeluaran.createdAt).toLocaleString("ID", {
                          weekday: "short",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Invoice
                      </th>
                      <td scope="col">
                        <div className="row">
                          {!isEmpty(invoice)
                            ? invoice.map((f, i) => (
                                <Col xl={2} md={2} key={i}>
                                  <Link
                                    to="#"
                                    className="gallery-popup"
                                    title="Open Imagination"
                                    // onClick={() => {
                                    //   openLighboxInvoice()
                                    // }}
                                    onClick={() => {
                                      openLighboxInvoice(i)
                                    }}
                                  >
                                    <div className="project-item">
                                      <div className="overlay-container">
                                        <img
                                          className="img-bg-light gallery-thumb-img"
                                          alt={f.toString().split("-")[1]}
                                          src={env.IMG_URL + f}
                                        />
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                              ))
                            : "-"}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

Pengeluaran.propTypes = {
  dataPengeluaran: PropTypes.array,
  dataBarang: PropTypes.array,
  kategoriBarang: PropTypes.array,
  onGetDataPengeluaran: PropTypes.func,
  onGetDataBarang: PropTypes.func,
  onAddNewPengeluaran: PropTypes.func,
  onUpdatePengeluaran: PropTypes.func,
  onDeletePengeluaran: PropTypes.func,
  onGetKategoriBarang: PropTypes.func,
}

const mapStateToProps = ({ dataPengeluaranReducer, dataBarangReducer }) => ({
  dataPengeluaran: dataPengeluaranReducer.dataPengeluaran,
  dataBarang: dataBarangReducer.dataBarang,
  kategoriBarang: dataBarangReducer.kategoriBarang,
})

const mapDispatchToProps = dispatch => ({
  onGetDataPengeluaran: () => dispatch(getDataPengeluaran()),
  onGetDataBarang: () => dispatch(getDataBarang()),
  onGetKategoriBarang: () => dispatch(getKategoriBarang()),
  onAddNewPengeluaran: pengeluaran => dispatch(addNewPengeluaran(pengeluaran)),
  onUpdatePengeluaran: pengeluaran => dispatch(updatePengeluaran(pengeluaran)),
  onDeletePengeluaran: pengeluaran => dispatch(deletePengeluaran(pengeluaran)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Pengeluaran)
