import { GET_DATA_REVIEW_SUCCESS, GET_DATA_REVIEW_FAIL } from "./actionTypes"

const INIT_STATE = {
  dataReview: [],
  error: {},
}

const DataReviewReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_REVIEW_SUCCESS:
      return {
        ...state,
        dataReview: action.payload,
      }

    case GET_DATA_REVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default DataReviewReducer
