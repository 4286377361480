import {
  GET_DATA_VOUCHER_SUCCESS,
  GET_DATA_VOUCHER_FAIL,
  ADD_VOUCHER_SUCCESS,
  ADD_VOUCHER_FAIL,
  CHECK_VOUCHER_SUCCESS,
  CHECK_VOUCHER_FAIL,
  UPDATE_VOUCHER_SUCCESS,
  UPDATE_VOUCHER_FAIL,
  DELETE_VOUCHER_SUCCESS,
  DELETE_VOUCHER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataVoucher: [],
  checkVoucher: {},
  error: {},
}

const DataVoucherReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_VOUCHER_SUCCESS:
      return {
        ...state,
        dataVoucher: action.payload,
      }

    case GET_DATA_VOUCHER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CHECK_VOUCHER_SUCCESS:
      return {
        ...state,
        checkVoucher: action.payload,
      }

    case CHECK_VOUCHER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_VOUCHER_SUCCESS:
      return {
        ...state,
        dataVoucher: [...state.dataVoucher, action.payload],
      }

    case ADD_VOUCHER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_VOUCHER_SUCCESS:
      return {
        ...state,
        dataVoucher: state.dataVoucher.map(voucher =>
          voucher._id === action.payload._id
            ? { voucher, ...action.payload }
            : voucher
        ),
      }

    case UPDATE_VOUCHER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_VOUCHER_SUCCESS:
      return {
        ...state,
        dataVoucher: state.dataVoucher.filter(
          voucher => voucher._id !== action.payload._id
        ),
      }

    case DELETE_VOUCHER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default DataVoucherReducer
