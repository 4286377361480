import {
  GET_DATA_GAJI_SUCCESS,
  GET_DATA_GAJI_FAIL,
  ADD_GAJI_SUCCESS,
  ADD_GAJI_FAIL,
  UPDATE_GAJI_SUCCESS,
  UPDATE_GAJI_FAIL,
  DELETE_GAJI_SUCCESS,
  DELETE_GAJI_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataGaji: [],
  error: {},
}

const DataGajiReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_GAJI_SUCCESS:
      return {
        ...state,
        dataGaji: action.payload,
      }

    case GET_DATA_GAJI_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_GAJI_SUCCESS:
      return {
        ...state,
        dataGaji: [...state.dataGaji, action.payload],
      }

    case ADD_GAJI_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_GAJI_SUCCESS:
      return {
        ...state,
        dataGaji: state.dataGaji.map(gaji =>
          gaji._id === action.payload._id ? { gaji, ...action.payload } : gaji
        ),
      }

    case UPDATE_GAJI_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_GAJI_SUCCESS:
      return {
        ...state,
        dataGaji: state.dataGaji.filter(
          gaji => gaji._id !== action.payload._id
        ),
      }

    case DELETE_GAJI_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default DataGajiReducer
