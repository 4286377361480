import {
  GET_DATA_VOUCHER,
  GET_DATA_VOUCHER_FAIL,
  GET_DATA_VOUCHER_SUCCESS,
  CHECK_VOUCHER,
  CHECK_VOUCHER_SUCCESS,
  CHECK_VOUCHER_FAIL,
  ADD_NEW_VOUCHER,
  ADD_VOUCHER_SUCCESS,
  ADD_VOUCHER_FAIL,
  UPDATE_VOUCHER,
  UPDATE_VOUCHER_SUCCESS,
  UPDATE_VOUCHER_FAIL,
  DELETE_VOUCHER,
  DELETE_VOUCHER_SUCCESS,
  DELETE_VOUCHER_FAIL,
  // GET_CATEGORIES,
  // GET_CATEGORIES_SUCCESS,
  // GET_CATEGORIES_FAIL,
} from "./actionTypes"

export const getDataVoucher = () => ({
  type: GET_DATA_VOUCHER,
})

export const getDataVoucherSuccess = dataVoucher => ({
  type: GET_DATA_VOUCHER_SUCCESS,
  payload: dataVoucher,
})

export const getDataVoucherFail = error => ({
  type: GET_DATA_VOUCHER_FAIL,
  payload: error,
})

export const addNewVoucher = voucher => ({
  type: ADD_NEW_VOUCHER,
  payload: voucher,
})

export const addVoucherSuccess = voucher => ({
  type: ADD_VOUCHER_SUCCESS,
  payload: voucher,
})

export const addVoucherFail = error => ({
  type: ADD_VOUCHER_FAIL,
  payload: error,
})

export const checkVoucher = voucher => ({
  type: CHECK_VOUCHER,
  payload: voucher,
})

export const checkVoucherSuccess = voucher => ({
  type: CHECK_VOUCHER_SUCCESS,
  payload: voucher,
})

export const checkVoucherFail = error => ({
  type: CHECK_VOUCHER_FAIL,
  payload: error,
})

export const updateVoucher = voucher => ({
  type: UPDATE_VOUCHER,
  payload: voucher,
})

export const updateVoucherSuccess = voucher => ({
  type: UPDATE_VOUCHER_SUCCESS,
  payload: voucher,
})

export const updateVoucherFail = error => ({
  type: UPDATE_VOUCHER_FAIL,
  payload: error,
})

export const deleteVoucher = voucher => ({
  type: DELETE_VOUCHER,
  payload: voucher,
})

export const deleteVoucherSuccess = voucher => ({
  type: DELETE_VOUCHER_SUCCESS,
  payload: voucher,
})

export const deleteVoucherFail = error => ({
  type: DELETE_VOUCHER_FAIL,
  payload: error,
})

// export const getCategories = () => ({
//   type: GET_CATEGORIES,
// })

// export const getCategoriesSuccess = categories => ({
//   type: GET_CATEGORIES_SUCCESS,
//   payload: categories,
// })

// export const getCategoriesFail = error => ({
//   type: GET_CATEGORIES_FAIL,
//   payload: error,
// })
