import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_USER,
  SEARCH_USER,
  DELETE_USER,
  GET_DATA_USER,
  GET_DATA_KARYAWAN,
  UPDATE_USER,
} from "./actionTypes"
import {
  getDataUserSuccess,
  getDataUserFail,
  getDataKaryawanSuccess,
  getDataKaryawanFail,
  addUserFail,
  addUserSuccess,
  searchUserFail,
  searchUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataUser,
  getDataKaryawan,
  addNewUser,
  searchUser,
  updateUser,
  deleteUser,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"

function* fetchDataUser() {
  yield put(startLoading());
  try {
    const response = yield call(getDataUser)
    yield put(getDataUserSuccess(response))
  } catch (error) {
    yield put(getDataUserFail(error))
  }
  yield put(stopLoading());
}

function* fetchDataKaryawan() {
  yield put(startLoading());
  try {
    const response = yield call(getDataKaryawan)
    yield put(getDataKaryawanSuccess(response))
  } catch (error) {
    yield put(getDataKaryawanFail(error))
  }
  yield put(stopLoading());
}

function* onAddNewUser({ payload: user }) {
  yield put(startLoading());
  try {
    const response = yield call(addNewUser, user)
    yield put(addUserSuccess(response))
  } catch (error) {
    yield put(addUserFail(error))
  }
  yield put(stopLoading());
}

function* onSearchUser({ payload: user }) {
  // yield put(startLoading());
  try {
    const response = yield call(searchUser, user)
    yield put(searchUserSuccess(response))
  } catch (error) {
    yield put(searchUserFail(error))
  }
  // yield put(stopLoading());
}

function* onUpdateUser({ payload: user }) {
  yield put(startLoading());
  try {
    // console.log(user)
    const response = yield call(updateUser, user)
    yield put(updateUserSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
  yield put(stopLoading());
}

function* onDeleteUser({ payload: user }) {
  yield put(startLoading());
  try {
    const response = yield call(deleteUser, user)
    yield put(deleteUserSuccess(response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
  yield put(stopLoading());
}

function* DataUserSaga() {
  yield takeEvery(GET_DATA_USER, fetchDataUser)
  yield takeEvery(GET_DATA_KARYAWAN, fetchDataKaryawan)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(SEARCH_USER, onSearchUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
}

export default DataUserSaga
