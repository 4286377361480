import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { postLogin } from "../../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../../actions"

// const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  yield put(startLoading());
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    })
    if (
      response.role === "superAdmin" ||
      response.role === "dokter" ||
      response.role === "gudang" ||
      response.role === "admin"
    ) {
      yield localStorage.setItem("authUser", JSON.stringify(response))
      yield localStorage.setItem("accessToken", response.accessToken)
      yield put(loginSuccess(response))

      if (response.role === "dokter") {
        history.push("/konsultasi")
      } else {
        history.push("/dashboard")
      }
    } else {
      const error = {
        response: {
          data: {
            message: "User Bukan admin!!",
          },
        },
      }
      throw error
    }
  } catch (error) {
    yield put(apiError(error.response?.data?.message || "Terjadi kesalahan server, coba beberapa saat lagi!"))
  }
  yield put(stopLoading());
}

function* logoutUser({ payload: { history } }) {
  yield put(startLoading());
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("accessToken")
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
  yield put(stopLoading());
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
