import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_LIST_TASK,
  DELETE_LIST_TASK,
  // GET_CATEGORIES,
  GET_DATA_LIST_TASK,
  UPDATE_LIST_TASK,
} from "./actionTypes"
import {
  getDataListTaskSuccess,
  getDataListTaskFail,
  addListTaskFail,
  addListTaskSuccess,
  updateListTaskSuccess,
  updateListTaskFail,
  deleteListTaskSuccess,
  deleteListTaskFail,
  // getCategoriesSuccess,
  // getCategoriesFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataListTask,
  addNewListTask,
  updateListTask,
  deleteListTask,
  // getCategories,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"


function* fetchDataListTask() {
  yield put(startLoading());
  try {
    const response = yield call(getDataListTask)
    yield put(getDataListTaskSuccess(response))
  } catch (error) {
    yield put(getDataListTaskFail(error))
  }
  yield put(stopLoading());
}

function* onAddNewListTask({ payload: task }) {
  yield put(startLoading());
  try {
    const response = yield call(addNewListTask, task)
    yield put(addListTaskSuccess(response))
  } catch (error) {
    yield put(addListTaskFail(error))
  }
  yield put(stopLoading());
}

function* onUpdateListTask({ payload: task }) {
  yield put(startLoading());
  try {
    // console.log(task)
    const response = yield call(updateListTask, task)
    yield put(updateListTaskSuccess(response))
  } catch (error) {
    yield put(updateListTaskFail(error))
  }
  yield put(stopLoading());
}

function* onDeleteListTask({ payload: task }) {
  yield put(startLoading());
  try {
    const response = yield call(deleteListTask, task)
    yield put(deleteListTaskSuccess(response))
  } catch (error) {
    yield put(deleteListTaskFail(error))
  }
  yield put(stopLoading());
}

// function* onGetCategories() {
//   try {
//     const response = yield call(getCategories)
//     yield put(getCategoriesSuccess(response))
//   } catch (error) {
//     yield put(getCategoriesFail(error))
//   }
// }

function* DataListTaskSaga() {
  yield takeEvery(GET_DATA_LIST_TASK, fetchDataListTask)
  yield takeEvery(ADD_NEW_LIST_TASK, onAddNewListTask)
  yield takeEvery(UPDATE_LIST_TASK, onUpdateListTask)
  yield takeEvery(DELETE_LIST_TASK, onDeleteListTask)
  // yield takeEvery(GET_CATEGORIES, onGetCategories)
}

export default DataListTaskSaga
