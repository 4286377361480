import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  addNewListTask,
  deleteListTask,
  getDataListTask,
  updateListTask,
} from "../../store/actions"

const ListTask = props => {
  const { onGetDataListTask, dataListTask } = props
  const [modal, setModal] = useState(false)
  const [listTask, setListTask] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [judul, setJudul] = useState("")
  const [list, setList] = useState("")
  const [dataForm, setDataForm] = useState([])

  const [listEdit, setListEdit] = useState("")
  const [idEdit, setIdEdit] = useState("")

  useEffect(() => {
    if (dataListTask) {
      let no = 0
      dataListTask.map((item, index) => {
        no++
        item.no = no
        item.action = (
          <Button
            color="primary"
            className="font-16 btn-block btn-sm"
            outline
            onClick={e => handleOpenModal(item)}
          >
            {/* <i className="mdi mdi-grease-pencil"></i>  */}
            Ubah
          </Button>
        )
      })
    }

    if (!modal && !isEmpty(listTask) && !!isEdit) {
      // setTimeout(() => {
      setListTask({})
      setJudul("")
      setDataForm([])
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, listTask, dataListTask])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOpenModal = data => {
    // console.log("data", data)
    let datalist = ""
    if (data) {
      // console.log("data exist")
      setListTask({
        _id: data._id,
        judul: data.judul,
        list: data.list,
      })

      data.list.map((item, index) => {
        datalist += (index > 0 ? " @ " : "") + item
      })

      setIdEdit(data._id)
      setListEdit(datalist)
      setJudul(data.judul)
      setIsEdit(true)
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  useEffect(() => {
    onGetDataListTask()
  }, [])

  const handleValidListTaskSubmit = () => {
    const { onAddNewListTask, onUpdateListTask } = props
    if (isEdit) {
      let datalistedit = listEdit.split("@")
      const updateListTask = {
        id: idEdit,
        judul: judul,
        list: datalistedit,
      }
      // update listTask
      onUpdateListTask(updateListTask)
    } else {
      const newListTask = {
        judul: judul,
        list: dataForm,
      }
      // save new listTask
      onAddNewListTask(newListTask)
    }

    setListEdit("")
    setIdEdit("")
    setDataForm([])
    setJudul("")
    toggle()
  }

  const handleDeleteListTask = () => {
    const { onDeleteListTask } = props
    onDeleteListTask(listTask._id)
    setDeleteModal(false)
    toggle()
  }

  const handleAddData = () => {
    setDataForm([...dataForm, list])
    setList("")
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Judul Task",
        field: "judul",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: dataListTask,
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteListTask}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>ListTask | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs
                    breadcrumbItem="List Task"
                    handleOpenModal={handleOpenModal}
                    buttonModal="List Task"
                  />
                  {/* <CardTitle className="h4">Data ListTask</CardTitle>
                  <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable modal-lg"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Pengeluaran" : "Tambah Data Pengeluaran"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleAddData}>
                <Row form>
                  {!!isEdit ? (
                    <>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="judul"
                          label="Judul atau Kategori Task"
                          type="text"
                          errorMessage="Invalid judul"
                          validate={{
                            required: { value: true },
                            minLength: { value: 3 },
                          }}
                          onChange={e => setJudul(e.target.value)}
                          value={judul}
                        />
                      </Col>
                      <Col className="col-12 mb-3 form-row">
                        <AvField
                          name="listEdit"
                          label="List Task (Pisahkan tiap list dengan tanda @ , contoh: satu @ dua )"
                          type="textarea"
                          value={listEdit}
                          onChange={e => setListEdit(e.target.value)}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <div data-repeater-list="group-a">
                        <div data-repeater-item className="row">
                          <Col className="col-12 mb-3">
                            <AvField
                              name="judul"
                              label="Judul atau Kategori Task"
                              type="text"
                              errorMessage="Invalid judul"
                              validate={{
                                required: { value: true },
                                minLength: { value: 3 },
                              }}
                              onChange={e => setJudul(e.target.value)}
                              value={judul}
                            />
                          </Col>
                          <Col className="col-12 mb-3">
                            <AvField
                              name="list"
                              label="List Task"
                              type="text"
                              errorMessage="Invalid task"
                              onChange={e => setList(e.target.value)}
                              value={list}
                              focus={true}
                              focus
                            />
                          </Col>
                        </div>
                      </div>
                      <br />
                      <div className="text-center">
                        <button
                          type="submit"
                          color="primary"
                          className="btn btn-outline-primary btn-block mt-3 mt-lg-0"
                          disabled={!isEmpty(list) ? false : true}
                        >
                          Tambah List
                        </button>
                      </div>
                      <br />
                      <br />
                      <div className="table-responsive">
                        <table className="table table-hover table-centered responsiveTable table-nowrap mb-0">
                          <thead>
                            <tr>
                              <th scope="col">(#) No</th>
                              <th scope="col">List Task</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isEmpty(dataForm) ? (
                              <>
                                {dataForm.map((item, index) => (
                                  <tr key={index}>
                                    <th scope="row">#{index + 1}</th>
                                    <td>{item}</td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <tr>
                                <th
                                  scope="row"
                                  colSpan={7}
                                  className="text-center"
                                >
                                  Belum ada transaksi
                                </th>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                  <br />
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={() => toggle("close")}
                      >
                        Tutup
                      </button>
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-outline-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        onClick={() => {
                          handleValidListTaskSubmit()
                        }}
                        disabled={
                          isEmpty(dataForm) && isEmpty(judul) ? true : false
                        }
                        className="btn btn-outline-success save-pengeluaran"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

ListTask.propTypes = {
  dataListTask: PropTypes.array,
  onGetDataListTask: PropTypes.func,
  onAddNewListTask: PropTypes.func,
  onUpdateListTask: PropTypes.func,
  onDeleteListTask: PropTypes.func,
}

const mapStateToProps = ({ dataListTaskReducer }) => ({
  dataListTask: dataListTaskReducer.dataListTask,
})

const mapDispatchToProps = dispatch => ({
  onGetDataListTask: () => dispatch(getDataListTask()),
  onAddNewListTask: listTask => dispatch(addNewListTask(listTask)),
  onUpdateListTask: listTask => dispatch(updateListTask(listTask)),
  onDeleteListTask: listTask => dispatch(deleteListTask(listTask)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListTask)
