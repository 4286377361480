import {
  GET_DATA_CAROUSEL_SUCCESS,
  GET_DATA_CAROUSEL_FAIL,
  ADD_CAROUSEL_SUCCESS,
  ADD_CAROUSEL_FAIL,
  UPDATE_CAROUSEL_SUCCESS,
  UPDATE_CAROUSEL_FAIL,
  DELETE_CAROUSEL_SUCCESS,
  DELETE_CAROUSEL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataCarousel: [],
  error: {},
}

const DataCarouselReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_CAROUSEL_SUCCESS:
      // console.log("res redc ", action.payload)
      return {
        ...state,
        dataCarousel: action.payload,
      }

    case GET_DATA_CAROUSEL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CAROUSEL_SUCCESS:
      return {
        ...state,
        dataCarousel: [...state.dataCarousel, action.payload],
      }

    case ADD_CAROUSEL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CAROUSEL_SUCCESS:
      // console.log("res reducer ", action.payload)
      return {
        ...state,
        dataCarousel: state.dataCarousel.map(carousel =>
          carousel._id === action.payload._id
            ? { carousel, ...action.payload }
            : carousel
        ),
      }

    case UPDATE_CAROUSEL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CAROUSEL_SUCCESS:
      return {
        ...state,
        dataCarousel: state.dataCarousel.filter(
          carousel => carousel._id !== action.payload._id
        ),
      }

    case DELETE_CAROUSEL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default DataCarouselReducer
