import {
  GET_DATA_VIDEO_CALL,
  GET_DATA_VIDEO_CALL_FAIL,
  GET_DATA_VIDEO_CALL_SUCCESS,
  ADD_NEW_VIDEO_CALL,
  ADD_NEW_VIDEO_CALL_SUCCESS,
  ADD_NEW_VIDEO_CALL_FAIL,
  UPDATE_VIDEO_CALL,
  UPDATE_VIDEO_CALL_SUCCESS,
  UPDATE_VIDEO_CALL_FAIL,
} from "./actionTypes"

export const getDataVideoCall = () => ({
  type: GET_DATA_VIDEO_CALL,
})

export const getDataVideoCallSuccess = dataVideoCall => ({
  type: GET_DATA_VIDEO_CALL_SUCCESS,
  payload: dataVideoCall,
})

export const getDataVideoCallFail = error => ({
  type: GET_DATA_VIDEO_CALL_FAIL,
  payload: error,
})

export const addNewVideoCall = videoCall => ({
  type: ADD_NEW_VIDEO_CALL,
  payload: videoCall,
})

export const addNewVideoCallSuccess = videoCall => ({
  type: ADD_NEW_VIDEO_CALL_SUCCESS,
  payload: videoCall,
})

export const addNewVideoCallFail = error => ({
  type: ADD_NEW_VIDEO_CALL_FAIL,
  payload: error,
})

export const updateVideoCall = videoCall => ({
  type: UPDATE_VIDEO_CALL,
  payload: videoCall,
})

export const updateVideoCallSuccess = videoCall => ({
  type: UPDATE_VIDEO_CALL_SUCCESS,
  payload: videoCall,
})

export const updateVideoCallFail = error => ({
  type: UPDATE_VIDEO_CALL_FAIL,
  payload: error,
})
