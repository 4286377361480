import {
  GET_DATA_LEVEL_AFFILIASI_SUCCESS,
  GET_DATA_LEVEL_AFFILIASI_FAIL,
  ADD_LEVEL_AFFILIASI_SUCCESS,
  ADD_LEVEL_AFFILIASI_FAIL,
  UPDATE_LEVEL_AFFILIASI_SUCCESS,
  UPDATE_LEVEL_AFFILIASI_FAIL,
  DELETE_LEVEL_AFFILIASI_SUCCESS,
  DELETE_LEVEL_AFFILIASI_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataLevelAffiliasi: [],
  error: {},
}

const DataLevelAffiliasiReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_LEVEL_AFFILIASI_SUCCESS:
      return {
        ...state,
        dataLevelAffiliasi: action.payload,
      }

    case GET_DATA_LEVEL_AFFILIASI_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_LEVEL_AFFILIASI_SUCCESS:
      return {
        ...state,
        dataLevelAffiliasi: [...state.dataLevelAffiliasi, action.payload],
      }

    case ADD_LEVEL_AFFILIASI_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_LEVEL_AFFILIASI_SUCCESS:
      return {
        ...state,
        dataLevelAffiliasi: state.dataLevelAffiliasi.map(levelaffiliasi =>
          levelaffiliasi._id === action.payload._id
            ? { levelaffiliasi, ...action.payload }
            : levelaffiliasi
        ),
      }

    case UPDATE_LEVEL_AFFILIASI_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_LEVEL_AFFILIASI_SUCCESS:
      return {
        ...state,
        dataLevelAffiliasi: state.dataLevelAffiliasi.filter(
          levelaffiliasi => levelaffiliasi._id !== action.payload._id
        ),
      }

    case DELETE_LEVEL_AFFILIASI_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default DataLevelAffiliasiReducer
