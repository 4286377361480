/* DATA_LEVEL_AFFILIASI */
export const GET_DATA_LEVEL_AFFILIASI = "GET_DATA_LEVEL_AFFILIASI"
export const GET_DATA_LEVEL_AFFILIASI_SUCCESS =
  "GET_DATA_LEVEL_AFFILIASI_SUCCESS"
export const GET_DATA_LEVEL_AFFILIASI_FAIL = "GET_DATA_LEVEL_AFFILIASI_FAIL"

export const ADD_NEW_LEVEL_AFFILIASI = "ADD_NEW_LEVEL_AFFILIASI"
export const ADD_LEVEL_AFFILIASI_SUCCESS = "ADD_LEVEL_AFFILIASI_SUCCESS"
export const ADD_LEVEL_AFFILIASI_FAIL = "ADD_LEVEL_AFFILIASI_FAIL"

export const UPDATE_LEVEL_AFFILIASI = "UPDATE_LEVEL_AFFILIASI"
export const UPDATE_LEVEL_AFFILIASI_SUCCESS = "UPDATE_LEVEL_AFFILIASI_SUCCESS"
export const UPDATE_LEVEL_AFFILIASI_FAIL = "UPDATE_LEVEL_AFFILIASI_FAIL"

export const DELETE_LEVEL_AFFILIASI = "DELETE_LEVEL_AFFILIASI"
export const DELETE_LEVEL_AFFILIASI_SUCCESS = "DELETE_LEVEL_AFFILIASI_SUCCESS"
export const DELETE_LEVEL_AFFILIASI_FAIL = "DELETE_LEVEL_AFFILIASI_FAIL"

// export const GET_CATEGORIES = "GET_CATEGORIES"
// export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
// export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"
