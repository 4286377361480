import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty, groupBy } from "lodash"
import { env } from "../../helpers/config"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
  Label,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import moment from "moment"
//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import {
  getDataGaji,
  getDataKaryawan,
  addNewGaji,
  updateGaji,
  deleteGaji,
} from "../../store/actions"

const Gaji = props => {
  const { onGetDataGaji, onGetDataKaryawan, dataGaji, dataKaryawan } = props

  const [modal, setModal] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [gaji, setGaji] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [invoice, setInvoice] = useState([])
  const [errImage, setErrImage] = useState({})

  const [selectedKaryawan, setSelectedKaryawan] = useState({})

  const [dataForm, setDataForm] = useState([])
  const [catatan, setCatatan] = useState("")

  const [jumlahGaji, setJumlahGaji] = useState("")
  const [jumlahBonus, setJumlahBonus] = useState(0)

  const [photoIndex, setphotoIndex] = useState(0)
  const [isGallery, setisGallery] = useState(false)

  useEffect(() => {
    if (dataGaji) {
      dataGaji.map((item, index) => {
        item.action = (
          <>
            <Button
              key={`detail-${index}`}
              color="success"
              outline
              className="font-16 btn-block btn-sm"
              onClick={e => handleOpenModalDetail(item)}
            >
              Detail
              {/* <i className="mdi mdi-receipt"></i> */}
            </Button>{" "}
            {/* &nbsp;&nbsp;
            <Button
              key={index}
              color="primary"
              className="font-16 btn-block"
              onClick={e => handleOpenModal(item)}
            >
              <i className="mdi mdi-grease-pencil"></i>
            </Button> */}
          </>
        )
        item.invoice2 = item.invoice.map((img, i) => [
          <CardImg
            key={i}
            src={env.IMG_URL + img}
            alt="image-invoice"
            className="avatar-sm"
          />,
        ])
        item.total =
          "Rp." +
          item.data_gaji
            .reduce(
              (acc, obj) =>
                (acc += parseInt(obj.jumlah_gaji) + parseInt(obj.jumlah_bonus)),
              0
            )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        item.tanggal = new Date(item.createdAt).toLocaleString("ID", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })
        item.note = item.catatan || "-"
      })
      // console.log(dataGaji)
    }

    if (!modal && !isEmpty(gaji) && !!isEdit) {
      // setTimeout(() => {
      setGaji({})
      setDataForm([])
      setSelectedKaryawan({})
      setJumlahGaji("")
      setJumlahBonus(0)
      setInvoice([])
      setErrImage({})
      setCatatan("")

      setIsEdit(false)
      // }, 500)
    }
  }, [modal, gaji, dataGaji])

  const toggle = close => {
    if (close) {
      setGaji({})
      setDataForm([])
      setSelectedKaryawan({})
      setJumlahGaji("")
      setJumlahBonus(0)
      setInvoice([])
      setErrImage({})
      setCatatan("")
    }
    setModal(!modal)
  }

  const toggleDetail = close => {
    // console.log(booking)
    setModalDetail(!modalDetail)
    if (close) {
      setInvoice([])
      setGaji({})
    }
  }

  const handleOpenModal = data => {
    // console.log("data", data)
    if (data) {
      // console.log("data exist")
      setGaji({
        _id: data._id,
        data_gaji: data.data_gaji,
        catatan: data.catatan,
        invoice: data.invoice,
        createdAt: data.createdAt,
      })
      setInvoice(data.image)
      setIsEdit(true)
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  const handleOpenModalDetail = data => {
    if (data) {
      setGaji({
        _id: data._id,
        data_gaji: data.data_gaji,
        catatan: data.catatan,
        invoice: data.invoice,
        createdAt: data.createdAt,
      })

      setInvoice(data.invoice)
    }
    toggleDetail()
  }

  useEffect(() => {
    onGetDataGaji()
    onGetDataKaryawan()
  }, [])

  const handleValidGajiSubmit = (e, values) => {
    const { onAddNewGaji, onUpdateGaji } = props

    const formData = new FormData()
    formData.append("data_gaji", JSON.stringify(dataForm))
    formData.append("catatan", catatan || "")
    invoice.map(img => {
      formData.append("invoice", img)
    })

    // if (Object.keys(invoice).length === 0 && !isEdit) {
    //   setErrImage({ ...errImage, invoice: "Gambar Invoice harus di upload.." })
    // } else {
    if (isEdit) {
      formData.append("_id", gaji._id)
      onUpdateGaji(formData)
    } else {
      onAddNewGaji(formData)
    }
    // console.log(dataForm)
    setInvoice([])
    setDataForm([])
    toggle()
    // }
  }

  const handleDeleteGaji = () => {
    const { onDeleteGaji } = props
    onDeleteGaji(gaji._id)
    setDeleteModal(false)
    setInvoice([])
    toggle()
  }

  function handleInvoice(files) {
    if (files.length > 5) {
      setErrImage({ invoice: "Maksimal upload 5 gambar" })
    } else {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setInvoice(files)
      setErrImage(delete errImage.invoice)
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleAddData = () => {
    const data = {
      id_karyawan: selectedKaryawan._id,
      nama_karyawan: selectedKaryawan.nama,
      role: selectedKaryawan.role,
      jumlah_gaji: parseInt(jumlahGaji),
      jumlah_bonus: parseInt(jumlahBonus),
    }
    setDataForm([...dataForm, data])

    setSelectedKaryawan({})
    setJumlahGaji("")
    setJumlahBonus(0)
  }

  const handleSelectKaryawan = val => {
    let data = dataKaryawan.find(item => item._id === val.target.value)
    setSelectedKaryawan(data)
  }

  const data = {
    columns: [
      {
        label: "Tanggal",
        field: "tanggal",
        sort: "asc",
      },
      {
        label: "Total",
        field: "total",
        sort: "asc",
      },
      {
        label: "Invoice",
        field: "invoice2",
        sort: "asc",
      },
      {
        label: "Catatan",
        field: "note",
        sort: "asc",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: dataGaji,
  }

  const openLighboxInvoice = i => {
    setphotoIndex(i)
    setisGallery(true)
    setModalDetail(!modalDetail)
  }

  const closeLighboxInvoice = async () => {
    setphotoIndex(0)
    await setModalDetail(!modalDetail)
    setisGallery(false)
  }

  return (
    <React.Fragment>
      {isGallery && !isEmpty(invoice) ? (
        <Lightbox
          mainSrc={env.IMG_URL + invoice[photoIndex]}
          nextSrc={env.IMG_URL + invoice[(photoIndex + 1) % invoice.length]}
          prevSrc={
            env.IMG_URL +
            invoice[(photoIndex + invoice.length - 1) % invoice.length]
          }
          enableZoom={true}
          onCloseRequest={() => {
            closeLighboxInvoice()
          }}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + invoice.length - 1) % invoice.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % invoice.length)
          }
          imageCaption={"Invoice " + parseFloat(photoIndex + 1)}
        />
      ) : null}

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteGaji}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Gaji | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs
                    breadcrumbItem="Gaji Karyawan"
                    handleOpenModal={handleOpenModal}
                    buttonModal="Gaji"
                  />
                  {/* <CardTitle className="h4">Data Gaji</CardTitle> */}
                  {/* <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable modal-lg"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Gaji" : "Tambah Data Gaji"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleAddData}>
                <Row form>
                  <div data-repeater-list="group-a">
                    <div data-repeater-item className="row">
                      {/* // */}

                      <div className="mb-3 col-4">
                        <AvField
                          type="select"
                          name="Karyawan"
                          label="List Karyawan"
                          // validate={{
                          //   required: { value: true },
                          // }}
                          value={selectedKaryawan ? selectedKaryawan?._id : ""}
                          onChange={e => handleSelectKaryawan(e)}
                        >
                          <option value="" selected>
                            -- Pilih Karyawan --
                          </option>
                          {!isEmpty(dataKaryawan) &&
                            dataKaryawan.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item.nama}
                              </option>
                            ))}
                        </AvField>
                      </div>

                      <div className="mb-3 col-4">
                        <AvField
                          name="jumlah_gaji"
                          label="Jumlah Gaji"
                          type="number"
                          errorMessage="Invalid jumlah gaji"
                          validate={{
                            // required: { value: true },
                            number: { value: true },
                          }}
                          min={1}
                          onChange={e => setJumlahGaji(e.target.value)}
                          value={jumlahGaji ? jumlahGaji : ""}
                        />
                      </div>

                      <div className="mb-3 col-4">
                        <AvField
                          name="jumlah_bonus"
                          label="Jumlah Bonus"
                          type="number"
                          errorMessage="Invalid jumlah bonus"
                          validate={{
                            // required: { value: true },
                            number: true,
                          }}
                          min={0}
                          onChange={e => setJumlahBonus(e.target.value)}
                          value={jumlahBonus ? jumlahBonus : 0}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="text-center">
                    <button
                      type="submit"
                      color="primary"
                      className="btn btn-outline-primary btn-block mt-3 mt-lg-0"
                      disabled={
                        !isEmpty(selectedKaryawan) && jumlahGaji ? false : true
                      }
                    >
                      Tambah
                    </button>
                  </div>
                  <br />
                  <br />
                  <div className="table-responsive">
                    <table className="table table-hover table-centered responsiveTable table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">(#) No</th>
                          <th scope="col">Nama Karyawan</th>
                          <th scope="col">Jumlah Gaji</th>
                          <th scope="col">Jumlah Bonus</th>
                          <th scope="col">Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isEmpty(dataForm) ? (
                          <>
                            {dataForm.map((item, index) => (
                              <tr key={index}>
                                <th scope="row">#{index + 1}</th>
                                <td>{item.nama_karyawan}</td>
                                <td>
                                  Rp.
                                  {item.jumlah_gaji
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                </td>
                                <td>
                                  Rp.
                                  {item.jumlah_bonus
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                </td>
                                <td>
                                  Rp.
                                  {(
                                    parseInt(item.jumlah_gaji) +
                                    parseInt(item.jumlah_bonus)
                                  )
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <th
                                scope="row"
                                colSpan={4}
                                className="text-center"
                              >
                                T O T A L
                              </th>
                              <th scope="row">
                                Rp.
                                {dataForm
                                  .reduce(
                                    (acc, obj) =>
                                      (acc +=
                                        parseInt(obj.jumlah_gaji) +
                                        parseInt(obj.jumlah_bonus)),
                                    0
                                  )
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                              </th>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <th scope="row" colSpan={7} className="text-center">
                              Belum ada transaksi
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <br />

                  {/* <Col className="col-12 mb-3">
                    <AvField
                      name="nama"
                      label="Nama Gaji"
                      type="text"
                      errorMessage="Invalid nama gaji"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={gaji ? gaji.nama : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="harga"
                      label="Harga Gaji"
                      type="number"
                      errorMessage="Invalid gaji"
                      validate={{
                        required: { value: true },
                        number: true,
                      }}
                      min={1}
                      value={gaji ? gaji.harga : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="fasilitas"
                      label="Fasilitas Gaji"
                      type="textarea"
                      errorMessage="Invalid fasilitas"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={gaji ? gaji.fasilitas : ""}
                    />
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      name="deskripsi"
                      label="Deskripsi Gaji"
                      type="textarea"
                      errorMessage="Invalid name"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={gaji ? gaji.deskripsi : ""}
                    />
                  </Col> */}

                  <Col className="col-12 mb-3 form-row">
                    <AvField
                      name="catatan"
                      label="Catatan"
                      type="textarea"
                      onChange={e => setCatatan(e.target.value)}
                    />
                  </Col>
                  <Col className="col-12 mb-3 form-row">
                    <Dropzone
                      accept="image/jpeg, image/png"
                      onDrop={img => {
                        handleInvoice(img)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                            </div>
                            <h6>Seret Gambar Paket atau Klik untuk Upload</h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {invoice.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name || f.toString().split("-")[1]}
                                    src={f.preview || env.IMG_URL + f}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name || f.toString().split("-")[1]}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                    <div className=" is-invalid"></div>
                    <div className="invalid-feedback">{errImage.invoice}</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={() => toggle("close")}
                      >
                        Tutup
                      </button>
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-outline-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        onClick={() => {
                          handleValidGajiSubmit()
                        }}
                        disabled={isEmpty(dataForm) ? true : false}
                        className="btn btn-outline-success save-gaji"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={modalDetail}
            className="modal-dialog modal-dialog-scrollable modal-lg"
          >
            <ModalHeader toggle={toggleDetail} tag="h4">
              Detail Gaji
            </ModalHeader>
            <ModalBody>
              <div>
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="col" width="200">
                        List Gaji Karyawan
                      </th>
                      <td scope="col"></td>
                    </tr>
                    <tr>
                      <td scope="col" colSpan={2}>
                        <table className="table table-hover table-centered table-nowrap mb-0">
                          <thead>
                            <tr>
                              <th scope="col">(#) No</th>
                              <th scope="col">Nama Karyawan</th>
                              <th scope="col">Jumlah Gaji</th>
                              <th scope="col">Jumlah Bonus</th>
                              <th scope="col">Subtotal</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isEmpty(gaji.data_gaji) ? (
                              <>
                                {gaji.data_gaji.map((item, index) => (
                                  <tr key={index}>
                                    <th scope="row">#{index + 1}</th>
                                    <td>{item.nama_karyawan}</td>
                                    <td>
                                      Rp.
                                      {item.jumlah_gaji
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                    </td>
                                    <td>
                                      Rp.
                                      {item.jumlah_bonus
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                    </td>
                                    <td>
                                      Rp.
                                      {(
                                        parseInt(item.jumlah_gaji) +
                                        parseInt(item.jumlah_bonus)
                                      )
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                  <th
                                    scope="row"
                                    colSpan={4}
                                    className="text-center"
                                  >
                                    T O T A L
                                  </th>
                                  <th scope="row">
                                    Rp.
                                    {gaji.data_gaji
                                      .reduce(
                                        (acc, obj) =>
                                          (acc +=
                                            parseInt(obj.jumlah_gaji) +
                                            parseInt(obj.jumlah_bonus)),
                                        0
                                      )
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                  </th>
                                </tr>
                              </>
                            ) : (
                              <tr>
                                <th
                                  scope="row"
                                  colSpan={7}
                                  className="text-center"
                                >
                                  Belum ada transaksi
                                </th>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Tanggal
                      </th>
                      <td scope="col">
                        {new Date(gaji.createdAt).toLocaleString("ID", {
                          weekday: "short",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Invoice
                      </th>
                      <td scope="col">
                        <div className="row">
                          {!isEmpty(invoice)
                            ? invoice.map((f, i) => (
                                <Col xl={2} md={2} key={i}>
                                  <Link
                                    to="#"
                                    className="gallery-popup"
                                    title="Open Imagination"
                                    // onClick={() => {
                                    //   openLighboxInvoice()
                                    // }}
                                    onClick={() => {
                                      openLighboxInvoice(i)
                                    }}
                                  >
                                    <div className="project-item">
                                      <div className="overlay-container">
                                        <img
                                          className="img-bg-light gallery-thumb-img"
                                          alt={f.toString().split("-")[1]}
                                          src={env.IMG_URL + f}
                                        />
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                              ))
                            : "-"}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

Gaji.propTypes = {
  dataGaji: PropTypes.array,
  dataKaryawan: PropTypes.array,
  onGetDataGaji: PropTypes.func,
  onGetDataKaryawan: PropTypes.func,
  onAddNewGaji: PropTypes.func,
  onUpdateGaji: PropTypes.func,
  onDeleteGaji: PropTypes.func,
}

const mapStateToProps = ({ dataGajiReducer, dataUserReducer }) => ({
  dataGaji: dataGajiReducer.dataGaji,
  dataKaryawan: dataUserReducer.dataKaryawan,
})

const mapDispatchToProps = dispatch => ({
  onGetDataGaji: () => dispatch(getDataGaji()),
  onGetDataKaryawan: () => dispatch(getDataKaryawan()),
  onAddNewGaji: gaji => dispatch(addNewGaji(gaji)),
  onUpdateGaji: gaji => dispatch(updateGaji(gaji)),
  onDeleteGaji: gaji => dispatch(deleteGaji(gaji)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Gaji)
