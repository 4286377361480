import {
  GET_DATA_PENGELUARAN_SUCCESS,
  GET_DATA_PENGELUARAN_FAIL,
  ADD_PENGELUARAN_SUCCESS,
  ADD_PENGELUARAN_FAIL,
  UPDATE_PENGELUARAN_SUCCESS,
  UPDATE_PENGELUARAN_FAIL,
  DELETE_PENGELUARAN_SUCCESS,
  DELETE_PENGELUARAN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataPengeluaran: [],
  error: {},
}

const DataPengeluaranReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_PENGELUARAN_SUCCESS:
      return {
        ...state,
        dataPengeluaran: action.payload,
      }

    case GET_DATA_PENGELUARAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PENGELUARAN_SUCCESS:
      return {
        ...state,
        dataPengeluaran: [action.payload, ...state.dataPengeluaran],
      }

    case ADD_PENGELUARAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PENGELUARAN_SUCCESS:
      return {
        ...state,
        dataPengeluaran: state.dataPengeluaran.map(pengeluaran =>
          pengeluaran._id === action.payload._id
            ? { ...action.payload, pengeluaran }
            : pengeluaran
        ),
      }

    case UPDATE_PENGELUARAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PENGELUARAN_SUCCESS:
      return {
        ...state,
        dataPengeluaran: state.dataPengeluaran.filter(
          pengeluaran => pengeluaran._id !== action.payload._id
        ),
      }

    case DELETE_PENGELUARAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default DataPengeluaranReducer
