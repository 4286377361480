import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_REKENING,
  DELETE_REKENING,
  GET_DATA_REKENING,
  UPDATE_REKENING,
} from "./actionTypes"
import {
  getDataRekeningSuccess,
  getDataRekeningFail,
  addRekeningFail,
  addRekeningSuccess,
  updateRekeningSuccess,
  updateRekeningFail,
  deleteRekeningSuccess,
  deleteRekeningFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataRekening,
  addNewRekening,
  updateRekening,
  deleteRekening,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"


function* fetchDataRekening() {
  yield put(startLoading());
  try {
    const response = yield call(getDataRekening)
    yield put(getDataRekeningSuccess(response))
  } catch (error) {
    yield put(getDataRekeningFail(error))
  }
  yield put(stopLoading());
}

function* onAddNewRekening({ payload: data }) {
  yield put(startLoading());
  try {
    const response = yield call(addNewRekening, data)
    yield put(addRekeningSuccess(response))
  } catch (error) {
    yield put(addRekeningFail(error))
  }
  yield put(stopLoading());
}

function* onUpdateRekening({ payload: data }) {
  yield put(startLoading());
  try {
    // console.log(data)
    const response = yield call(updateRekening, data)
    yield put(updateRekeningSuccess(response))
  } catch (error) {
    yield put(updateRekeningFail(error))
  }
  yield put(stopLoading());
}

function* onDeleteRekening({ payload: data }) {
  yield put(startLoading());
  try {
    const response = yield call(deleteRekening, data)
    yield put(deleteRekeningSuccess(response))
  } catch (error) {
    yield put(deleteRekeningFail(error))
  }
  yield put(stopLoading());
}

function* DataRekeningSaga() {
  yield takeEvery(GET_DATA_REKENING, fetchDataRekening)
  yield takeEvery(ADD_NEW_REKENING, onAddNewRekening)
  yield takeEvery(UPDATE_REKENING, onUpdateRekening)
  yield takeEvery(DELETE_REKENING, onDeleteRekening)
}

export default DataRekeningSaga
