import {
  GET_DATA_REKENING,
  GET_DATA_REKENING_FAIL,
  GET_DATA_REKENING_SUCCESS,
  ADD_NEW_REKENING,
  ADD_REKENING_SUCCESS,
  ADD_REKENING_FAIL,
  UPDATE_REKENING,
  UPDATE_REKENING_SUCCESS,
  UPDATE_REKENING_FAIL,
  DELETE_REKENING,
  DELETE_REKENING_SUCCESS,
  DELETE_REKENING_FAIL,
} from "./actionTypes"

export const getDataRekening = () => ({
  type: GET_DATA_REKENING,
})

export const getDataRekeningSuccess = data => ({
  type: GET_DATA_REKENING_SUCCESS,
  payload: data,
})

export const getDataRekeningFail = error => ({
  type: GET_DATA_REKENING_FAIL,
  payload: error,
})

export const addNewRekening = data => ({
  type: ADD_NEW_REKENING,
  payload: data,
})

export const addRekeningSuccess = data => ({
  type: ADD_REKENING_SUCCESS,
  payload: data,
})

export const addRekeningFail = error => ({
  type: ADD_REKENING_FAIL,
  payload: error,
})

export const updateRekening = data => ({
  type: UPDATE_REKENING,
  payload: data,
})

export const updateRekeningSuccess = data => ({
  type: UPDATE_REKENING_SUCCESS,
  payload: data,
})

export const updateRekeningFail = error => ({
  type: UPDATE_REKENING_FAIL,
  payload: error,
})

export const deleteRekening = data => ({
  type: DELETE_REKENING,
  payload: data,
})

export const deleteRekeningSuccess = data => ({
  type: DELETE_REKENING_SUCCESS,
  payload: data,
})

export const deleteRekeningFail = error => ({
  type: DELETE_REKENING_FAIL,
  payload: error,
})
