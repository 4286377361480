import {
  START_LOADING,
  STOP_LOADING,
} from "./actionTypes"

const INIT_STATE = {
  globalLoading: false,
}

const loadingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        globalLoading: true,
      }

    case STOP_LOADING:
      return {
        ...state,
        globalLoading: false,
      }

    default:
      return state
  }
}

export default loadingReducer
