/* DATA_BARANG */
export const GET_DATA_BARANG = "GET_DATA_BARANG"
export const GET_DATA_BARANG_SUCCESS = "GET_DATA_BARANG_SUCCESS"
export const GET_DATA_BARANG_FAIL = "GET_DATA_BARANG_FAIL"

export const ADD_NEW_BARANG = "ADD_NEW_BARANG"
export const ADD_BARANG_SUCCESS = "ADD_BARANG_SUCCESS"
export const ADD_BARANG_FAIL = "ADD_BARANG_FAIL"

export const UPDATE_BARANG = "UPDATE_BARANG"
export const UPDATE_BARANG_SUCCESS = "UPDATE_BARANG_SUCCESS"
export const UPDATE_BARANG_FAIL = "UPDATE_BARANG_FAIL"

export const DELETE_BARANG = "DELETE_BARANG"
export const DELETE_BARANG_SUCCESS = "DELETE_BARANG_SUCCESS"
export const DELETE_BARANG_FAIL = "DELETE_BARANG_FAIL"

export const GET_KATEGORI_BARANG = "GET_KATEGORI_BARANG"
export const GET_KATEGORI_BARANG_SUCCESS = "GET_KATEGORI_BARANG_SUCCESS"
export const GET_KATEGORI_BARANG_FAIL = "GET_KATEGORI_BARANG_FAIL"
