import {
  GET_DATA_BARANG,
  GET_DATA_BARANG_FAIL,
  GET_DATA_BARANG_SUCCESS,
  ADD_NEW_BARANG,
  ADD_BARANG_SUCCESS,
  ADD_BARANG_FAIL,
  UPDATE_BARANG,
  UPDATE_BARANG_SUCCESS,
  UPDATE_BARANG_FAIL,
  DELETE_BARANG,
  DELETE_BARANG_SUCCESS,
  DELETE_BARANG_FAIL,
  GET_KATEGORI_BARANG,
  GET_KATEGORI_BARANG_SUCCESS,
  GET_KATEGORI_BARANG_FAIL,
} from "./actionTypes"

export const getDataBarang = () => ({
  type: GET_DATA_BARANG,
})

export const getDataBarangSuccess = data => ({
  type: GET_DATA_BARANG_SUCCESS,
  payload: data,
})

export const getDataBarangFail = error => ({
  type: GET_DATA_BARANG_FAIL,
  payload: error,
})

export const addNewBarang = data => ({
  type: ADD_NEW_BARANG,
  payload: data,
})

export const addBarangSuccess = data => ({
  type: ADD_BARANG_SUCCESS,
  payload: data,
})

export const addBarangFail = error => ({
  type: ADD_BARANG_FAIL,
  payload: error,
})

export const updateBarang = data => ({
  type: UPDATE_BARANG,
  payload: data,
})

export const updateBarangSuccess = data => ({
  type: UPDATE_BARANG_SUCCESS,
  payload: data,
})

export const updateBarangFail = error => ({
  type: UPDATE_BARANG_FAIL,
  payload: error,
})

export const deleteBarang = data => ({
  type: DELETE_BARANG,
  payload: data,
})

export const deleteBarangSuccess = data => ({
  type: DELETE_BARANG_SUCCESS,
  payload: data,
})

export const deleteBarangFail = error => ({
  type: DELETE_BARANG_FAIL,
  payload: error,
})

export const getKategoriBarang = () => ({
  type: GET_KATEGORI_BARANG,
})

export const getKategoriBarangSuccess = data => ({
  type: GET_KATEGORI_BARANG_SUCCESS,
  payload: data,
})

export const getKategoriBarangFail = error => ({
  type: GET_KATEGORI_BARANG_FAIL,
  payload: error,
})
