import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import { GET_DATA_PRESENSI } from "./actionTypes"
import { getDataPresensiSuccess, getDataPresensiFail } from "./actions"

import {  
  startLoading, 
  stopLoading,
} from "../actions"

//Include Both Helper File with needed methods
import { getDataPresensi } from "../../helpers/backend_helper"

function* fetchDataPresensi({ payload: keyword }) {
  yield put(startLoading());
  try {
    const response = yield call(getDataPresensi, keyword)
    yield put(getDataPresensiSuccess(response))
  } catch (error) {
    yield put(getDataPresensiFail(error))
  }
  yield put(stopLoading());
}

function* DataPresensiSaga() {
  yield takeEvery(GET_DATA_PRESENSI, fetchDataPresensi)
}

export default DataPresensiSaga
