import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { env } from "../../helpers/config"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
  Label,
  InputGroup,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  addNewVoucher,
  deleteVoucher,
  getDataVoucher,
  updateVoucher,
} from "../../store/actions"

const Voucher = props => {
  const { onGetDataVoucher, dataVoucher } = props
  const [modal, setModal] = useState(false)
  const [voucher, setVoucher] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [katLainnya, setKatLainnya] = useState(false)
  const [isPersen, setIsPersen] = useState("")
  const [nominal, setNominal] = useState(0)
  const [expiredDate, setExpiredDate] = useState("")
  const [imageVoucher, setImageVoucher] = useState([])
  const [errImage, setErrImage] = useState("")

  useEffect(() => {
    if (dataVoucher) {
      dataVoucher.map((item, index) => {
        item.action = (
          <Button
            color="primary"
            outline
            className="font-16 btn-block btn-sm"
            onClick={e => handleOpenModal(item)}
          >
            Ubah
          </Button>
        )
        item.image1 = (
          <CardImg
            src={env.IMG_URL + item.image}
            alt="image-voucher"
            className="avatar-lg img-thumbnail img-fluid"
          />
        )

        item.display_isPersen = item.isPersen ? "Persen" : "Nominal"
        item.display_isSekali = item.isSekali ? "Sekali" : "Berulang"
        item.display_isRefferal = item.isRefferal
          ? "Butuh Refferal"
          : "Tanpa Refferal"
        item.display_isActive = item.isActive ? "Aktif" : "Tidak Aktif"
        item.display_tipe =
          item.tipe === "private"
            ? "Private"
            : item.tipe === "daftar"
            ? "Setelah Daftar"
            : item.tipe === "booking"
            ? "Setelah Booking"
            : item.tipe === "all"
            ? "Semua User"
            : "-"
      })
    }

    if (!modal && !isEmpty(voucher) && !!isEdit) {
      // setTimeout(() => {
      setVoucher({})
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, voucher, dataVoucher])

  const toggle = close => {
    if (close) {
      setIsPersen("")
      setNominal(0)
      setExpiredDate("")
      setImageVoucher([])
      setErrImage("")
    }
    setModal(!modal)
  }

  const handleOpenModal = async data => {
    // console.log("data", data)
    if (data) {
      // console.log("data exist")
      setVoucher({
        _id: data._id,
        kode: data.kode,
        nama: data.nama,
        deskripsi: data.deskripsi,
        isPersen: data.isPersen,
        nominal: data.nominal,
        tipe: data.tipe,
        isRefferal: data.isRefferal,
        expiredDate: data.expiredDate,
        isActive: data.isActive,
        isSekali: data.isSekali,
        isForUser: data.isForUser,
        id_user: data.id_user,
        tipe: data.tipe,
      })

      await setIsPersen(data && data.isPersen)
      setExpiredDate(data && data.expiredDate)
      setNominal(data && data.nominal)

      setImageVoucher([data.image])

      setIsEdit(true)
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  useEffect(() => {
    onGetDataVoucher()
  }, [])

  const handleValidVoucherSubmit = (e, values) => {
    const { onAddNewVoucher, onUpdateVoucher } = props

    if (typeof imageVoucher[0] === "undefined" && !isEdit) {
      setErrImage("Gambar voucher harus di upload..")
    } else {
      const formData = new FormData()

      formData.append("kode", values.kode)
      formData.append("nama", values.nama)
      formData.append("deskripsi", values.deskripsi)
      formData.append("isPersen", values.isPersen)
      formData.append("nominal", nominal)
      formData.append("tipe", values.tipe)
      formData.append("isRefferal", values.isRefferal)
      formData.append("expiredDate", values.expiredDate)
      formData.append("isActive", values.isActive)
      formData.append("isSekali", values.isSekali)
      formData.append("isForUser", "false")
      formData.append("id_user", "")
      formData.append("image", imageVoucher[0])

      if (isEdit) {
        formData.append("id", voucher._id)
        // update voucher
        onUpdateVoucher(formData)
      } else {
        // save new voucher
        onAddNewVoucher(formData)
      }
      setKatLainnya(false)

      setIsPersen("")
      setImageVoucher([])
      setErrImage("")
      setNominal(0)
      setExpiredDate("")
      toggle()
    }
  }

  const handleDeleteVoucher = () => {
    const { onDeleteVoucher } = props
    onDeleteVoucher(voucher._id)
    setDeleteModal(false)

    setIsPersen("")
    setNominal(0)
    setExpiredDate("")
    setImageVoucher([])
    setErrImage("")
    toggle()
  }

  useEffect(() => {
    // alert(isPersen)
    setNominal(0)
  }, [isPersen])

  function validateKode(value, ctx, input, cb) {
    if (!!isEdit) {
      if (!value || value === "") {
        // setErrMessage({ ...errMessage, email: "Email is required" })
        cb(false)
        return
      }

      var exists = dataVoucher.find(
        item => item.kode.toUpperCase() === value.toUpperCase()
      )
      if (exists) {
        // setErrMessage({ ...errMessage, email: "Email sudah digunakan" })
        cb(value.toUpperCase() === voucher.kode.toUpperCase() ? true : false)
      } else {
        cb(true)
      }
    } else {
      if (!value || value === "") {
        // setErrMessage({ ...errMessage, email: "Email is required" })
        cb(false)
        return
      }

      var exists = dataVoucher.find(
        item => item.kode.toUpperCase() === value.toUpperCase()
      )
      if (exists) {
        // setErrMessage({ ...errMessage, email: "Email sudah digunakan" })
        cb(false)
      } else {
        cb(true)
      }
    }
  }

  function handleImageVoucher(files) {
    if (files.length > 1) {
      setErrImage("Maksimal upload 1 gambar")
    } else {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setImageVoucher(files)
      setErrImage("")
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const data = {
    columns: [
      {
        label: "Image",
        field: "image1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Kode",
        field: "kode",
        sort: "asc",
        width: 150,
      },
      {
        label: "Nama",
        field: "nama",
        width: 150,
      },
      // {
      //   label: "Deskripsi",
      //   field: "deskripsi",
      //   width: 150,
      // },
      {
        label: "Tipe Potongan",
        field: "display_isPersen", //
        width: 150,
      },
      {
        label: "Nominal",
        field: "nominal",
        width: 150,
      },
      {
        label: "Tipe Pakai",
        field: "display_isSekali",
        width: 150,
      },
      {
        label: "Refferal",
        field: "display_isRefferal",
        width: 150,
      },
      {
        label: "Tipe Display",
        field: "display_tipe",
        width: 150,
      },
      {
        label: "Expired Date",
        field: "expiredDate",
        width: 150,
      },
      // {
      //   label: "User",
      //   field: "id_user",
      //   width: 150,
      // },
      {
        label: "Status",
        field: "display_isActive",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: dataVoucher,
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteVoucher}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Voucher | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs
                    breadcrumbItem="Voucher"
                    handleOpenModal={handleOpenModal}
                    buttonModal="Voucher"
                  />
                  {/* <CardTitle className="h4">Data Voucher</CardTitle>
                  <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Voucher" : "Tambah Voucher"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidVoucherSubmit}>
                <Row form>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="kode"
                      label="Kode Voucher"
                      type="text"
                      errorMessage="Invalid kode voucher / Kode sudah ada"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                        custom: validateKode,
                      }}
                      value={!isEmpty(voucher) && voucher ? voucher.kode : ""}
                      className="text-uppercase"
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="nama"
                      label="Nama Voucher"
                      type="text"
                      errorMessage="Invalid nama voucher"
                      validate={{
                        required: { value: true },
                        minLength: { value: 3 },
                      }}
                      value={!isEmpty(voucher) && voucher ? voucher.nama : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="deskripsi"
                      label="Deskripsi Voucher"
                      type="text"
                      errorMessage="Invalid deskripsi"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={
                        !isEmpty(voucher) && voucher ? voucher.deskripsi : ""
                      }
                    />
                  </Col>

                  <Col className="col-12 d-flex mb-3">
                    <Col className="col-6">
                      <AvField
                        name="isPersen"
                        label="Tipe Potongan"
                        type="select"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={e => setIsPersen(e.target.value)}
                        value={
                          !isEmpty(voucher) && voucher
                            ? voucher.isPersen.toString()
                            : ""
                        }
                      >
                        <option hidden value="">
                          -- Pilih Tipe Voucher --
                        </option>
                        <option value="true">Persentasi</option>
                        <option value="false">Nominal</option>
                      </AvField>
                    </Col>
                    <Col className="col-6 ps-4 ">
                      <AvField
                        name="Nominal"
                        label="Nominal Voucher"
                        type="number"
                        errorMessage={
                          nominal
                            ? isPersen === "true"
                              ? "Maksimal voucher 100%"
                              : "Voucher Terlalu besar?"
                            : "Invalid Potongan"
                        }
                        validate={{
                          number: true,
                          required: {
                            value: true,
                          },
                        }}
                        onChange={e => setNominal(e.target.value)}
                        min={1}
                        max={isPersen === "true" ? 100 : 5000000}
                        value={nominal}
                      />
                    </Col>
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      name="isSekali"
                      label="Tipe Pakai"
                      type="select"
                      validate={{
                        required: { value: true },
                      }}
                      // onChange={e => setIsPersen(e.target.value)}
                      value={
                        !isEmpty(voucher) && voucher
                          ? voucher.isSekali.toString()
                          : ""
                      }
                    >
                      <option hidden value="">
                        -- Pilih Tipe Pakai --
                      </option>
                      <option value="true">Sekali Pakai</option>
                      <option value="false">Berulang</option>
                    </AvField>
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="isRefferal"
                      label="Butuh refferal untuk menggunakan voucher?"
                      type="select"
                      validate={{
                        required: { value: true },
                      }}
                      // onChange={e => setIsPersen(e.target.value)}
                      value={
                        !isEmpty(voucher) && voucher
                          ? voucher.isRefferal.toString()
                          : ""
                      }
                    >
                      <option hidden value="">
                        -- Pilih Pilihan --
                      </option>
                      <option value="true">Membutuhkan Refferal</option>
                      <option value="false">Tanpa Refferal</option>
                    </AvField>
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      name="tipe"
                      label="Tipe Voucher Display"
                      type="select"
                      validate={{
                        required: { value: true },
                      }}
                      // onChange={e => setIsPersen(e.target.value)}
                      value={!isEmpty(voucher) && voucher ? voucher.tipe : ""}
                    >
                      <option hidden value="">
                        -- Pilih Tipe --
                      </option>
                      <option value="private">
                        Voucher Private (Tidak tampil di App)
                      </option>
                      <option value="daftar">Setelah User Daftar App</option>
                      <option value="booking">Seteleh User Booking App</option>
                      <option value="all">Untuk semua user</option>
                    </AvField>
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      name="expiredDate"
                      label="Tanggal Expired"
                      type="date"
                      // defaultValue="2022-10-01"
                      errorMessage="Invalid tanggal expired"
                      validate={{
                        dateRange: {
                          format: "YYYY/MM/DD",
                          start: { value: new Date() },
                          end: {
                            value: new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() + 5
                              )
                            ),
                          },
                        },
                        required: { value: false },
                      }}
                      value={
                        !isEmpty(voucher) && voucher
                          ? voucher.expiredDate
                          : "2022-10-01"
                      }
                    />
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      name="isActive"
                      label="Status Voucher"
                      type="select"
                      validate={{
                        required: { value: true },
                      }}
                      // onChange={e => setIsPersen(e.target.value)}
                      value={
                        !isEmpty(voucher) && voucher
                          ? voucher.isActive.toString()
                          : ""
                      }
                    >
                      <option hidden value="">
                        -- Pilih Pilihan --
                      </option>
                      <option value="true">Active</option>
                      <option value="false">Tidak Aktif</option>
                    </AvField>
                  </Col>
                </Row>

                <Col className="col-12 mb-3">
                  <Dropzone
                    accept="image/jpeg, image/png"
                    onDrop={img => {
                      handleImageVoucher(img)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                          </div>
                          <h6>Seret Gambar Voucher atau Klik untuk Upload</h6>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {!isEmpty(imageVoucher) &&
                      imageVoucher.length > 0 &&
                      imageVoucher?.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={
                                      f?.name ||
                                      imageVoucher?.toString().split("-")[1]
                                    }
                                    src={
                                      f?.preview || env.IMG_URL + imageVoucher
                                    }
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f?.name ||
                                      imageVoucher.toString().split("-")[1]}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f?.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                  </div>
                  <div className=" is-invalid"></div>
                  <div className="invalid-feedback">{errImage}</div>
                </Col>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={toggle}
                      >
                        Tutup
                      </button>
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-outline-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        type="submit"
                        className="btn btn-outline-success save-voucher"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

Voucher.propTypes = {
  dataVoucher: PropTypes.array,
  onGetDataVoucher: PropTypes.func,
  onAddNewVoucher: PropTypes.func,
  onUpdateVoucher: PropTypes.func,
  onDeleteVoucher: PropTypes.func,
}

const mapStateToProps = ({ dataVoucherReducer }) => ({
  dataVoucher: dataVoucherReducer.dataVoucher,
})

const mapDispatchToProps = dispatch => ({
  onGetDataVoucher: () => dispatch(getDataVoucher()),
  onAddNewVoucher: voucher => dispatch(addNewVoucher(voucher)),
  onUpdateVoucher: voucher => dispatch(updateVoucher(voucher)),
  onDeleteVoucher: voucher => dispatch(deleteVoucher(voucher)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Voucher)
