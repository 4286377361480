import {
  GET_DATA_PENGELUARAN,
  GET_DATA_PENGELUARAN_FAIL,
  GET_DATA_PENGELUARAN_SUCCESS,
  ADD_NEW_PENGELUARAN,
  ADD_PENGELUARAN_SUCCESS,
  ADD_PENGELUARAN_FAIL,
  UPDATE_PENGELUARAN,
  UPDATE_PENGELUARAN_SUCCESS,
  UPDATE_PENGELUARAN_FAIL,
  DELETE_PENGELUARAN,
  DELETE_PENGELUARAN_SUCCESS,
  DELETE_PENGELUARAN_FAIL,
} from "./actionTypes"

export const getDataPengeluaran = () => ({
  type: GET_DATA_PENGELUARAN,
})

export const getDataPengeluaranSuccess = dataPengeluaran => ({
  type: GET_DATA_PENGELUARAN_SUCCESS,
  payload: dataPengeluaran,
})

export const getDataPengeluaranFail = error => ({
  type: GET_DATA_PENGELUARAN_FAIL,
  payload: error,
})

export const addNewPengeluaran = pengeluaran => ({
  type: ADD_NEW_PENGELUARAN,
  payload: pengeluaran,
})

export const addPengeluaranSuccess = pengeluaran => ({
  type: ADD_PENGELUARAN_SUCCESS,
  payload: pengeluaran,
})

export const addPengeluaranFail = error => ({
  type: ADD_PENGELUARAN_FAIL,
  payload: error,
})

export const updatePengeluaran = pengeluaran => ({
  type: UPDATE_PENGELUARAN,
  payload: pengeluaran,
})

export const updatePengeluaranSuccess = pengeluaran => ({
  type: UPDATE_PENGELUARAN_SUCCESS,
  payload: pengeluaran,
})

export const updatePengeluaranFail = error => ({
  type: UPDATE_PENGELUARAN_FAIL,
  payload: error,
})

export const deletePengeluaran = pengeluaran => ({
  type: DELETE_PENGELUARAN,
  payload: pengeluaran,
})

export const deletePengeluaranSuccess = pengeluaran => ({
  type: DELETE_PENGELUARAN_SUCCESS,
  payload: pengeluaran,
})

export const deletePengeluaranFail = error => ({
  type: DELETE_PENGELUARAN_FAIL,
  payload: error,
})
