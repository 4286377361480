import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
// import { io } from "socket.io-client"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//i18n
// import { withTranslation } from "react-i18next"
// Redux Store
import {
  getDataNotif,
  updateNotif,
  getIoNotif,
  getUnreadKonsultasi,
} from "../../../store/actions"

const NotificationDropdown = props => {
  const {
    onGetDataNotif,
    dataNotif,
    onGetIoNotif,
    ioNotif,
    isRun,
    onGetUnreadKonsultasi,
  } = props
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [totalNotif, setTotalNotif] = useState(0)

  // useEffect(() => {
  //   const socket = io("http://localhost:4000")

  //   socket.on("connnection", () => {
  //     // console.log("connected to server")
  //   })

  //   socket.on("booking-notif", result => {
  //     if (result) {
  //       onGetDataNotif()
  //     }
  //   })

  //   socket.on("disconnect", () => {
  //     // console.log("Socket disconnecting")
  //   })
  // }, [])

  useEffect(() => {
    if (isRun) {
      onGetDataNotif()
      onGetIoNotif()
      onGetUnreadKonsultasi()
    }
  }, [])

  useEffect(() => {
    onGetDataNotif()
    onGetUnreadKonsultasi()
  }, [ioNotif])

  useEffect(() => {
    setTotalNotif(dataNotif.filter(item => !item.isRead).length)
  }, [dataNotif])

  const markNotifRead = () => {
    const { onUpdateNotif } = props
    onUpdateNotif({ isAdmin: true })
    onGetDataNotif()
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="mdi mdi-bell-outline"></i>
          {totalNotif && totalNotif > 0 ? (
            <span className="badge bg-danger rounded-pill text-center p-1 rounded-circle">
              {totalNotif}
            </span>
          ) : (
            <span className="badge bg-primary rounded-pill text-center p-1 rounded-circle">
              {totalNotif}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16">
                  {" "}
                  Notifications ({totalNotif})
                </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {dataNotif.map(item => {
              return item.type === "booking" ? (
                <Link
                  to="/booking"
                  className="text-reset notification-item "
                  key={item._id}
                >
                  <div className={`d-flex ${item.isRead ? "opacity-50" : ""}`}>
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="fas fa-receipt"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <h6 className="mt-0 mb-1">Booking Khitan Baru</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          Tanggal {item.content.tanggal} Jam {item.content.jam}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ) : item.type === "barang_habis" ? (
                <Link
                  to="/barang"
                  className="text-reset notification-item "
                  key={item._id}
                >
                  <div className={`d-flex ${item.isRead ? "opacity-50" : ""}`}>
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-danger rounded-circle font-size-16">
                        <i className="fas fa-box"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <h6 className="mt-0 mb-1">Reminder Stock Barang</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          Barang {item.content.barang} - ({item.content.stock})
                        </p>
                        <p className="mb-1">Di Klinik {item.content.klinik}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              ) : item.type === "video-call" ? (
                <Link
                  to="/videoCall"
                  className="text-reset notification-item "
                  key={item._id}
                >
                  <div className={`d-flex ${item.isRead ? "opacity-50" : ""}`}>
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-success rounded-circle font-size-16">
                        <i className="fas fa-video"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <h6 className="mt-0 mb-1">Janji Video Call Baru</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          Tanggal {item.content.tanggal} Jam {item.content.jam}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ) : item.type === "tarik-dana" ? (
                <Link
                  to="/tarikDana"
                  className="text-reset notification-item "
                  key={item._id}
                >
                  <div className={`d-flex ${item.isRead ? "opacity-50" : ""}`}>
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-warning rounded-circle font-size-16">
                        <i className="fas fa-donate"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <h6 className="mt-0 mb-1">Tarik Dana Baru</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          Tanggal{" "}
                          {new Date(item.content.tanggal).toLocaleString("ID", {
                            // weekday: "short",
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            // day: "numeric",
                            // hour: "2-digit",
                            // minute: "2-digit",
                            // hour12: false,
                          })}{" "}
                          {" - Rp."}
                          {item.content.nominal
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ) : (
                <></>
              )
            })}

            {/* <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-warning rounded-circle font-size-16">
                    <i className="mdi mdi-message-text-outline"></i>
                  </span>
                </div>
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">New Message received</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">You have 87 unread messages</p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-info rounded-circle font-size-16">
                    <i className="mdi mdi-glass-cocktail"></i>
                  </span>
                </div>
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">Your item is shipped</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      It is a long established fact that a reader will
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                    <i className="mdi mdi-cart-outline"></i>
                  </span>
                </div>
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">Your order is placed</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      Dummy text of the printing and typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-danger rounded-circle font-size-16">
                    <i className="mdi mdi-message-text-outline"></i>
                  </span>
                </div>
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">New Message received</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">You have 87 unread messages</p>
                  </div>
                </div>
              </div>
            </Link> */}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
              onClick={() => markNotifRead()}
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i> Mark All As
              Read
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

// export default withTranslation()(NotificationDropdown)

// NotificationDropdown.propTypes = {
//   t: PropTypes.any,
// }

NotificationDropdown.propTypes = {
  t: PropTypes.any,
  dataNotif: PropTypes.array,
  ioNotif: PropTypes.object,
  onGetDataNotif: PropTypes.func,
  onIoDataNotif: PropTypes.func,
  onUpdateNotif: PropTypes.func,
  isRun: PropTypes.any,
  onGetUnreadKonsultasi: PropTypes.func,
}

const mapStateToProps = ({
  dataNotifReducer,
  ioNotifReducer,
  konsultasiReducer,
}) => ({
  dataNotif: dataNotifReducer.dataNotif,
  ioNotif: ioNotifReducer.ioNotif,
  isRun: ioNotifReducer.isRun,
})

const mapDispatchToProps = dispatch => ({
  onGetDataNotif: () => dispatch(getDataNotif()),
  onGetIoNotif: () => dispatch(getIoNotif()),
  onUpdateNotif: notif => dispatch(updateNotif(notif)),
  onGetUnreadKonsultasi: () => dispatch(getUnreadKonsultasi()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDropdown)
