import {
  GET_DATA_PAKET_KHITAN,
  GET_DATA_PAKET_KHITAN_FAIL,
  GET_DATA_PAKET_KHITAN_SUCCESS,
  ADD_NEW_PAKET_KHITAN,
  ADD_PAKET_KHITAN_SUCCESS,
  ADD_PAKET_KHITAN_FAIL,
  UPDATE_PAKET_KHITAN,
  UPDATE_PAKET_KHITAN_SUCCESS,
  UPDATE_PAKET_KHITAN_FAIL,
  DELETE_PAKET_KHITAN,
  DELETE_PAKET_KHITAN_SUCCESS,
  DELETE_PAKET_KHITAN_FAIL,
  // GET_CATEGORIES,
  // GET_CATEGORIES_SUCCESS,
  // GET_CATEGORIES_FAIL,
} from "./actionTypes"

export const getDataPaketKhitan = () => ({
  type: GET_DATA_PAKET_KHITAN,
})

export const getDataPaketKhitanSuccess = dataPaketKhitan => ({
  type: GET_DATA_PAKET_KHITAN_SUCCESS,
  payload: dataPaketKhitan,
})

export const getDataPaketKhitanFail = error => ({
  type: GET_DATA_PAKET_KHITAN_FAIL,
  payload: error,
})

export const addNewPaketKhitan = paket => ({
  type: ADD_NEW_PAKET_KHITAN,
  payload: paket,
})

export const addPaketKhitanSuccess = paket => ({
  type: ADD_PAKET_KHITAN_SUCCESS,
  payload: paket,
})

export const addPaketKhitanFail = error => ({
  type: ADD_PAKET_KHITAN_FAIL,
  payload: error,
})

export const updatePaketKhitan = paket => ({
  type: UPDATE_PAKET_KHITAN,
  payload: paket,
})

export const updatePaketKhitanSuccess = paket => ({
  type: UPDATE_PAKET_KHITAN_SUCCESS,
  payload: paket,
})

export const updatePaketKhitanFail = error => ({
  type: UPDATE_PAKET_KHITAN_FAIL,
  payload: error,
})

export const deletePaketKhitan = paket => ({
  type: DELETE_PAKET_KHITAN,
  payload: paket,
})

export const deletePaketKhitanSuccess = paket => ({
  type: DELETE_PAKET_KHITAN_SUCCESS,
  payload: paket,
})

export const deletePaketKhitanFail = error => ({
  type: DELETE_PAKET_KHITAN_FAIL,
  payload: error,
})

// export const getCategories = () => ({
//   type: GET_CATEGORIES,
// })

// export const getCategoriesSuccess = categories => ({
//   type: GET_CATEGORIES_SUCCESS,
//   payload: categories,
// })

// export const getCategoriesFail = error => ({
//   type: GET_CATEGORIES_FAIL,
//   payload: error,
// })
