import {
  GET_DATA_LIST_TASK,
  GET_DATA_LIST_TASK_FAIL,
  GET_DATA_LIST_TASK_SUCCESS,
  ADD_NEW_LIST_TASK,
  ADD_LIST_TASK_SUCCESS,
  ADD_LIST_TASK_FAIL,
  UPDATE_LIST_TASK,
  UPDATE_LIST_TASK_SUCCESS,
  UPDATE_LIST_TASK_FAIL,
  DELETE_LIST_TASK,
  DELETE_LIST_TASK_SUCCESS,
  DELETE_LIST_TASK_FAIL,
  // GET_CATEGORIES,
  // GET_CATEGORIES_SUCCESS,
  // GET_CATEGORIES_FAIL,
} from "./actionTypes"

export const getDataListTask = () => ({
  type: GET_DATA_LIST_TASK,
})

export const getDataListTaskSuccess = dataListTask => ({
  type: GET_DATA_LIST_TASK_SUCCESS,
  payload: dataListTask,
})

export const getDataListTaskFail = error => ({
  type: GET_DATA_LIST_TASK_FAIL,
  payload: error,
})

export const addNewListTask = task => ({
  type: ADD_NEW_LIST_TASK,
  payload: task,
})

export const addListTaskSuccess = task => ({
  type: ADD_LIST_TASK_SUCCESS,
  payload: task,
})

export const addListTaskFail = error => ({
  type: ADD_LIST_TASK_FAIL,
  payload: error,
})

export const updateListTask = task => ({
  type: UPDATE_LIST_TASK,
  payload: task,
})

export const updateListTaskSuccess = task => ({
  type: UPDATE_LIST_TASK_SUCCESS,
  payload: task,
})

export const updateListTaskFail = error => ({
  type: UPDATE_LIST_TASK_FAIL,
  payload: error,
})

export const deleteListTask = task => ({
  type: DELETE_LIST_TASK,
  payload: task,
})

export const deleteListTaskSuccess = task => ({
  type: DELETE_LIST_TASK_SUCCESS,
  payload: task,
})

export const deleteListTaskFail = error => ({
  type: DELETE_LIST_TASK_FAIL,
  payload: error,
})

// export const getCategories = () => ({
//   type: GET_CATEGORIES,
// })

// export const getCategoriesSuccess = categories => ({
//   type: GET_CATEGORIES_SUCCESS,
//   payload: categories,
// })

// export const getCategoriesFail = error => ({
//   type: GET_CATEGORIES_FAIL,
//   payload: error,
// })
