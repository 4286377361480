import {
  GET_DATA_CONFIG,
  GET_DATA_CONFIG_FAIL,
  GET_DATA_CONFIG_SUCCESS,
  UPDATE_CONFIG,
  UPDATE_CONFIG_SUCCESS,
  UPDATE_CONFIG_FAIL,
} from "./actionTypes"

export const getDataConfig = config => ({
  type: GET_DATA_CONFIG,
  payload: config,
})

export const getDataConfigSuccess = dataConfig => ({
  type: GET_DATA_CONFIG_SUCCESS,
  payload: dataConfig,
})

export const getDataConfigFail = error => ({
  type: GET_DATA_CONFIG_FAIL,
  payload: error,
})

export const updateConfig = config => ({
  type: UPDATE_CONFIG,
  payload: config,
})

export const updateConfigSuccess = config => ({
  type: UPDATE_CONFIG_SUCCESS,
  payload: config,
})

export const updateConfigFail = error => ({
  type: UPDATE_CONFIG_FAIL,
  payload: error,
})
