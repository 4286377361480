import axios from "axios"
import { env } from "./config"
// import accessToken from "./jwt-token-access/accessToken"
// import authHeader from "./jwt-token-access/auth-token-header"

//pass new generated access token here
// const token = accessToken

// const token = authHeader()
// console.log("token", token)
//apply base url for axios

// const API_URL = "http://localhost:4000/v1/"
// alert(env.API_URL)

const axiosApi = axios.create({
  // baseURL: API_URL,
  baseURL: env.API_URL,
})

// axiosApi.defaults.headers.common["authorization"] = token

axiosApi.interceptors.request.use(config => {
  config.headers.Authorization = localStorage.getItem("accessToken")
  return config
}, null)

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data.data)
}

export async function postPdf(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config, responseType: 'blob'  })
    .then(response => response)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, data, { ...config })
    .then(response => response.data.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data.data)
}
