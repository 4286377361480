import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"

class MetodeChart extends Component {
  constructor(props) {
    super(props)
  }
  getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: this.props.data && this.props.data.map(value => value.name),
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#02a499", "#f8b425", "#ec4561", "#38a4f8", "#3c4ccf"],
      series: [
        {
          name: "Total Metode",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: this.props.data,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "300px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}
export default MetodeChart
