// require("dotenv").config()

// const config = {
//   production: {
//     API_URL: process.env.REACT_APP_APIURL,
//     IMG_URL: process.env.REACT_APP_IMGURL,
//     SOCKET_URL: process.env.REACT_APP_SOCKETURL,
//   },
//   default: {
//     API_URL: "http://localhost:4000/v1/",
//     IMG_URL: "http://localhost:4000/",
//     SOCKET_URL: "http://localhost:4000",
//   },
// }

export const env = {
  API_URL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_APIURL
      : `http://${process.env.REACT_APP_LOCAL_IP}:4000/v1/`,
  IMG_URL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_IMGURL
      : `http://${process.env.REACT_APP_LOCAL_IP}:4000/`,
  SOCKET_URL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_SOCKETURL
      : `http://${process.env.REACT_APP_LOCAL_IP}:4000`,
}

// exports.get = function get(env) {
//   return config[env] || config.default
// }

// const env = config[process.env.NODE_ENV] || config.default

// exports.env

// exports.get = function get(env) {
//   return config[env] || config.default
// }
