/* DATA_VOUCHER */
export const GET_DATA_VOUCHER = "GET_DATA_VOUCHER"
export const GET_DATA_VOUCHER_SUCCESS = "GET_DATA_VOUCHER_SUCCESS"
export const GET_DATA_VOUCHER_FAIL = "GET_DATA_VOUCHER_FAIL"

export const ADD_NEW_VOUCHER = "ADD_NEW_VOUCHER"
export const ADD_VOUCHER_SUCCESS = "ADD_VOUCHER_SUCCESS"
export const ADD_VOUCHER_FAIL = "ADD_VOUCHER_FAIL"

export const CHECK_VOUCHER = "CHECK_VOUCHER"
export const CHECK_VOUCHER_SUCCESS = "CHECK_VOUCHER_SUCCESS"
export const CHECK_VOUCHER_FAIL = "CHECK_VOUCHER_FAIL"

export const UPDATE_VOUCHER = "UPDATE_VOUCHER"
export const UPDATE_VOUCHER_SUCCESS = "UPDATE_VOUCHER_SUCCESS"
export const UPDATE_VOUCHER_FAIL = "UPDATE_VOUCHER_FAIL"

export const DELETE_VOUCHER = "DELETE_VOUCHER"
export const DELETE_VOUCHER_SUCCESS = "DELETE_VOUCHER_SUCCESS"
export const DELETE_VOUCHER_FAIL = "DELETE_VOUCHER_FAIL"

