/* DATA_USER */
export const GET_DATA_USER = "GET_DATA_USER"
export const GET_DATA_USER_SUCCESS = "GET_DATA_USER_SUCCESS"
export const GET_DATA_USER_FAIL = "GET_DATA_USER_FAIL"

export const GET_DATA_KARYAWAN = "GET_DATA_KARYAWAN"
export const GET_DATA_KARYAWAN_SUCCESS = "GET_DATA_KARYAWAN_SUCCESS"
export const GET_DATA_KARYAWAN_FAIL = "GET_DATA_KARYAWAN_FAIL"

export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

export const SEARCH_USER = "SEARCH_USER"
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS"
export const SEARCH_USER_FAIL = "SEARCH_USER_FAIL"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"
