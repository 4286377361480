import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  GET_DATA_CONFIG,
  UPDATE_CONFIG,
} from "./actionTypes"
import {
  getDataConfigSuccess,
  getDataConfigFail,
  updateConfigSuccess,
  updateConfigFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataConfig,
  updateConfig,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"


function* fetchDataConfig({ payload: config }) {
  yield put(startLoading());
  try {
    const response = yield call(getDataConfig, config)
    yield put(getDataConfigSuccess(response))
  } catch (error) {
    yield put(getDataConfigFail(error))
  }
  yield put(stopLoading());
}

function* onUpdateConfig({ payload: config }) {
  yield put(startLoading());
  try {
    const response = yield call(updateConfig, config)
    yield put(updateConfigSuccess(response))
  } catch (error) {
    yield put(updateConfigFail(error))
  }
  yield put(stopLoading());
}

function* DataConfigSaga() {
  yield takeEvery(GET_DATA_CONFIG, fetchDataConfig)
  yield takeEvery(UPDATE_CONFIG, onUpdateConfig)
}

export default DataConfigSaga
