import {
  GET_DATA_USER_SUCCESS,
  GET_DATA_USER_FAIL,
  GET_DATA_KARYAWAN_SUCCESS,
  GET_DATA_KARYAWAN_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  SEARCH_USER,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataUser: [],
  dataUserSearch: [],
  dataKaryawan: [],
  error: {},
  loadingUserSearch: false,
}

const DataUserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_USER_SUCCESS:
      return {
        ...state,
        dataUser: action.payload,
      }

    case GET_DATA_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DATA_KARYAWAN_SUCCESS:
      return {
        ...state,
        dataKaryawan: action.payload,
      }

    case GET_DATA_KARYAWAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        dataUser: [...state.dataUser, action.payload],
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SEARCH_USER:
      return {
        ...state,
        loadingUserSearch: true,
      }

    case SEARCH_USER_SUCCESS:
      return {
        ...state,
        dataUserSearch: action.payload,
        loadingUserSearch: false,
      }

    case SEARCH_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingUserSearch: false,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        dataUser: state.dataUser.map(user =>
          user._id === action.payload._id ? { user, ...action.payload } : user
        ),
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        dataUser: state.dataUser.filter(
          user => user._id !== action.payload._id
        ),
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default DataUserReducer
