import {
  GET_DATA_PRESENSI_SUCCESS,
  GET_DATA_PRESENSI_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataPresensi: [],
  error: {},
}

const DataPresensiReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_PRESENSI_SUCCESS:
      return {
        ...state,
        dataPresensi: action.payload,
      }

    case GET_DATA_PRESENSI_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default DataPresensiReducer
