import { takeEvery, put, call } from "redux-saga/effects"

import { GET_DATA_DASHBOARD, FILTER_DATA_DASHBOARD } from "./actionTypes"
import {
  getDataDashboardSuccess,
  getDataDashboardFail,
  filterDataDashboardSuccess,
  filterDataDashboardFail,
} from "./actions"
import {
  getDataDashboard,
  filterDataDashboard,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"


function* fetchDataDashboard() {
  yield put(startLoading());
  try {
    const response = yield call(getDataDashboard)
    yield put(getDataDashboardSuccess(response))
  } catch (error) {
    yield put(getDataDashboardFail(error))
  }
  yield put(stopLoading());
}

function* onFilterDataDashboard({ payload: data }) {
  yield put(startLoading());
  try {
    const response = yield call(filterDataDashboard, data)
    yield put(filterDataDashboardSuccess(response))
  } catch (error) {
    yield put(filterDataDashboardFail(error))
  }
  yield put(stopLoading());
}

function* DataDashboardSaga() {
  yield takeEvery(GET_DATA_DASHBOARD, fetchDataDashboard)
  yield takeEvery(FILTER_DATA_DASHBOARD, onFilterDataDashboard)
}

export default DataDashboardSaga
