import {
  takeEvery,
  put,
  call,
  all,
  apply,
  fork,
  take,
} from "redux-saga/effects"
import { eventChannel } from "redux-saga"
import io from "socket.io-client"
import { env } from "../../helpers/config"

import {
  GET_KONSULTASI,
  GET_UNREAD_KONSULTASI,
  GET_LIST_KONSULTASI,
  ADD_NEW_KONSULTASI,
  READ_KONSULTASI,
} from "./actionTypes"
import {
  getKonsultasiSuccess,
  getKonsultasiFail,
  getUnreadKonsultasiSuccess,
  getUnreadKonsultasiFail,
  getListKonsultasiSuccess,
  getListKonsultasiFail,
  addKonsultasiFail,
  addKonsultasiSuccess,
  readKonsultasiFail,
  readKonsultasiSuccess,
  updateListKonsultasiSuccess,
} from "./actions"

import {
  addNewKonsultasi,
  getListKonsultasi,
  getUnreadKonsultasi,
  readKonsultasi,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"



function* onAddNewKonsultasi({ payload: konsultasi }) {
  // socket.emit('sendmsg', konsultasi);
  try {
    const response = yield call(addNewKonsultasi, konsultasi)
    // alert(JSON.stringify(response))
    yield put(updateListKonsultasiSuccess(response))
  } catch (error) {
    // alert(error);
    yield put(updateListKonsultasiFail(error))
  }
}

function* onReadKonsultasi({ payload: konsultasi }) {
  // socket.emit('sendmsg', konsultasi);
  try {
    const response = yield call(readKonsultasi, konsultasi)
    // alert(response)
    yield put(readKonsultasiSuccess(response))
  } catch (error) {
    // alert(error);
    yield put(readKonsultasiFail(error))
  }
}

function createSocketConnection(url) {
  return io(url)
}

function createSocketChannel(socket) {
  return eventChannel(emit => {
    const eventHandler = event => {
      // console.log("emit 1", event)
      emit(event)
    }

    const errorHandler = errorEvent => {
      emit(new Error(errorEvent.reason))
    }

    // socket.on("AdminReceiveMessage", function (data) {
    //   alert(data.content) //should output 'hello world'
    // })
    socket.on("AdminReceiveMessage", eventHandler)

    // socket.on("konsultasi-notif", result => {
    //   if (result) {
    //     return result
    //   }
    // })

    socket.on("error", errorHandler)

    // socket.on("disconnect", () => {
    //   alert("disconnect")
    // })

    const unsubscribe = () => {
      socket.off("AdminReceiveMessage", eventHandler)
      alert("unsub")
    }

    return unsubscribe
  })
}

function* fetchKonsultasi() {
  // const socket = createSocketConnection(env.SOCKET_URL)
  // const socket = yield call(createSocketConnection, env.SOCKET_URL)
  // const socketChannel = yield call(createSocketChannel, socket)
  // while (true) {
  //   try {
  //     const response = yield take(socketChannel)
  //     // console.log("emit 2", response)
  //     yield put(updateListKonsultasiSuccess(response))
  //   } catch (err) {
  //     // console.log("socket error: ", err)
  //   }
  // }
}

function* fetchListKonsultasi() {
  yield put(startLoading());
  try {
    const response = yield call(getListKonsultasi)
    yield put(getListKonsultasiSuccess(response))
  } catch (error) {
    yield put(getListKonsultasiFail(error))
  }
  yield put(stopLoading());
}

function* fetchUnreadKonsultasi() {
  try {
    const response = yield call(getUnreadKonsultasi)
    yield put(getUnreadKonsultasiSuccess(response))
  } catch (error) {
    yield put(getUnreadKonsultasiFail(error))
  }
}

// export default function* rootSaga() {
//   yield fork(fetchKonsultasi);
// }

export function* watchSocket() {
  yield takeEvery(GET_KONSULTASI, fetchKonsultasi)
  yield takeEvery(GET_UNREAD_KONSULTASI, fetchUnreadKonsultasi)
  yield takeEvery(GET_LIST_KONSULTASI, fetchListKonsultasi)
  yield takeEvery(ADD_NEW_KONSULTASI, onAddNewKonsultasi)
  yield takeEvery(READ_KONSULTASI, onReadKonsultasi)
}

function* konsultasiSaga() {
  yield all([fork(watchSocket)])
}

export default konsultasiSaga
