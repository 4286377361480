import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap"

import { isEmpty, debounce } from "lodash"
//i18n
// import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { env } from "../../../helpers/config"

// users
import emptyProfile from "../../../assets/images/users/profile.png"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")
  const [profile, setProfile] = useState("")
  const [role, setRole] = useState("")

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setusername(obj.nama)
      let profile =
        obj && obj?.profile
          ? // ? `http://localhost:4000/${obj.profile}`
            env.IMG_URL + obj.profile
          : emptyProfile
      setProfile(profile)
      setRole(obj.role)
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <Badge
          className={
            "font-size-12 badge bg-" +
            (role === "superAdmin"
              ? "dark"
              : role === "admin"
              ? "secondary"
              : role === "dokter"
              ? "danger"
              : role === "gudang"
              ? "info"
              : role === "staff"
              ? "primary"
              : "warning")
          }
          pill
        >
          {role === "superAdmin"
            ? "SuperAdmin"
            : role === "admin"
            ? "Admin"
            : role === "dokter"
            ? "Dokter"
            : role === "gudang"
            ? "Gudang"
            : role === "staff"
            ? "Staff"
            : "Who Are You ?"}
        </Badge>
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profile}
            alt="Header Avatar"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Profile
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            Lock screen
          </DropdownItem> */}
          {/* <div className="dropdown-divider" /> */}
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(connect(mapStatetoProps, {})(ProfileMenu))
