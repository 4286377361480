import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  accessRole,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      } else {
        if (!isAuthProtected) {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          )
        } else {
          let userRole = JSON.parse(localStorage.getItem("authUser")).role

          // if (userRole === "admin" && accessRole === "superAdmin") {
          //   return <Redirect to={{ pathname: "/dashboard" }} />
          // } else if (
          //   (userRole === "admin" ||
          //     userRole === "dokter" ||
          //     userRole === "gudang") &&
          //   accessRole === "superAdmin"
          // ) {
          //   return <Redirect to={{ pathname: "/dashboard" }} />
          // } else if (
          //   (userRole === "admin" && accessRole === "admin") ||
          //   (userRole === "gudang" && accessRole === "staff") ||
          //   (userRole === "dokter" && accessRole === "staff") ||
          //   userRole === "superAdmin"
          // ) {
          //   return (
          //     <Layout>
          //       <Component {...props} />
          //     </Layout>
          //   )
          // } else {
          //   return (
          //     <Redirect
          //       to={{ pathname: "/login", state: { from: props.location } }}
          //     />
          //   )
          // }

          if (accessRole === "superAdmin" && userRole !== "superAdmin") {
            if (userRole === "dokter") {
              return <Redirect to={{ pathname: "/konsultasi" }} />
            } else if (userRole === "gudang") {
              return <Redirect to={{ pathname: "/barang" }} />
            } else {
              return <Redirect to={{ pathname: "/dashboard" }} />
            }
          } else if (
            (userRole === "gudang" && accessRole === "gudang") ||
            (userRole === "dokter" && accessRole === "dokter") ||
            userRole === "superAdmin" ||
            (userRole === "admin" &&
              (accessRole === "admin" || accessRole === "dokter"))
          ) {
            return (
              <Layout>
                <Component {...props} />
              </Layout>
            )
          } else {
            if (userRole === "dokter") {
              return <Redirect to={{ pathname: "/konsultasi" }} />
            } else if (userRole === "gudang") {
              return <Redirect to={{ pathname: "/barang" }} />
            } else {
              return (
                <Redirect
                  to={{ pathname: "/login", state: { from: props.location } }}
                />
              )
            }
          }
        }
      }

      // return (
      //   <Layout>
      //     <Component {...props} />
      //   </Layout>
      // )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
