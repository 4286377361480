import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty, debounce } from "lodash"
import { env } from "../../helpers/config"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  Label,
  Badge,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//Qr-Code
import { QRCode } from "react-qrcode-logo"
import html2canvas from "html2canvas"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

import profile from "../../assets/images/logo-sm.png"

import { dataRegions } from "../../assets/regions"
// users
import emptyProfile from "../../assets/images/users/profile.png"

import {
  addNewUser,
  deleteUser,
  getDataUser,
  updateUser,
  getDataLevelAffiliasi,
} from "../../store/actions"

const User = props => {
  const {
    onGetDataUser,
    dataUser,
    onGetDataLevelAffiliasi,
    dataLevelAffiliasi,
  } = props
  const [modal, setModal] = useState(false)
  const [modalAffiliasi, setModalAffiliasi] = useState(false)
  const [user, setUser] = useState({})
  const [dataModalAffiliasi, setDataModalAffiliasi] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [imageUser, setImageUser] = useState([])
  const [tanggalLahir, setTanggalLahir] = useState("")
  const [errImage, setErrImage] = useState("")
  const [affiliasi, setAffiliasi] = useState(false)
  const [selectedProvinsi, setSelectedProvinsi] = useState("")
  const [selectedUserRole, setSelectedUserRole] = useState("")
  const [metodeAffiliasi, setMetodeAffiliasi] = useState("")

  const [email, setEmail] = useState("")
  const [errMessage, setErrMessage] = useState({
    email: "Invalid Email",
    telepon: "Invalid Telepon",
  })

  let userRole = JSON.parse(localStorage.getItem("authUser")).role

  useEffect(() => {
    if (dataUser) {
      dataUser.map((item, index) => {
        item.action = (
          <>
            {item.affiliasi.isAffiliasi === "true" && (
              <>
                <Button
                  key={`qrcode-${index}`}
                  color="success"
                  outline
                  className="font-16 btn-block btn-sm"
                  onClick={() => handleOpenModalAffiliasi(item)}
                >
                  Kode QR
                </Button>
                &nbsp;&nbsp;&nbsp;
              </>
            )}
            <Button
              key={`detail-${index}`}
              color="primary"
              outline
              className="font-16 btn-block btn-sm"
              onClick={e => handleOpenModal(item)}
            >
              Ubah
            </Button>
          </>
        )
        item.roleBadge = (
          <>
            <Badge
              className={
                "font-size-12 badge bg-" +
                (item.role === "superAdmin"
                  ? "dark"
                  : item.role === "admin"
                  ? "secondary"
                  : item.role === "dokter"
                  ? "danger"
                  : item.role === "gudang"
                  ? "info"
                  : item.role === "staff"
                  ? "primary"
                  : item.affiliasi.isAffiliasi === "true"
                  ? "success"
                  : "warning")
              }
              pill
            >
              {item.role === "superAdmin"
                ? "SuperAdmin"
                : item.role === "admin"
                ? "Admin"
                : item.role === "dokter"
                ? "Dokter"
                : item.role === "gudang"
                ? "Gudang"
                : item.role === "staff"
                ? "Staff"
                : item.affiliasi.isAffiliasi === "true"
                ? "Affiliasi"
                : "Pelanggan"}
            </Badge>
            {(item.role === "admin" ||
              item.role === "dokter" ||
              item.role === "gudang" ||
              item.role === "staff") && (
              <>
                <br />
                <Badge
                  className={
                    "font-size-12 badge bg-" +
                    (item?.data_karyawan?.akses_klinik === "singosari"
                      ? "primary"
                      : item?.data_karyawan?.akses_klinik === "joyoagung"
                      ? "success"
                      : "danger")
                  }
                  pill
                >
                  {item?.data_karyawan?.akses_klinik || "belum ada klinik"}
                </Badge>
              </>
            )}
            {item.affiliasi.isAffiliasi === "true" && (
              <>
                <br />
                <Badge
                  className={
                    "font-size-12 badge bg-" +
                    (item.affiliasi.level === "sapphire"
                      ? "danger"
                      : item.affiliasi.level === "diamond"
                      ? "primary"
                      : "danger")
                  }
                  pill
                >
                  {item.affiliasi.level}
                </Badge>
              </>
            )}
          </>
        )
        item.image1 =
          item.image !== "" ? (
            <CardImg
              src={env.IMG_URL + item.image}
              alt="image-user"
              className="avatar-md rounded-circle"
            />
          ) : (
            <CardImg
              src={emptyProfile}
              alt="image-user"
              className="avatar-md rounded-circle"
            />
          )
      })
    }

    if (!modal && !isEmpty(user) && !!isEdit) {
      // setTimeout(() => {
      setUser({})
      setIsEdit(false)
      // }, 1000)
    }
  }, [modal, user, dataUser])

  useEffect(() => {
    if (!modalAffiliasi && !isEmpty(dataModalAffiliasi)) {
      // setTimeout(() => {
      setUser({})
      // }, 500)
    }
  }, [modalAffiliasi])

  const toggle = close => {
    if (close) {
      setImageUser([])
      setErrImage("")
      setTanggalLahir("")
      setAffiliasi(false)
      setSelectedUserRole("")
      setSelectedProvinsi("")
    }
    setModal(!modal)
  }

  const toggleAffiliasi = close => {
    if (close) {
      // setImageUser([])
      // setErrImage("")
      // setTanggalLahir("")
      // setAffiliasi(false)
      // setSelectedProvinsi("")
    }
    setModalAffiliasi(!modalAffiliasi)
  }

  const handleOpenModal = data => {
    // console.log("data", data)
    if (data) {
      // console.log("data exist")
      setUser({
        _id: data._id,
        nama: data.nama,
        telepon: data.telepon,
        tanggal_lahir: data.tanggal_lahir,
        alamat: data.alamat,
        email: data.email,
        image: data.image,
        affiliasi: data.affiliasi,
        role: data.role,
        data_karyawan: data.data_karyawan,
      })
      setImageUser([data.image])
      setTanggalLahir(data.tanggal_lahir)
      // console.log(data.affiliasi)
      setAffiliasi(data.affiliasi.isAffiliasi === "true" ? true : false)
      setSelectedUserRole(data.role)
      setIsEdit(true)
      setSelectedProvinsi(
        dataRegions.find(item => item.provinsi === data.alamat?.provinsi)
      )
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  const handleOpenModalAffiliasi = data => {
    setDataModalAffiliasi({
      _id: data._id,
      nama: data.nama,
      affiliasi: data.affiliasi,
    })
    toggleAffiliasi()
  }

  useEffect(() => {
    onGetDataUser()
    onGetDataLevelAffiliasi()
  }, [])

  const handleValidUserSubmit = (e, values) => {
    const { onAddNewUser, onUpdateUser } = props
    // console.log(JSON.stringify(imageUser[0]))
    const formData = new FormData()
    formData.append("nama", values.nama)
    formData.append("telepon", values.telepon)
    formData.append("email", values.email)
    formData.append("tanggal_lahir", tanggalLahir || "")
    formData.append("alamat", values.alamat)
    formData.append("provinsi", values.provinsi)
    formData.append("kota", values.kota)
    formData.append("kecamatan", values.kecamatan)
    formData.append("role", values.role)
    formData.append("image", !isEmpty(imageUser) ? imageUser[0] : "")
    formData.append("isAffiliasi", affiliasi)
    formData.append("level", values.level || "")
    formData.append("bank", values.bank || "")
    formData.append("cabang", values.cabang || "")
    formData.append("rekening", values.rekening || "")
    formData.append("atasNama", values.atasNama || "")
    formData.append("akses_klinik", values.akses_klinik || "")
    formData.append("password", values.password || "")

    // if (typeof imageUser[0] === "undefined" && !isEdit) {
    //   setErrImage("Gambar user harus di upload..")
    // }
    //  else {
    if (isEdit) {
      formData.append("_id", user._id)
      onUpdateUser(formData)
    } else {
      setErrImage("")
      onAddNewUser(formData)
    }

    setImageUser([])
    setTanggalLahir("")
    setAffiliasi(false)
    setSelectedUserRole("")
    setSelectedProvinsi("")
    toggle()
    // }
  }

  const handleDeleteUser = () => {
    const { onDeleteUser } = props
    onDeleteUser(user._id)
    setDeleteModal(false)
    setImageUser([])
    setTanggalLahir("")
    setAffiliasi(false)
    setSelectedUserRole("")
    setSelectedProvinsi("")
    toggle()
  }

  function handleImageUser(files) {
    if (files.length > 1) {
      setErrImage("Maksimal upload 1 gambar")
    } else {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setImageUser(files)
      setErrImage("")
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleTanggalLahir = (selectedDates, dateStr) => {
    setTanggalLahir(dateStr)
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
          paddingLeft: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
          paddingLeft: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }

  const downloadQrAffiliasi = () => {
    html2canvas(document.querySelector("#react-qrcode-logo")).then(function (
      canvas
    ) {
      const link = document.createElement("a")
      link.download = `KhitanSultan_Affiliasi - ${dataModalAffiliasi.nama}-#${dataModalAffiliasi.affiliasi.id_affiliasi}.png`
      link.href = canvas.toDataURL()
      link.click()
    })
  }

  // debounce to not pound the 'server'
  function validateEmail(value, ctx, input, cb) {
    if (!!isEdit) {
      if (!value || value === "") {
        // setErrMessage({ ...errMessage, email: "Email is required" })
        cb(true)
        return
      }

      var exists = dataUser.find(item => item.email === value)
      if (exists) {
        // setErrMessage({ ...errMessage, email: "Email sudah digunakan" })
        cb(value === user.email ? true : false)
      } else {
        cb(true)
      }
    } else {
      if (!value || value === "") {
        // setErrMessage({ ...errMessage, email: "Email is required" })
        cb(true)
        return
      }

      var exists = dataUser.find(item => item.email === value)
      if (exists) {
        // setErrMessage({ ...errMessage, email: "Email sudah digunakan" })
        cb(false)
      } else {
        cb(true)
      }
    }
  }

  function validateTelepon(value, ctx, input, cb) {
    if (!!isEdit) {
      if (!value || value === "") {
        // setErrMessage({ ...errMessage, email: "Email is required" })
        cb(false)
        return
      }

      var exists = dataUser.find(item => item.telepon === value)
      if (exists) {
        // setErrMessage({ ...errMessage, email: "Email sudah digunakan" })
        cb(value === user.telepon ? true : false)
      } else {
        cb(true)
      }
    } else {
      if (!value || value === "") {
        // setErrMessage({ ...errMessage, email: "Email is required" })
        cb(false)
        return
      }

      var exists = dataUser.find(item => item.telepon === value)
      if (exists) {
        // setErrMessage({ ...errMessage, email: "Email sudah digunakan" })
        cb(false)
      } else {
        cb(true)
      }
    }
  }

  const data = {
    columns: [
      {
        label: "Nama",
        field: "nama",
        sort: "desc",
      },
      {
        label: "Telepon",
        field: "telepon",
        sort: "asc",
      },
      {
        label: "Tanggal Lahir",
        field: "tanggal_lahir",
        sort: "asc",
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
      },
      {
        label: "Profile",
        field: "image1",
        sort: "asc",
      },
      {
        label: "Role",
        field: "roleBadge",
        sort: "asc",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: dataUser,
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>User | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs
                    breadcrumbItem="User"
                    handleOpenModal={handleOpenModal}
                    buttonModal="User"
                  />
                  {/* <CardTitle className="h4">Data User</CardTitle> */}
                  {/* <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable modal-lg"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit User" : "Tambah  User"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidUserSubmit}>
                <Row form>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="nama"
                      label="Nama"
                      type="text"
                      errorMessage="Invalid nama user"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={user ? user.nama : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      type="email"
                      errorMessage="Format email salah / email sudah digunakan"
                      // errorMessage={errMessage.email}
                      // onChange={e => handleEmail(e)}
                      validate={{ custom: validateEmail }}
                      // errorMessage="Invalid email user"
                      // validate={{
                      //   required: { value: true },
                      //   minLength: { value: 4 },
                      // }}
                      value={user ? user.email : ""}
                      disabled={!!isEdit ? true : false}
                    />
                  </Col>
                  {/* {!isEdit && ( */}
                  <Col className="col-12 mb-3">
                    <AvField
                      name="password"
                      label={!!isEdit ? "Password (Opsional)" : "Password"}
                      type="text"
                      errorMessage="Invalid password"
                      validate={{
                        required: { value: !isEdit },
                        minLength: { value: 5 },
                      }}
                      value={""}
                      // disabled={!!isEdit ? true : false}
                    />
                  </Col>
                  {/* )} */}
                  <Col className="col-12 d-flex mb-3">
                    <Col className="col-8">
                      <AvField
                        name="telepon"
                        label="Telepon"
                        type="number"
                        errorMessage="Format telepon salah / telepon sudah digunakan"
                        minLength={8}
                        validate={{
                          required: { value: true },
                          number: true,
                          custom: validateTelepon,
                        }}
                        value={user ? user.telepon : ""}
                        // disabled={!!isEdit ? true : false}
                      />
                    </Col>

                    <Col className="col-4 ps-4 ">
                      <Label>Tanggal Lahir</Label>
                      {/* <DatePicker
                          selected={startDate}
                          onChange={date => setStartDate(date)}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                        /> */}
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Tanggal Lahir"
                          onChange={(date, dateStr) =>
                            handleTanggalLahir(date, dateStr)
                          }
                          options={{
                            altInput: true,
                            altFormat: "j F, Y",
                            dateFormat: "Y-m-d",
                            maxDate: "today",
                          }}
                          value={user ? user.tanggal_lahir : ""}
                        />
                      </InputGroup>

                      <AvField
                        name="tanggalLahir-hidden"
                        label=""
                        type="hidden"
                        validate={{
                          required: { value: true },
                        }}
                        value={tanggalLahir}
                      ></AvField>
                    </Col>
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="alamat"
                      label="Alamat"
                      type="textarea"
                      errorMessage="Invalid isi user"
                      validate={{
                        required: { value: false },
                        minLength: { value: 4 },
                      }}
                      value={user && user.alamat ? user.alamat.jalan : ""}
                    />
                  </Col>
                  <Col className="col-12 d-flex mb-3">
                    <Col className="col-4">
                      <AvField
                        name="provinsi"
                        label="Provinsi"
                        type="select"
                        validate={{
                          required: { value: false },
                        }}
                        onChange={e =>
                          setSelectedProvinsi(
                            dataRegions.find(
                              item => item.provinsi === e.target.value
                            )
                          )
                        }
                        value={user && user.alamat ? user.alamat.provinsi : ""}
                      >
                        <option hidden value="">
                          -- Pilih Provinsi --
                        </option>
                        {dataRegions &&
                          dataRegions.map((item, i) => (
                            <option key={item.provinsi} value={item.provinsi}>
                              {item.provinsi}
                            </option>
                          ))}
                      </AvField>
                    </Col>
                    <Col className="col-4 ps-4 ">
                      <AvField
                        name="kota"
                        label="Kota"
                        type="select"
                        disabled={selectedProvinsi ? false : true}
                        validate={{
                          required: { value: false },
                        }}
                        value={user && user.alamat ? user.alamat.kota : ""}
                      >
                        <option hidden value="">
                          -- Pilih Kota --
                        </option>
                        {selectedProvinsi &&
                          selectedProvinsi?.kota.map((item, i) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                      </AvField>
                    </Col>
                    <Col className="col-4 ps-4 ">
                      <AvField
                        name="kecamatan"
                        label="Kecamatan"
                        type="text"
                        errorMessage="Invalid kecamatan"
                        validate={{
                          required: { value: false },
                          minLength: { value: 4 },
                        }}
                        value={user && user.alamat ? user.alamat.kecamatan : ""}
                      />
                    </Col>
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      name="role"
                      label="Role User"
                      type="select"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={e => setSelectedUserRole(e.target.value)}
                      value={
                        !isEdit && userRole === "admin"
                          ? "user"
                          : user
                          ? user.role
                          : "user"
                      }
                    >
                      <option hidden value="">
                        -- Pilih Role --
                      </option>
                      <option
                        value="user"
                        // selected={
                        //   !isEdit && userRole === "admin" ? true : false
                        // }
                      >
                        User
                      </option>
                      {userRole === "superAdmin" && (
                        <>
                          <option value="admin">Admin</option>
                          <option value="dokter">Dokter</option>
                          <option value="gudang">Gudang</option>
                          <option value="staff">Staff</option>
                          <option value="superAdmin">SuperAdmin</option>
                        </>
                      )}
                    </AvField>
                  </Col>

                  {(selectedUserRole === "admin" ||
                    selectedUserRole === "dokter" ||
                    selectedUserRole === "gudang" ||
                    selectedUserRole === "staff") &&
                    userRole === "superAdmin" && (
                      <Col className="col-12 mb-3">
                        <AvField
                          name="akses_klinik"
                          label="Akses Klinik Admin"
                          type="select"
                          validate={{
                            required: {
                              value:
                                selectedUserRole === "admin" ||
                                selectedUserRole === "dokter" ||
                                selectedUserRole === "gudang" ||
                                selectedUserRole === "staff"
                                  ? true
                                  : false,
                            },
                          }}
                          value={
                            user && user.data_karyawan
                              ? user?.data_karyawan?.akses_klinik
                              : ""
                          }
                        >
                          <option hidden value="">
                            -- Pilih Akses Klinik --
                          </option>
                          <option value="singosari">Singosari</option>
                          <option value="joyoagung">Joyoagung</option>
                        </AvField>
                      </Col>
                    )}

                  <Col className="col-12 mb-3">
                    <Dropzone
                      accept="image/jpeg, image/png"
                      onDrop={img => {
                        handleImageUser(img)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                            </div>
                            <h6>Seret Profile User atau Klik untuk Upload</h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {imageUser.map((f, i) => {
                        return f ? (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={
                                      f.name ||
                                      imageUser.toString().split("-")[1]
                                    }
                                    src={f.preview || env.IMG_URL + imageUser}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name ||
                                      imageUser.toString().split("-")[1]}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        ) : (
                          ""
                        )
                      })}
                    </div>
                    <div className=" is-invalid"></div>
                    <div className="invalid-feedback">{errImage}</div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <Label>Jadikan Affiliasi</Label>
                    <InputGroup>
                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#02a499"
                        onChange={() => {
                          setAffiliasi(!affiliasi)
                        }}
                        checked={affiliasi}
                      />
                    </InputGroup>
                  </Col>

                  {affiliasi && (
                    <>
                      <Col className="col-12 d-flex mb-3">
                        <Col className="col-4">
                          <AvField
                            name="level"
                            label="Level Affiliasi"
                            type="select"
                            validate={{
                              required: { value: true },
                            }}
                            value={
                              user && user.affiliasi ? user.affiliasi.level : ""
                            }
                          >
                            <option hidden value="">
                              -- Pilih Level --
                            </option>
                            {dataLevelAffiliasi &&
                              dataLevelAffiliasi.map((item, i) => (
                                <option key={item._id} value={item.nama}>
                                  {item.level}
                                </option>
                              ))}
                            {/* <option value="sapphire">Sapphire</option>
                            <option value="diamond">Diamond</option> */}
                          </AvField>
                        </Col>
                        <Col className="col-4 ps-3">
                          <AvField
                            name="bank"
                            label="Metode Tarik Dana"
                            type="select"
                            validate={{
                              required: { value: false },
                            }}
                            onChange={val =>
                              setMetodeAffiliasi(val.target.value)
                            }
                            value={
                              user && user.affiliasi ? user.affiliasi.bank : ""
                            }
                          >
                            <option hidden value="">
                              -- Pilih Metode --
                            </option>
                            <option value="bank">Bank</option>
                            <option value="gopay">Gopay</option>
                            <option value="dana">Dana</option>
                            <option value="ovo">Ovo</option>

                            {/* <option value="sapphire">Sapphire</option>
                            <option value="diamond">Diamond</option> */}
                          </AvField>

                          {/* <AvField */}
                          {/*   name="bank" */}
                          {/*   label="Nama Bank" */}
                          {/*   type="text" */}
                          {/*   errorMessage="Invalid bank" */}
                          {/*   validate={{ */}
                          {/*     required: { value: false }, */}
                          {/*     minLength: { value: 2 }, */}
                          {/*   }} */}
                          {/*   value={ */}
                          {/*     user && user.affiliasi ? user.affiliasi.bank : "" */}
                          {/*   } */}
                          {/* /> */}
                        </Col>
                        {metodeAffiliasi === "bank" && (
                          <Col className="col-4 ps-3">
                            <AvField
                              name="cabang"
                              label="Nama & Cabang Bank"
                              type="text"
                              errorMessage="Invalid Cabang"
                              validate={{
                                required: { value: false },
                                minLength: { value: 2 },
                              }}
                              value={
                                user && user.affiliasi
                                  ? user.affiliasi.cabang
                                  : ""
                              }
                            />
                          </Col>
                        )}
                      </Col>
                      <Col className="col-12 d-flex mb-3">
                        <Col className="col-4">
                          <AvField
                            name="rekening"
                            label="Nomor / Rekening"
                            type="number"
                            errorMessage="Invalid rekening"
                            min={1}
                            validate={{
                              required: { value: false },
                              number: true,
                            }}
                            value={
                              user && user.affiliasi
                                ? user.affiliasi.rekening
                                : ""
                            }
                          />
                        </Col>
                        <Col className="col-8 ps-3">
                          <AvField
                            name="atasNama"
                            label="Atas Nama"
                            type="text"
                            errorMessage="Invalid atas nama"
                            validate={{
                              required: { value: false },
                              minLength: { value: 2 },
                            }}
                            value={
                              user && user.affiliasi
                                ? user.affiliasi.atasNama
                                : ""
                            }
                          />
                        </Col>
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={() => toggle("close")}
                      >
                        Tutup
                      </button>
                      {!!isEdit && userRole === "superAdmin" && (
                        <button
                          type="button"
                          className="btn btn-outline-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        type="submit"
                        className="btn btn-outline-success save-user"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={modalAffiliasi}
            className="modal-dialog modal-dialog-scrollable modal-sm"
          >
            <ModalHeader toggle={toggleAffiliasi} tag="h4">
              {!isEmpty(dataModalAffiliasi) ? dataModalAffiliasi.nama : ""}
              <br />#
              {!isEmpty(dataModalAffiliasi)
                ? dataModalAffiliasi.affiliasi.id_affiliasi
                : ""}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12 mb-4 mt-3 text-center">
                  <QRCode
                    value={
                      !isEmpty(dataModalAffiliasi)
                        ? dataModalAffiliasi.affiliasi.id_affiliasi
                        : ""
                    }
                    eyeRadius={[
                      // build eyeRadius manually
                      {
                        outer: [20, 0, 0, 0],
                        inner: [1, 0, 0, 0],
                      },
                      {
                        outer: [0, 20, 0, 0],
                        inner: [0, 1, 0, 0],
                      },
                      {
                        outer: [0, 0, 0, 20],
                        inner: [0, 0, 0, 1],
                      },
                    ]}
                    bgColor="#07332E"
                    fgColor="#FFFFFF"
                    logoWidth="32"
                    logoHeight="31"
                    ecLevel="M"
                    quietZone="25"
                    logoImage={profile}
                    logoOpacity="0.3"
                    size="190"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-outline-secondary me-2"
                      onClick={() => toggleAffiliasi("close")}
                    >
                      Tutup
                    </button>

                    <button
                      onClick={() => downloadQrAffiliasi()}
                      className="btn btn-outline-success save-user"
                    >
                      Download
                    </button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

User.propTypes = {
  dataUser: PropTypes.array,
  onGetDataUser: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  dataLevelAffiliasi: PropTypes.array,
  onGetDataLevelAffiliasi: PropTypes.func,
}

const mapStateToProps = ({ dataUserReducer, dataLevelAffiliasiReducer }) => ({
  dataUser: dataUserReducer.dataUser,
  dataLevelAffiliasi: dataLevelAffiliasiReducer.dataLevelAffiliasi,
})

const mapDispatchToProps = dispatch => ({
  onGetDataUser: () => dispatch(getDataUser()),
  onAddNewUser: user => dispatch(addNewUser(user)),
  onUpdateUser: user => dispatch(updateUser(user)),
  onDeleteUser: user => dispatch(deleteUser(user)),
  onGetDataLevelAffiliasi: () => dispatch(getDataLevelAffiliasi()),
})

export default connect(mapStateToProps, mapDispatchToProps)(User)
