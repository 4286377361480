import {
  GET_DATA_ARTIKEL_SUCCESS,
  GET_DATA_ARTIKEL_FAIL,
  ADD_ARTIKEL_SUCCESS,
  ADD_ARTIKEL_FAIL,
  UPDATE_ARTIKEL_SUCCESS,
  UPDATE_ARTIKEL_FAIL,
  DELETE_ARTIKEL_SUCCESS,
  DELETE_ARTIKEL_FAIL,
  GET_KATEGORI_ARTIKEL_SUCCESS,
  GET_KATEGORI_ARTIKEL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataArtikel: [],
  kategoriArtikel: [],
  error: {},
}

const DataArtikelReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_ARTIKEL_SUCCESS:
      // console.log("res redc ", action.payload)
      return {
        ...state,
        dataArtikel: action.payload,
      }

    case GET_DATA_ARTIKEL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ARTIKEL_SUCCESS:
      return {
        ...state,
        dataArtikel: [...state.dataArtikel, action.payload],
      }

    case ADD_ARTIKEL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_ARTIKEL_SUCCESS:
      // console.log("res reducer ", action.payload)
      return {
        ...state,
        dataArtikel: state.dataArtikel.map(artikel =>
          artikel._id === action.payload._id
            ? { artikel, ...action.payload }
            : artikel
        ),
      }

    case UPDATE_ARTIKEL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ARTIKEL_SUCCESS:
      return {
        ...state,
        dataArtikel: state.dataArtikel.filter(
          artikel => artikel._id !== action.payload._id
        ),
      }

    case DELETE_ARTIKEL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_KATEGORI_ARTIKEL_SUCCESS:
      return {
        ...state,
        kategoriArtikel: action.payload,
      }

    case GET_KATEGORI_ARTIKEL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default DataArtikelReducer
