import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import { GET_DATA_TARIK_DANA, UPDATE_TARIK_DANA } from "./actionTypes"
import {
  getDataTarikDanaSuccess,
  getDataTarikDanaFail,
  updateTarikDanaSuccess,
  updateTarikDanaFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getDataTarikDana, updateTarikDana } from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"


function* fetchDataTarikDana() {
  yield put(startLoading());
  try {
    const response = yield call(getDataTarikDana)
    yield put(getDataTarikDanaSuccess(response))
  } catch (error) {
    yield put(getDataTarikDanaFail(error))
  }
  yield put(stopLoading());
}

function* onUpdateTarikDana({ payload: tarikDana }) {
  yield put(startLoading());
  try {
    const response = yield call(updateTarikDana, tarikDana)
    // showSuccess("Tarik Dana Berhasil")
    yield put(updateTarikDanaSuccess(response))
  } catch (error) {
    // showError(error.message)
    // alert(error.message);
    yield put(updateTarikDanaFail(error))
  }
  yield put(stopLoading());
}

function* DataTarikDanaSaga() {
  yield takeEvery(GET_DATA_TARIK_DANA, fetchDataTarikDana)
  yield takeEvery(UPDATE_TARIK_DANA, onUpdateTarikDana)
}

export default DataTarikDanaSaga
