export const GET_KONSULTASI = "GET_KONSULTASI"
export const GET_KONSULTASI_SUCCESS = "GET_KONSULTASI_SUCCESS"
export const GET_KONSULTASI_FAIL = "GET_KONSULTASI_FAIL"

export const GET_UNREAD_KONSULTASI = "GET_UNREAD_KONSULTASI"
export const GET_UNREAD_KONSULTASI_SUCCESS = "GET_UNREAD_KONSULTASI_SUCCESS"
export const GET_UNREAD_KONSULTASI_FAIL = "GET_UNREAD_KONSULTASI_FAIL"

export const GET_LIST_KONSULTASI = "GET_LIST_KONSULTASI"
export const GET_LIST_KONSULTASI_SUCCESS = "GET_LIST_KONSULTASI_SUCCESS"
export const GET_LIST_KONSULTASI_FAIL = "GET_LIST_KONSULTASI_FAIL"

export const UPDATE_LIST_KONSULTASI = "UPDATE_LIST_KONSULTASI"
export const UPDATE_LIST_KONSULTASI_SUCCESS = "UPDATE_LIST_KONSULTASI_SUCCESS"
export const UPDATE_LIST_KONSULTASI_FAIL = "UPDATE_LIST_KONSULTASI_FAIL"

export const ADD_NEW_KONSULTASI = "ADD_NEW_KONSULTASI"
export const ADD_KONSULTASI_SUCCESS = "ADD_KONSULTASI_SUCCESS"
export const ADD_KONSULTASI_FAIL = "ADD_KONSULTASI_FAIL"

export const READ_KONSULTASI = "READ_KONSULTASI"
export const READ_KONSULTASI_SUCCESS = "READ_KONSULTASI_SUCCESS"
export const READ_KONSULTASI_FAIL = "READ_KONSULTASI_FAIL"
