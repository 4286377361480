import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_PENGELUARAN,
  DELETE_PENGELUARAN,
  GET_DATA_PENGELUARAN,
  UPDATE_PENGELUARAN,
} from "./actionTypes"
import {
  getDataPengeluaranSuccess,
  getDataPengeluaranFail,
  addPengeluaranFail,
  addPengeluaranSuccess,
  updatePengeluaranSuccess,
  updatePengeluaranFail,
  deletePengeluaranSuccess,
  deletePengeluaranFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataPengeluaran,
  addNewPengeluaran,
  updatePengeluaran,
  deletePengeluaran,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"

function* fetchDataPengeluaran() {
  yield put(startLoading());
  try {
    const response = yield call(getDataPengeluaran)
    yield put(getDataPengeluaranSuccess(response))
  } catch (error) {
    yield put(getDataPengeluaranFail(error))
  }
  yield put(stopLoading());
}

function* onAddNewPengeluaran({ payload: pengeluaran }) {
  yield put(startLoading());
  try {
    const response = yield call(addNewPengeluaran, pengeluaran)
    yield put(addPengeluaranSuccess(response))
  } catch (error) {
    yield put(addPengeluaranFail(error))
  }
  yield put(stopLoading());
}

function* onUpdatePengeluaran({ payload: pengeluaran }) {
  yield put(startLoading());
  try {
    // console.log(pengeluaran)
    const response = yield call(updatePengeluaran, pengeluaran)
    yield put(updatePengeluaranSuccess(response))
  } catch (error) {
    yield put(updatePengeluaranFail(error))
  }
  yield put(stopLoading());
}

function* onDeletePengeluaran({ payload: pengeluaran }) {
  yield put(startLoading());
  try {
    const response = yield call(deletePengeluaran, pengeluaran)
    yield put(deletePengeluaranSuccess(response))
  } catch (error) {
    yield put(deletePengeluaranFail(error))
  }
  yield put(stopLoading());
}

function* DataPengeluaranSaga() {
  yield takeEvery(GET_DATA_PENGELUARAN, fetchDataPengeluaran)
  yield takeEvery(ADD_NEW_PENGELUARAN, onAddNewPengeluaran)
  yield takeEvery(UPDATE_PENGELUARAN, onUpdatePengeluaran)
  yield takeEvery(DELETE_PENGELUARAN, onDeletePengeluaran)
}

export default DataPengeluaranSaga
