import {takeEvery, put, call} from 'redux-saga/effects';

// Calender Redux States
import {GET_DATA_AFFILIASI} from './actionTypes';
import {getDataAffiliasiSuccess, getDataAffiliasiFail} from './actions';

//Include Both Helper File with needed methods
import {getDataAffiliasi} from '../../helpers/backend_helper';

import {  
  startLoading, 
  stopLoading,
} from "../actions"


function* fetchDataAffiliasi() {
  yield put(startLoading());
  try {
    const response = yield call(getDataAffiliasi);
    yield put(getDataAffiliasiSuccess(response));
  } catch (error) {
    yield put(getDataAffiliasiFail(error));
  }
  yield put(stopLoading());
}

function* DataAffiliasiSaga() {
  yield takeEvery(GET_DATA_AFFILIASI, fetchDataAffiliasi);
}

export default DataAffiliasiSaga;
