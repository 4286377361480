import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_BOOKING,
  DELETE_BOOKING,
  // GET_CATEGORIES,
  GET_DATA_BOOKING,
  GET_DATA_BOOKING_FILTER,
  UPDATE_BOOKING,
  EXPORT_BOOKING,
  EXPORT_SERTIFIKAT,
} from "./actionTypes"
import {
  getDataBookingSuccess,
  getDataBookingFail,
  getDataBookingFilterSuccess,
  getDataBookingFilterFail,
  addBookingFail,
  addBookingSuccess,
  updateBookingSuccess,
  updateBookingFail,
  exportBookingSuccess,
  exportBookingFail,
  exportSertifikatSuccess,
  exportSertifikatFail,
  deleteBookingSuccess,
  deleteBookingFail,
  // getCategoriesSuccess,
  // getCategoriesFail,
} from "./actions"

import { startLoading, stopLoading } from "../actions"

//Include Both Helper File with needed methods
import {
  getDataBooking,
  getDataBookingFilter,
  addNewBooking,
  updateBooking,
  exportBooking,
  exportSertifikat,
  deleteBooking,
  // getCategories,
} from "../../helpers/backend_helper"

function* fetchDataBooking() {
  yield put(startLoading())
  try {
    const response = yield call(getDataBooking)
    yield put(getDataBookingSuccess(response))
  } catch (error) {
    yield put(getDataBookingFail(error))
  }
  yield put(stopLoading())
}

function* fetchDataBookingFilter({ payload: booking }) {
  yield put(startLoading())
  try {
    const response = yield call(getDataBookingFilter, booking)
    yield put(getDataBookingFilterSuccess(response))
  } catch (error) {
    yield put(getDataBookingFilterFail(error))
  }
  yield put(stopLoading())
}

function* onAddNewBooking({ payload: booking }) {
  yield put(startLoading())
  try {
    const response = yield call(addNewBooking, booking)
    yield put(addBookingSuccess(response))
  } catch (error) {
    yield put(addBookingFail(error))
  }
  yield put(stopLoading())
}

function* onUpdateBooking({ payload: booking }) {
  yield put(startLoading())
  try {
    // console.log(booking)
    const response = yield call(updateBooking, booking)
    yield put(updateBookingSuccess(response))
  } catch (error) {
    yield put(updateBookingFail(error))
  }
  yield put(stopLoading())
}

function* onExportBooking({ payload: booking }) {
  yield put(startLoading())
  try {
    // console.log(booking)
    const response = yield call(exportBooking, booking)
    yield put(exportBookingSuccess(response))
  } catch (error) {
    yield put(exportBookingFail(error))
  }
  yield put(stopLoading())
}

function* onExportSertifikat({ payload: booking }) {
  yield put(startLoading())
  try {
    // console.log(booking)
    const response = yield call(exportSertifikat, booking)
    yield put(exportSertifikatSuccess(response))
  } catch (error) {
    yield put(exportSertifikatFail(error))
  }
  yield put(stopLoading())
}

function* onDeleteBooking({ payload: booking }) {
  yield put(startLoading())
  try {
    const response = yield call(deleteBooking, booking)
    yield put(deleteBookingSuccess(response))
  } catch (error) {
    yield put(deleteBookingFail(error))
  }
  yield put(stopLoading())
}

// function* onGetCategories() {
//   try {
//     const response = yield call(getCategories)
//     yield put(getCategoriesSuccess(response))
//   } catch (error) {
//     yield put(getCategoriesFail(error))
//   }
// }

function* DataBookingSaga() {
  yield takeEvery(GET_DATA_BOOKING, fetchDataBooking)
  yield takeEvery(GET_DATA_BOOKING_FILTER, fetchDataBookingFilter)
  yield takeEvery(ADD_NEW_BOOKING, onAddNewBooking)
  yield takeEvery(UPDATE_BOOKING, onUpdateBooking)
  yield takeEvery(EXPORT_BOOKING, onExportBooking)
  yield takeEvery(EXPORT_SERTIFIKAT, onExportSertifikat)
  yield takeEvery(DELETE_BOOKING, onDeleteBooking)
  // yield takeEvery(GET_CATEGORIES, onGetCategories)
}

export default DataBookingSaga
