import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { env } from "../../helpers/config"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

import moment from "moment"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

import { getDataPresensi, getDataKaryawan } from "../../store/actions"

const Presensi = props => {
  const { onGetDataPresensi, dataPresensi, dataKaryawan, onGetDataKaryawan } =
    props
  const [modal, setModal] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [presensi, setPresensi] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [imagePresensi, setImagePresensi] = useState([])
  const [errImage, setErrImage] = useState("")
  const [status, setStatus] = useState("")
  const [tanggalFilter, setTanggalFilter] = useState("")
  const [karyawanFilter, setKaryawanFilter] = useState("")
  const [totalJamKaryawan, setTotalJamKaryawan] = useState(0)

  useEffect(() => {
    if (dataPresensi) {
      var totalJamKerja = 0

      dataPresensi.map((item, index) => {
        item.nama = !isEmpty(item?.detail[0])
          ? item.detail[0].nama
          : "Akun Dihapus"

        item.pagi =
          !isEmpty(item.presensi_pagi) &&
          item.presensi_pagi.klinik &&
          item.presensi_pagi.jam ? (
            <>
              {item.presensi_pagi.jam} -{" "}
              <Badge
                className={
                  "font-size-12 badge bg-" +
                  (item.presensi_pagi.klinik === "singosari"
                    ? "primary"
                    : item.presensi_pagi.klinik === "joyoagung"
                    ? "success"
                    : item.presensi_pagi.klinik === "diluar"
                    ? "warning"
                    : "danger")
                }
                pill
              >
                {item.presensi_pagi.klinik.charAt(0).toUpperCase() +
                  item.presensi_pagi.klinik.slice(1)}
              </Badge>
            </>
          ) : (
            "Belum Presensi"
          )

        item.sore =
          !isEmpty(item.presensi_sore) &&
          item.presensi_sore.klinik &&
          item.presensi_sore.jam ? (
            <>
              {item.presensi_sore.jam} -{" "}
              <Badge
                className={
                  "font-size-12 badge bg-" +
                  (item.presensi_sore.klinik === "singosari"
                    ? "primary"
                    : item.presensi_sore.klinik === "joyoagung"
                    ? "success"
                    : item.presensi_pagi.klinik === "diluar"
                    ? "warning"
                    : "danger")
                }
                pill
              >
                {item.presensi_sore.klinik.charAt(0).toUpperCase() +
                  item.presensi_sore.klinik.slice(1)}
              </Badge>
            </>
          ) : (
            "Belum Presensi"
          )
        item.tanggalFormated = new Date(item.tanggal).toLocaleString("ID", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })

        item.action = (
          <>
            <Button
              key={`detail-${index}`}
              color="success"
              outline
              className="font-16 btn-block btn-sm"
              onClick={e => handleOpenModalDetail(item)}
            >
              Detail
            </Button>
          </>
        )

        let diffDuration = 0

        if (!isEmpty(item?.presensi_pagi) || !isEmpty(item?.presensi_sore)) {
          if (
            item?.presensi_pagi?.klinik !== "izin" ||
            item?.presensi_sore?.klinik !== "izin"
          ) {
            var startTime = moment(
              !isEmpty(item?.presensi_pagi)
                ? item?.presensi_pagi?.jam
                : "10:00",
              "HH:mm:ss a"
            )
            var endTime = moment(
              !isEmpty(item?.presensi_sore)
                ? item?.presensi_sore?.jam
                : "14:00",
              "HH:mm:ss a"
            )
            var duration = moment.duration(endTime.diff(startTime))
            diffDuration = parseFloat(duration.asHours())
          }
        }
        totalJamKerja += diffDuration

        if (diffDuration > 8) {
          diffDuration = `8 Jam + Lembur ${
            Number.isInteger(diffDuration)
              ? diffDuration
              : (diffDuration - 8).toFixed(1)
          }`
        } else {
          diffDuration = Number.isInteger(diffDuration)
            ? diffDuration
            : diffDuration.toFixed(1)
        }

        item.jam_kerja = diffDuration + " Jam"

        // item.image1 = (
        //   <CardImg
        //     src={env.IMG_URL + item.image}
        //     alt="image-presensi"
        //     className="avatar-lg img-thumbnail img-fluid"
        //   />
        // )
      })

      setTotalJamKaryawan(totalJamKerja)
    }

    if (!modal && !isEmpty(presensi) && !!isEdit) {
      // setTimeout(() => {
      setPresensi({})
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, presensi, dataPresensi])

  // const toggle = close => {
  //   if (close) {
  //     setImagePresensi([])
  //     setErrImage("")
  //     setStatus("")
  //   }
  //   setModal(!modal)
  // }

  const toggleDetail = close => {
    // console.log(booking)
    setModalDetail(!modalDetail)
    if (close) {
      setPresensi({})
    }
  }

  const handleOpenModalDetail = data => {
    if (data) {
      setPresensi(data)
    }
    toggleDetail()
  }

  // const handleOpenModal = data => {
  //   // console.log("data", data)
  //   if (data) {
  //     // console.log("data exist")
  //     setPresensi({
  //       _id: data._id,
  //       id_affiliasi: data.id_affiliasi,
  //       nominal: data.nominal,
  //       status: data.status,
  //       image: data.image,
  //     })
  //     setImagePresensi([data.image])
  //     setStatus(data.status)

  //     setIsEdit(true)
  //   } else {
  //     // console.log("data not exist")
  //     setIsEdit(false)
  //   }
  //   toggle()
  // }

  useEffect(() => {
    onGetDataKaryawan()
  }, [])

  useEffect(() => {
    onGetDataPresensi({
      id_karyawan: karyawanFilter,
      tanggal_filter: tanggalFilter,
    })
  }, [tanggalFilter, karyawanFilter])

  const handleValidPresensiSubmit = (e, values) => {
    const { onAddNewPresensi } = props

    if (
      (isEmpty(imagePresensi[0]) || typeof imagePresensi[0] === "undefined") &&
      !!isEdit &&
      status === "selesai"
    ) {
      setErrImage("Gambar transfer harus di upload..")
    } else {
      const formData = new FormData()
      formData.append("status", values.status)
      formData.append("image", status === "selesai" ? imagePresensi[0] : "")

      if (isEdit) {
        formData.append("id", presensi._id)
        // onUpdatePresensi(formData)
      } else {
        setErrImage("")
        // onAddNewPresensi(formData)
      }

      setImagePresensi([])
      setStatus("")
      toggle()
    }
  }

  const handleDeletePresensi = () => {
    const { onDeletePresensi } = props
    onDeletePresensi(presensi._id)
    setDeleteModal(false)
    setImagePresensi([])
    setStatus("")
    toggle()
  }

  function handleImagePresensi(files) {
    if (files.length > 1) {
      setErrImage("Maksimal upload 1 gambar")
    } else {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setImagePresensi(files)
      setErrImage("")
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const data = {
    columns: [
      {
        label: "Nama Karyawan",
        field: "nama",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tanggal",
        field: "tanggalFormated",
        sort: "asc",
        width: 200,
      },
      {
        label: "Pagi",
        field: "pagi",
        sort: "asc",
        width: 200,
      },
      {
        label: "Sore",
        field: "sore",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total Jam Kerja",
        field: "jam_kerja",
        sort: "asc",
        width: 200,
      },
      {
        label: "Detail",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: dataPresensi,
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePresensi}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Presensi | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <div className="page-title-box">
            <Row className="col-12">
              <Col md="8" className="align-self-center">
                <h6 className="page-title">Presensi Karyawan</h6>
                <ol className="breadcrumb m-0">
                  {karyawanFilter && (
                    <Row className="ms-2 mt-3">
                      <h6>
                        Nama Karyawan :{" "}
                        {!isEmpty(dataKaryawan) &&
                          dataKaryawan.flatMap(item => {
                            return item._id === karyawanFilter && item.nama
                          })}
                      </h6>
                      <h6>
                        Total Jam Kerja :{" "}
                        {Number.isInteger(totalJamKaryawan)
                          ? totalJamKaryawan
                          : totalJamKaryawan.toFixed(1)}{" "}
                        Jam
                      </h6>
                    </Row>
                  )}
                </ol>
              </Col>

              <Col md="2">
                <div className="">
                  <label
                    htmlFor="example-month-input"
                    className="col-form-label"
                  >
                    {" "}
                    Filter Karyawan
                  </label>
                  <AvForm onValidSubmit={handleValidPresensiSubmit}>
                    <Row form>
                      <AvField
                        type="select"
                        // label="Status Tarik Dana"
                        name="karyawan_filter"
                        validate={{
                          required: { value: false },
                        }}
                        onChange={e => setKaryawanFilter(e.target.value)}
                        value={karyawanFilter}
                      >
                        <option value="">-- Semua Karyawan --</option>
                        {!isEmpty(dataKaryawan) &&
                          dataKaryawan.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.nama}
                            </option>
                          ))}
                      </AvField>
                    </Row>
                  </AvForm>
                </div>
              </Col>
              <Col md="2">
                <div className="">
                  <label
                    htmlFor="example-month-input"
                    className="col-form-label"
                  >
                    {" "}
                    Filter Bulan
                  </label>
                  <input
                    className="form-control"
                    type="month"
                    // defaultValue="2022-05"
                    id="example-month-input"
                    onChange={val => setTanggalFilter(val.target.value)}
                  />
                </div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col className="col-12 ">
              <Card>
                <CardBody>
                  {/* <Col className="col-12 mt-4"> */}
                  {/*   <Breadcrumbs breadcrumbItem="Presensi Karyawan" /> */}
                  {/* </Col> */}

                  {/* <CardTitle className="h4">Data Presensi</CardTitle> */}
                  {/* <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modalDetail}
            className="modal-dialog modal-dialog-scrollable modal-md"
          >
            <ModalHeader toggle={toggleDetail} tag="h4">
              Detail Presensi
            </ModalHeader>
            <ModalBody>
              <div>
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="col" width="200">
                        Tanggal Presensi
                      </th>
                      <td scope="col">
                        {presensi ? presensi.tanggalFormated : ""}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" width="200">
                        Presensi Pagi
                      </th>
                      <td scope="col">
                        {presensi.pagi}
                        <br />
                        {presensi?.presensi_pagi?.keterangan || "-"}

                        {presensi?.presensi_pagi &&
                          presensi?.presensi_pagi?.selfie && (
                            <>
                              <br />
                              <img
                                height="150"
                                className="bg-light img-thumbnail"
                                alt="Selfie Presensi"
                                src={
                                  env.IMG_URL + presensi?.presensi_pagi?.selfie
                                }
                              />
                            </>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Presensi Sore
                      </th>
                      <td scope="col">
                        {presensi.sore}
                        <br />
                        {presensi?.presensi_sore?.keterangan || "-"}

                        {presensi?.presensi_sore &&
                          presensi?.presensi_sore?.selfie && (
                            <>
                              <br />
                              <img
                                height="150"
                                className="bg-light img-thumbnail"
                                alt="Selfie Presensi"
                                src={
                                  env.IMG_URL + presensi?.presensi_sore?.selfie
                                }
                              />
                            </>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Total Jam Kerja
                      </th>
                      <td scope="col">{presensi ? presensi.jam_kerja : ""}</td>
                    </tr>

                    <tr>
                      <th scope="col" width="200">
                        <ul className="list-unstyled mb-0">
                          <li>Tugas Harian</li>
                        </ul>
                      </th>
                      <td scope="col">
                        <ul className="list-unstyled mb-0">
                          <li>
                            {/* &nbsp; */}
                            <ul className="list-styled mb-0">
                              {presensi && !isEmpty(presensi.tugas_harian)
                                ? presensi.tugas_harian.map(item => (
                                    <li>{item}</li>
                                  ))
                                : "Belum Laporan"}
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ModalBody>
          </Modal>

          {/* <Modal isOpen={modal} className="modal-dialog modal-lg">
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Tarik Dana" : "Tambah Tarik Dana"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidPresensiSubmit}>
                <Row form>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="id_affiliasi"
                      label="Id Affiliasi"
                      type="text"
                      disabled={true}
                      value={presensi ? presensi.id_affiliasi : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="nominal"
                      label="Nominal"
                      type="text"
                      disabled={true}
                      value={presensi ? presensi.nominal : ""}
                    />
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      type="select"
                      name="status"
                      label="Status Tarik Dana"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={e => setStatus(e.target.value)}
                      value={presensi ? presensi.status : ""}
                    >
                      <option hidden value="">
                        -- Pilih Status --
                      </option>
                      <option value="pending">Pending</option>
                      <option value="selesai">Selesai</option>
                      <option value="batal">Batal</option>
                    </AvField>
                  </Col>

                  {status === "selesai" && (
                    <Col className="col-12 mb-3">
                      <Dropzone
                        accept="image/jpeg, image/png"
                        onDrop={img => {
                          handleImagePresensi(img)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                              </div>
                              <h6>
                                Seret Gambar Presensi atau Klik untuk Upload
                              </h6>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {!isEmpty(imagePresensi) &&
                          imagePresensi.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={
                                          f.name || f.toString().split("-")[1]
                                        }
                                        src={f.preview || env.IMG_URL + f}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name || f.toString().split("-")[1]}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                      </div>
                      <div className=" is-invalid"></div>
                      <div className="invalid-feedback">{errImage}</div>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-light me-2"
                        onClick={() => toggle("close")}
                      >
                        Tutup
                      </button>
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        type="submit"
                        className="btn btn-success save-presensi"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal> */}
        </div>
      </div>
    </React.Fragment>
  )
}

Presensi.propTypes = {
  dataPresensi: PropTypes.array,
  onGetDataPresensi: PropTypes.func,
  dataKaryawan: PropTypes.array,
  onGetDataKaryawan: PropTypes.func,
  // onUpdatePresensi: PropTypes.func,
}

const mapStateToProps = ({ dataPresensiReducer, dataUserReducer }) => ({
  dataPresensi: dataPresensiReducer.dataPresensi,
  dataKaryawan: dataUserReducer.dataKaryawan,
})

const mapDispatchToProps = dispatch => ({
  onGetDataPresensi: keyword => dispatch(getDataPresensi(keyword)),
  onGetDataKaryawan: () => dispatch(getDataKaryawan()),
  // onUpdatePresensi: presensi => dispatch(updatePresensi(presensi)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Presensi)
