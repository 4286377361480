import {
  GET_DATA_LIST_TASK_SUCCESS,
  GET_DATA_LIST_TASK_FAIL,
  ADD_LIST_TASK_SUCCESS,
  ADD_LIST_TASK_FAIL,
  UPDATE_LIST_TASK_SUCCESS,
  UPDATE_LIST_TASK_FAIL,
  DELETE_LIST_TASK_SUCCESS,
  DELETE_LIST_TASK_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataListTask: [],
  error: {},
}

const DataListTaskReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_LIST_TASK_SUCCESS:
      return {
        ...state,
        dataListTask: action.payload,
      }

    case GET_DATA_LIST_TASK_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_LIST_TASK_SUCCESS:
      return {
        ...state,
        dataListTask: [...state.dataListTask, action.payload],
      }

    case ADD_LIST_TASK_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_LIST_TASK_SUCCESS:
      return {
        ...state,
        dataListTask: state.dataListTask.map(task =>
          task._id === action.payload._id
            ? { task, ...action.payload }
            : task
        ),
      }

    case UPDATE_LIST_TASK_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_LIST_TASK_SUCCESS:
      return {
        ...state,
        dataListTask: state.dataListTask.filter(
          task => task._id !== action.payload._id
        ),
      }

    case DELETE_LIST_TASK_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default DataListTaskReducer
