import {
  GET_DATA_CAROUSEL,
  GET_DATA_CAROUSEL_FAIL,
  GET_DATA_CAROUSEL_SUCCESS,
  ADD_NEW_CAROUSEL,
  ADD_CAROUSEL_SUCCESS,
  ADD_CAROUSEL_FAIL,
  UPDATE_CAROUSEL,
  UPDATE_CAROUSEL_SUCCESS,
  UPDATE_CAROUSEL_FAIL,
  DELETE_CAROUSEL,
  DELETE_CAROUSEL_SUCCESS,
  DELETE_CAROUSEL_FAIL,
} from "./actionTypes"

export const getDataCarousel = () => ({
  type: GET_DATA_CAROUSEL,
})

export const getDataCarouselSuccess = dataCarousel => ({
  type: GET_DATA_CAROUSEL_SUCCESS,
  payload: dataCarousel,
})

export const getDataCarouselFail = error => ({
  type: GET_DATA_CAROUSEL_FAIL,
  payload: error,
})

export const addNewCarousel = carousel => ({
  type: ADD_NEW_CAROUSEL,
  payload: carousel,
})

export const addCarouselSuccess = carousel => ({
  type: ADD_CAROUSEL_SUCCESS,
  payload: carousel,
})

export const addCarouselFail = error => ({
  type: ADD_CAROUSEL_FAIL,
  payload: error,
})

export const updateCarousel = carousel => ({
  type: UPDATE_CAROUSEL,
  payload: carousel,
})

export const updateCarouselSuccess = carousel => ({
  type: UPDATE_CAROUSEL_SUCCESS,
  payload: carousel,
})

export const updateCarouselFail = error => ({
  type: UPDATE_CAROUSEL_FAIL,
  payload: error,
})

export const deleteCarousel = carousel => ({
  type: DELETE_CAROUSEL,
  payload: carousel,
})

export const deleteCarouselSuccess = carousel => ({
  type: DELETE_CAROUSEL_SUCCESS,
  payload: carousel,
})

export const deleteCarouselFail = error => ({
  type: DELETE_CAROUSEL_FAIL,
  payload: error,
})
