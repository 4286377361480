/* DATA_CAROUSEL */
export const GET_DATA_CAROUSEL = "GET_DATA_CAROUSEL"
export const GET_DATA_CAROUSEL_SUCCESS = "GET_DATA_CAROUSEL_SUCCESS"
export const GET_DATA_CAROUSEL_FAIL = "GET_DATA_CAROUSEL_FAIL"

export const ADD_NEW_CAROUSEL = "ADD_NEW_CAROUSEL"
export const ADD_CAROUSEL_SUCCESS = "ADD_CAROUSEL_SUCCESS"
export const ADD_CAROUSEL_FAIL = "ADD_CAROUSEL_FAIL"

export const UPDATE_CAROUSEL = "UPDATE_CAROUSEL"
export const UPDATE_CAROUSEL_SUCCESS = "UPDATE_CAROUSEL_SUCCESS"
export const UPDATE_CAROUSEL_FAIL = "UPDATE_CAROUSEL_FAIL"

export const DELETE_CAROUSEL = "DELETE_CAROUSEL"
export const DELETE_CAROUSEL_SUCCESS = "DELETE_CAROUSEL_SUCCESS"
export const DELETE_CAROUSEL_FAIL = "DELETE_CAROUSEL_FAIL"

