import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  addNewRekening,
  deleteRekening,
  getDataRekening,
  updateRekening,
} from "../../store/actions"
import "./datatables.scss"

const Rekening = props => {
  const { onGetDataRekening, dataRekening } = props
  const [modal, setModal] = useState(false)
  const [rekening, setRekening] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  useEffect(() => {
    if (dataRekening) {
      dataRekening.map((item, index) => {
        item.action = (
          <Button
            color="primary"
            outline
            className="font-16 btn-block btn-sm"
            onClick={e => handleOpenModal(item)}
          >
            Ubah
          </Button>
        )
      })
    }

    if (!modal && !isEmpty(rekening) && !!isEdit) {
      // setTimeout(() => {
      setRekening({})
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, rekening, dataRekening])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOpenModal = data => {
    // console.log("data", data)
    if (data) {
      // console.log("data exist")
      setRekening({
        _id: data._id,
        nama: data.nama,
        noRek: data.noRek,
        atasNama: data.atasNama,
      })
      setIsEdit(true)
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  useEffect(() => {
    onGetDataRekening()
  }, [])

  const handleValidRekeningSubmit = (e, values) => {
    const { onAddNewRekening, onUpdateRekening } = props

    if (isEdit) {
      const updateRekening = {
        _id: rekening._id,
        nama: values.nama,
        noRek: values.noRek,
        atasNama: values.atasNama,
      }
      // update rekening
      onUpdateRekening(updateRekening)
    } else {
      const newRekening = {
        nama: values.nama,
        noRek: values.noRek,
        atasNama: values.atasNama,
      }
      // save new rekening
      onAddNewRekening(newRekening)
    }
    toggle()
  }

  const handleDeleteRekening = () => {
    const { onDeleteRekening } = props
    onDeleteRekening(rekening._id)
    setDeleteModal(false)
    toggle()
  }

  const data = {
    columns: [
      {
        label: "Nama",
        field: "nama",
        sort: "asc",
        width: 150,
      },
      {
        label: "noRek",
        field: "noRek",
        sort: "asc",
        width: 270,
      },
      {
        label: "Atas Nama",
        field: "atasNama",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: dataRekening,
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRekening}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Rekening | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs
                    breadcrumbItem="Rekening"
                    handleOpenModal={handleOpenModal}
                    buttonModal="Rekening"
                  />
                  {/* <CardTitle className="h4">Data Rekening</CardTitle>
                  <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Rekening" : "Tambah Rekening"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidRekeningSubmit}>
                <Row form>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="nama"
                      label="Nama Bank"
                      type="text"
                      errorMessage="Invalid nama Bank"
                      validate={{
                        required: { value: true },
                        minLength: { value: 3 },
                      }}
                      value={rekening ? rekening.nama : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="noRek"
                      label="Nomor Rekening"
                      type="number"
                      errorMessage="Invalid nomor rekening"
                      min={1}
                      validate={{
                        required: { value: true },
                        number: true,
                      }}
                      value={rekening ? rekening.noRek : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="atasNama"
                      label="Atas Nama"
                      type="text"
                      errorMessage="Invalid atas nama"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={rekening ? rekening.atasNama : ""}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={toggle}
                      >
                        Tutup
                      </button>
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-outline-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        type="submit"
                        className="btn btn-outline-success save-rekening"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

Rekening.propTypes = {
  dataRekening: PropTypes.array,
  onGetDataRekening: PropTypes.func,
  onAddNewRekening: PropTypes.func,
  onUpdateRekening: PropTypes.func,
  onDeleteRekening: PropTypes.func,
}

const mapStateToProps = ({ dataRekeningReducer }) => ({
  dataRekening: dataRekeningReducer.dataRekening,
})

const mapDispatchToProps = dispatch => ({
  onGetDataRekening: () => dispatch(getDataRekening()),
  onAddNewRekening: rekening => dispatch(addNewRekening(rekening)),
  onUpdateRekening: rekening => dispatch(updateRekening(rekening)),
  onDeleteRekening: rekening => dispatch(deleteRekening(rekening)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Rekening)
