/* DATA_BOOKING */
export const GET_DATA_BOOKING = "GET_DATA_BOOKING"
export const GET_DATA_BOOKING_SUCCESS = "GET_DATA_BOOKING_SUCCESS"
export const GET_DATA_BOOKING_FAIL = "GET_DATA_BOOKING_FAIL"

export const GET_DATA_BOOKING_FILTER = "GET_DATA_BOOKING_FILTER"
export const GET_DATA_BOOKING_FILTER_SUCCESS = "GET_DATA_BOOKING_FILTER_SUCCESS"
export const GET_DATA_BOOKING_FILTER_FAIL = "GET_DATA_BOOKING_FILTER_FAIL"

export const ADD_NEW_BOOKING = "ADD_NEW_BOOKING"
export const ADD_BOOKING_SUCCESS = "ADD_BOOKING_SUCCESS"
export const ADD_BOOKING_FAIL = "ADD_BOOKING_FAIL"

export const UPDATE_BOOKING = "UPDATE_BOOKING"
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS"
export const UPDATE_BOOKING_FAIL = "UPDATE_BOOKING_FAIL"

export const EXPORT_BOOKING = "EXPORT_BOOKING"
export const EXPORT_BOOKING_SUCCESS = "EXPORT_BOOKING_SUCCESS"
export const EXPORT_BOOKING_FAIL = "EXPORT_BOOKING_FAIL"

export const EXPORT_SERTIFIKAT = "EXPORT_SERTIFIKAT"
export const EXPORT_SERTIFIKAT_SUCCESS = "EXPORT_SERTIFIKAT_SUCCESS"
export const EXPORT_SERTIFIKAT_FAIL = "EXPORT_SERTIFIKAT_FAIL"

export const DELETE_BOOKING = "DELETE_BOOKING"
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS"
export const DELETE_BOOKING_FAIL = "DELETE_BOOKING_FAIL"

// export const GET_CATEGORIES = "GET_CATEGORIES"
// export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
// export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"
