import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  addNewBarang,
  deleteBarang,
  getDataBarang,
  getKategoriBarang,
  updateBarang,
} from "../../store/actions"
import "./datatables.scss"

const Barang = props => {
  const { onGetDataBarang, onGetKategoriBarang, dataBarang, kategoriBarang } =
    props
  const [modal, setModal] = useState(false)
  const [barang, setBarang] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [katLainnya, setKatLainnya] = useState(false)

  useEffect(() => {
    if (dataBarang) {
      dataBarang.map((item, index) => {
        item.action = (
          <Button
            color="primary"
            outline
            className="font-16 btn-block btn-sm"
            onClick={e => handleOpenModal(item)}
          >
            {/* <i className="mdi mdi-grease-pencil"></i>  */}
            Ubah
          </Button>
        )

        item.display_klinik = (
          <Badge
            className={
              "font-size-12 badge bg-" +
              (item.klinik === "singosari"
                ? "primary"
                : item.klinik === "joyoagung"
                ? "success"
                : "danger")
            }
            pill
          >
            {item.klinik.charAt(0).toUpperCase() + item.klinik.slice(1) ||
              "belum_dipilih"}
          </Badge>
        )
      })
    }

    if (!modal && !isEmpty(barang) && !!isEdit) {
      // setTimeout(() => {
      setBarang({})
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, barang, dataBarang, kategoriBarang])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOpenModal = data => {
    // console.log("data", data)
    if (data) {
      // console.log("data exist")
      setBarang({
        _id: data._id,
        nama: data.nama,
        kategori: data.kategori,
        stock: data.stock,
        deskripsi: data.deskripsi,
        min: data.min,
        klinik: data.klinik,
      })
      setIsEdit(true)
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  useEffect(() => {
    onGetDataBarang()
  }, [])

  useEffect(() => {
    onGetKategoriBarang()
  }, [dataBarang])

  const handleValidBarangSubmit = (e, values) => {
    const { onAddNewBarang, onUpdateBarang } = props
    let kategori
    if (values.kategori === "lainnya") {
      kategori = values.kat_barang_lainnya
    } else {
      kategori = values.kategori
    }

    if (isEdit) {
      const updateBarang = {
        _id: barang._id,
        nama: values.nama,
        kategori: kategori,
        stock: values.stock,
        deskripsi: values.deskripsi,
        min: values.min,
        klinik: values.klinik,
      }
      // update barang
      onUpdateBarang(updateBarang)
    } else {
      const newBarang = {
        nama: values.nama,
        kategori: kategori,
        stock: values.stock,
        deskripsi: values.deskripsi,
        min: values.min,
        klinik: values.klinik,
      }
      // save new barang
      onAddNewBarang(newBarang)
    }
    setKatLainnya(false)
    toggle()
  }

  const handleDeleteBarang = () => {
    const { onDeleteBarang } = props
    onDeleteBarang(barang._id)
    setDeleteModal(false)
    toggle()
  }

  const handleKatBarang = val => {
    if (val.target.value === "lainnya") {
      setKatLainnya(true)
    } else {
      setKatLainnya(false)
    }
  }

  const data = {
    columns: [
      {
        label: "Nama",
        field: "nama",
        sort: "asc",
        width: 150,
      },
      {
        label: "Kategori",
        field: "kategori",
        sort: "asc",
        width: 270,
      },
      {
        label: "Stock",
        field: "stock",
        sort: "asc",
        width: 200,
      },
      {
        label: "Deskripsi",
        field: "deskripsi",
        sort: "asc",
        width: 100,
      },
      {
        label: "Klinik",
        field: "display_klinik",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Created At",
      //   field: "createdAt",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Updated At",
      //   field: "updatedAt",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: dataBarang,
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBarang}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Barang | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs
                    breadcrumbItem="Barang"
                    handleOpenModal={handleOpenModal}
                    buttonModal="Barang"
                  />
                  {/* <CardTitle className="h4">Data Barang</CardTitle>
                  <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Barang" : "Tambah Barang"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidBarangSubmit}>
                <Row form>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="nama"
                      label="Nama Barang"
                      type="text"
                      errorMessage="Invalid nama barang"
                      validate={{
                        required: { value: true },
                        minLength: { value: 3 },
                      }}
                      value={barang ? barang.nama : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="stock"
                      label="Stock Barang"
                      type="number"
                      errorMessage="Invalid stock barang"
                      min={0}
                      validate={{
                        required: { value: true },
                        number: true,
                      }}
                      value={barang ? barang.stock : ""}
                      disabled={!!isEdit ? true : false}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      type="select"
                      name="kategori"
                      label="Kategori Barang"
                      validate={{
                        required: { value: true },
                      }}
                      value={barang ? barang.kategori : ""}
                      onChange={value => handleKatBarang(value)}
                    >
                      <option hidden value="">
                        -- Pilih Kategori --
                      </option>
                      {kategoriBarang &&
                        kategoriBarang.map((item, i) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      <option value="lainnya">Lainnya</option>
                    </AvField>
                    {katLainnya && (
                      <div className="ps-1 pt-3">
                        <AvField
                          name="kat_barang_lainnya"
                          type="textarea"
                          placeholder="Lainnya.."
                          validate={{
                            required: { value: katLainnya },
                          }}
                        ></AvField>
                      </div>
                    )}
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="deskripsi"
                      label="Deskripsi Barang"
                      type="text"
                      errorMessage="Invalid name"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={barang ? barang.deskripsi : ""}
                    />
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      name="min"
                      label="Minimum Barang"
                      type="number"
                      errorMessage="Invalid min barang"
                      min={1}
                      validate={{
                        required: { value: true },
                        number: true,
                      }}
                      value={barang ? barang.min : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      type="select"
                      name="klinik"
                      label="Lokasi Klinik"
                      validate={{
                        required: { value: true },
                      }}
                      value={barang ? barang.klinik : ""}
                    >
                      <option hidden value="">
                        -- Pilih Lokasi --
                      </option>
                      <option value="singosari">Singosari</option>
                      <option value="joyoagung">Joyoagung</option>
                    </AvField>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={toggle}
                      >
                        Tutup
                      </button>
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-outline-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        type="submit"
                        className="btn btn-outline-success save-barang"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

Barang.propTypes = {
  dataBarang: PropTypes.array,
  kategoriBarang: PropTypes.array,
  onGetDataBarang: PropTypes.func,
  onAddNewBarang: PropTypes.func,
  onUpdateBarang: PropTypes.func,
  onDeleteBarang: PropTypes.func,
  onGetKategoriBarang: PropTypes.func,
}

const mapStateToProps = ({ dataBarangReducer }) => ({
  dataBarang: dataBarangReducer.dataBarang,
  kategoriBarang: dataBarangReducer.kategoriBarang,
})

const mapDispatchToProps = dispatch => ({
  onGetDataBarang: () => dispatch(getDataBarang()),
  onGetKategoriBarang: () => dispatch(getKategoriBarang()),
  onAddNewBarang: barang => dispatch(addNewBarang(barang)),
  onUpdateBarang: barang => dispatch(updateBarang(barang)),
  onDeleteBarang: barang => dispatch(deleteBarang(barang)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Barang)
