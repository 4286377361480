import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_TEMUKAN_METODE,
  DELETE_TEMUKAN_METODE,
  // GET_CATEGORIES,
  GET_DATA_TEMUKAN_METODE,
  UPDATE_TEMUKAN_METODE,
} from "./actionTypes"
import {
  getDataTemukanMetodeSuccess,
  getDataTemukanMetodeFail,
  addTemukanMetodeFail,
  addTemukanMetodeSuccess,
  updateTemukanMetodeSuccess,
  updateTemukanMetodeFail,
  deleteTemukanMetodeSuccess,
  deleteTemukanMetodeFail,
  // getCategoriesSuccess,
  // getCategoriesFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataTemukanMetode,
  addNewTemukanMetode,
  updateTemukanMetode,
  deleteTemukanMetode,
  // getCategories,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"

function* fetchDataTemukanMetode() {
  yield put(startLoading());
  try {
    const response = yield call(getDataTemukanMetode)
    yield put(getDataTemukanMetodeSuccess(response))
  } catch (error) {
    yield put(getDataTemukanMetodeFail(error))
  }
  yield put(stopLoading());
}

function* onAddNewTemukanMetode({ payload: temukanMetode }) {
  yield put(startLoading());
  try {
    const response = yield call(addNewTemukanMetode, temukanMetode)
    yield put(addTemukanMetodeSuccess(response))
  } catch (error) {
    yield put(addTemukanMetodeFail(error))
  }
  yield put(stopLoading());
}

function* onUpdateTemukanMetode({ payload: temukanMetode }) {
  yield put(startLoading());
  try {
    // console.log(temukanMetode)
    const response = yield call(updateTemukanMetode, temukanMetode)
    yield put(updateTemukanMetodeSuccess(response))
  } catch (error) {
    yield put(updateTemukanMetodeFail(error))
  }
  yield put(stopLoading());
}

function* onDeleteTemukanMetode({ payload: temukanMetode }) {
  yield put(startLoading());
  try {
    const response = yield call(deleteTemukanMetode, temukanMetode)
    yield put(deleteTemukanMetodeSuccess(response))
  } catch (error) {
    yield put(deleteTemukanMetodeFail(error))
  }
  yield put(stopLoading());
}

// function* onGetCategories() {
//   try {
//     const response = yield call(getCategories)
//     yield put(getCategoriesSuccess(response))
//   } catch (error) {
//     yield put(getCategoriesFail(error))
//   }
// }

function* DataTemukanMetodeSaga() {
  yield takeEvery(GET_DATA_TEMUKAN_METODE, fetchDataTemukanMetode)
  yield takeEvery(ADD_NEW_TEMUKAN_METODE, onAddNewTemukanMetode)
  yield takeEvery(UPDATE_TEMUKAN_METODE, onUpdateTemukanMetode)
  yield takeEvery(DELETE_TEMUKAN_METODE, onDeleteTemukanMetode)
  // yield takeEvery(GET_CATEGORIES, onGetCategories)
}

export default DataTemukanMetodeSaga
