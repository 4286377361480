import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"
import Barang from "../pages/Barang/index"
import BarangSingosari from "../pages/Barang/singosari"
import BarangJoyoagung from "../pages/Barang/joyoagung"
import PaketKhitan from "../pages/PaketKhitan/index"
import Artikel from "../pages/Artikel/index"
import Booking from "../pages/Booking/index"
import User from "../pages/User/index"
import Carousel from "../pages/Carousel/index"
import Konsultasi from "../pages/Konsultasi/index"
import Rekening from "../pages/Rekening/index"
import Pengeluaran from "../pages/Pengeluaran/index"
import PengeluaranSingosari from "../pages/Pengeluaran/singosari"
import PengeluaranJoyoagung from "../pages/Pengeluaran/joyoagung"
import LevelAffiliasi from "../pages/LevelAffiliasi/index"
import TarikDana from "../pages/TarikDana/index"
import Presensi from "../pages/Presensi/index"
import VideoCall from "../pages/VideoCall/index"
import ListTask from "../pages/ListTask/index"
import TemukanMetode from "../pages/TemukanMetode/index"
import Neraca from "../pages/Neraca/index"
import Gaji from "../pages/Gaji/index"
import Voucher from "../pages/Voucher/index"
import AdditionalConfig from "../pages/AdditionalConfig/index"
import Review from "../pages/Review/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// accessRole = min user yg bisa akses

const userRoutes = [
  { path: "/dashboard", component: Dashboard, accessRole: "admin" },

  // { path: "/profile", component: UserProfile, accessRole: "all" },

  { path: "/barang", component: Barang, accessRole: "gudang" },
  {
    path: "/barang-singosari",
    component: BarangSingosari,
    accessRole: "admin",
  },
  {
    path: "/barang-joyoagung",
    component: BarangJoyoagung,
    accessRole: "admin",
  },

  { path: "/paketKhitan", component: PaketKhitan, accessRole: "superAdmin" },

  { path: "/voucher", component: Voucher, accessRole: "superAdmin" },

  { path: "/artikel", component: Artikel, accessRole: "admin" },

  { path: "/booking", component: Booking, accessRole: "dokter" },

  { path: "/user", component: User, accessRole: "admin" },

  { path: "/carousel", component: Carousel, accessRole: "admin" },

  { path: "/konsultasi", component: Konsultasi, accessRole: "dokter" },

  { path: "/tarikDana", component: TarikDana, accessRole: "superAdmin" },

  { path: "/presensi", component: Presensi, accessRole: "superAdmin" },

  { path: "/videoCall", component: VideoCall, accessRole: "dokter" },

  { path: "/listTask", component: ListTask, accessRole: "superAdmin" },

  { path: "/neraca", component: Neraca, accessRole: "superAdmin" },

  { path: "/gaji", component: Gaji, accessRole: "superAdmin" },

  { path: "/review", component: Review, accessRole: "superAdmin" },

  {
    path: "/pengaturanTambahan",
    component: AdditionalConfig,
    accessRole: "superAdmin",
  },

  {
    path: "/temukanMetode",
    component: TemukanMetode,
    accessRole: "superAdmin",
  },

  // { path: "/rekening", component: Rekening, accessRole: "superAdmin" },

  {
    path: "/levelAffiliasi",
    component: LevelAffiliasi,
    accessRole: "superAdmin",
  },

  { path: "/pengeluaran", component: Pengeluaran, accessRole: "gudang" },
  {
    path: "/pengeluaran-singosari",
    component: PengeluaranSingosari,
    accessRole: "admin",
  },
  {
    path: "/pengeluaran-joyoagung",
    component: PengeluaranJoyoagung,
    accessRole: "admin",
  },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
]

export { userRoutes, authRoutes }
