/* DATA_GAJI */
export const GET_DATA_GAJI = "GET_DATA_GAJI"
export const GET_DATA_GAJI_SUCCESS = "GET_DATA_GAJI_SUCCESS"
export const GET_DATA_GAJI_FAIL = "GET_DATA_GAJI_FAIL"

export const ADD_NEW_GAJI = "ADD_NEW_GAJI"
export const ADD_GAJI_SUCCESS = "ADD_GAJI_SUCCESS"
export const ADD_GAJI_FAIL = "ADD_GAJI_FAIL"

export const UPDATE_GAJI = "UPDATE_GAJI"
export const UPDATE_GAJI_SUCCESS = "UPDATE_GAJI_SUCCESS"
export const UPDATE_GAJI_FAIL = "UPDATE_GAJI_FAIL"

export const DELETE_GAJI = "DELETE_GAJI"
export const DELETE_GAJI_SUCCESS = "DELETE_GAJI_SUCCESS"
export const DELETE_GAJI_FAIL = "DELETE_GAJI_FAIL"
