import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  addNewLevelAffiliasi,
  deleteLevelAffiliasi,
  getDataLevelAffiliasi,
  updateLevelAffiliasi,
} from "../../store/actions"

const LevelAffiliasi = props => {
  const { onGetDataLevelAffiliasi, dataLevelAffiliasi } = props
  const [modal, setModal] = useState(false)
  const [levelaffiliasi, setLevelAffiliasi] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  useEffect(() => {
    if (dataLevelAffiliasi) {
      dataLevelAffiliasi.map((item, index) => {
        item.action = (
          <Button
            color="primary"
            className="font-16 btn-block btn-sm"
            outline
            onClick={e => handleOpenModal(item)}
          >
            {/* <i className="mdi mdi-grease-pencil"></i> */}
            Ubah
          </Button>
        )
        item.nominalFormated =
          "Rp." +
          item.nominalFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      })
    }

    if (!modal && !isEmpty(levelaffiliasi) && !!isEdit) {
      // setTimeout(() => {
      setLevelAffiliasi({})
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, levelaffiliasi, dataLevelAffiliasi])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOpenModal = data => {
    // console.log("data", data)
    if (data) {
      // console.log("data exist")
      setLevelAffiliasi({
        _id: data._id,
        level: data.level,
        nominalFee: data.nominalFee,
      })
      setIsEdit(true)
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  useEffect(() => {
    onGetDataLevelAffiliasi()
  }, [])

  const handleValidLevelAffiliasiSubmit = (e, values) => {
    const { onAddNewLevelAffiliasi, onUpdateLevelAffiliasi } = props

    if (isEdit) {
      const updateLevelAffiliasi = {
        id: levelaffiliasi._id,
        level: values.level,
        nominalFee: values.nominalFee,
      }
      onUpdateLevelAffiliasi(updateLevelAffiliasi)
    } else {
      const newLevelAffiliasi = {
        level: values.level,
        nominalFee: values.nominalFee,
      }
      onAddNewLevelAffiliasi(newLevelAffiliasi)
    }
    toggle()
  }

  const handleDeleteLevelAffiliasi = () => {
    const { onDeleteLevelAffiliasi } = props
    onDeleteLevelAffiliasi(levelaffiliasi._id)
    setDeleteModal(false)
    toggle()
  }

  function validateLevel(value, ctx, input, cb) {
    if (!!isEdit) {
      if (!value || value === "") {
        cb(false)
        return
      }

      var exists = dataLevelAffiliasi.find(item => item.level === value)
      if (exists) {
        cb(value === levelaffiliasi.level ? true : false)
      } else {
        cb(true)
      }
    } else {
      if (!value || value === "") {
        cb(false)
        return
      }

      var exists = dataLevelAffiliasi.find(item => item.level === value)
      if (exists) {
        cb(false)
      } else {
        cb(true)
      }
    }
  }

  const data = {
    columns: [
      {
        label: "Level",
        field: "level",
        sort: "asc",
        width: 150,
      },
      {
        label: "Nominal Fee",
        field: "nominalFormated",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: dataLevelAffiliasi,
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteLevelAffiliasi}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Level Affiliasi | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs
                    breadcrumbItem="Level Affiliasi"
                    handleOpenModal={handleOpenModal}
                    buttonModal="Level Affiliasi"
                  />
                  {/* <CardTitle className="h4">Data LevelAffiliasi</CardTitle>
                  <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Level Affiliasi" : "Tambah Level Affiliasi"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidLevelAffiliasiSubmit}>
                <Row form>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="level"
                      label="Level Affiliasi"
                      type="text"
                      errorMessage="Invalid level / duplikasi level"
                      validate={{ custom: validateLevel }}
                      disabled={!!isEdit ? true : false}
                      // validate={{
                      //   required: { value: true },
                      //   minLength: { value: 3 },
                      // }}
                      value={levelaffiliasi ? levelaffiliasi.level : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="nominalFee"
                      label="Nominal Fee"
                      type="number"
                      errorMessage="Invalid nominal"
                      min={1}
                      validate={{
                        required: { value: true },
                        number: true,
                      }}
                      value={levelaffiliasi ? levelaffiliasi.nominalFee : ""}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={toggle}
                      >
                        Tutup
                      </button>
                      {/* {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )} */}
                      <button
                        type="submit"
                        className="btn btn-outline-success save-levelaffiliasi"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

LevelAffiliasi.propTypes = {
  dataLevelAffiliasi: PropTypes.array,
  onGetDataLevelAffiliasi: PropTypes.func,
  onAddNewLevelAffiliasi: PropTypes.func,
  onUpdateLevelAffiliasi: PropTypes.func,
  onDeleteLevelAffiliasi: PropTypes.func,
}

const mapStateToProps = ({ dataLevelAffiliasiReducer }) => ({
  dataLevelAffiliasi: dataLevelAffiliasiReducer.dataLevelAffiliasi,
})

const mapDispatchToProps = dispatch => ({
  onGetDataLevelAffiliasi: () => dispatch(getDataLevelAffiliasi()),
  onAddNewLevelAffiliasi: levelaffiliasi =>
    dispatch(addNewLevelAffiliasi(levelaffiliasi)),
  onUpdateLevelAffiliasi: levelaffiliasi =>
    dispatch(updateLevelAffiliasi(levelaffiliasi)),
  onDeleteLevelAffiliasi: levelaffiliasi =>
    dispatch(deleteLevelAffiliasi(levelaffiliasi)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LevelAffiliasi)
