import {
  GET_DATA_USER,
  GET_DATA_USER_FAIL,
  GET_DATA_USER_SUCCESS,
  GET_DATA_KARYAWAN,
  GET_DATA_KARYAWAN_FAIL,
  GET_DATA_KARYAWAN_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  SEARCH_USER,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
} from "./actionTypes"

export const getDataUser = () => ({
  type: GET_DATA_USER,
})

export const getDataUserSuccess = dataUser => ({
  type: GET_DATA_USER_SUCCESS,
  payload: dataUser,
})

export const getDataUserFail = error => ({
  type: GET_DATA_USER_FAIL,
  payload: error,
})

export const getDataKaryawan = () => ({
  type: GET_DATA_KARYAWAN,
})

export const getDataKaryawanSuccess = dataKaryawan => ({
  type: GET_DATA_KARYAWAN_SUCCESS,
  payload: dataKaryawan,
})

export const getDataKaryawanFail = error => ({
  type: GET_DATA_KARYAWAN_FAIL,
  payload: error,
})

export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const searchUser = user => ({
  type: SEARCH_USER,
  payload: user,
})

export const searchUserSuccess = user => ({
  type: SEARCH_USER_SUCCESS,
  payload: user,
})

export const searchUserFail = error => ({
  type: SEARCH_USER_FAIL,
  payload: error,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})
