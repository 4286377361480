import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_BARANG,
  DELETE_BARANG,
  GET_KATEGORI_BARANG,
  GET_DATA_BARANG,
  UPDATE_BARANG,
} from "./actionTypes"
import {
  getDataBarangSuccess,
  getDataBarangFail,
  addBarangFail,
  addBarangSuccess,
  updateBarangSuccess,
  updateBarangFail,
  deleteBarangSuccess,
  deleteBarangFail,
  getKategoriBarangSuccess,
  getKategoriBarangFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataBarang,
  addNewBarang,
  updateBarang,
  deleteBarang,
  getKategoriBarang,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"

function* fetchDataBarang() {
  yield put(startLoading());
  try {
    const response = yield call(getDataBarang)
    yield put(getDataBarangSuccess(response))
  } catch (error) {
    yield put(getDataBarangFail(error))
  }
  yield put(stopLoading());
}

function* onAddNewBarang({ payload: data }) {
  yield put(startLoading());
  try {
    const response = yield call(addNewBarang, data)
    yield put(addBarangSuccess(response))
  } catch (error) {
    yield put(addBarangFail(error))
  }
  yield put(stopLoading());
}

function* onUpdateBarang({ payload: data }) {
  yield put(startLoading());
  try {
    // console.log(data)
    const response = yield call(updateBarang, data)
    yield put(updateBarangSuccess(response))
  } catch (error) {
    yield put(updateBarangFail(error))
  }
  yield put(stopLoading());
}

function* onDeleteBarang({ payload: data }) {
  yield put(startLoading());
  try {
    const response = yield call(deleteBarang, data)
    yield put(deleteBarangSuccess(response))
  } catch (error) {
    yield put(deleteBarangFail(error))
  }
  yield put(stopLoading());
}

function* onGetKategoriBarang() {
  yield put(startLoading());
  try {
    const response = yield call(getKategoriBarang)
    yield put(getKategoriBarangSuccess(response))
  } catch (error) {
    yield put(getKategoriBarangFail(error))
  }
  yield put(stopLoading());
}

function* DataBarangSaga() {
  yield takeEvery(GET_DATA_BARANG, fetchDataBarang)
  yield takeEvery(ADD_NEW_BARANG, onAddNewBarang)
  yield takeEvery(UPDATE_BARANG, onUpdateBarang)
  yield takeEvery(DELETE_BARANG, onDeleteBarang)
  yield takeEvery(GET_KATEGORI_BARANG, onGetKategoriBarang)
}

export default DataBarangSaga
