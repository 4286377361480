import {
  GET_DATA_AFFILIASI_SUCCESS,
  GET_DATA_AFFILIASI_FAIL,
} from './actionTypes';

const INIT_STATE = {
  dataAffiliasi: [],
  error: {},
};

const DataAffiliasiReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_AFFILIASI_SUCCESS:
      // console.log("res redc ", action.payload)
      return {
        ...state,
        dataAffiliasi: action.payload,
      };

    case GET_DATA_AFFILIASI_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default DataAffiliasiReducer;
