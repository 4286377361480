import {
  GET_IO_NOTIF,
  GET_IO_NOTIF_SUCCESS,
  GET_IO_NOTIF_FAIL,
} from "./actionTypes"

export const getIoNotif = () => ({
  type: GET_IO_NOTIF,
})

export const getIoNotifSuccess = ioNotif => ({
  type: GET_IO_NOTIF_SUCCESS,
  payload: ioNotif,
})

export const getIoNotifFail = error => ({
  type: GET_IO_NOTIF_FAIL,
  payload: error,
})
