import {
  GET_DATA_AFFILIASI,
  GET_DATA_AFFILIASI_FAIL,
  GET_DATA_AFFILIASI_SUCCESS,
} from './actionTypes';

export const getDataAffiliasi = () => ({
  type: GET_DATA_AFFILIASI,
});

export const getDataAffiliasiSuccess = dataAffiliasi => ({
  type: GET_DATA_AFFILIASI_SUCCESS,
  payload: dataAffiliasi,
});

export const getDataAffiliasiFail = error => ({
  type: GET_DATA_AFFILIASI_FAIL,
  payload: error,
});
