import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import { GET_DATA_REVIEW } from "./actionTypes"
import { getDataReviewSuccess, getDataReviewFail } from "./actions"

import { startLoading, stopLoading } from "../actions"

//Include Both Helper File with needed methods
import { getDataReview } from "../../helpers/backend_helper"

function* fetchDataReview({ payload: keyword }) {
  yield put(startLoading())
  try {
    const response = yield call(getDataReview, keyword)
    yield put(getDataReviewSuccess(response))
  } catch (error) {
    yield put(getDataReviewFail(error))
  }
  yield put(stopLoading())
}

function* DataReviewSaga() {
  yield takeEvery(GET_DATA_REVIEW, fetchDataReview)
}

export default DataReviewSaga
