import {
  GET_DATA_PRESENSI,
  GET_DATA_PRESENSI_FAIL,
  GET_DATA_PRESENSI_SUCCESS,
} from "./actionTypes"

export const getDataPresensi = keyword => ({
  type: GET_DATA_PRESENSI,
  payload: keyword,
})

export const getDataPresensiSuccess = dataPresensi => ({
  type: GET_DATA_PRESENSI_SUCCESS,
  payload: dataPresensi,
})

export const getDataPresensiFail = error => ({
  type: GET_DATA_PRESENSI_FAIL,
  payload: error,
})
