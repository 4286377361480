/* DATA_TEMUKAN_METODE */
export const GET_DATA_TEMUKAN_METODE = "GET_DATA_TEMUKAN_METODE"
export const GET_DATA_TEMUKAN_METODE_SUCCESS = "GET_DATA_TEMUKAN_METODE_SUCCESS"
export const GET_DATA_TEMUKAN_METODE_FAIL = "GET_DATA_TEMUKAN_METODE_FAIL"

export const ADD_NEW_TEMUKAN_METODE = "ADD_NEW_TEMUKAN_METODE"
export const ADD_TEMUKAN_METODE_SUCCESS = "ADD_TEMUKAN_METODE_SUCCESS"
export const ADD_TEMUKAN_METODE_FAIL = "ADD_TEMUKAN_METODE_FAIL"

export const UPDATE_TEMUKAN_METODE = "UPDATE_TEMUKAN_METODE"
export const UPDATE_TEMUKAN_METODE_SUCCESS = "UPDATE_TEMUKAN_METODE_SUCCESS"
export const UPDATE_TEMUKAN_METODE_FAIL = "UPDATE_TEMUKAN_METODE_FAIL"

export const DELETE_TEMUKAN_METODE = "DELETE_TEMUKAN_METODE"
export const DELETE_TEMUKAN_METODE_SUCCESS = "DELETE_TEMUKAN_METODE_SUCCESS"
export const DELETE_TEMUKAN_METODE_FAIL = "DELETE_TEMUKAN_METODE_FAIL"

// export const GET_CATEGORIES = "GET_CATEGORIES"
// export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
// export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"
