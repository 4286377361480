import {
  GET_DATA_BOOKING_SUCCESS,
  GET_DATA_BOOKING_FAIL,
  GET_DATA_BOOKING_FILTER_SUCCESS,
  GET_DATA_BOOKING_FILTER_FAIL,
  ADD_BOOKING_SUCCESS,
  ADD_BOOKING_FAIL,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAIL,
  EXPORT_BOOKING_SUCCESS,
  EXPORT_BOOKING_FAIL,
  EXPORT_SERTIFIKAT_SUCCESS,
  EXPORT_SERTIFIKAT_FAIL,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataBooking: [],
  dataBookingFilter: [],
  dataBookingExport: null,
  dataSertifikatExport: null,
  error: {},
}

const DataBookingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_BOOKING_SUCCESS:
      return {
        ...state,
        dataBooking: action.payload,
      }

    case GET_DATA_BOOKING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DATA_BOOKING_FILTER_SUCCESS:
      return {
        ...state,
        dataBookingFilter: action.payload,
      }

    case GET_DATA_BOOKING_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_BOOKING_SUCCESS:
      if (state.dataBooking.find(item => item._id === action.payload._id)) {
      } else {
        return {
          ...state,
          dataBooking: [action.payload, ...state.dataBooking],
          dataBookingFilter: [action.payload, ...state.dataBookingFilter],
        }

        // dataListKonsultasi: state.dataListKonsultasi.data.map(konsultasi =>
        //   konsultasi._id === action.payload._id
        //     ? { konsultasi, ...action.payload }
        //     : konsultasi
        // ),
      }

    // return {
    //   ...state,
    //   dataBooking: [action.payload, ...state.dataBooking],
    // }

    case ADD_BOOKING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        dataBooking: state.dataBooking.map(booking =>
          booking._id === action.payload._id
            ? { booking, ...action.payload }
            : booking
        ),
        dataBookingFilter: state.dataBookingFilter.map(booking =>
          booking._id === action.payload._id
            ? { booking, ...action.payload }
            : booking
        ),
      }

    case UPDATE_BOOKING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case EXPORT_BOOKING_SUCCESS:
      return {
        ...state,
        dataBookingExport: action.payload,
      }

    case EXPORT_BOOKING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case EXPORT_SERTIFIKAT_SUCCESS:
      return {
        ...state,
        dataSertifikatExport: action.payload,
      }

    case EXPORT_SERTIFIKAT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        dataBooking: state.dataBooking.filter(
          booking => booking._id !== action.payload._id
        ),
      }

    case DELETE_BOOKING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default DataBookingReducer
