// AUTH
export const POST_LOGIN = "/auth/login"
//DATA ARTIKEL
export const GET_DATA_USER = "/user/users"
export const GET_DATA_KARYAWAN = "/user/staffs"
export const ADD_NEW_USER = "/user/insert"
export const SEARCH_USER = "/user/search"
export const UPDATE_USER = "/user/user"
export const DELETE_USER = "/user/user"
//DATA BARANG
export const GET_DATA_BARANG = "/barang/barangs"
export const ADD_NEW_BARANG = "/barang/insert"
export const UPDATE_BARANG = "/barang/barang"
export const DELETE_BARANG = "/barang/barang"
export const GET_KATEGORI_BARANG = "/barang/kategori"
//DATA PAKET KHITAN
export const GET_DATA_PAKET_KHITAN = "/paket/khitans"
export const ADD_NEW_PAKET_KHITAN = "/paket/insert"
export const UPDATE_PAKET_KHITAN = "/paket/khitan"
export const DELETE_PAKET_KHITAN = "/paket/khitan"
//DATA ARTIKEL
export const GET_DATA_ARTIKEL = "/artikel/artikels"
export const ADD_NEW_ARTIKEL = "/artikel/insert"
export const UPDATE_ARTIKEL = "/artikel/artikel"
export const DELETE_ARTIKEL = "/artikel/artikel"
export const GET_KATEGORI_ARTIKEL = "/artikel/kategori"
//DATA BOOKING
export const GET_DATA_BOOKING = "/booking/bookings"
export const GET_DATA_BOOKING_FILTER = "/booking/bookings-filter"
export const ADD_NEW_BOOKING = "/booking/insert"
export const UPDATE_BOOKING = "/booking/booking"
export const EXPORT_BOOKING = "/booking/export-invoice"
export const EXPORT_SERTIFIKAT = "/booking/export-sertifikat"
export const DELETE_BOOKING = "/booking/booking"
//DATA NOTIF
export const GET_DATA_NOTIF = "/notif/notifications"
export const UPDATE_NOTIF = "/notif/notification"
//DATA DASHBOARD
export const GET_DATA_DASHBOARD = "/office/dashboard"
export const FILTER_DATA_DASHBOARD = "/office/dashboard"
//DATA CAROUSEL
export const GET_DATA_CAROUSEL = "/carousel/carousels"
export const ADD_NEW_CAROUSEL = "/carousel/insert"
export const UPDATE_CAROUSEL = "/carousel/carousel"
export const DELETE_CAROUSEL = "/carousel/carousel"
//DATA NOTIF
export const GET_LIST_KONSULTASI = "/konsultasi/messages-list"
export const ADD_NEW_KONSULTASI = "/konsultasi/send-message"
export const READ_KONSULTASI = "/konsultasi/read-message"
export const GET_UNREAD_KONSULTASI = "/konsultasi/unread-messages"
//DATA REKENING
export const GET_DATA_REKENING = "/rekening/rekenings"
export const ADD_NEW_REKENING = "/rekening/insert"
export const UPDATE_REKENING = "/rekening/rekening"
export const DELETE_REKENING = "/rekening/rekening"
//DATA PAKET KHITAN
export const GET_DATA_PENGELUARAN = "/pengeluaran/pengeluarans"
export const ADD_NEW_PENGELUARAN = "/pengeluaran/insert"
export const UPDATE_PENGELUARAN = "/pengeluaran/pengeluaran"
export const DELETE_PENGELUARAN = "/pengeluaran/pengeluaran"
//DATA AFFILIASI
export const GET_DATA_AFFILIASI = "/affiliasi/affiliasis"
//DATA LEVEL AFFILIASI
export const GET_DATA_LEVEL_AFFILIASI = "/levelaffiliasi/levels"
export const ADD_NEW_LEVEL_AFFILIASI = "/levelaffiliasi/insert"
export const UPDATE_LEVEL_AFFILIASI = "/levelaffiliasi/level"
export const DELETE_LEVEL_AFFILIASI = "/levelaffiliasi/level"
//DATA TARIK DANA
export const GET_DATA_TARIK_DANA = "/tarikdana/tarik-danas"
export const UPDATE_TARIK_DANA = "/tarikdana/tarik-dana"
//DATA PRESENSI
export const GET_DATA_PRESENSI = "/presensi/presensi-staffs"
//DATA VIDEO CALL
export const ADD_NEW_VIDEO_CALL = "/videocall/insert"
export const GET_DATA_VIDEO_CALL = "/videocall/video-calls"
export const UPDATE_VIDEO_CALL = "/videocall/video-call"
//DATA LIST TASK
export const GET_DATA_LIST_TASK = "/listtask/lists"
export const ADD_NEW_LIST_TASK = "/listtask/insert"
export const UPDATE_LIST_TASK = "/listtask/list"
export const DELETE_LIST_TASK = "/listtask/list"
//DATA TEMUKAN METODE
export const GET_DATA_TEMUKAN_METODE = "/temukanmetode/metodes"
export const ADD_NEW_TEMUKAN_METODE = "/temukanmetode/insert"
export const UPDATE_TEMUKAN_METODE = "/temukanmetode/metode"
export const DELETE_TEMUKAN_METODE = "/temukanmetode/metode"
//DATA NERACA
export const GET_DATA_NERACA = "/neraca/laba-rugi"
export const FILTER_DATA_NERACA = "/neraca/laba-rugi"
//DATA GAJI
export const GET_DATA_GAJI = "/gaji/gajis"
export const ADD_NEW_GAJI = "/gaji/insert"
export const UPDATE_GAJI = "/gaji/gaji"
export const DELETE_GAJI = "/gaji/gaji"
//DATA CONFIG
export const GET_DATA_CONFIG = "/additional/config"
export const UPDATE_CONFIG = "/additional/config"
//DATA VOUCHER
export const GET_DATA_VOUCHER = "/voucher/vouchers"
export const ADD_NEW_VOUCHER = "/voucher/insert"
export const UPDATE_VOUCHER = "/voucher/voucher"
export const DELETE_VOUCHER = "/voucher/voucher"
export const CHECK_VOUCHER = "/voucher/check-voucher"
//DATA REVIEW
export const GET_DATA_REVIEW = "/reviewKaryawan/reviewKaryawans"

////////////////
////////////////
////////////////
////////////////
////////////////
////////////////
//fake
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//CALENDER
// export const GET_CATEGORIES = "/categories"
