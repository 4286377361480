/* DATA_NOTIF */
export const GET_DATA_NOTIF = "GET_DATA_NOTIF"
export const GET_DATA_NOTIF_SUCCESS = "GET_DATA_NOTIF_SUCCESS"
export const GET_DATA_NOTIF_FAIL = "GET_DATA_NOTIF_FAIL"

export const UPDATE_NOTIF = "UPDATE_NOTIF"
export const UPDATE_NOTIF_SUCCESS = "UPDATE_NOTIF_SUCCESS"
export const UPDATE_NOTIF_FAIL = "UPDATE_NOTIF_FAIL"

// export const DELETE_NOTIF = "DELETE_NOTIF"
// export const DELETE_NOTIF_SUCCESS = "DELETE_NOTIF_SUCCESS"
// export const DELETE_NOTIF_FAIL = "DELETE_NOTIF_FAIL"
