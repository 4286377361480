import {
  GET_DATA_VIDEO_CALL_SUCCESS,
  GET_DATA_VIDEO_CALL_FAIL,
  ADD_NEW_VIDEO_CALL_SUCCESS,
  ADD_NEW_VIDEO_CALL_FAIL,
  UPDATE_VIDEO_CALL_SUCCESS,
  UPDATE_VIDEO_CALL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataVideoCall: [],
  error: {},
}

const DataVideoCallReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_VIDEO_CALL_SUCCESS:
      return {
        ...state,
        dataVideoCall: action.payload,
      }

    case GET_DATA_VIDEO_CALL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEW_VIDEO_CALL_SUCCESS:
      return {
        ...state,
        dataVideoCall: action.payload,
      }

    case ADD_NEW_VIDEO_CALL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_VIDEO_CALL_SUCCESS:
      return {
        ...state,
        dataVideoCall: state.dataVideoCall.map(videoCall =>
          videoCall._id === action.payload._id
            ? { videoCall, ...action.payload }
            : videoCall
        ),
      }

    case UPDATE_VIDEO_CALL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default DataVideoCallReducer
