import React, { useEffect, useState, useRef, createRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { env } from "../../helpers/config"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { isEmpty } from "lodash"

import { Link } from "react-router-dom"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

// Custom Scrollbar
import SimpleBar from "simplebar-react"

import sultanImg from "../../assets/images/users/sultan2.png"
import profile from "../../assets/images/users/profile.png"
import Dropzone from "react-dropzone"

import { format } from "date-fns"
import { id } from "date-fns/locale"

import {
  getKonsultasi,
  getListKonsultasi,
  addNewKonsultasi,
  readKonsultasi,
} from "../../store/actions"

const Konsultasi = props => {
  const {
    onGetKonsultasi,
    dataKonsultasi,
    onGetListKonsultasi,
    dataListKonsultasi,
    onAddNewKonsultasi,
    onReadKonsultasi,
  } = props

  const [chatListState, setChatListState] = useState({})
  const [selectedUserId, setSelectedUserId] = useState()
  const [selectedMessages, setSelectedMessages] = useState({})
  const [selectedUserData, setSelectedUserData] = useState({})
  const [adminMessage, setAdminMessage] = useState("")
  const chatRef = useRef(null)
  const scrollableNodeRef = createRef()

  const [photoIndex, setphotoIndex] = useState(0)
  const [isGallery, setisGallery] = useState(false)
  const [imageExpand, setImageExpand] = useState("")
  const [dataTambahChat, setDataTambahChat] = useState([])

  const [modal, setModal] = useState(false)

  const msgGroup = {}
  useEffect(() => {
    // console.log("data2", dataKonsultasi)
    if (!isEmpty(dataListKonsultasi) && !isEmpty(selectedUserId)) {
      handleSelectChat(selectedUserId, selectedUserData)
    }

    if (!isEmpty(dataListKonsultasi)) {
      setDataTambahChat(dataListKonsultasi?.users)

      dataListKonsultasi.data.map(item => {
        msgGroup[item.chatId] = msgGroup[item.chatId] || []
        msgGroup[item.chatId].push(item)
      })
      const chatList = Object.values(msgGroup)

      chatList.sort((item, index) => {
        const item_last = getLast(item).createdAt
        const index_last = getLast(index).createdAt
        return new Date(index_last) - new Date(item_last)
      })

      // const sortedUserIds = chatlist  // you array of messagesProps
      // .sort() // you already know the correct way to sort your messages
      // .map(m => m.send_by)
      // .filter(m => m.send_by !== null)

      // const sortedUsers = [...new Set(chatList)].map(id => dataListKonsultasi.users.find(u => u.id === id))
      // console.log({ chatList, dataListKonsultasi })
      setChatListState(chatList)
    }
    scrollChatBottom()
  }, [dataListKonsultasi])

  const getLast = arr => {
    return arr[arr.length - 1]
  }

  // const handleSelectChat = async (targetId, userData) => {
  //   setSelectedUserId("")
  //   setSelectedUserId(targetId)
  //   const chatId = ["admin", targetId].sort().join("_")
  //   // console.log(dataListKonsultasi.data)
  //   await setSelectedMessages(
  //     dataListKonsultasi.data.filter(item => item.chatId === chatId)
  //   )
  //   await setSelectedUserData(userData)
  //   // console.log(selectedMessages)
  //   await onReadKonsultasi({ userId: targetId })
  //   scrollChatBottom()
  // }

  const handleSelectChat = async (targetId, userData) => {
    setSelectedUserId("")
    setSelectedUserId(targetId)
    const chatId = ["admin", targetId].sort().join("_")
    // console.log(dataListKonsultasi.data)

    const dataSelectedChat = dataListKonsultasi.data.filter(
      item => item.chatId === chatId
    )

    const groupedMessages = []
    let currentDate = null

    dataSelectedChat.forEach(message => {
      // const messageDate = new Date(message.createdAt).toLocaleDateString()
      const options = { day: "2-digit", month: "2-digit", year: "numeric" }
      const messageDate = new Date(message.createdAt).toLocaleDateString(
        undefined,
        options
      )

      if (messageDate !== currentDate) {
        currentDate = messageDate

        const formattedDate = format(
          new Date(message.createdAt),
          "EEEE, dd-MM-yyyy",
          { locale: id }
        )

        groupedMessages.push({
          date: formattedDate,
          messages: [message],
        })
      } else {
        groupedMessages[groupedMessages.length - 1].messages.push(message)
      }
    })

    await setSelectedMessages(groupedMessages)
    await setSelectedUserData(userData)
    // console.log(selectedMessages)
    await onReadKonsultasi({ userId: targetId })
    scrollChatBottom()
  }

  const handleSelectChatAdmin = async targetId => {
    setSelectedUserId("")
    setSelectedUserId(targetId)
    const chatId = [targetId, "admin"].sort().join("_")
    // console.log(dataListKonsultasi.data)
    await setSelectedMessages(
      dataListKonsultasi.data.filter(item => item.chatId === chatId)
    )
    // console.log(selectedMessages)
    await onReadKonsultasi({ userId: targetId })
    scrollChatBottom()
  }

  const scrollChatBottom = () => {
    chatRef.current.getScrollElement().scrollTop =
      chatRef.current.getScrollElement().scrollHeight
  }

  const handleSendMessage = async () => {
    const formData = new FormData()

    formData.append("message", adminMessage)
    formData.append("to", selectedUserId)
    formData.append("image_konsultasi", "")

    await onAddNewKonsultasi(formData)
    setAdminMessage("")
    scrollChatBottom()
  }

  useEffect(() => {
    onGetListKonsultasi()
    onGetKonsultasi()
  }, [])

  // useEffect(() => {
  //   console.log("3")
  //   async function getlistkonsul() {
  //     await onGetListKonsultasi()
  //     scrollChatBottom()
  //   }

  //   getlistkonsul()
  // }, [dataKonsultasi])

  useEffect(() => {
    // console.log(dataKonsultasi)
    // console.log(dataListKonsultasi)
    scrollChatBottom()
  }, [dataListKonsultasi])

  const openLighboxInvoice = image => {
    setImageExpand(image)
    setisGallery(true)
  }

  const closeLighboxInvoice = async () => {
    setImageExpand("")
    setisGallery(false)
  }

  const addNewChat = () => {}

  const toggle = () => {
    setModal(!modal)
  }

  const handleOpenModal = () => {
    toggle()
  }

  const handleTambahSelectChat = async (id, data) => {
    await handleSelectChat(id, data)
    await toggle()
    if (!isEmpty(dataListKonsultasi)) {
      setDataTambahChat(dataListKonsultasi?.users)
    }
  }

  // const searchTambahChat = val => {
  //   let searchString = val.toLowerCase().split(" ")

  //   let filteredListChat = []
  //   if (val) {
  //     filteredListChat = dataListKonsultasi.users.filter(({ nama }) => {
  //       let containsAtLeastOneWord = false
  //       // If at least a word is matched return it!
  //       searchString.forEach(word => {
  //         if (nama.toLowerCase().includes(word)) containsAtLeastOneWord = true
  //       })
  //       if (containsAtLeastOneWord) return nama
  //     })

  //     setDataTambahChat(filteredListChat)
  //   } else {
  //     setDataTambahChat(
  //       !isEmpty(dataListKonsultasi) &&
  //         !isEmpty(dataListKonsultasi?.users) &&
  //         dataListKonsultasi?.users
  //     )
  //   }
  // }

  const searchTambahChat = val => {
    let searchString = val.toLowerCase().split(" ")

    let filteredListChat = []
    if (val) {
      filteredListChat = dataListKonsultasi.users.filter(
        ({ nama, nama_anak }) => {
          let containsAtLeastOneWord = false
          // If at least a word is matched return it!
          searchString.forEach(word => {
            if (
              nama?.toLowerCase().includes(word) ||
              nama_anak?.toLowerCase().includes(word)
            ) {
              containsAtLeastOneWord = true
            }
          })
          if (containsAtLeastOneWord) return true
        }
      )

      setDataTambahChat(filteredListChat)
    } else {
      setDataTambahChat(
        !isEmpty(dataListKonsultasi) &&
          !isEmpty(dataListKonsultasi?.users) &&
          dataListKonsultasi?.users
      )
    }
  }

  const handleSendImage = async files => {
    if (files.length > 1) {
      // setErrImage("Maksimal upload 1 gambar")
    } else {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      const formData = new FormData()

      formData.append("message", "")
      formData.append("to", selectedUserId)
      formData.append("image_konsultasi", files[0])

      await onAddNewKonsultasi(formData)
      setAdminMessage("")
      scrollChatBottom()
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      {isGallery && !isEmpty(imageExpand) ? (
        <Lightbox
          enableZoom={true}
          mainSrc={env.IMG_URL + imageExpand}
          // nextSrc={env.IMG_URL + invoice[(photoIndex + 1) % invoice.length]}
          // prevSrc={
          //   env.IMG_URL +
          //   invoice[(photoIndex + invoice.length - 1) % invoice.length]
          // }
          onCloseRequest={() => {
            closeLighboxInvoice()
          }}
          // onMovePrevRequest={() =>
          //   setphotoIndex((photoIndex + invoice.length - 1) % invoice.length)
          // }
          // onMoveNextRequest={() =>
          //   setphotoIndex((photoIndex + 1) % invoice.length)
          // }
          imageCaption={"Gambar Konsultasi"}
        />
      ) : null}

      <div className="page-content">
        <MetaTags>
          <title>Konsultasi | KhitanSultan BackOffice</title>
        </MetaTags>
        <Container fluid>
          {/* <div className="page-title-box"> */}
          {/*   <Row className="align-items-center"> */}
          {/*     <Col md={8}> */}
          {/* <h6 className="page-title">Chat Konsultasi</h6> */}
          {/* <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to Veltrix Konsultasi
                  </li>
                </ol> */}
          {/*     </Col> */}
          {/*   </Row> */}
          {/* </div> */}
          <Row className="mt-4">
            <Col xl={4}>
              <Card>
                <CardBody>
                  <div className="d-flex button-items justify-content-between mb-2 px-2">
                    <h5 className="">List Chat</h5>
                    <button
                      className="btn btn-outline-success btn-sm waves-effect"
                      onClick={() => handleOpenModal()}
                    >
                      {" "}
                      Tambah Chat <i className="fas fa-plus"></i>
                    </button>
                  </div>

                  <SimpleBar style={{ height: "430px" }}>
                    <div className="table-responsive">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        {/* <thead>
                        <tr>
                          <th scope="col">(#) Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Date</th>
                          <th scope="col">Amount</th>
                          <th scope="col" colSpan="2">
                            Status
                          </th>
                        </tr>
                      </thead> */}
                        <tbody>
                          {!isEmpty(chatListState) &&
                            chatListState.map((item, index) => {
                              let targetId =
                                item[0].from === "admin"
                                  ? item[0].to
                                  : item[0].from

                              let unreadNum = item.filter(
                                item => !item.read && item.to === "admin"
                              ).length

                              let lastItem = getLast(item)

                              let userData = dataListKonsultasi?.users.find(
                                item => item.id === targetId
                              )

                              return (
                                <tr
                                  key={index}
                                  onClick={() =>
                                    handleSelectChat(targetId, userData)
                                  }
                                >
                                  <td>
                                    <div className="d-flex">
                                      <img
                                        src={
                                          userData && userData.image
                                            ? env.IMG_URL + userData.image
                                            : profile
                                        }
                                        alt=""
                                        className="avatar-xs rounded-circle me-2"
                                      />{" "}
                                      <div>
                                        <span className="font-size-12 fw-bold">
                                          {userData && userData?.nama_anak
                                            ? userData?.nama_anak
                                            : userData?.nama
                                            ? userData?.nama
                                            : "Akun Dihapus"}
                                        </span>
                                        <br />

                                        <span className="font-size-10">
                                          {lastItem.content.length > 10
                                            ? lastItem.content.substring(
                                                0,
                                                10
                                              ) + "...."
                                            : lastItem.content}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="badge rounded-pill bg-danger float-end">
                                      {unreadNum}
                                    </span>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </SimpleBar>
                </CardBody>
              </Card>
            </Col>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <div className="d-flex mb-4 align-items-center">
                    {!isEmpty(selectedMessages) ||
                    !isEmpty(selectedUserData) ? (
                      <>
                        <img
                          src={
                            selectedUserData && selectedUserData.image
                              ? env.IMG_URL + selectedUserData.image
                              : profile
                          }
                          alt=""
                          className="avatar-xs rounded-circle me-2"
                        />
                        <div className="d-block">
                          <span className="font-size-14 fw-bold">
                            {!isEmpty(selectedUserData)
                              ? selectedUserData?.nama
                              : "Akun Dihapus"}
                          </span>
                          <br />
                          {!isEmpty(selectedUserData) &&
                            selectedUserData.nama_anak && (
                              <span className="font-size-12">
                                {selectedUserData?.nama_anak}
                              </span>
                            )}
                        </div>
                      </>
                    ) : (
                      <h4 className="card-title ">Chat Konsultasi</h4>
                    )}
                  </div>
                  <div className="chat-conversation">
                    <SimpleBar
                      style={{ height: "365px" }}
                      ref={chatRef}
                      scrollableNodeProps={{ ref: scrollableNodeRef }}
                    >
                      <ul
                        className="conversation-list"
                        data-simplebar
                        style={{ maxHeight: "367px" }}
                      >
                        {!isEmpty(selectedMessages) &&
                          selectedMessages.map((group, index) => (
                            <div key={index}>
                              <div className="text-center">
                                <button className="btn btn-outline-secondary disabled mb-2">
                                  {group.date}
                                </button>
                              </div>

                              {group.messages.map(v => {
                                let adminData = dataListKonsultasi?.users.find(
                                  item => item.id === v.sender
                                )

                                return v.from === selectedUserId ? (
                                  <li className="clearfix" key={v._id}>
                                    <div className="chat-avatar">
                                      <img
                                        src={
                                          selectedUserData &&
                                          selectedUserData.image
                                            ? env.IMG_URL +
                                              selectedUserData.image
                                            : profile
                                        }
                                        className="avatar-xs rounded-circle"
                                        alt=""
                                      />
                                      <span className="time">
                                        {new Date(
                                          v.createdAt
                                        ).toLocaleTimeString([], {
                                          hourCycle: "h23",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}
                                      </span>
                                    </div>
                                    <div className="conversation-text">
                                      <div className="ctext-wrap">
                                        <span className="user-name">
                                          {!isEmpty(selectedUserData)
                                            ? selectedUserData?.nama
                                            : "Akun Dihapus"}
                                        </span>
                                        {v.type === "image" ? (
                                          <Row className="col-12">
                                            <Col md="12">
                                              <Link
                                                to="#"
                                                className="gallery-popup"
                                                title="Klik untuk memperbesar"
                                                // onClick={() => {
                                                //   openLighboxInvoice()
                                                // }}
                                                onClick={() => {
                                                  openLighboxInvoice(v.content)
                                                }}
                                              >
                                                <img
                                                  width="150"
                                                  className="bg-light rounded"
                                                  alt="Foto Konsultasi"
                                                  src={env.IMG_URL + v.content}
                                                />
                                              </Link>
                                            </Col>
                                          </Row>
                                        ) : (
                                          <p>{v.content}</p>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                ) : (
                                  <li className="clearfix odd" key={v._id}>
                                    <div className="chat-avatar">
                                      <img
                                        src={
                                          adminData && adminData.image
                                            ? env.IMG_URL + adminData.image
                                            : profile
                                        }
                                        className="avatar-xs rounded-circle"
                                        alt="Female"
                                      />
                                      <span className="time">
                                        {new Date(
                                          v.createdAt
                                        ).toLocaleTimeString([], {
                                          hourCycle: "h23",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}
                                      </span>
                                    </div>
                                    <div className="conversation-text">
                                      <div className="ctext-wrap">
                                        <span className="user-name">
                                          {adminData
                                            ? adminData?.nama
                                            : "Akun Dihapus"}
                                        </span>
                                        {v.type === "image" ? (
                                          <Row className="col-12">
                                            <Col md="12">
                                              <Link
                                                to="#"
                                                className="gallery-popup"
                                                title="Klik untuk memperbesar"
                                                // onClick={() => {
                                                //   openLighboxInvoice()
                                                // }}
                                                onClick={() => {
                                                  openLighboxInvoice(v.content)
                                                }}
                                              >
                                                <img
                                                  width="150"
                                                  className="bg-light rounded"
                                                  alt="Foto Konsultasi"
                                                  src={env.IMG_URL + v.content}
                                                />
                                              </Link>
                                            </Col>
                                          </Row>
                                        ) : (
                                          <p>{v.content}</p>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                )
                              })}
                            </div>
                          ))}
                      </ul>
                    </SimpleBar>

                    <AvForm onValidSubmit={handleSendMessage}>
                      <Row className="mt-3 pt-1 g-0">
                        <div className="chat-inputbar col-8 col-md-10">
                          <Input
                            type="text"
                            className="chat-input"
                            placeholder="Masukkan Pesan"
                            onChange={e => setAdminMessage(e.target.value)}
                            value={adminMessage}
                            disabled={!isEmpty(selectedUserId) ? false : true}
                          />
                        </div>
                        <div className="chat-send col-md-2 col-4">
                          <div className="d-flex">
                            <div className=" ms-2">
                              <Dropzone
                                accept="image/jpeg, image/png"
                                noDrag={true}
                                onDrop={img => {
                                  handleSendImage(img)
                                }}
                                disabled={
                                  !isEmpty(selectedUserId) ? false : true
                                }
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="">
                                    <div
                                      className={`btn btn-block btn-primary ${
                                        !isEmpty(selectedUserId)
                                          ? ""
                                          : "disabled"
                                      }`}
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      {/* <div className="mb-3"> */}
                                      {/*   <i className="mdi mdi-cloud-upload display-4 text-muted"></i> */}
                                      {/* </div> */}
                                      <i className="fas fa-camera"></i>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>

                              {/* <Button */}
                              {/*   color="primary" */}
                              {/*   className="btn-block" */}
                              {/*   onClick={() => handleSendImage()} */}
                              {/*   disabled={!isEmpty(selectedUserId) ? false : true} */}
                              {/* > */}
                              {/* </Button> */}
                            </div>
                            <Button
                              type="submit"
                              color="primary"
                              outline
                              className="btn-block ms-2"
                              // onClick={() => handleSendMessage()}
                              disabled={
                                !isEmpty(selectedUserId) &&
                                !isEmpty(adminMessage)
                                  ? false
                                  : true
                              }
                            >
                              Kirim
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable"
          >
            <ModalHeader toggle={toggle} tag="h4">
              Tambah Chat
            </ModalHeader>
            <ModalBody>
              <div className="app-search px-4">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cari..."
                    onChange={e => searchTambahChat(e.target.value)}
                  />
                  <span className="fa fa-search"></span>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <tbody>
                    {!isEmpty(dataTambahChat) &&
                      dataTambahChat.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            onClick={() =>
                              handleTambahSelectChat(item.id, item)
                            }
                          >
                            <td>
                              <div className="d-flex">
                                <img
                                  src={
                                    item.image
                                      ? env.IMG_URL + item.image
                                      : profile
                                  }
                                  alt=""
                                  className="avatar-xs rounded-circle me-2"
                                />{" "}
                                <div>
                                  <span className="font-size-12 fw-bold">
                                    {item?.nama ? item?.nama : "Akun Dihapus"}
                                  </span>
                                  <br />
                                  {item?.nama_anak && (
                                    <span className="font-size-12">
                                      {item?.nama_anak}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

Konsultasi.propTypes = {
  dataKonsultasi: PropTypes.object,
  dataListKonsultasi: PropTypes.object,
  onGetKonsultasi: PropTypes.func,
  onGetListKonsultasi: PropTypes.func,
  onAddNewKonsultasi: PropTypes.func,
  onReadKonsultasi: PropTypes.func,
}

const mapStateToProps = ({ konsultasiReducer }) => ({
  dataKonsultasi: konsultasiReducer.dataKonsultasi,
  dataListKonsultasi: konsultasiReducer.dataListKonsultasi,
})

const mapDispatchToProps = dispatch => ({
  onGetKonsultasi: () => dispatch(getKonsultasi()),
  onGetListKonsultasi: () => dispatch(getListKonsultasi()),
  onAddNewKonsultasi: konsultasi => dispatch(addNewKonsultasi(konsultasi)),
  onReadKonsultasi: konsultasi => dispatch(readKonsultasi(konsultasi)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Konsultasi)

// export default Konsultasi
