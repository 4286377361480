import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Row,
  Col,
  BreadcrumbItem,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"

const Breadcrumb = props => {
  const [setting_Menu, setsetting_Menu] = useState(false)

  const handleModalAdd = () => {
    props.handleOpenModal()
  }

  const handleRefreshButton = () => {
    props.refreshFunc()
  }

  if (props.buttonModal === "Booking") {
    return (
      <Col className="col-md-7 col-sm-12 align-self-center">
        <div className="float-md-end d-sm-block gap-2">
          {props.exportData && props.exportData}{" "}
          {props.refreshButton && (
            <button
              className="btn btn-outline-primary btn-sm-block font-16 d-sm-block d-md-inline d-block"
              onClick={handleRefreshButton}
            >
              <i className="mdi mdi-refresh"></i> Muat Ulang Data
            </button>
          )}{" "}
          {props.buttonModal && (
            <button
              className="btn btn-outline-success btn-sm-block font-16 d-sm-block d-md-inline d-block"
              onClick={handleModalAdd}
            >
              <i className="mdi mdi-plus-circle-outline"></i> Tambah Data
            </button>
          )}
          {/* <Dropdown
        isOpen={setting_Menu}
        toggle={() => {
          setsetting_Menu(!setting_Menu)
        }}
      >
        <DropdownToggle color="primary" className="btn btn-primary dropdown-toggle waves-effect waves-light">
          <i className="mdi mdi-cog me-2"></i> Settings
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag="a" href="#">Action</DropdownItem>
          <DropdownItem tag="a" href="#">Another action</DropdownItem>
          <DropdownItem tag="a" href="#">Something else here</DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag="a" href="#">Separated link</DropdownItem>
        </DropdownMenu>
      </Dropdown> */}
        </div>
      </Col>
    )
  }

  return (
    <Row className="align-items-center">
      <Col className="col-12 d-block">
        <Col className="col-4">
          <div className="page-title-box m-0 py-2">
            <h4 className="font-size-18">{props.breadcrumbItem}</h4>

            {/* <p className="card-nama-desc">
            Working Actions : <code>add, update, delete</code>.
          </p> */}
            {/* <ol className="breadcrumb mb-0">
            {props.maintitle ? (
              <>
                <BreadcrumbItem>
                  <Link to="/#">{props.maintitle}</Link>
                </BreadcrumbItem>
              </>
            ) : (
              ""
            )}
            <BreadcrumbItem>
              <Link to="/#">{props.title}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{props.breadcrumbItem}</BreadcrumbItem>
          </ol> */}
          </div>
        </Col>
        <Col className="col-12">
          <div className="float-end d-md-block">
            {props.exportData && props.exportData}{" "}
            {props.refreshButton && (
              <button
                className="btn btn-outline-primary font-16 btn-block"
                onClick={handleRefreshButton}
              >
                <i className="mdi mdi-refresh"></i> Muat Ulang Data
              </button>
            )}{" "}
            {props.buttonModal && (
              <button
                className="btn btn-outline-success font-16 btn-block"
                onClick={handleModalAdd}
              >
                <i className="mdi mdi-plus-circle-outline"></i> Tambah Data
              </button>
            )}
            {/* <Dropdown
            isOpen={setting_Menu}
            toggle={() => {
              setsetting_Menu(!setting_Menu)
            }}
          >
            <DropdownToggle color="primary" className="btn btn-primary dropdown-toggle waves-effect waves-light">
              <i className="mdi mdi-cog me-2"></i> Settings
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag="a" href="#">Action</DropdownItem>
              <DropdownItem tag="a" href="#">Another action</DropdownItem>
              <DropdownItem tag="a" href="#">Something else here</DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag="a" href="#">Separated link</DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
          </div>
        </Col>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
