import {
  GET_DATA_TARIK_DANA,
  GET_DATA_TARIK_DANA_FAIL,
  GET_DATA_TARIK_DANA_SUCCESS,
  UPDATE_TARIK_DANA,
  UPDATE_TARIK_DANA_SUCCESS,
  UPDATE_TARIK_DANA_FAIL,
} from "./actionTypes"

export const getDataTarikDana = () => ({
  type: GET_DATA_TARIK_DANA,
})

export const getDataTarikDanaSuccess = dataTarikDana => ({
  type: GET_DATA_TARIK_DANA_SUCCESS,
  payload: dataTarikDana,
})

export const getDataTarikDanaFail = error => ({
  type: GET_DATA_TARIK_DANA_FAIL,
  payload: error,
})

export const updateTarikDana = tarikDana => ({
  type: UPDATE_TARIK_DANA,
  payload: tarikDana,
})

export const updateTarikDanaSuccess = tarikDana => ({
  type: UPDATE_TARIK_DANA_SUCCESS,
  payload: tarikDana,
})

export const updateTarikDanaFail = error => ({
  type: UPDATE_TARIK_DANA_FAIL,
  payload: error,
})
