import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"

class UsiaChart extends Component {
  constructor(props) {
    super(props)
  }
  getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} {b}: {c} ({d}%)",
      },
      legend: {
        orient: "horizontal",
        x: "left",
        data: this.props.data && this.props.data.map(value => value.name),
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: [
        "#02a499",
        "#f8b425",
        "#ec4561",
        "#38a4f8",
        "#3c4ccf",
        "#fd1a05",
        "#25d511",
        "#BA53E5",
        "#EDD510",
        "#BEDA0C",
        "#C3370A",
        "#AB126C",
      ],
      series: [
        {
          name: "Usia",
          type: "pie",
          radius: ["35%", "55%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: this.props.data,
        },
      ],
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "300px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}
export default UsiaChart
