/* DATA_PENGELUARAN */
export const GET_DATA_PENGELUARAN = "GET_DATA_PENGELUARAN"
export const GET_DATA_PENGELUARAN_SUCCESS = "GET_DATA_PENGELUARAN_SUCCESS"
export const GET_DATA_PENGELUARAN_FAIL = "GET_DATA_PENGELUARAN_FAIL"

export const ADD_NEW_PENGELUARAN = "ADD_NEW_PENGELUARAN"
export const ADD_PENGELUARAN_SUCCESS = "ADD_PENGELUARAN_SUCCESS"
export const ADD_PENGELUARAN_FAIL = "ADD_PENGELUARAN_FAIL"

export const UPDATE_PENGELUARAN = "UPDATE_PENGELUARAN"
export const UPDATE_PENGELUARAN_SUCCESS = "UPDATE_PENGELUARAN_SUCCESS"
export const UPDATE_PENGELUARAN_FAIL = "UPDATE_PENGELUARAN_FAIL"

export const DELETE_PENGELUARAN = "DELETE_PENGELUARAN"
export const DELETE_PENGELUARAN_SUCCESS = "DELETE_PENGELUARAN_SUCCESS"
export const DELETE_PENGELUARAN_FAIL = "DELETE_PENGELUARAN_FAIL"
