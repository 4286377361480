import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { env } from "../../helpers/config"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  Button,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  Label,
} from "reactstrap"
import { Link } from "react-router-dom"
import DeleteModal from "../../components/Common/DeleteModal"

import { AvField, AvForm } from "availity-reactstrap-validation"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import DeleteModal from "../../components/Common/DeleteModal"
import Dropzone from "react-dropzone"

import {
  addNewCarousel,
  getDataCarousel,
  updateCarousel,
  deleteCarousel,
} from "../../store/actions"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

const Carousel = props => {
  const { onGetDataCarousel, dataCarousel } = props
  const [modal, setModal] = useState(false)
  const [carousel, setCarousel] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [imageCarousel, setImageCarousel] = useState([])
  const [errImage, setErrImage] = useState("")

  const [photoIndex, setphotoIndex] = useState(0)
  const [isGallery, setisGallery] = useState(false)

  const [typeLink, setTypeLink] = useState("")

  function setpopovertop() {
    setphotoIndex(0)
    setisGallery(true)
  }

  useEffect(() => {
    if (dataCarousel) {
      dataCarousel.map((item, index) => {
        item.action = (
          <Button
            color="primary"
            className="font-16 btn-block"
            onClick={e => handleOpenModal(item)}
          >
            <i className="mdi mdi-grease-pencil"></i> EDIT
          </Button>
        )
        item.image1 = (
          <CardImg
            src={env.IMG_URL + item.image}
            alt="image-carousel"
            className="avatar-lg img-thumbnail"
          />
        )
      })
    }

    if (!modal && !isEmpty(carousel) && !!isEdit) {
      // setTimeout(() => {
      setCarousel({})
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, carousel, dataCarousel])

  const toggle = close => {
    if (close) {
      setImageCarousel([])
      setTypeLink("")
      setErrImage("")
    }
    setModal(!modal)
  }

  const handleOpenModal = data => {
    // console.log("data", data)
    if (data) {
      // console.log("data exist")
      setCarousel({
        _id: data._id,
        status: data.status,
        data_link: data.data_link,
        image: data.image,
      })
      setImageCarousel([data.image])
      setTypeLink(data.data_link ? data.data_link.type : "")
      setIsEdit(true)
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  useEffect(() => {
    onGetDataCarousel()
  }, [])

  const handleValidCarouselSubmit = (e, values) => {
    const { onAddNewCarousel, onUpdateCarousel } = props

    if (typeof imageCarousel[0] === "undefined" && !isEdit) {
      setErrImage("Gambar carousel harus di upload..")
    } else {
      const formData = new FormData()
      formData.append("status", values.status)
      formData.append("image", imageCarousel[0])
      formData.append("type", typeLink)
      formData.append("link", values.link || "")

      if (isEdit) {
        formData.append("_id", carousel._id)
        onUpdateCarousel(formData)
      } else {
        setErrImage("")
        onAddNewCarousel(formData)
      }

      setImageCarousel([])
      setTypeLink("")
      toggle()
    }
  }

  const handleDeleteCarousel = () => {
    const { onDeleteCarousel } = props
    onDeleteCarousel(carousel._id)
    setDeleteModal(false)
    setImageCarousel([])
    setTypeLink("")
    toggle()
  }

  function handleImageCarousel(files) {
    if (files.length > 1) {
      setErrImage("Maksimal upload 1 gambar")
    } else {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setImageCarousel(files)
      setErrImage("")
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCarousel}
        onCloseClick={() => setDeleteModal(false)}
      />
      {isGallery ? (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          enableZoom={true}
          onCloseRequest={() => setisGallery(false)}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % images.length)
          }
          imageCaption={"Gambar " + parseFloat(photoIndex + 1)}
        />
      ) : null}
      <div className="page-content">
        <MetaTags>
          <title>Carousel App | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs
                    breadcrumbItem="Carousel"
                    handleOpenModal={handleOpenModal}
                    buttonModal="Carousel"
                  />
                  {/* <CardTitle className="h4">Data Carousel</CardTitle> */}
                  {/* <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}
                  <div className="row">
                    {dataCarousel.map((item, key) => (
                      <Col xl={3} md={6} key={key}>
                        <Link
                          to="#"
                          className="gallery-popup"
                          title="Open Imagination"
                          // onClick={() => {
                          //   setpopovertop()
                          // }}
                          onClick={e => handleOpenModal(item)}
                        >
                          <div
                            className={
                              item.status === "active"
                                ? "project-item"
                                : "project-item opacity-50"
                            }
                          >
                            <div className="overlay-container">
                              <img
                                src={env.IMG_URL + item.image}
                                alt="img"
                                className="gallery-thumb-img"
                              />
                              {/* <div className="project-item-overlay"></div> */}
                            </div>
                          </div>
                        </Link>
                      </Col>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable modal-lg"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Carousel" : "Tambah Carousel"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidCarouselSubmit}>
                <Row form>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="status"
                      label="Status Carousel"
                      type="select"
                      validate={{
                        required: { value: true },
                      }}
                      value={carousel ? carousel.status : ""}
                    >
                      <option hidden value="">
                        -- Pilih Status --
                      </option>
                      <option value="active">Aktif</option>
                      <option value="hidden">Sembunyikan</option>
                    </AvField>
                  </Col>

                  <Col className="col-12 mb-3 d-flex">
                    <Col className="col-4">
                      <AvField
                        name="type_link"
                        label="Tipe Link"
                        type="select"
                        onChange={e => setTypeLink(e.target.value)}
                        validate={{
                          required: { value: true },
                        }}
                        value={
                          carousel && carousel.data_link
                            ? carousel.data_link.type
                            : ""
                        }
                      >
                        <option hidden value="">
                          -- Pilih Status --
                        </option>
                        <option value="tanpa_link">Tanpa Link</option>
                        <option value="external">Link Eksternal</option>
                        <option value="internal">Link Internal</option>
                      </AvField>
                    </Col>
                    <Col className="col-8 ps-3">
                      {typeLink === "external" ? (
                        <AvField
                          name="link"
                          label="Url Link Eksternal "
                          placeholder="Contoh : https://google.com"
                          type="text"
                          errorMessage="Invalid Link"
                          validate={{
                            required: { value: true },
                            minLength: { value: 3 },
                          }}
                          value={
                            carousel && carousel.data_link
                              ? carousel.data_link.link
                              : ""
                          }
                        />
                      ) : typeLink === "internal" ? (
                        <AvField
                          name="link"
                          label="Pilih Halaman App"
                          type="select"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            carousel && carousel.data_link
                              ? carousel.data_link.link
                              : ""
                          }
                        >
                          <option hidden value="">
                            -- Pilih Halaman --
                          </option>
                          <option value="Booking">List Booking</option>
                          <option value="Chatting">Konsultasi</option>
                          <option value="BuatJanji">Buat Janji</option>
                          <option value="ProfileDetail">Detail Profil</option>
                          <option value="Tentang">Tentang</option>
                          <option value="Berita">Berita</option>
                        </AvField>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>

                  <Col className="col-12 mb-3">
                    <Dropzone
                      accept="image/jpeg, image/png"
                      onDrop={img => {
                        handleImageCarousel(img)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                            </div>
                            <h6>
                              Seret Gambar Carousel atau Klik untuk Upload
                            </h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {imageCarousel.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={
                                      f.name ||
                                      imageCarousel.toString().split("-")[1]
                                    }
                                    src={
                                      f.preview || env.IMG_URL + imageCarousel
                                    }
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name ||
                                      imageCarousel.toString().split("-")[1]}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                    <div className=" is-invalid"></div>
                    <div className="invalid-feedback">{errImage}</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={() => toggle("close")}
                      >
                        Tutup
                      </button>
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-outline-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        type="submit"
                        className="btn btn-outline-success save-carousel"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

Carousel.propTypes = {
  dataCarousel: PropTypes.array,
  onGetDataCarousel: PropTypes.func,
  onAddNewCarousel: PropTypes.func,
  onUpdateCarousel: PropTypes.func,
  onDeleteCarousel: PropTypes.func,
}

const mapStateToProps = ({ dataCarouselReducer }) => ({
  dataCarousel: dataCarouselReducer.dataCarousel,
})

const mapDispatchToProps = dispatch => ({
  onGetDataCarousel: () => dispatch(getDataCarousel()),
  onAddNewCarousel: carousel => dispatch(addNewCarousel(carousel)),
  onUpdateCarousel: carousel => dispatch(updateCarousel(carousel)),
  onDeleteCarousel: carousel => dispatch(deleteCarousel(carousel)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Carousel)
