import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_VOUCHER,
  DELETE_VOUCHER,
  CHECK_VOUCHER,
  GET_DATA_VOUCHER,
  UPDATE_VOUCHER,
} from "./actionTypes"
import {
  getDataVoucherSuccess,
  getDataVoucherFail,
  addVoucherFail,
  addVoucherSuccess,
  checkVoucherSuccess,
  checkVoucherFail,
  updateVoucherSuccess,
  updateVoucherFail,
  deleteVoucherSuccess,
  deleteVoucherFail,
  // getCategoriesSuccess,
  // getCategoriesFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataVoucher,
  addNewVoucher,
  updateVoucher,
  checkVoucher,
  deleteVoucher,
  // getCategories,
} from "../../helpers/backend_helper"

import { startLoading, stopLoading } from "../actions"

function* fetchDataVoucher() {
  yield put(startLoading())
  try {
    const response = yield call(getDataVoucher)
    yield put(getDataVoucherSuccess(response))
  } catch (error) {
    yield put(getDataVoucherFail(error))
  }
  yield put(stopLoading())
}

function* onAddNewVoucher({ payload: voucher }) {
  yield put(startLoading())
  try {
    const response = yield call(addNewVoucher, voucher)
    yield put(addVoucherSuccess(response))
  } catch (error) {
    yield put(addVoucherFail(error))
  }
  yield put(stopLoading())
}

function* onCheckVoucher({ payload: voucher }) {
  yield put(startLoading())
  try {
    const response = yield call(checkVoucher, voucher)
    yield put(checkVoucherSuccess(response))
  } catch (error) {
    yield put(checkVoucherFail(error))
  }
  yield put(stopLoading())
}

function* onUpdateVoucher({ payload: voucher }) {
  yield put(startLoading())
  try {
    // console.log(voucher)
    const response = yield call(updateVoucher, voucher)
    yield put(updateVoucherSuccess(response))
  } catch (error) {
    yield put(updateVoucherFail(error))
  }
  yield put(stopLoading())
}

function* onDeleteVoucher({ payload: voucher }) {
  yield put(startLoading())
  try {
    const response = yield call(deleteVoucher, voucher)
    yield put(deleteVoucherSuccess(response))
  } catch (error) {
    yield put(deleteVoucherFail(error))
  }
  yield put(stopLoading())
}

// function* onGetCategories() {
//   try {
//     const response = yield call(getCategories)
//     yield put(getCategoriesSuccess(response))
//   } catch (error) {
//     yield put(getCategoriesFail(error))
//   }
// }

function* DataVoucherSaga() {
  yield takeEvery(GET_DATA_VOUCHER, fetchDataVoucher)
  yield takeEvery(ADD_NEW_VOUCHER, onAddNewVoucher)
  yield takeEvery(CHECK_VOUCHER, onCheckVoucher)
  yield takeEvery(UPDATE_VOUCHER, onUpdateVoucher)
  yield takeEvery(DELETE_VOUCHER, onDeleteVoucher)
  // yield takeEvery(GET_CATEGORIES, onGetCategories)
}

export default DataVoucherSaga
