import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { env } from "../../helpers/config"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

import moment from "moment"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

import { getDataReview } from "../../store/actions"

const Review = props => {
  const { onGetDataReview, dataReview } = props
  const [modal, setModal] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [review, setReview] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [status, setStatus] = useState("")
  const [tanggalFilter, setTanggalFilter] = useState("")

  useEffect(() => {
    if (dataReview) {
      var totalJamKerja = 0

      dataReview.map((item, index) => {
        item.tanggalFormated = new Date(item.tanggal).toLocaleString("ID", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })

        item.roleBadge = (
          <>
            <Badge
              className={
                "font-size-12 badge bg-" +
                (item.role === "superAdmin"
                  ? "dark"
                  : item.role === "admin"
                  ? "secondary"
                  : item.role === "dokter"
                  ? "danger"
                  : item.role === "gudang"
                  ? "info"
                  : item.role === "staff"
                  ? "primary"
                  : item.affiliasi.isAffiliasi === "true"
                  ? "success"
                  : "warning")
              }
              pill
            >
              {item.role === "superAdmin"
                ? "SuperAdmin"
                : item.role === "admin"
                ? "Admin"
                : item.role === "dokter"
                ? "Dokter"
                : item.role === "gudang"
                ? "Gudang"
                : item.role === "staff"
                ? "Staff"
                : item.affiliasi.isAffiliasi === "true"
                ? "Affiliasi"
                : "Pelanggan"}
            </Badge>
          </>
        )

        item.action = (
          <>
            <Button
              key={`detail-${index}`}
              color="success"
              outline
              className="font-16 btn-block btn-sm"
              onClick={e => handleOpenModalDetail(item)}
            >
              Detail
            </Button>
          </>
        )
      })
    }

    if (!modal && !isEmpty(review) && !!isEdit) {
      // setTimeout(() => {
      setReview({})
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, review, dataReview])

  const toggleDetail = close => {
    // console.log(booking)
    setModalDetail(!modalDetail)
    if (close) {
      setReview({})
    }
  }

  const handleValidReviewSubmit = (e, values) => {
    const { onAddNewReview } = props

    if (
      (isEmpty(imageReview[0]) || typeof imageReview[0] === "undefined") &&
      !!isEdit &&
      status === "selesai"
    ) {
      setErrImage("Gambar transfer harus di upload..")
    } else {
      const formData = new FormData()
      formData.append("status", values.status)
      formData.append("image", status === "selesai" ? imageReview[0] : "")

      if (isEdit) {
        formData.append("id", review._id)
        // onUpdateReview(formData)
      } else {
        setErrImage("")
        // onAddNewReview(formData)
      }

      setImageReview([])
      setStatus("")
      toggle()
    }
  }

  const handleOpenModalDetail = data => {
    if (data) {
      setReview(data)
    }
    toggleDetail()
  }

  useEffect(() => {
    onGetDataReview({
      tanggal_filter: tanggalFilter,
    })
  }, [tanggalFilter])

  const handleDeleteReview = () => {
    const { onDeleteReview } = props
    onDeleteReview(review._id)
    setDeleteModal(false)
    setStatus("")
    toggle()
  }

  const data = {
    columns: [
      {
        label: "Nama Karyawan",
        field: "nama",
        sort: "asc",
        width: 150,
      },
      {
        label: "Role",
        field: "roleBadge",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total Rate",
        field: "totalRate",
        sort: "asc",
        width: 200,
      },
      {
        label: "Detail",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: dataReview,
  }

  const dataDetailReviews = {
    columns: [
      {
        label: "Nama Anak",
        field: "nama_anak",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tanggal Khitan",
        field: "tanggal_khitan",
        sort: "asc",
        width: 150,
      },
      {
        label: "Rate",
        field: "rate",
        sort: "asc",
        width: 200,
      },
      {
        label: "Desc",
        field: "text",
        sort: "asc",
        width: 200,
      },
    ],
    rows: review && !isEmpty(review) ? review?.reviews : [],
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Review | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <div className="page-title-box">
            <Row className="col-12">
              <Col md="10" className="align-self-center">
                <h6 className="page-title">Review Karyawan</h6>
              </Col>

              <Col md="2">
                <div className="">
                  <label
                    htmlFor="example-month-input"
                    className="col-form-label"
                  >
                    {" "}
                    Filter Bulan
                  </label>
                  <input
                    className="form-control"
                    type="month"
                    // defaultValue="2022-05"
                    id="example-month-input"
                    onChange={val => setTanggalFilter(val.target.value)}
                  />
                </div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col className="col-12 ">
              <Card>
                <CardBody>
                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modalDetail}
            className="modal-dialog modal-dialog-scrollable modal-xl"
          >
            <ModalHeader toggle={toggleDetail} tag="h4">
              Detail Review
            </ModalHeader>
            <ModalBody>
              <div>
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="col" width="200">
                        Nama
                      </th>
                      <td scope="col">{review ? review.nama : ""}</td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Role
                      </th>
                      <td scope="col">{review ? review.roleBadge : ""}</td>
                    </tr>

                    <tr>
                      <th scope="col" width="200">
                        Total Rate
                      </th>
                      <td scope="col">{review ? review.totalRate : ""}</td>
                    </tr>
                    <tr>
                      <th colspan="2" scope="col">
                        Data Reviews
                      </th>
                    </tr>

                    <tr>
                      <td colspan="2" scope="col">
                        <MDBDataTable
                          responsive
                          striped
                          bordered
                          data={dataDetailReviews}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

Review.propTypes = {
  dataReview: PropTypes.array,
  onGetDataReview: PropTypes.func,
}

const mapStateToProps = ({ dataReviewReducer, dataUserReducer }) => ({
  dataReview: dataReviewReducer.dataReview,
})

const mapDispatchToProps = dispatch => ({
  onGetDataReview: keyword => dispatch(getDataReview(keyword)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Review)
