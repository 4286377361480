import {
  GET_DATA_TEMUKAN_METODE_SUCCESS,
  GET_DATA_TEMUKAN_METODE_FAIL,
  ADD_TEMUKAN_METODE_SUCCESS,
  ADD_TEMUKAN_METODE_FAIL,
  UPDATE_TEMUKAN_METODE_SUCCESS,
  UPDATE_TEMUKAN_METODE_FAIL,
  DELETE_TEMUKAN_METODE_SUCCESS,
  DELETE_TEMUKAN_METODE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataTemukanMetode: [],
  error: {},
}

const DataTemukanMetodeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_TEMUKAN_METODE_SUCCESS:
      return {
        ...state,
        dataTemukanMetode: action.payload,
      }

    case GET_DATA_TEMUKAN_METODE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_TEMUKAN_METODE_SUCCESS:
      return {
        ...state,
        dataTemukanMetode: [...state.dataTemukanMetode, action.payload],
      }

    case ADD_TEMUKAN_METODE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TEMUKAN_METODE_SUCCESS:
      return {
        ...state,
        dataTemukanMetode: state.dataTemukanMetode.map(temukanMetode =>
          temukanMetode._id === action.payload._id
            ? { temukanMetode, ...action.payload }
            : temukanMetode
        ),
      }

    case UPDATE_TEMUKAN_METODE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_TEMUKAN_METODE_SUCCESS:
      return {
        ...state,
        dataTemukanMetode: state.dataTemukanMetode.filter(
          temukanMetode => temukanMetode._id !== action.payload._id
        ),
      }

    case DELETE_TEMUKAN_METODE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default DataTemukanMetodeReducer
