import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_GAJI,
  DELETE_GAJI,
  GET_DATA_GAJI,
  UPDATE_GAJI,
} from "./actionTypes"
import {
  getDataGajiSuccess,
  getDataGajiFail,
  addGajiFail,
  addGajiSuccess,
  updateGajiSuccess,
  updateGajiFail,
  deleteGajiSuccess,
  deleteGajiFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataGaji,
  addNewGaji,
  updateGaji,
  deleteGaji,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"

function* fetchDataGaji() {
  yield put(startLoading());
  try {
    const response = yield call(getDataGaji)
    yield put(getDataGajiSuccess(response))
  } catch (error) {
    yield put(getDataGajiFail(error))
  }
  yield put(stopLoading());
}

function* onAddNewGaji({ payload: gaji }) {
  yield put(startLoading());
  try {
    const response = yield call(addNewGaji, gaji)
    yield put(addGajiSuccess(response))
  } catch (error) {
    yield put(addGajiFail(error))
  }
  yield put(stopLoading());
}

function* onUpdateGaji({ payload: gaji }) {
  yield put(startLoading());
  try {
    // console.log(gaji)
    const response = yield call(updateGaji, gaji)
    yield put(updateGajiSuccess(response))
  } catch (error) {
    yield put(updateGajiFail(error))
  }
  yield put(stopLoading());
}

function* onDeleteGaji({ payload: gaji }) {
  yield put(startLoading());
  try {
    const response = yield call(deleteGaji, gaji)
    yield put(deleteGajiSuccess(response))
  } catch (error) {
    yield put(deleteGajiFail(error))
  }
  yield put(stopLoading());
}

function* DataGajiSaga() {
  yield takeEvery(GET_DATA_GAJI, fetchDataGaji)
  yield takeEvery(ADD_NEW_GAJI, onAddNewGaji)
  yield takeEvery(UPDATE_GAJI, onUpdateGaji)
  yield takeEvery(DELETE_GAJI, onDeleteGaji)
}

export default DataGajiSaga
