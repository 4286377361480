/* DATA_ARTIKEL */
export const GET_DATA_ARTIKEL = "GET_DATA_ARTIKEL"
export const GET_DATA_ARTIKEL_SUCCESS = "GET_DATA_ARTIKEL_SUCCESS"
export const GET_DATA_ARTIKEL_FAIL = "GET_DATA_ARTIKEL_FAIL"

export const ADD_NEW_ARTIKEL = "ADD_NEW_ARTIKEL"
export const ADD_ARTIKEL_SUCCESS = "ADD_ARTIKEL_SUCCESS"
export const ADD_ARTIKEL_FAIL = "ADD_ARTIKEL_FAIL"

export const UPDATE_ARTIKEL = "UPDATE_ARTIKEL"
export const UPDATE_ARTIKEL_SUCCESS = "UPDATE_ARTIKEL_SUCCESS"
export const UPDATE_ARTIKEL_FAIL = "UPDATE_ARTIKEL_FAIL"

export const DELETE_ARTIKEL = "DELETE_ARTIKEL"
export const DELETE_ARTIKEL_SUCCESS = "DELETE_ARTIKEL_SUCCESS"
export const DELETE_ARTIKEL_FAIL = "DELETE_ARTIKEL_FAIL"

export const GET_KATEGORI_ARTIKEL = "GET_KATEGORI_ARTIKEL"
export const GET_KATEGORI_ARTIKEL_SUCCESS = "GET_KATEGORI_ARTIKEL_SUCCESS"
export const GET_KATEGORI_ARTIKEL_FAIL = "GET_KATEGORI_ARTIKEL_FAIL"
