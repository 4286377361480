import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_ARTIKEL,
  DELETE_ARTIKEL,
  GET_KATEGORI_ARTIKEL,
  GET_DATA_ARTIKEL,
  UPDATE_ARTIKEL,
} from "./actionTypes"
import {
  getDataArtikelSuccess,
  getDataArtikelFail,
  addArtikelFail,
  addArtikelSuccess,
  updateArtikelSuccess,
  updateArtikelFail,
  deleteArtikelSuccess,
  deleteArtikelFail,
  getKategoriArtikelSuccess,
  getKategoriArtikelFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataArtikel,
  addNewArtikel,
  updateArtikel,
  deleteArtikel,
  getKategoriArtikel,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"

function* fetchDataArtikel() {
  yield put(startLoading());
  try {
    const response = yield call(getDataArtikel)
    yield put(getDataArtikelSuccess(response))
  } catch (error) {
    yield put(getDataArtikelFail(error))
  }
  yield put(stopLoading());
}

function* onAddNewArtikel({ payload: artikel }) {
  yield put(startLoading());
  try {
    const response = yield call(addNewArtikel, artikel)
    yield put(addArtikelSuccess(response))
  } catch (error) {
    yield put(addArtikelFail(error))
  }
  yield put(stopLoading());
}

function* onUpdateArtikel({ payload: artikel }) {
  yield put(startLoading());
  try {
    // console.log(artikel)
    const response = yield call(updateArtikel, artikel)
    yield put(updateArtikelSuccess(response))
  } catch (error) {
    yield put(updateArtikelFail(error))
  }
  yield put(stopLoading());
}

function* onDeleteArtikel({ payload: artikel }) {
  yield put(startLoading());
  try {
    const response = yield call(deleteArtikel, artikel)
    yield put(deleteArtikelSuccess(response))
  } catch (error) {
    yield put(deleteArtikelFail(error))
  }
  yield put(stopLoading());
}

function* onGetKategoriArtikel() {
  yield put(startLoading());
  try {
    const response = yield call(getKategoriArtikel)
    yield put(getKategoriArtikelSuccess(response))
  } catch (error) {
    yield put(getKategoriArtikelFail(error))
  }
  yield put(stopLoading());
}

function* DataArtikelSaga() {
  yield takeEvery(GET_DATA_ARTIKEL, fetchDataArtikel)
  yield takeEvery(ADD_NEW_ARTIKEL, onAddNewArtikel)
  yield takeEvery(UPDATE_ARTIKEL, onUpdateArtikel)
  yield takeEvery(DELETE_ARTIKEL, onDeleteArtikel)
  yield takeEvery(GET_KATEGORI_ARTIKEL, onGetKategoriArtikel)
}

export default DataArtikelSaga
