import {
  GET_DATA_NOTIF,
  GET_DATA_NOTIF_FAIL,
  GET_DATA_NOTIF_SUCCESS,
  UPDATE_NOTIF,
  UPDATE_NOTIF_SUCCESS,
  UPDATE_NOTIF_FAIL,
  // DELETE_NOTIF,
  // DELETE_NOTIF_SUCCESS,
  // DELETE_NOTIF_FAIL,
} from "./actionTypes"

export const getDataNotif = () => ({
  type: GET_DATA_NOTIF,
})

export const getDataNotifSuccess = dataNotif => ({
  type: GET_DATA_NOTIF_SUCCESS,
  payload: dataNotif,
})

export const getDataNotifFail = error => ({
  type: GET_DATA_NOTIF_FAIL,
  payload: error,
})

export const updateNotif = notif => ({
  type: UPDATE_NOTIF,
  payload: notif,
})

export const updateNotifSuccess = notif => ({
  type: UPDATE_NOTIF_SUCCESS,
  payload: notif,
})

export const updateNotifFail = error => ({
  type: UPDATE_NOTIF_FAIL,
  payload: error,
})

// export const deleteNotif = notif => ({
//   type: DELETE_NOTIF,
//   payload: notif,
// })

// export const deleteNotifSuccess = notif => ({
//   type: DELETE_NOTIF_SUCCESS,
//   payload: notif,
// })

// export const deleteNotifFail = error => ({
//   type: DELETE_NOTIF_FAIL,
//   payload: error,
// })
