import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Container, Row, Col, Card, CardBody, Badge } from "reactstrap"
import { isEmpty } from "lodash"
import { MDBDataTable } from "mdbreact"

import {
  getDataNeraca,
  filterDataNeraca,
  getDataConfig,
} from "../../store/actions"

import { Link } from "react-router-dom"

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"
// // Charts
// import Doughnut from "../AllCharts/echart/doughnutchart"
// import Pie from "../AllCharts/echart/piechart"
// import DonutBookingType from "../AllCharts/apex/DonutBookingType"

import "chartist/dist/scss/chartist.scss"

const Neraca = props => {
  const {
    onGetDataNeraca,
    onFilterDataNeraca,
    dataNeraca,
    onGetDataConfig,
    dataConfig,
  } = props
  const [menu, setMenu] = useState(false)
  const [formatTotal, setFormatTotal] = useState(0)
  const [apexOptions, setApexOptions] = useState({
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
        },
      },
    },
    colors: ["#626ed4", "#f8b425"],
  })
  const [dataChartPlatform, setDataChartPlatform] = useState([0, 0])
  const [dataChartMetode, setDataChartMetode] = useState([])
  const toggle = () => {
    setMenu(!menu)
  }
  const [dataFilter, setDataFilter] = useState("")

  const [dataTableNeraca, setDataTableNeraca] = useState([])
  const [totalPendapatan, setTotalPendapatan] = useState(0)
  const [totalPengeluaran, setTotalPengeluaran] = useState(0)
  const [totalTarikDana, setTotalTarikDana] = useState(0)
  const [totalSaldo, setTotalSaldo] = useState(0)

  const [data, setData] = useState({
    columns: [
      {
        label: "Nama / Tanggal",
        field: "nama_neraca",
        width: 150,
      },
      {
        label: "Tipe",
        field: "tipe_neraca",
        width: 150,
      },
      {
        label: "Total",
        field: "total_neraca",
        width: 270,
      },
    ],
    rows: dataTableNeraca,
  })

  useEffect(() => {
    if (!isEmpty(dataNeraca) && !isEmpty(dataConfig)) {
      let total = dataNeraca?.total_labarugi
      if (total >= 1e6 && total < 1e9) total = (total / 1e6).toFixed(0) + "Jt"
      if (total >= 1e9 && total < 1e12) total = (total / 1e9).toFixed(1) + "Mi"
      setFormatTotal(total)

      let neraca_pendapatan = []
      let neraca_pengeluaran = []
      let neraca_tarikdana = []
      let neraca_gaji = []

      let total_pendapatan = 0
      let total_pengeluaran = 0
      let total_pemasukan = 0
      let total_tarikDana = 0
      let total_gaji = 0
      let total_saldo = 0

      if (!isEmpty(dataNeraca.neraca_pendapatan)) {
        neraca_pendapatan = dataNeraca?.neraca_pendapatan.map(item => {
          let ongkos =
            item.tempat_khitan.tempat_khitan === "panggilan_rumah" && dataConfig
              ? item.tempat_khitan.tempat_khitan_panggilan === "dalam_kota"
                ? parseInt(dataConfig.biaya_dalam_kota)
                : item.tempat_khitan.tempat_khitan_panggilan === "luar_kota"
                ? parseInt(dataConfig.biaya_luar_kota)
                : 0
              : 0

          let diskon =
            item.paket_khitan.tipePotongan === "persen"
              ? (parseInt(item.paket_khitan.harga) *
                  parseInt(item.paket_khitan.potongan)) /
                100
              : item.paket_khitan.tipePotongan === "nominal"
              ? parseInt(item.paket_khitan.potongan)
              : 0

          let voucher =
            item.paket_khitan.kode_voucher &&
            !isEmpty(item.paket_khitan.kode_voucher)
              ? item.paket_khitan.isVoucherPersen
                ? (parseInt(item.paket_khitan.harga) *
                    parseInt(item.paket_khitan.nominal_voucher)) /
                  100
                : !item.paket_khitan.isVoucherPersen
                ? parseInt(item.paket_khitan.nominal_voucher)
                : 0
              : 0

          let total =
            parseInt(item.paket_khitan.harga) + ongkos - diskon - voucher
          let totalcek = total > 0 ? total : 0

          total_pendapatan += totalcek

          return {
            ...item,
            tipe_neraca: (
              <Badge className={"font-size-12 badge bg-primary"} pill>
                Pendapatan
              </Badge>
            ),
            nama_neraca: item.data_anak.nama_anak,
            total_neraca:
              "Rp." + totalcek.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
          }
        })
      }

      if (!isEmpty(dataNeraca.neraca_pengeluaran)) {
        neraca_pengeluaran = dataNeraca?.neraca_pengeluaran.map(item => {
          let total_pemasukan_single = 0
          let total_pengeluaran_single = 0

          item.data_barang.reduce((acc, obj) => {
            let subtotal = obj.harga * obj.jumlah

            if (obj.tipe === "pemasukan") {
              total_pemasukan += subtotal
              total_pemasukan_single += subtotal
            } else {
              total_pengeluaran += subtotal
              total_pengeluaran_single += subtotal
            }
            // (acc += obj.harga * obj.jumlah)
          }, 0)

          let total_single = total_pemasukan_single - total_pengeluaran_single

          return {
            ...item,
            tipe_neraca: (
              <Badge
                className={
                  total_pemasukan_single > 0 && total_pengeluaran_single > 0
                    ? "font-size-12 badge bg-danger"
                    : total_pemasukan_single > 0
                    ? "font-size-12 badge bg-success"
                    : total_pengeluaran_single > 0
                    ? "font-size-12 badge bg-danger"
                    : ""
                }
                pill
              >
                {total_pemasukan_single > 0 && total_pengeluaran_single > 0
                  ? "Pemasukan + Pengeluaran"
                  : total_pemasukan_single > 0
                  ? "Pemasukan"
                  : total_pengeluaran_single > 0
                  ? "Pengeluaran"
                  : ""}
              </Badge>
            ),
            nama_neraca: new Date(item.createdAt).toLocaleString("ID", {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            }),
            // total_neraca: "Rp." + (total_single >= 0 ? total_single : (total_single * -1)).toString()
            total_neraca:
              "Rp." +
              total_single.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
            // total_neraca: item.data_barang
            //   .reduce((acc, obj) => (acc += obj.harga * obj.jumlah), 0)
            //   .toString()
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, "."),
          }
        })
      }

      if (!isEmpty(dataNeraca.neraca_tarikdana)) {
        neraca_tarikdana = dataNeraca?.neraca_tarikdana.map(item => {
          total_tarikDana += item.nominal

          return {
            ...item,
            tipe_neraca: (
              <Badge className={"font-size-12 badge bg-warning"} pill>
                Dana Affiliasi
              </Badge>
            ),
            nama_neraca: item?.data_user[0].nama,
            total_neraca:
              "Rp." +
              item.nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
          }
        })
      }

      if (!isEmpty(dataNeraca.neraca_gaji)) {
        neraca_gaji = dataNeraca?.neraca_gaji.map(item => {
          total_gaji += item.data_gaji.reduce(
            (acc, obj) =>
              (acc += parseInt(obj.jumlah_gaji) + parseInt(obj.jumlah_bonus)),
            0
          )

          return {
            ...item,
            tipe_neraca: (
              <Badge className={"font-size-12 badge bg-danger"} pill>
                Pengeluaran - Gaji
              </Badge>
            ),
            nama_neraca: new Date(item.createdAt).toLocaleString("ID", {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            }),
            total_neraca:
              "Rp.-" +
              item.data_gaji
                .reduce(
                  (acc, obj) =>
                    (acc +=
                      parseInt(obj.jumlah_gaji) + parseInt(obj.jumlah_bonus)),
                  0
                )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, "."),
          }
        })
      }

      setTotalPendapatan(total_pendapatan)
      setTotalPengeluaran(total_pengeluaran + total_gaji)
      setTotalTarikDana(total_tarikDana)
      total_saldo =
        Number(total_pendapatan) +
        Number(total_pemasukan) -
        Number(total_pengeluaran) -
        Number(total_gaji) -
        Number(total_tarikDana)
      setTotalSaldo(total_saldo)

      let dataneracasort = [].concat(
        neraca_pendapatan,
        neraca_pengeluaran,
        neraca_tarikdana,
        neraca_gaji
      )

      setDataTableNeraca(
        dataneracasort.sort((a, b) => b?.createdAt.localeCompare(a?.createdAt))
      )
    }
  }, [dataNeraca, dataConfig])

  useEffect(() => {
    setData({
      columns: [
        {
          label: "Nama / Tanggal",
          field: "nama_neraca",
          width: 150,
        },
        {
          label: "Tipe",
          field: "tipe_neraca",
          width: 150,
        },
        {
          label: "Total",
          field: "total_neraca",
          width: 270,
        },
      ],
      rows: dataTableNeraca,
    })
  }, [dataTableNeraca])

  // useEffect(() => {
  //   onGetDataNeraca()
  // }, [])

  useEffect(() => {
    onFilterDataNeraca({ data_filter: dataFilter })
    onGetDataConfig(["biaya_dalam_kota", "biaya_luar_kota"])
  }, [dataFilter])

  const filterNeraca = val => {
    setDataFilter(val)
  }

  // const data = {
  //   columns: [
  //     {
  //       label: "Nama / Tanggal",
  //       field: "nama_neraca",
  //       width: 150,
  //     },
  //     {
  //       label: "Tipe",
  //       field: "tipe_neraca",
  //       width: 150,
  //     },
  //     {
  //       label: "Total",
  //       field: "total_neraca",
  //       width: 270,
  //     },
  //   ],
  //   rows: dataTableNeraca,
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Neraca | KhitanSultan Backoffice</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col className="col-6">
                <h6 className="page-title">Neraca Laba Rugi</h6>
                <ol className="breadcrumb m-0">
                  {/* <li className="breadcrumb-item active">
                    Selamat datang di Neraca Khitan Sultan
                  </li> */}
                </ol>
              </Col>

              <Col className="col-6">
                <div className="float-end d-md-block">
                  <label
                    htmlFor="example-month-input"
                    className="col-form-label"
                  >
                    Filter Bulan
                  </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="month"
                      // defaultValue="2022-05"
                      id="example-month-input"
                      onChange={val => filterNeraca(val.target.value)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <Link to="/booking">
                    <div className="mb-2">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon1} alt="" />
                      </div>
                      <h5 className="font-size-13 text-uppercase mt-0 text-white-50">
                        Pendapatan
                      </h5>
                      <h6 className="fw-medium font-size-14 text-white">
                        Rp.{" "}
                        {totalPendapatan
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".") || 0}
                      </h6>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-danger text-white">
                <CardBody>
                  <Link to="/pengeluaran">
                    <div className="mb-2">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon2} alt="" />
                      </div>
                      <h5 className="font-size-13 text-uppercase mt-0 text-white-50">
                        Pengeluaran
                      </h5>
                      <h6 className="fw-medium font-size-14 text-white">
                        Rp.{" "}
                        {totalPengeluaran
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".") || 0}
                      </h6>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-warning text-white">
                <CardBody>
                  <Link to="/tarikDana">
                    <div className="mb-2">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon3} alt="" />
                      </div>
                      <h5 className="font-size-13 text-uppercase mt-0 text-white-50">
                        Affiliasi
                      </h5>
                      <h6 className="fw-medium font-size-14 text-white">
                        Rp.{" "}
                        {totalTarikDana
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".") || 0}
                      </h6>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-success text-white">
                <CardBody>
                  <div className="mb-2">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon4} alt="" />
                    </div>
                    <h5 className="font-size-13 text-uppercase mt-0 text-white-50">
                      Saldo
                    </h5>
                    <h6 className="fw-medium font-size-14 text-white">
                      Rp.{" "}
                      {totalSaldo
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".") || 0}
                    </h6>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row>
            <Col lg="7">
              <Card>
                <CardBody>
                  <h4 className="mt-0 header-title mb-4">Metode Digunakan</h4>
                  <div id="pie-chart" className="e-chart">
                    <Pie
                      data={dataChartMetode}
                      notMerge={true}
                      lazyUpdate={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="5">
              <Card>
                <CardBody>
                  <h4 className="mt-0 header-title mb-4">Platform Booking</h4>

                  <div id="doughnut-chart" className="e-chart">
                    <Doughnut
                      data={dataChartPlatform}
                      notMerge={true}
                      lazyUpdate={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Transaksi Terakhir</h4>

                  <MDBDataTable responsive striped bordered data={data} />

                  {/* <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">Tipe</th>
                          <th scope="col">Nama</th>
                          <th scope="col">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isEmpty(dataTableNeraca) ? (
                          dataTableNeraca.map((item, index) => (
                            <tr key={index}>
                              <td>{(index += 1)}</td>
                              <td>{item.tipe_neraca}</td>
                              <td>{item.nama_neraca}</td>
                              <td>{item.total}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <th scope="row" colSpan={5} className="text-center">
                              Belum ada transaksi
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

Neraca.propTypes = {
  dataNeraca: PropTypes.object,
  dataConfig: PropTypes.object,
  onGetDataNeraca: PropTypes.func,
  onFilterDataNeraca: PropTypes.func,
  onGetDataConfig: PropTypes.func,
}

const mapStateToProps = ({ dataNeracaReducer, dataConfigReducer }) => ({
  dataNeraca: dataNeracaReducer.dataNeraca,
  dataConfig: dataConfigReducer.dataConfig,
})

const mapDispatchToProps = dispatch => ({
  onGetDataNeraca: () => dispatch(getDataNeraca()),
  onFilterDataNeraca: data => dispatch(filterDataNeraca(data)),
  onGetDataConfig: config => dispatch(getDataConfig(config)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Neraca)
