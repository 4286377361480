import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { env } from "../../helpers/config"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

import { getDataTarikDana, updateTarikDana } from "../../store/actions"

const TarikDana = props => {
  const { onGetDataTarikDana, dataTarikDana } = props
  const [modal, setModal] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [tarikdana, setTarikDana] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [imageTarikDana, setImageTarikDana] = useState([])
  const [errImage, setErrImage] = useState("")
  const [status, setStatus] = useState("")

  useEffect(() => {
    if (dataTarikDana) {
      dataTarikDana.map((item, index) => {
        item.nama = !isEmpty(item?.detail[0])
          ? item?.detail[0].nama
          : "Akun Dihapus"
        item.action = (
          <>
            <Button
              key={`detail-${index}`}
              color="success"
              outline
              className="font-16 btn-block btn-sm"
              onClick={e => handleOpenModalDetail(item)}
            >
              Detail
            </Button>{" "}
            <Button
              color="primary"
              outline
              className="font-16 btn-block btn-sm"
              onClick={e => handleOpenModal(item)}
            >
              Ubah
            </Button>
          </>
        )
        item.image1 = (
          <CardImg
            src={env.IMG_URL + item.image}
            alt="image-tarikdana"
            className="avatar-lg img-thumbnail img-fluid"
          />
        )
        item.nominalFormated =
          "Rp. " + item.nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

        item.statusBadge = (
          <>
            <Badge
              className={
                "font-size-12 badge bg-" +
                (item.status === "verifikasi"
                  ? "warning"
                  : item.status === "selesai"
                  ? "success"
                  : "danger")
              }
              pill
            >
              {item.status === "verifikasi"
                ? "diproses"
                : item.status === "pending"
                ? "diproses"
                : item.status}
            </Badge>
          </>
        )
      })
    }

    if (!modal && !isEmpty(tarikdana) && !!isEdit) {
      // setTimeout(() => {
      setTarikDana({})
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, tarikdana, dataTarikDana])

  const toggle = close => {
    if (close) {
      setImageTarikDana([])
      setErrImage("")
      setStatus("")
    }
    setModal(!modal)
  }

  const toggleDetail = close => {
    // console.log(booking)
    setModalDetail(!modalDetail)
    if (close) {
      setTarikDana({})
    }
  }

  const handleOpenModalDetail = data => {
    if (data) {
      setTarikDana(data)
    }
    toggleDetail()
  }

  const handleOpenModal = data => {
    // console.log("data", data)
    if (data) {
      // console.log("data exist")
      setTarikDana({
        _id: data._id,
        nama: data.nama,
        nominal: data.nominal,
        status: data.status,
        image: data.image,
      })
      setImageTarikDana([data.image])
      setStatus(data.status)

      setIsEdit(true)
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  useEffect(() => {
    onGetDataTarikDana()
  }, [])

  // useEffect(() => {
  //   console.log(dataTarikDana)
  // }, [dataTarikDana])

  const handleValidTarikDanaSubmit = (e, values) => {
    const { onAddNewTarikDana, onUpdateTarikDana } = props

    if (
      (isEmpty(imageTarikDana[0]) ||
        typeof imageTarikDana[0] === "undefined") &&
      !!isEdit &&
      status === "selesai"
    ) {
      setErrImage("Gambar transfer harus di upload..")
    } else {
      const formData = new FormData()
      formData.append("status", values.status)
      formData.append("image", status === "selesai" ? imageTarikDana[0] : "")

      if (isEdit) {
        formData.append("id", tarikdana._id)
        onUpdateTarikDana(formData)
      } else {
        setErrImage("")
        // onAddNewTarikDana(formData)
      }

      setImageTarikDana([])
      setStatus("")
      toggle()
    }
  }

  const handleDeleteTarikDana = () => {
    const { onDeleteTarikDana } = props
    onDeleteTarikDana(tarikdana._id)
    setDeleteModal(false)
    setImageTarikDana([])
    setStatus("")
    toggle()
  }

  function handleImageTarikDana(files) {
    if (files.length > 1) {
      setErrImage("Maksimal upload 1 gambar")
    } else {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setImageTarikDana(files)
      setErrImage("")
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const data = {
    columns: [
      {
        label: "Nama Affiliasi",
        field: "nama",
        sort: "asc",
        width: 150,
      },
      {
        label: "Nominal",
        field: "nominalFormated",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "statusBadge",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: dataTarikDana,
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTarikDana}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>TarikDana | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs breadcrumbItem="Tarik Dana" />
                  {/* <CardTitle className="h4">Data TarikDana</CardTitle> */}
                  {/* <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable modal-lg"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Tarik Dana" : "Tambah Tarik Dana"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidTarikDanaSubmit}>
                <Row form>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="id_affiliasi"
                      label="Nama Affiliasi"
                      type="text"
                      disabled={true}
                      value={tarikdana ? tarikdana.nama : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="nominal"
                      label="Nominal"
                      type="text"
                      disabled={true}
                      value={tarikdana ? tarikdana.nominal : ""}
                    />
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      type="select"
                      name="status"
                      label="Status Tarik Dana"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={e => setStatus(e.target.value)}
                      value={tarikdana ? tarikdana.status : ""}
                    >
                      <option hidden value="">
                        -- Pilih Status --
                      </option>
                      <option value="pending">Diproses</option>
                      <option value="selesai">Selesai</option>
                      <option value="batal">Batal</option>
                    </AvField>
                  </Col>

                  {status === "selesai" && (
                    <Col className="col-12 mb-3">
                      <Dropzone
                        accept="image/jpeg, image/png"
                        onDrop={img => {
                          handleImageTarikDana(img)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                              </div>
                              <h6>
                                Seret Gambar TarikDana atau Klik untuk Upload
                              </h6>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {!isEmpty(imageTarikDana) &&
                          imageTarikDana.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={
                                          f.name || f.toString().split("-")[1]
                                        }
                                        src={f.preview || env.IMG_URL + f}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name || f.toString().split("-")[1]}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                      </div>
                      <div className=" is-invalid"></div>
                      <div className="invalid-feedback">{errImage}</div>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={() => toggle("close")}
                      >
                        Tutup
                      </button>
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-outline-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        type="submit"
                        className="btn btn-outline-success save-tarikdana"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={modalDetail}
            className="modal-dialog modal-dialog-scrollable modal-md"
          >
            <ModalHeader toggle={toggleDetail} tag="h4">
              Detail Tarik Dana
            </ModalHeader>
            <ModalBody>
              {modalDetail && !isEmpty(tarikdana) && (
                <div>
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="col" width="200">
                          Nama Affilaisi
                        </th>
                        <td scope="col">{tarikdana ? tarikdana.nama : ""}</td>
                      </tr>

                      <tr>
                        <th scope="col" width="200">
                          Nominal
                        </th>
                        <td scope="col">{tarikdana.nominalFormated}</td>
                      </tr>
                      <tr>
                        <th scope="col" width="200">
                          Status
                        </th>
                        <td scope="col">{tarikdana.statusBadge}</td>
                      </tr>

                      <tr>
                        <th scope="col" width="200">
                          <ul className="list-unstyled mb-0">
                            <li>Metode Tarik Dana</li>
                          </ul>
                        </th>
                        <td scope="col">
                          <ul className="list-unstyled mb-0">
                            <li>
                              {/* &nbsp; */}
                              <ul className="list-styled mb-0">
                                <li>
                                  Metode :{" "}
                                  {!isEmpty(tarikdana) &&
                                    !isEmpty(tarikdana?.detail) &&
                                    tarikdana?.detail[0].affiliasi?.bank}
                                </li>
                                {!isEmpty(tarikdana) &&
                                  !isEmpty(tarikdana?.detail[0]) &&
                                  tarikdana?.detail[0].affiliasi?.bank ===
                                    "bank" && (
                                    <li>
                                      Nama & Cabang :{" "}
                                      {!isEmpty(tarikdana) &&
                                        !isEmpty(tarikdana?.detail[0]) &&
                                        tarikdana?.detail[0].affiliasi?.cabang}
                                    </li>
                                  )}
                                <li>
                                  Nomor / Rekening :{" "}
                                  {!isEmpty(tarikdana) &&
                                    !isEmpty(tarikdana?.detail[0]) &&
                                    tarikdana?.detail[0].affiliasi?.rekening}
                                </li>
                                <li>
                                  Atas Nama :{" "}
                                  {!isEmpty(tarikdana) &&
                                    !isEmpty(tarikdana?.detail[0]) &&
                                    tarikdana?.detail[0].affiliasi?.atasNama}
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <th scope="col" width="200">
                          Nota / Bukti Transfer
                        </th>
                        <td scope="col">
                          {!isEmpty(tarikdana) &&
                            !isEmpty(tarikdana?.image) && (
                              <>
                                <br />
                                <img
                                  height="150"
                                  className="bg-light img-thumbnail"
                                  alt="Selfie Presensi"
                                  src={env.IMG_URL + tarikdana?.image}
                                />
                              </>
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

TarikDana.propTypes = {
  dataTarikDana: PropTypes.array,
  onGetDataTarikDana: PropTypes.func,
  onUpdateTarikDana: PropTypes.func,
}

const mapStateToProps = ({ dataTarikDanaReducer }) => ({
  dataTarikDana: dataTarikDanaReducer.dataTarikDana,
})

const mapDispatchToProps = dispatch => ({
  onGetDataTarikDana: () => dispatch(getDataTarikDana()),
  onUpdateTarikDana: tarikdana => dispatch(updateTarikDana(tarikdana)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TarikDana)
