import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty, groupBy, isRegExp } from "lodash"

import { env } from "../../helpers/config"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  Label,
} from "reactstrap"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

//Loading Overlay
import LoadingOverlay from "react-loading-overlay"
import BounceLoader from "react-spinners/BounceLoader"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import { dataRegions } from "../../assets/regions"

import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

import { CSVLink } from "react-csv"
import ReactToPrint, { useReactToPrint } from "react-to-print"

import { jsPDF } from "jspdf"

import {
  addNewBooking,
  deleteBooking,
  getDataBooking,
  getDataBookingFilter,
  getDataPaketKhitan,
  updateBooking,
  exportBooking,
  exportSertifikat,
  getDataAffiliasi,
  getDataKaryawan,
  getDataConfig,
  checkVoucher,
  searchUser,
} from "../../store/actions"

const Booking = props => {
  const {
    onGetDataBooking,
    onGetDataBookingFilter,
    onExportBooking,
    onExportSertifikat,
    onGetDataPaketKhitan,
    onGetDataAffiliasi,
    onGetDataKaryawan,
    onCheckVoucher,
    dataBooking,
    dataBookingFilter,
    dataBookingExport,
    dataSertifikatExport,
    dataPaketKhitan,
    dataAffiliasi,
    dataKaryawan,
    onGetDataConfig,
    dataConfig,
    checkVoucher,
    globalLoading,
    dataUserSearch,
    loadingUserSearch,
    onSearchUser,
  } = props
  const componentRefPdf = useRef()
  const htmlData = useRef(null);

  const [firstErrorField, setFirstErrorField] = useState(null)
  const [modal, setModal] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [booking, setBooking] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [valTanggalKhitan, setValTanggalKhitan] = useState("")
  const [tanggalKhitan, setTanggalKhitan] = useState("")
  const [refLainnya, setRefLainnya] = useState(false)
  const [tempatKhitan, setTempatKhitan] = useState("")
  const [tempatPanggilan, setTempatPanggilan] = useState(false)
  const [isSelesai, setIsSelesai] = useState(false)
  const [bookingStatus, setBookingStatus] = useState("")
  const [metode, setMetode] = useState(false)
  const [errImage, setErrImage] = useState("")
  const [imageBuktiPembayaran, setImageBuktiPembayaran] = useState([])
  const [jamKhitan, setjamKhitan] = useState("")
  const [selectedJamKhitan, setSelectedJamKhitan] = useState([])
  const [dataExportCsv, setDataExportCsv] = useState([])
  const [dokterOperator, setDokterOperator] = useState({
    id_dokter: "",
    nama_dokter: "",
  })
  // const [dataBookingUpdated, setSelectedJamKhitan] = useState([])
  const [jamBuka, setJamBuka] = useState([
    "08.00",
    "08.30",
    "09.00",
    "09.30",
    "10.00",
    "10.30",
    "11.00",
    "13.00",
    "13.30",
    "14.00",
    "14.30",
    "15.00",
    "15.30",
    "16.00",
  ])
  const [selectedProvinsi, setSelectedProvinsi] = useState(
    dataRegions.find(item => item.provinsi === "Jawa Timur"),
  )
  const [kodeAffiliasi, setKodeAffiliasi] = useState("")
  const [kodeVoucher, setKodeVoucher] = useState("")
  const [selectedAffiliasi, setSelectedAffiliasi] = useState({})
  const [selectedVoucher, setSelectedVoucher] = useState({})
  const [filterTanggal, setFilterTanggal] = useState("")
  const [filterKlinik, setFilterKlinik] = useState("")
  const [tanggalLahirAnak, setTanggalLahirAnak] = useState("")
  const [displayDataBooking, setDisplayDataBooking] = useState([])
  const [kodePdf, setKodePdf] = useState("")

  const [querySearchUser, setQuerySearchUser] = useState("")
  const [assignUser, setAssignUser] = useState({
    label: "-- Cari nama atau telepon pasien --",
  })
  const [optionUserSearch, setOptionUserSearch] = useState([])

  let userRole = JSON.parse(localStorage.getItem("authUser")).role

  useEffect(() => {
    setFilterTanggal(filterTanggal)
    setFilterKlinik(filterKlinik)

    if (dataBooking) {
      let dataKodeBooking = sortAndGroup(dataBooking)

      dataBooking.map((item, index) => {
        item.action = (
          <>
            <div className="d-flex">
              <Button
                key={`detail-${index}`}
                color="success"
                className="mt-1 mx-1 btn-sm d-flex align-items-center"
                outline
                onClick={e => handleOpenModalDetail(item)}
              >
                {/* <i className="fas fa-list-alt"></i>  */}
                Detail
              </Button>
              {/* {userRole === "superAdmin" && ( */}
              <Button
                key={`edit-${index}`}
                color="primary"
                className="mt-1 mx-1 btn-sm d-flex align-items-center"
                outline
                onClick={e => handleOpenModal(item)}
              >
                {/* <i className="fas fa-pen"></i>  */}
                Ubah
              </Button>
              <br />
              {/* {userRole === "superAdmin" && ( */}
            </div>
            <div className="d-flex">
              <Button
                key={`invoice-${index}`}
                color="warning"
                className="mt-1 mx-1 btn-sm d-flex align-items-center"
                outline
                onClick={() =>
                  handleInvoicePdf(
                    item._id,
                    dataKodeBooking.find(arr => arr.id === item._id)
                      ?.kodeBooking,
                  )
                }
              >
                {/* <i className="fas fa-receipt"></i>  */}
                Invoice
              </Button>
              {/* )} */}
              {/* {userRole === "superAdmin" && ( */}
              {item.status === "selesai" && (
                <Button
                  key={`sertifikat-${index}`}
                  color="info"
                  className="mt-1 mx-1 btn-sm d-flex align-items-center"
                  outline
                  onClick={() =>
                    handleSertifikatPdf(
                      item._id,
                      dataKodeBooking.find(arr => arr.id === item._id)
                        ?.kodeBooking,
                    )
                  }
                >
                  {/* <i className="fas fa-window-maximize"></i>  */}
                  Sertifikat
                </Button>
              )}
            </div>
          </>
        )
        item.image = (
          <CardImg
            src={env.IMG_URL + item.image}
            alt="image-booking"
            className="avatar-md"
          />
        )
        item.statusBadge = (
          <>
            <Badge
              className={
                "font-size-12 badge bg-" +
                (item.status === "verifikasi"
                  ? "warning"
                  : item.status === "aktif"
                  ? "primary"
                  : item.status === "selesai"
                  ? "success"
                  : item.status === "rejadwal"
                  ? "info"
                  : "danger")

                //   enable transfer
                // (item.status === "belum_bayar"
                //   ? "warning"
                //   : item.status === "lunas"
                //   ? "primary"
                //   : item.status === "selesai"
                //   ? "success"
                //   : "danger")
              }
              pill
            >
              {item.status === "verifikasi"
                ? "diproses"
                : item.status === "aktif"
                ? "terverifikasi"
                : item.status}
            </Badge>
            {item.status === "selesai" &&
              item.data_pembayaran &&
              item.data_pembayaran?.metode_pembayaran && (
                <>
                  <br />
                  <Badge
                    className={
                      "font-size-12 badge bg-" +
                      (item.data_pembayaran.metode_pembayaran === "cash"
                        ? "primary"
                        : item.data_pembayaran.metode_pembayaran === "transfer"
                        ? "warning"
                        : "danger")
                    }
                    pill
                  >
                    {item.data_pembayaran.metode_pembayaran}
                  </Badge>
                </>
              )}
          </>
        )
        item.display_tempat_khitan = (
          <Badge
            className={
              "font-size-12 badge bg-" +
              (item.tempat_khitan?.tempat_khitan === "klinik_singosari"
                ? "primary"
                : item.tempat_khitan?.tempat_khitan === "klinik_joyoagung"
                ? "success"
                : item.tempat_khitan?.tempat_khitan === "panggilan_rumah"
                ? "warning"
                : "danger")
            }
            pill
          >
            {item.tempat_khitan?.tempat_khitan || "belum_dipilih"}
          </Badge>
        )
        item.nama_anak = item.data_anak.nama_anak
        item.display_nama_dokter = item?.data_dokter?.nama_dokter
        // item.display_tempat_khitan = item.tempat_khitan?.tempat_khitan

        // let date = new Date(item.createdAt)
        // let m = date.getMonth() + 1
        // let y = date.getFullYear()

        // item.no_booking =
        //   m.toString().padStart(2, "0") +
        //   y.toString().slice(2) +
        //   String(dataLength + 1 - (index + 1)).padStart(3, "0")

        item.kode_booking = (
          <Badge
            className={
              "font-size-12 badge bg-" +
              (item.isFromAdmin
                ? "warning"
                : item.id_member === "" || item.id_member === "undefined"
                ? "success"
                : "primary")
            }
            pill
          >
            {dataKodeBooking.find(arr => arr.id === item._id)?.kodeBooking}
          </Badge>
        )
        item.kode_booking_plain = dataKodeBooking.find(
          arr => arr.id === item._id,
        )?.kodeBooking
      })

      // console.log(
      //   groupBy(dataBooking, ({ createdAt }) => new Date(createdAt).getMonth())
      // )

      // console.log(dataKodeBooking)
      // console.log(dataBooking)
    }

    sortDataBooking(dataBooking)

    // setDisplayDataBooking(sortDataBookings);

    if (isEmpty(filterTanggal) || isEmpty(filterKlinik)) {
      setDataExportCsv(dataBooking)
    }
  }, [dataBooking])

  useEffect(() => {
    if (!modal && !isEmpty(booking) && !!isEdit) {
      // setTimeout(() => {
      setBooking({})
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, booking])

  useEffect(() => {
    if (dataBookingFilter) {
      let dataKodeBooking = sortAndGroup(dataBooking)

      dataBookingFilter.map((item, index) => {
        item.action = (
          <>
            <div className="d-flex">
              <Button
                key={`detail-${index}`}
                color="success"
                className="mt-1 mx-1 btn-sm d-flex align-items-center"
                outline
                onClick={e => handleOpenModalDetail(item)}
              >
                {/* <i className="fas fa-list-alt"></i>  */}
                Detail
              </Button>
              {/* {userRole === "superAdmin" && ( */}
              <Button
                key={`edit-${index}`}
                color="primary"
                className="mt-1 mx-1 btn-sm d-flex align-items-center"
                outline
                onClick={e => handleOpenModal(item)}
              >
                {/* <i className="fas fa-pen"></i>  */}
                Ubah
              </Button>
              <br />
              {/* {userRole === "superAdmin" && ( */}
            </div>
            <div className="d-flex">
              <Button
                key={`invoice-${index}`}
                color="warning"
                className="mt-1 mx-1 btn-sm d-flex align-items-center"
                outline
                onClick={() =>
                  handleInvoicePdf(
                    item._id,
                    dataKodeBooking.find(arr => arr.id === item._id)
                      ?.kodeBooking,
                  )
                }
              >
                {/* <i className="fas fa-receipt"></i>  */}
                Invoice
              </Button>
              {/* )} */}
              {/* {userRole === "superAdmin" && ( */}
              {item.status === "selesai" && (
                <Button
                  key={`sertifikat-${index}`}
                  color="info"
                  className="mt-1 mx-1 btn-sm d-flex align-items-center"
                  outline
                  onClick={() =>
                    handleSertifikatPdf(
                      item._id,
                      dataKodeBooking.find(arr => arr.id === item._id)
                        ?.kodeBooking,
                    )
                  }
                >
                  {/* <i className="fas fa-window-maximize"></i>  */}
                  Sertifikat
                </Button>
              )}
            </div>
          </>
        )
        item.image = (
          <CardImg
            src={env.IMG_URL + item.image}
            alt="image-booking"
            className="avatar-md"
          />
        )
        item.statusBadge = (
          <>
            <Badge
              className={
                "font-size-12 badge bg-" +
                (item.status === "verifikasi"
                  ? "warning"
                  : item.status === "aktif"
                  ? "primary"
                  : item.status === "selesai"
                  ? "success"
                  : item.status === "rejadwal"
                  ? "info"
                  : "danger")

                //   enable transfer
                // (item.status === "belum_bayar"
                //   ? "warning"
                //   : item.status === "lunas"
                //   ? "primary"
                //   : item.status === "selesai"
                //   ? "success"
                //   : "danger")
              }
              pill
            >
              {item.status === "verifikasi"
                ? "diproses"
                : item.status === "aktif"
                ? "terverifikasi"
                : item.status}
            </Badge>
            {item.status === "selesai" &&
              item.data_pembayaran &&
              item.data_pembayaran?.metode_pembayaran && (
                <>
                  <br />
                  <Badge
                    className={
                      "font-size-12 badge bg-" +
                      (item.data_pembayaran.metode_pembayaran === "cash"
                        ? "primary"
                        : item.data_pembayaran.metode_pembayaran === "transfer"
                        ? "warning"
                        : "danger")
                    }
                    pill
                  >
                    {item.data_pembayaran.metode_pembayaran}
                  </Badge>
                </>
              )}
          </>
        )
        item.display_tempat_khitan = (
          <Badge
            className={
              "font-size-12 badge bg-" +
              (item.tempat_khitan?.tempat_khitan === "klinik_singosari"
                ? "primary"
                : item.tempat_khitan?.tempat_khitan === "klinik_joyoagung"
                ? "success"
                : item.tempat_khitan?.tempat_khitan === "panggilan_rumah"
                ? "warning"
                : "danger")
            }
            pill
          >
            {item.tempat_khitan?.tempat_khitan || "belum_dipilih"}
          </Badge>
        )
        item.nama_anak = item.data_anak.nama_anak
        // item.display_tempat_khitan = item.tempat_khitan?.tempat_khitan

        // let date = new Date(item.createdAt)
        // let m = date.getMonth() + 1
        // let y = date.getFullYear()

        // item.no_booking =
        //   m.toString().padStart(2, "0") +
        //   y.toString().slice(2) +
        //   String(dataLength + 1 - (index + 1)).padStart(3, "0")

        item.kode_booking = (
          <Badge
            className={
              "font-size-12 badge bg-" +
              (item.isFromAdmin
                ? "warning"
                : item.id_member === "" || item.id_member === "undefined"
                ? "success"
                : "primary")
            }
            pill
          >
            {dataKodeBooking.find(arr => arr.id === item._id)?.kodeBooking}
          </Badge>
        )
        item.kode_booking_plain = dataKodeBooking.find(
          arr => arr.id === item._id,
        )?.kodeBooking
      })

      // console.log(
      //   groupBy(dataBooking, ({ createdAt }) => new Date(createdAt).getMonth())
      // )

      // console.log(dataKodeBooking)
      // console.log(dataBooking)
    }

    sortDataBooking(dataBookingFilter)

    // setDisplayDataBooking(sortDataBookings);

    if (isEmpty(filterTanggal) && isEmpty(filterKlinik)) {
      setDataExportCsv(dataBooking)
    }

    if (!isEmpty(filterTanggal) || !isEmpty(filterKlinik)) {
      setDataExportCsv(dataBookingFilter)
    }
  }, [dataBookingFilter])

  const sortDataBooking = data => {
    return data.sort((a, b) => {
      if (a.status === "batal") return 1
      if (b.status === "batal") return -1
      return 0
    })
  }

  // helper method to get either YYYY-MM (if full=false) or YYYY-MM-DD (otherwise)
  const getDtYMD = (dt, full = false) =>
    full ? dt.createdAt.split("T")[0] : dt.slice(0, 7)
  // transform the arr to add a group-id (idGroup)
  const sortAndGroup = arr => {
    return Object.values(
      // get the values of the result object
      [...arr.map(x => ({ ...x }))]
        .sort(
          // first sort the input-array reverse-chronological order (latest date at 0)
          (a, b) => (getDtYMD(a, true) > getDtYMD(b, true) ? 1 : -1),
        )
        .reduce(
          // use .reduce to iterate over the sorted array
          (acc, item) => ({
            ...acc, // safe-keep existing accumulator object
            [getDtYMD(item.createdAt)]: [
              ...(acc[getDtYMD(item.createdAt)] || []),
              {
                // append new object to value array for key YYYY-MM
                idGroup: (acc[getDtYMD(item.createdAt)]?.length || 0) + 1,
                createdAt: item.createdAt, // update 'idGroup' based on existing value-array's length
                id: item._id,
                kodeBooking:
                  "#" +
                  (new Date(item.createdAt).getMonth() + 1)
                    .toString()
                    .padStart(2, "0") +
                  new Date(item.createdAt).getFullYear().toString().slice(2) +
                  String(
                    (acc[getDtYMD(item.createdAt)]?.length || 0) + 1,
                  ).padStart(3, "0"),
              },
            ],
          }),
          {},
        ),
    )
      .flat()
      .sort((a, b) =>
        getDtYMD(a, true) > getDtYMD(b, true)
          ? -1
          : getDtYMD(a, true) < getDtYMD(b, true)
          ? 1
          : a.idGroup > b.idGroup
          ? -1
          : 1,
      )
    // .sort((a, b) =>
    //   getDtYMD(a, true) > getDtYMD(b, true)
    //     ? -1
    //     : getDtYMD(a, true) < getDtYMD(b, true)
    //     ? 1
    //     : a.idGroup > b.idGroup
    //     ? -1
    //     : 1
    // )
  }

  const toggle = close => {
    setModal(!modal)
    if (close) {
      setImageBuktiPembayaran([])
      setTanggalKhitan("")
      setErrImage("")
      setSelectedJamKhitan([])
      setjamKhitan("")
      setKodeAffiliasi("")
      setAssignUser({
        label: "-- Cari nama atau telepon pasien --",
      })
      setKodeVoucher("")
      setSelectedAffiliasi({})
      setSelectedVoucher({})
      setSelectedProvinsi(
        dataRegions.find(item => item.provinsi === "Jawa Timur"),
      )
      setBookingStatus("")
      setTanggalLahirAnak("")
    }
  }

  const toggleDetail = close => {
    // console.log(booking)
    setModalDetail(!modalDetail)
    if (close) {
      setImageBuktiPembayaran([])
      setBooking({})
    }
  }

  const handleOpenModal = data => {
    if (data) {
      dataBooking.map((item, i) => {
        if (item.tanggal_khitan === data.tanggal_khitan) {
          setSelectedJamKhitan(selectedJamKhitan => [
            ...selectedJamKhitan,
            item.jam_khitan,
          ])
        }
      })

      setBooking({
        _id: data._id,
        data_affiliasi: data.data_affiliasi,
        telepon: data.telepon,
        tanggal_khitan: data.tanggal_khitan,
        jam_khitan: data.jam_khitan,
        paket_khitan: data.paket_khitan,
        alamat: data.alamat,
        tempat_khitan: data.tempat_khitan,
        data_ortu: data.data_ortu,
        data_anak: data.data_anak,
        data_obat: data.data_obat,
        data_pembayaran: data.data_pembayaran,
        data_dokter: data.data_dokter,
        status: data.status,
        isFromAdmin: data.isFromAdmin,
        id_member: data.id_member,
      })
      if (data.data_ortu.ref_khitan === "lainnya") {
        setRefLainnya(true)
      } else {
        setRefLainnya(false)
      }

      if (data.tempat_khitan?.tempat_khitan === "panggilan_rumah") {
        setTempatPanggilan(true)
      } else {
        setTempatPanggilan(false)
      }

      if (data.data_pembayaran.metode_pembayaran === "transfer") {
        setMetode(true)
      } else {
        setMetode(false)
      }

      if (data.status === "selesai") {
        setIsSelesai(true)
      } else {
        setIsSelesai(false)
      }

      setBookingStatus(data.status)

      setSelectedProvinsi(
        dataRegions.find(item => item.provinsi === data.alamat?.provinsi),
      )
      setKodeAffiliasi(
        (data && data.data_affiliasi && data.data_affiliasi.id_affiliasi) || "",
      )
      setKodeVoucher(
        (data && data.paket_khitan && data.paket_khitan?.kode_voucher) || "",
      )

      setjamKhitan(data.jam_khitan)
      setTanggalKhitan(data.tanggal_khitan)
      setTanggalLahirAnak(
        (data && data.data_anak && data.data_anak?.tanggal_lahir_anak) || "",
      )
      setImageBuktiPembayaran([data.data_pembayaran.image_bukti_pembayaran])
      setIsEdit(true)
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  const handleOpenModalDetail = async data => {
    if (data) {
      let total_paket = await (data.paket_khitan?.harga -
        (data.paket_khitan?.tipePotongan === "persen"
          ? (data.paket_khitan?.harga * data.paket_khitan?.potongan) / 100
          : data.paket_khitan?.tipePotongan === "nominal"
          ? data.paket_khitan?.potongan
          : 0) -
        (data.paket_khitan?.isVoucherPersen
          ? (data.paket_khitan?.harga * data.paket_khitan?.nominal_voucher) /
            100
          : data.paket_khitan.nominal_voucher || 0))

      let total_display = await (total_paket < 0
        ? data.tempat_khitan?.tempat_khitan === "panggilan_rumah" && dataConfig
          ? data.tempat_khitan?.tempat_khitan_panggilan === "dalam_kota"
            ? parseInt(dataConfig.biaya_dalam_kota)
            : data.tempat_khitan?.tempat_khitan_panggilan === "luar_kota"
            ? parseInt(dataConfig.biaya_luar_kota)
            : 0
          : 0
        : total_paket +
          (data.tempat_khitan?.tempat_khitan === "panggilan_rumah" && dataConfig
            ? data.tempat_khitan?.tempat_khitan_panggilan === "dalam_kota"
              ? parseInt(dataConfig.biaya_dalam_kota)
              : data.tempat_khitan?.tempat_khitan_panggilan === "luar_kota"
              ? parseInt(dataConfig.biaya_luar_kota)
              : 0
            : 0))

      await setBooking({
        _id: data._id,
        data_affiliasi: data.data_affiliasi,
        kode_booking: data.kode_booking,
        kode_booking_plain: data.kode_booking_plain,
        telepon: data.telepon,
        tanggal_khitan: data.tanggal_khitan,
        jam_khitan: data.jam_khitan,
        paket_khitan: data.paket_khitan,
        alamat: data.alamat,
        tempat_khitan: data.tempat_khitan,
        data_ortu: data.data_ortu,
        data_anak: data.data_anak,
        data_obat: data.data_obat,
        data_pembayaran: data.data_pembayaran,
        data_dokter: data.data_dokter,
        status: data.status,
        totalBayar: total_display,
        detail: data.detail,
        isFromAdmin: data.isFromAdmin,
      })

      setImageBuktiPembayaran([data.data_pembayaran.image_bukti_pembayaran])
    }
    toggleDetail()
  }

  useEffect(() => {
    onGetDataBooking()
    onGetDataPaketKhitan()
    onGetDataAffiliasi()
    onGetDataKaryawan()
    onGetDataConfig(["biaya_dalam_kota", "biaya_luar_kota"])
  }, [])

  useEffect(() => {
    if (querySearchUser?.length > 2) {
      onSearchUser({ query: querySearchUser })
    }
  }, [querySearchUser])

  useEffect(() => {
    if (dataUserSearch) {
      let optionSelect = []
      dataUserSearch.reduce((item, { _id, nama, telepon }) => {
        optionSelect.push({
          // label: nama,
          options: [{ value: _id, label: `${nama} - ${telepon}` }],
        })
      }, {})

      optionSelect = Object.values(
        optionSelect.reduce((c, { label, options }) => {
          c[label] = c[label] || { label, options: [] }
          c[label].options = c[label].options.concat(
            Array.isArray(options) ? options : [options],
          )
          return c
        }, {}),
      )
      setOptionUserSearch(optionSelect)
    }
  }, [dataUserSearch])

  useEffect(() => {
    if (!isEmpty(filterTanggal) || !isEmpty(filterKlinik)) {
      onGetDataBookingFilter({ tanggal: filterTanggal, klinik: filterKlinik })
    } else {
      setDataExportCsv(dataBooking)
    }
  }, [filterTanggal, filterKlinik, dataBooking])

  const handleValidBookingSubmit = (e, values) => {
    console.log(assignUser.value)
    const { onAddNewBooking, onUpdateBooking } = props

    const formData = new FormData()

    // if (userRole === "superAdmin" || (userRole !== "superAdmin" && !isEdit)) {
    formData.append("isFromAdmin", true)
    formData.append(
      "isAffiliasi",
      !isEmpty(kodeAffiliasi) && !isEmpty(selectedAffiliasi) ? true : false,
    )
    formData.append(
      "id_affiliasi",
      !isEmpty(kodeAffiliasi) && !isEmpty(selectedAffiliasi)
        ? values.id_affiliasi
        : "",
    )
    formData.append(
      "level_affiliasi",
      !isEmpty(kodeAffiliasi) && !isEmpty(selectedAffiliasi)
        ? selectedAffiliasi.affiliasi.level
        : "",
    )
    formData.append("telepon", values.telepon)
    formData.append("tanggal_khitan", tanggalKhitan)
    formData.append("jam_khitan", values.jam_khitan)
    formData.append("tempat_khitan", values.tempat_khitan)
    formData.append(
      "tempat_khitan_panggilan",
      values.tempat_khitan_panggilan || "",
    )
    formData.append("alamat", values.alamat)
    formData.append("provinsi", values.provinsi)
    formData.append("kota", values.kota)
    formData.append("kecamatan", values.kecamatan)
    formData.append("nama_ortu", values.nama_ortu)
    formData.append("agama", values.agama)
    formData.append("ref_khitan", values.ref_khitan)
    formData.append("ref_khitan_lainnya", values.ref_khitan_lainnya || "")
    formData.append("nama_anak", values.nama_anak)
    formData.append("usia_anak", values.usia_anak + "-" + values.tipe_usia)
    formData.append("penyakit_anak", values.penyakit_anak)
    formData.append("berat_anak", values.berat_anak)
    formData.append("tinggi_anak", values.tinggi_anak)
    formData.append("catatan_anak", values.catatan_anak)
    formData.append("obat", values.obat)
    formData.append("jumlah_obat", values.jumlah_obat)
    formData.append("id_dokter", dokterOperator.id_dokter || "")
    formData.append("nama_dokter", dokterOperator.nama_dokter || "")
    formData.append("tanggal_lahir_anak", tanggalLahirAnak || "")

    formData.append(
      "kode_voucher",
      !isEmpty(kodeVoucher) && !isEmpty(selectedVoucher)
        ? selectedVoucher.kode.toUpperCase()
        : "",
    )
    // formData.append("isVoucherPersen", !isEmpty(kodeVoucher) && !isEmpty(selectedVoucher) ? selectedVoucher.isPersen : "")
    // formData.append("nominal_voucher", !isEmpty(kodeVoucher) && !isEmpty(selectedVoucher) ? selectedVoucher.nominal : "")
    // }

    formData.append("paket_khitan", values.paket_khitan)
    formData.append("metode_pembayaran", values.metode_pembayaran || "")
    formData.append("status", values.status)
    formData.append("image_bukti_pembayaran", imageBuktiPembayaran[0])

    if (booking?.id_member === "" || booking?.id_member === "undefined") {
      formData.append("assign_member", assignUser.value || "")
    }

    if (
      (typeof imageBuktiPembayaran[0] === "undefined" && metode && !isEdit) ||
      (imageBuktiPembayaran[0] === "" && metode && isEdit)
    ) {
      setErrImage("Bukti pembayaran harus di upload..")
    } else {
      if (isEdit) {
        setErrImage("")
        formData.append("_id", booking._id)

        onUpdateBooking(formData)
      } else {
        setErrImage("")
        onAddNewBooking(formData)
      }

      setjamKhitan("")
      setTanggalKhitan("")
      setSelectedProvinsi(
        dataRegions.find(item => item.provinsi === "Jawa Timur"),
      )
      setImageBuktiPembayaran([])
      setSelectedJamKhitan([])
      setKodeAffiliasi("")
      setAssignUser({
        label: "-- Cari nama atau telepon pasien --",
      })
      setKodeVoucher("")
      setSelectedAffiliasi({})
      setSelectedVoucher({})
      setBookingStatus("")
      setTanggalLahirAnak("")
      setValTanggalKhitan("")
      setTempatKhitan("")
      toggle()
    }
  }

  const handleDeleteBooking = () => {
    const { onDeleteBooking } = props
    onDeleteBooking(booking._id)
    setDeleteModal(false)
    setjamKhitan("")
    setTanggalKhitan("")
    setSelectedProvinsi(
      dataRegions.find(item => item.provinsi === "Jawa Timur"),
    )
    setImageBuktiPembayaran([])
    setSelectedJamKhitan([])
    setKodeAffiliasi("")
    setAssignUser({
      label: "-- Cari nama atau telepon pasien --",
    })
    setKodeVoucher("")
    setSelectedAffiliasi({})
    setSelectedVoucher({})
    setBookingStatus("")
    setTanggalLahirAnak("")
    setValTanggalKhitan("")
    setTempatKhitan("")

    toggle()
  }

  function handleImageBuktiPembayaran(files) {
    if (files.length > 1) {
      setErrImage("Maksimal upload 1 gambar")
    } else {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        }),
      )
      setImageBuktiPembayaran(files)
      setErrImage("")
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  // const handleTanggalKhitan = () => {
  //   setTanggalKhitan(valTanggalKhitan)
  //   setjamKhitan("")

  //   setSelectedJamKhitan([])
  //   dataBooking.map((item, i) => {
  //     if (item.tanggal_khitan === valTanggalKhitan && item.status !== "batal" && item.tempat_khitan.tempat_khitan === tempatKhitan) {
  //       setSelectedJamKhitan(selectedJamKhitan => [
  //         ...selectedJamKhitan,
  //         item.jam_khitan,
  //       ])
  //     }
  //   })

  //   setJamBuka(jamBuka)
  // }

  useEffect(() => {
    setTanggalKhitan(valTanggalKhitan)
    setjamKhitan("")

    setSelectedJamKhitan([])
    dataBooking.map((item, i) => {
      if (
        item.tanggal_khitan === valTanggalKhitan &&
        item.status !== "batal" &&
        item.tempat_khitan.tempat_khitan === tempatKhitan
      ) {
        setSelectedJamKhitan(selectedJamKhitan => [
          ...selectedJamKhitan,
          item.jam_khitan,
        ])
      }
    })

    setJamBuka(jamBuka)
  }, [valTanggalKhitan, tempatKhitan])

  const handleRefKhitan = val => {
    if (val.target.value === "lainnya") {
      setRefLainnya(true)
    } else {
      setRefLainnya(false)
      // setMetode(false)
    }
  }
  const handleTempatKhitan = val => {
    setTempatKhitan(val.target.value)
    if (val.target.value === "panggilan_rumah") {
      setTempatPanggilan(true)
    } else {
      setTempatPanggilan(false)
      // setMetode(false)
    }
  }
  const HandleStatus = val => {
    if (val.target.value === "selesai") {
      setIsSelesai(true)
    } else if (bookingStatus === "batal" && val.target.value !== "batal") {
      setIsSelesai(false)
      setMetode(false)
      setjamKhitan("")
      setTanggalKhitan("")
    } else {
      setIsSelesai(false)
      setMetode(false)
    }
    setBookingStatus(val.target.value)
  }

  const HandleMetode = val => {
    if (val.target.value === "transfer") {
      setMetode(true)
    } else {
      setMetode(false)
    }
  }

  useEffect(() => {
    if (kodeAffiliasi.length === 5 && !isEmpty(dataAffiliasi)) {
      let result = dataAffiliasi.find(
        item => item.affiliasi.id_affiliasi === kodeAffiliasi,
      )

      if (!isEmpty(result)) {
        setSelectedAffiliasi(result)
      } else {
        setSelectedAffiliasi({})
      }
    } else {
      setSelectedAffiliasi({})
    }
  }, [kodeAffiliasi, dataAffiliasi])

  useEffect(() => {
    if (kodeVoucher.length >= 5) {
      if (!!isEdit && !booking?.isFromAdmin) {
        onCheckVoucher({
          kode: kodeVoucher,
          id_member: booking.id_member,
          id_booking: booking._id,
        })
      } else {
        onCheckVoucher({
          kode: kodeVoucher,
          id_member: "",
          id_booking: booking._id,
        })
      }
    }
  }, [kodeVoucher])

  useEffect(() => {
    if (kodeVoucher?.length >= 5 && !isEmpty(checkVoucher)) {
      if (kodeVoucher?.toUpperCase() === checkVoucher?.kode?.toUpperCase()) {
        setSelectedVoucher(checkVoucher)
      } else {
        setSelectedVoucher({})
      }
    } else {
      setSelectedVoucher({})
    }
  }, [kodeVoucher, checkVoucher])

  const refreshData = () => {
    onGetDataBooking()
    onGetDataPaketKhitan()
    onGetDataAffiliasi()
  }

  const handleInvoicePdf = (id, kode) => {
    setKodePdf(kode)
    onExportBooking({ id: id, kode_booking: kode })
  }

  const handleSertifikatPdf = (id, kode) => {
    setKodePdf(kode)
    onExportSertifikat({ id: id, kode_booking: kode })
  }

  useEffect(() => {
    if (!isEmpty(dataBookingExport)) {
      // handleDownload()
      // console.log(dataBookingExport);
      const pdf = new Blob([dataBookingExport.data], {
        type: "application/pdf",
      })
      const link = document.createElement("a")
      link.href = URL.createObjectURL(pdf)

      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = ("0" + (currentDate.getMonth() + 1)).slice(-2)
      const day = ("0" + currentDate.getDate()).slice(-2)
      const hours = ("0" + currentDate.getHours()).slice(-2)
      const minutes = ("0" + currentDate.getMinutes()).slice(-2)
      const seconds = ("0" + currentDate.getSeconds()).slice(-2)
      const filename = `Invoice-${kodePdf} - ${year}${month}${day}_${hours}${minutes}${seconds}.pdf`

      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      setKodePdf("")
    }
  }, [dataBookingExport])

  useEffect(() => {
    if (!isEmpty(dataSertifikatExport)) {
      // console.log(dataSertifikatExport)
      // handleDownload()
      // console.log(dataSertifikatExport);
      const pdf = new Blob([dataSertifikatExport.data], {
        type: "application/pdf",
      })
      const link = document.createElement("a")
      link.href = URL.createObjectURL(pdf)

      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = ("0" + (currentDate.getMonth() + 1)).slice(-2)
      const day = ("0" + currentDate.getDate()).slice(-2)
      const hours = ("0" + currentDate.getHours()).slice(-2)
      const minutes = ("0" + currentDate.getMinutes()).slice(-2)
      const seconds = ("0" + currentDate.getSeconds()).slice(-2)
      const filename = `Sertifikat-${kodePdf} - ${year}${month}${day}_${hours}${minutes}${seconds}.pdf`

      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      setKodePdf("")
    }
  }, [dataSertifikatExport])

  // const handleDownload = async () => {
  //   const pdfBlob = await new Blob([dataBookingExport.data], {
  //     type: "application/pdf",
  //   })
  //   const pdfUrl = URL.createObjectURL(pdfBlob)
  //   const iframe = document.createElement("iframe")
  //   iframe.style.display = "none"
  //   iframe.src = pdfUrl
  //   document.body.appendChild(iframe)
  //   await new Promise(resolve => {
  //     iframe.onload = resolve
  //   })
  //   iframe.contentWindow.print()
  // }

  const HandleDokterOperator = val => {
    const dokter = dataKaryawan.find(item => item._id === val)
    setDokterOperator({ id_dokter: dokter._id, nama_dokter: dokter.nama })
  }

  const linkToPrint = () => {
    return <button>Click To PrintOF Body</button>
  }

  const handleTanggalLahirAnak = (selectedDates, dateStr) => {
    setTanggalLahirAnak(dateStr)
  }

  const handleInvalidSubmit = (event, errors, values) => {
    setFirstErrorField(errors[0])
  }

  useEffect(() => {
    if (firstErrorField) {
      const element = document.querySelector(`[name="${firstErrorField}"]`)
      if (element) {
        const parent = element.parentElement
        parent.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        })
      }
    }
  }, [firstErrorField])

  const headersCsv = [
    { label: "Kode Booking", key: "kode_booking_plain" },
    { label: "Nama Paket", key: "paket_khitan.nama" },
    { label: "Harga Paket", key: "paket_khitan.harga" },
    { label: "Tipe Potongan", key: "paket_khitan.tipePotongan" },
    { label: "Potongan", key: "paket_khitan.potongan" },
    { label: "Kode Refferal", key: "data_affiliasi.id_affiliasi" },
    { label: "Telepon", key: "telepon" },
    { label: "Tanggal Khitan", key: "tanggal_khitan" },
    { label: "Jam Khitan", key: "jam_khitan" },
    { label: "Tempat Khitan", key: "tempat_khitan.tempat_khitan" },
    {
      label: "Tempat Khitan Panggilan",
      key: "tempat_khitan.tempat_khitan_panggilan",
    },
    { label: "Dokter Operaotr", key: "data_dokter.nama_dokter" },
    { label: "Alamat", key: "alamat.jalan" },
    { label: "Kecamatan", key: "alamat.kecamatan" },
    { label: "Kota", key: "alamat.kota" },
    { label: "Provinsi", key: "alamat.provinsi" },
    { label: "Nama Ortu", key: "data_ortu.nama_ortu" },
    { label: "Agama", key: "data_ortu.agama" },
    { label: "Refrensi", key: "data_ortu.ref_khitan" },
    { label: "Nama Anak", key: "data_anak.nama_anak" },
    { label: "Usia Anak", key: "data_anak.usia_anak" },
    { label: "Berat Anak", key: "data_anak.berat_anak" },
    { label: "Tinggi Anak", key: "data_anak.tinggi_anak" },
    { label: "Penyakit Anak", key: "data_anak.penyakit_anak" },
    { label: "Catatan Anak", key: "data_anak.catatan_anak" },
    { label: "Jenis Obat", key: "data_obat.obat" },
    { label: "Metode Bayar", key: "data_pembayaran.metode_pembayaran" },
    { label: "Status Booking", key: "status" },
  ]

  const data = {
    columns: [
      {
        label: "#Kode Booking",
        field: "kode_booking",
        width: 150,
      },
      {
        label: "Nama Anak",
        field: "nama_anak",
        width: 150,
      },
      {
        label: "Tempat",
        field: "display_tempat_khitan",
        width: 150,
      },
      {
        label: "Tanggal",
        field: "tanggal_khitan",
        width: 270,
      },
      {
        label: "Jam",
        field: "jam_khitan",
        width: 100,
      },
      {
        label: "Dokter",
        field: "display_nama_dokter",
        width: 100,
      },
      {
        label: "Status",
        field: "statusBadge",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        // width: 100,
      },
    ],
    rows:
      !isEmpty(filterTanggal) || !isEmpty(filterKlinik)
        ? dataBookingFilter
        : dataBooking,
    // rows: displayDataBooking,
  }

  const cetakInvoice = async () => {
    const DATA = htmlData.current;
    const doc = new jsPDF('p', 'pt', 'a4');

    const handleElement = await {
      '#test-pdf': (element, renderer) => {
        return true;
      }
    };  

    let srcwidth = document.getElementById('test-pdf').scrollWidth;


    doc.html(DATA.innerHTML, {
      margin: [55, 0, 40, -2],
      autoPaging: 'text',
      'x': 15,
      'y': 5,
      'elementHandlers': handleElement,
      html2canvas: {
        scale: 595.26 / srcwidth, //595.26 is the width of A4 page
        scrollY: 0,
      },
    }).then(() => {
      doc.save('test.pdf');
    })

  };


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBooking}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content" 
                    id="test-pdf" ref={htmlData}>
        <MetaTags>
          <title>Booking | KhitanSultan Backoffice</title>
        </MetaTags>

        <div className="container-fluid">
          <div className="page-title-box">
            <Row className="col-12">
              <Col md="12" className="align-self-center">
                <h6 className="page-title">Booking</h6>
              </Col>
              <Col md="3" className="align-self-center">
                <div className="">
                  <label
                    htmlFor="example-month-input"
                    className="col-form-label"
                  >
                    {" "}
                    Filter Klinik
                  </label>
                  <AvForm>
                    <Row form>
                      <AvField
                        type="select"
                        // label="Status Tarik Dana"
                        name="filterKlinik"
                        validate={{
                          required: { value: false },
                        }}
                        onChange={e => setFilterKlinik(e.target.value)}
                        value={filterKlinik}
                      >
                        <option value="">-- Semua Klinik --</option>
                        <option value="klinik_joyoagung">
                          Klinik Joyoagung
                        </option>
                        <option value="klinik_singosari">
                          Klinik Singosari
                        </option>
                        <option value="panggilan_rumah">Panggilan Rumah</option>
                      </AvField>
                    </Row>
                  </AvForm>
                </div>
              </Col>
              <Col md="2" className="align-self-center">
                <div className="">
                  <label
                    htmlFor="example-month-input"
                    className="col-form-label"
                  >
                    Filter Bulan
                  </label>
                  <input
                    className="form-control"
                    type="month"
                    // defaultValue="2022-05"
                    id="example-month-input"
                    onChange={val => setFilterTanggal(val.target.value)}
                  />
                </div>
              </Col>

              <Breadcrumbs
                breadcrumbItem=""
                handleOpenModal={handleOpenModal}
                buttonModal="Booking"
                refreshFunc={refreshData}
                refreshButton={true}
                exportData={
                  <CSVLink
                    data={dataExportCsv}
                    headers={headersCsv}
                    filename="DataBooking"
                    className="btn btn-outline-warning "
                  >
                    <i className="mdi mdi-cloud-download"></i> Export Data
                  </CSVLink>
                }
              />
            </Row>
            <Row>
                <div className="">
                  <button
                    className="btn btn-warning"
                    onClick={() => cetakInvoice()}
                  >
                    Test exportData
                  </button>
              </div>
            </Row>
          </div>

          <Row>
            <Col className="col-12 ">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    striped
                    bordered
                    data={data}
                    className="mt-4"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable modal-lg"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Booking" : "Tambah Booking"}
            </ModalHeader>
            <ModalBody>
              <AvForm
                onValidSubmit={handleValidBookingSubmit}
                onInvalidSubmit={handleInvalidSubmit}
              >
                <Row form>
                  <Col className="col-12 d-flex mb-3">
                    <Col className="col-4">
                      <AvField
                        name="id_affiliasi"
                        label="Kode Refferal"
                        type="text"
                        placeholder="Masukkan Kode Refferal "
                        errorMessage="Invalid kode"
                        onChange={e => setKodeAffiliasi(e.target.value)}
                        validate={{
                          required: { value: false },
                          minLength: { value: 5 },
                          maxLength: { value: 5 },
                        }}
                        disabled={
                          !!isEdit &&
                          !isEmpty(booking.data_affiliasi) &&
                          !isEmpty(booking.data_affiliasi.id_affiliasi)
                            ? true
                            : false
                        }
                        value={
                          booking && booking.data_affiliasi
                            ? booking.data_affiliasi.id_affiliasi
                            : ""
                        }
                      />
                    </Col>

                    <Col className="col-8 ps-4 ">
                      {/* {!isEmpty(selectedAffiliasi) && ( */}
                      <AvField
                        name="affiliator"
                        label="Detail Refferal"
                        type="text"
                        disabled={true}
                        value={
                          !isEmpty(selectedAffiliasi) && !isEmpty(kodeAffiliasi)
                            ? "#" +
                              selectedAffiliasi.affiliasi.id_affiliasi +
                              " - " +
                              selectedAffiliasi.nama
                            : !isEmpty(kodeAffiliasi) &&
                              isEmpty(selectedAffiliasi)
                            ? "Kode Affiliasi Tidak Valid"
                            : "Kode Refferal TIdak Ditambahkan"
                        }
                      />
                      {/* )} */}
                    </Col>
                  </Col>

                  <Col className="col-12 d-flex mb-3">
                    <Col className="col-4">
                      <AvField
                        className="text-uppercase"
                        name="kode_voucher"
                        label="Kode Voucher"
                        type="text"
                        placeholder="Masukkan Kode Voucher "
                        errorMessage="Invalid kode"
                        onChange={e => setKodeVoucher(e.target.value)}
                        validate={{
                          required: { value: false },
                          minLength: { value: 5 },
                        }}
                        disabled={
                          !!isEdit &&
                          !isEmpty(booking.paket_khitan) &&
                          !isEmpty(booking.paket_khitan?.kode_voucher)
                            ? true
                            : false
                        }
                        value={
                          booking && booking.paket_khitan
                            ? booking.paket_khitan?.kode_voucher
                            : ""
                        }
                      />
                    </Col>

                    <Col className="col-8 ps-4 ">
                      {/* {!isEmpty(selectedAffiliasi) && ( */}
                      <AvField
                        name="voucher"
                        label="Detail Voucher"
                        type="text"
                        disabled={true}
                        value={
                          !!isEdit &&
                          !isEmpty(selectedVoucher) &&
                          booking &&
                          booking.paket_khitan &&
                          booking.paket_khitan?.kode_voucher &&
                          kodeVoucher &&
                          booking.paket_khitan?.kode_voucher.toUpperCase() ===
                            kodeVoucher.toUpperCase()
                            ? selectedVoucher.nama +
                              " - Potongan " +
                              (selectedVoucher.isPersen
                                ? selectedVoucher.nominal + "%"
                                : "Nominal Rp." + selectedVoucher.nominal)
                            : !isEmpty(selectedVoucher) && !isEmpty(kodeVoucher)
                            ? selectedVoucher.isRefferal &&
                              isEmpty(selectedAffiliasi)
                              ? "Voucher Membutuhkan Refferal agar Aktif!"
                              : !isEmpty(kodeVoucher) &&
                                isEmpty(selectedVoucher)
                              ? "Voucher Tidak Valid!"
                              : selectedVoucher.nama +
                                " - Potongan " +
                                (selectedVoucher.isPersen
                                  ? selectedVoucher.nominal + "%"
                                  : "Nominal Rp." + selectedVoucher.nominal)
                            : !isEmpty(kodeVoucher) &&
                              isEmpty(selectedVoucher) &&
                              booking?.paket_khitan?.kode_voucher
                            ? "Kode Voucher Berhasil Dipakai"
                            : "Kode Voucher Tidak Ditambahkan"
                        }
                      />
                      {/* )} */}
                    </Col>
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      name="tempat_khitan"
                      label="Tempat Khitan"
                      type="select"
                      validate={{
                        required: { value: true },
                      }}
                      value={
                        booking && booking.tempat_khitan
                          ? booking.tempat_khitan.tempat_khitan
                          : ""
                      }
                      onChange={value => handleTempatKhitan(value)}
                      // disabled={
                      //   !!isEdit && userRole !== "superAdmin" ? true : false
                      // }
                    >
                      <option hidden value="">
                        -- Pilih Tempat Khitan --
                      </option>
                      <option value="klinik_singosari">Klinik Singosari</option>
                      <option value="klinik_joyoagung">Klinik Joyoagung</option>
                      <option value="panggilan_rumah">Panggilan Kerumah</option>
                    </AvField>
                    {tempatPanggilan && (
                      <div className="ps-1 pt-3">
                        <AvField
                          name="tempat_khitan_panggilan"
                          type="select"
                          placeholder="Lainnya.."
                          validate={{
                            required: { value: tempatPanggilan },
                          }}
                          value={
                            booking && booking.tempat_khitan
                              ? booking.tempat_khitan.tempat_khitan_panggilan
                              : ""
                          }
                          // disabled={
                          //   !!isEdit && userRole !== "superAdmin" ? true : false
                          // }
                        >
                          <option hidden value="">
                            -- Pilih Lokasi Panggilan --
                          </option>
                          <option value="dalam_kota">Dalam Kota</option>
                          <option value="luar_kota">Luar Kota</option>
                        </AvField>
                      </div>
                    )}
                  </Col>

                  <Col className="col-12 d-flex mb-3">
                    <Col className="col-6">
                      <AvField
                        type="select"
                        name="paket_khitan"
                        label="Paket Khitan"
                        validate={{
                          required: { value: true },
                        }}
                        value={
                          booking && booking.paket_khitan
                            ? booking.paket_khitan.id_paket
                            : ""
                        }
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                      >
                        <option hidden value="">
                          -- Pilih Paket --
                        </option>
                        {dataPaketKhitan &&
                          dataPaketKhitan.map((item, i) => (
                            <option key={item._id} value={item._id}>
                              {item.nama} | Rp.
                              {item.harga
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                              {item?.data_potongan &&
                              item?.data_potongan?.tipePotongan === "persen"
                                ? " - Potongan " +
                                  item?.data_potongan.potongan +
                                  "%"
                                : item?.data_potongan?.tipePotongan ===
                                  "nominal"
                                ? "- Potongan Rp." +
                                  item?.data_potongan?.potongan
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                : ""}
                            </option>
                          ))}
                      </AvField>
                    </Col>
                    <Col className="col-3 ps-4">
                      <Label>Tanggal Khitan</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Tanggal khitan"
                          onChange={(date, dateStr) =>
                            setValTanggalKhitan(dateStr)
                          }
                          options={{
                            altInput: true,
                            altFormat: "j F, Y",
                            dateFormat: "Y-m-d",
                            minDate: isEdit ? "" : "today",
                            disable: [
                              function (date) {
                                // return true to disable
                                return date.getDay() === 0
                              },
                            ],
                            locale: {
                              firstDayOfWeek: 1, // start week on Monday
                            },
                          }}
                          value={tanggalKhitan}
                          // disabled={
                          //   !!isEdit && userRole !== "superAdmin" ? true : false
                          // }
                        />
                      </InputGroup>

                      <AvField
                        name="tanggalKhitan-hidden"
                        label=""
                        type="hidden"
                        validate={{
                          required: { value: true },
                        }}
                        value={tanggalKhitan}
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                      ></AvField>
                    </Col>

                    <Col className="col-3 ps-4 ">
                      <AvField
                        name="jam_khitan"
                        label="Jam Khitan"
                        type="select"
                        onChange={e => setjamKhitan(e.target.value)}
                        validate={{
                          required: { value: true },
                        }}
                        value={jamKhitan}
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                      >
                        <option hidden value="">
                          -- Pilih Jam --
                        </option>
                        {jamBuka.map((item, i) => (
                          <option
                            value={item}
                            key={item}
                            disabled={
                              selectedJamKhitan.includes(item) ? true : false
                            }
                            className={
                              selectedJamKhitan.includes(item)
                                ? "bg-dark"
                                : "bg-light"
                            }
                          >
                            {item}
                          </option>
                        ))}
                      </AvField>
                    </Col>
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      name="alamat"
                      label="Alamat "
                      type="textarea"
                      placeholder="Masukkan alamat "
                      errorMessage="Invalid alamat"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={
                        booking && booking.alamat ? booking.alamat.jalan : ""
                      }
                      // disabled={
                      //   !!isEdit && userRole !== "superAdmin" ? true : false
                      // }
                    />
                  </Col>
                  <Col className="col-12 d-flex mb-3">
                    <Col className="col-4">
                      <AvField
                        name="provinsi"
                        label="Provinsi"
                        type="select"
                        validate={{
                          required: { value: true },
                        }}
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                        onChange={e =>
                          setSelectedProvinsi(
                            dataRegions.find(
                              item => item.provinsi === e.target.value,
                            ),
                          )
                        }
                        value={
                          booking && booking.alamat
                            ? booking.alamat.provinsi
                            : "Jawa Timur"
                        }
                      >
                        <option hidden value="">
                          -- Pilih Provinsi --
                        </option>
                        {dataRegions &&
                          dataRegions.map((item, i) => (
                            <option key={item.provinsi} value={item.provinsi}>
                              {item.provinsi}
                            </option>
                          ))}
                      </AvField>
                    </Col>
                    <Col className="col-4 ps-4 ">
                      <AvField
                        name="kota"
                        label="Kota"
                        type="select"
                        disabled={selectedProvinsi ? false : true}
                        validate={{
                          required: { value: true },
                        }}
                        value={
                          booking && booking.alamat
                            ? booking.alamat.kota
                            : "Kota Malang"
                        }
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                      >
                        <option hidden value="">
                          -- Pilih Kota --
                        </option>
                        {selectedProvinsi &&
                          selectedProvinsi?.kota.map((item, i) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                      </AvField>
                    </Col>
                    <Col className="col-4 ps-4 ">
                      <AvField
                        name="kecamatan"
                        label="Kecamatan"
                        type="text"
                        errorMessage="Invalid kecamatan"
                        validate={{
                          required: { value: true },
                          minLength: { value: 1 },
                        }}
                        value={
                          booking && booking.alamat
                            ? booking.alamat.kecamatan
                            : ""
                        }
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                      />
                    </Col>
                  </Col>

                  <h3 className="card-title mt-4 mb-2">Data Ortu</h3>
                  <Col className="col-11 d-flex ms-4 mb-3">
                    <Col className="col-8">
                      <AvField
                        name="nama_ortu"
                        label="Nama Ortu"
                        type="text"
                        placeholder="Masukkan nama lengkap ortu"
                        errorMessage="Invalid data"
                        validate={{
                          required: { value: true },
                          minLength: { value: 4 },
                        }}
                        value={
                          booking && booking.data_ortu
                            ? booking.data_ortu.nama_ortu
                            : ""
                        }
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                      />
                    </Col>

                    <Col className="col-4 ps-4">
                      <AvField
                        name="telepon"
                        label="Nomor Telepon"
                        type="text"
                        placeholder="Masukkan nomor telepon "
                        errorMessage="Invalid telepon"
                        validate={{
                          required: { value: true },
                          number: true,
                          // pattern: {
                          //   value:
                          //     /\(?(?:\+62|62|0)(?:\d{2,3})?\)?[ .-]?\d{2,4}[ .-]?\d{2,4}[ .-]?\d{2,4}/g

                          // },
                        }}
                        value={booking ? booking.telepon : ""}
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                      />
                    </Col>
                  </Col>

                  <Col className="col-11 d-flex ms-4 mb-3">
                    <Col className="col-8">
                      <AvField
                        name="ref_khitan"
                        label="Pertama Mengetahui Khitan Sultan dari?"
                        type="select"
                        validate={{
                          required: { value: true },
                        }}
                        value={
                          booking && booking.data_ortu
                            ? booking.data_ortu.ref_khitan
                            : ""
                        }
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                        onChange={value => handleRefKhitan(value)}
                      >
                        <option hidden value="">
                          -- Pilih Refrensi --
                        </option>
                        <option value="saudara">Teman / Saudara</option>
                        <option value="facebook">Facebook</option>
                        <option value="instagram">Instagram</option>
                        <option value="map">Google Maps</option>
                        <option value="youtube">Youtube</option>
                        <option value="lainnya">Lainnya</option>
                      </AvField>
                    </Col>

                    <Col className="col-4 ps-4">
                      <AvField
                        name="agama"
                        label="Agama"
                        type="select"
                        validate={{
                          required: { value: true },
                        }}
                        value={
                          booking && booking.data_ortu
                            ? booking.data_ortu.agama
                            : ""
                        }
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                      >
                        <option hidden value="">
                          -- Pilih Agama --
                        </option>
                        <option value="Islam">Islam</option>
                        <option value="Kristen">Kristen</option>
                        <option value="Katolik">Katolik</option>
                        <option value="Hindu">Hindu</option>
                        <option value="Budha">Budha</option>
                        <option value="Konghucu">Konghucu</option>
                      </AvField>
                    </Col>
                  </Col>

                  <Col className="col-11 mb-3 ms-4">
                    {refLainnya && (
                      <div className="ps-1 pt-3">
                        <AvField
                          name="ref_khitan_lainnya"
                          type="textarea"
                          placeholder="Lainnya.."
                          validate={{
                            required: { value: refLainnya },
                          }}
                          value={
                            booking && booking.data_ortu
                              ? booking.data_ortu.ref_khitan_lainnya
                              : ""
                          }
                          // disabled={
                          //   !!isEdit && userRole !== "superAdmin" ? true : false
                          // }
                        ></AvField>
                      </div>
                    )}
                  </Col>

                  <h3 className="card-title mt-4 mb-2">Data Anak</h3>

                  <Col className="col-11 ms-4 mb-3">
                    <AvField
                      name="nama_anak"
                      label="Nama Anak"
                      type="text"
                      placeholder="Masukkan nama lengkap anak"
                      errorMessage="Invalid data"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={
                        booking && booking.data_anak
                          ? booking.data_anak.nama_anak
                          : ""
                      }
                      // disabled={
                      //   !!isEdit && userRole !== "superAdmin" ? true : false
                      // }
                    />
                  </Col>

                  <Col className="col-11 d-flex ms-4 mb-3">
                    <Col className="col-6">
                      <Label>Tanggal Lahir</Label>
                      {/* <DatePicker
                          selected={startDate}
                          onChange={date => setStartDate(date)}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                        /> */}
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Tanggal Lahir Anak"
                          onChange={(date, dateStr) =>
                            handleTanggalLahirAnak(date, dateStr)
                          }
                          options={{
                            altInput: true,
                            altFormat: "j F, Y",
                            dateFormat: "Y-m-d",
                            maxDate: "today",
                          }}
                          value={
                            booking && booking.data_anak
                              ? booking?.data_anak?.tanggal_lahir_anak
                              : ""
                          }
                        />
                      </InputGroup>

                      <AvField
                        name="tanggalLahirAnak-hidden"
                        label=""
                        type="hidden"
                        validate={{
                          required: { value: true },
                        }}
                        value={tanggalLahirAnak}
                      ></AvField>
                    </Col>

                    <Col className="col-3 ps-4">
                      <AvField
                        name="usia_anak"
                        label="Usia Anak"
                        type="number"
                        placeholder="Usia anak"
                        errorMessage="Invalid usia"
                        min={1}
                        validate={{
                          required: { value: true },
                          number: true,
                        }}
                        value={
                          booking &&
                          booking.data_anak &&
                          booking.data_anak.usia_anak
                            ? booking.data_anak.usia_anak.split("-")[0]
                            : ""
                        }
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                      />
                    </Col>

                    <Col className="col-3 mb-3 ms-4">
                      <AvField
                        name="tipe_usia"
                        label="Tipe Usia"
                        type="select"
                        validate={{
                          required: { value: true },
                        }}
                        value={
                          booking &&
                          booking.data_ortu &&
                          booking.data_anak.usia_anak
                            ? booking.data_anak.usia_anak.split("-")[1]
                            : ""
                        }
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                        // onChange={value => handleRefKhitan(value)}
                      >
                        <option hidden value="">
                          -- Pilih Tipe Usia --
                        </option>
                        <option value="Hari">Hari</option>
                        <option value="Bulan">Bulan</option>
                        <option value="Tahun">Tahun</option>
                      </AvField>
                    </Col>
                  </Col>
                  <Col className="col-11 d-flex ms-4 mb-3">
                    <Col className="col-8">
                      <AvField
                        name="penyakit_anak"
                        label="Penyakit Bawaan / Alergi (jika ada)"
                        type="text"
                        placeholder="Penyakit bawaan"
                        errorMessage="Invalid data"
                        value={
                          booking && booking.data_anak
                            ? booking.data_anak.penyakit_anak
                            : ""
                        }
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                      />
                    </Col>

                    <Col className="col-2 ps-2">
                      <AvField
                        name="berat_anak"
                        label="Berat Badan"
                        type="number"
                        placeholder="Berat(kg)"
                        errorMessage="Invalid berat"
                        min={1}
                        validate={{
                          required: { value: false },
                          number: true,
                        }}
                        value={
                          booking && booking.data_anak
                            ? booking.data_anak.berat_anak
                            : ""
                        }
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                      />
                    </Col>

                    <Col className="col-2 ps-2">
                      <AvField
                        name="tinggi_anak"
                        label="Tinggi Badan"
                        type="number"
                        placeholder="Tinggi(cm)"
                        errorMessage="Invalid tinggi"
                        min={1}
                        validate={{
                          required: { value: false },
                          number: true,
                        }}
                        value={
                          booking && booking.data_anak
                            ? booking.data_anak.tinggi_anak
                            : ""
                        }
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                      />
                    </Col>
                  </Col>
                  <Col className="col-11 mb-3 ms-4">
                    <AvField
                      name="catatan_anak"
                      label="Catatan"
                      type="textArea"
                      placeholder="Catatan tambahan"
                      errorMessage="Invalid data"
                      value={
                        booking && booking.data_anak
                          ? booking.data_anak.catatan_anak
                          : ""
                      }
                      // disabled={
                      //   !!isEdit && userRole !== "superAdmin" ? true : false
                      // }
                    />
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      name="obat"
                      label="Jenis Obat"
                      type="select"
                      validate={{
                        required: { value: false },
                      }}
                      value={
                        booking && booking.data_obat
                          ? booking.data_obat.obat
                          : ""
                      }
                      // disabled={
                      //   !!isEdit && userRole !== "superAdmin" ? true : false
                      // }
                    >
                      <option hidden value="">
                        -- Pilih Jenis --
                      </option>
                      <option value="syrup">Syrup</option>
                      <option value="puyer">Puyer</option>
                      <option value="tablet">Tablet</option>
                    </AvField>
                  </Col>

                  {(userRole === "superAdmin" || userRole === "admin") && (
                    <Col className="col-12 mb-3">
                      <AvField
                        type="select"
                        name="id_dokter"
                        label="Dokter Operator"
                        validate={{
                          required: { value: false },
                        }}
                        value={
                          booking && booking.data_dokter
                            ? booking.data_dokter.id_dokter
                            : ""
                        }
                        onChange={e => HandleDokterOperator(e.target.value)}
                        // disabled={
                        //   !!isEdit && userRole !== "superAdmin" ? true : false
                        // }
                      >
                        <option hidden value="">
                          -- Pilih Dokter Operator --
                        </option>
                        {dataKaryawan &&
                          dataKaryawan.map((item, i) => {
                            if (
                              item.role === "dokter" ||
                              item.email === "jakasuganda@gmail.com"
                            ) {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.nama}
                                </option>
                              )
                            }
                          })}
                      </AvField>
                    </Col>
                  )}

                  <Col className="col-12 mb-3">
                    <AvField
                      type="select"
                      name="status"
                      label="Status Booking Khitan"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={e => HandleStatus(e)}
                      value={bookingStatus ? bookingStatus : ""}
                    >
                      <option hidden value="">
                        -- Pilih Status --
                      </option>
                      {/* enable transfer */}
                      {/* <option value="belum_bayar">Belum Bayar</option>
                      <option value="lunas">Lunas</option> */}
                      <option value="verifikasi">Diproses</option>
                      <option value="aktif">Terverifikasi</option>
                      <option value="selesai">Selesai</option>
                      <option value="rejadwal">Jadwal Ulang</option>
                      <option value="batal">Batal</option>
                    </AvField>
                  </Col>
                  {isSelesai && (
                    <Col className="col-12 mb-3">
                      <AvField
                        type="select"
                        name="metode_pembayaran"
                        label="Metode Pembayaran"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={e => HandleMetode(e)}
                        value={
                          booking && booking.data_pembayaran
                            ? booking.data_pembayaran.metode_pembayaran
                            : ""
                        }
                      >
                        <option hidden value="">
                          -- Pilih Metode --
                        </option>
                        <option value="cash">Cash</option>
                        <option value="transfer">Transfer</option>
                      </AvField>
                    </Col>
                  )}

                  {metode && isSelesai && (
                    <Col className="col-12 mb-3">
                      <Dropzone
                        accept="image/jpeg, image/png"
                        onDrop={img => {
                          handleImageBuktiPembayaran(img)
                        }}
                        required
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                              </div>
                              <h6>
                                Seret Gambar Bukti Pembayaran atau Klik untuk
                                Upload
                              </h6>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {/* {imageBuktiPembayaran === null ? "true" : "false"} */}
                        {imageBuktiPembayaran.map((f, i) => {
                          return f ? (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={
                                        f.name ||
                                        imageBuktiPembayaran
                                          .toString()
                                          .split("-")[1]
                                      }
                                      src={
                                        f.preview ||
                                        env.IMG_URL + imageBuktiPembayaran
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name ||
                                        imageBuktiPembayaran
                                          .toString()
                                          .split("-")[1]}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          ) : (
                            ""
                          )
                        })}
                      </div>
                      <div className=" is-invalid"></div>
                      <div className="invalid-feedback">{errImage}</div>
                    </Col>
                  )}

                  {(booking?.id_member === "" ||
                    booking?.id_member === "undefined") && (
                    <div className="mb-3 col-12">
                      <Label>Berikan Data ke Aplikasi Pasien</Label>
                      <Select
                        required={true}
                        value={assignUser ? assignUser : ""}
                        onInputChange={e => setQuerySearchUser(e)}
                        onChange={e => setAssignUser(e)}
                        options={optionUserSearch}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  )}
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={() => toggle("close")}
                      >
                        Tutup
                      </button>
                      {!!isEdit && userRole === "superAdmin" && (
                        <button
                          type="button"
                          className="btn btn-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        type="submit"
                        className="btn btn-outline-success save-booking"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={modalDetail}
            className="modal-dialog modal-dialog-scrollable modal-lg"
          >
            <ModalHeader toggle={toggleDetail} tag="h4">
              Detail Booking
            </ModalHeader>
            <ModalBody>
              <div ref={componentRefPdf}>
                <table className="table table-hover table-centered table-nowrap mb-0">
                  {/* <thead>
                        <tr>
                          <th scope="col">(#) No</th>
                          <th scope="col">Nama Anak</th>
                          <th scope="col">Tanggal Khitan</th>
                          <th scope="col">Jam Khitan</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead> */}
                  <tbody>
                    <tr>
                      <th scope="col" width="200">
                        Kode Booking
                      </th>
                      <td scope="col">
                        {booking && booking.kode_booking} -{" "}
                        {!isEmpty(booking) &&
                          !isEmpty(booking?.detail) &&
                          booking?.detail.length > 0 &&
                          booking?.detail[0].nama}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        <ul className="list-unstyled mb-0">
                          <li>
                            Paket Khitan
                            <ul>
                              <li>Nama Paket</li>
                              <li>Harga</li>
                              {/* <li>Tipe Potongan</li> */}
                              <li>Potongan</li>
                              {/* <li>Kode Voucher</li> */}
                              <li>Voucher</li>
                              <li>Biaya Panggilan</li>
                            </ul>
                          </li>
                        </ul>
                      </th>
                      <td scope="col">
                        <ul className="list-unstyled mb-0">
                          <li>
                            &nbsp;
                            <ul className="list-unstyled mb-0">
                              <li>
                                {booking && booking.paket_khitan
                                  ? booking.paket_khitan.nama
                                  : ""}
                              </li>
                              <li>
                                Rp.
                                {booking && booking.paket_khitan
                                  ? booking.paket_khitan.harga
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                  : ""}
                              </li>
                              {/* <li> */}
                              {/*   {booking && booking.paket_khitan && booking.paket_khitan?.tipePotongan */}
                              {/*     ? booking.paket_khitan.tipePotongan ===  */}
                              {/*     : "-"} */}
                              {/* </li> */}
                              <li>
                                {booking && booking.paket_khitan
                                  ? booking.paket_khitan.tipePotongan ===
                                    "persen"
                                    ? booking.paket_khitan.potongan + "%"
                                    : booking.paket_khitan.tipePotongan ===
                                      "nominal"
                                    ? "Rp." +
                                      booking.paket_khitan.potongan
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    : "-"
                                  : "-"}
                              </li>
                              {/* <li> */}
                              {/*   {booking && */}
                              {/*   booking.paket_khitan && */}
                              {/*   booking.paket_khitan?.kode_voucher */}
                              {/*     ? booking.paket_khitan.kode_voucher */}
                              {/*     : "-"} */}
                              {/* </li> */}
                              <li>
                                {booking &&
                                booking.paket_khitan &&
                                booking.paket_khitan?.kode_voucher
                                  ? booking.paket_khitan?.isVoucherPersen
                                    ? booking.paket_khitan.nominal_voucher +
                                      "%" +
                                      " - " +
                                      booking.paket_khitan.kode_voucher
                                    : "Rp." +
                                      booking.paket_khitan.nominal_voucher +
                                      " - " +
                                      booking.paket_khitan.kode_voucher
                                  : "-"}
                              </li>
                            </ul>
                          </li>
                          <li>
                            {booking &&
                            booking?.tempat_khitan?.tempat_khitan ===
                              "panggilan_rumah" &&
                            dataConfig
                              ? booking.tempat_khitan
                                  .tempat_khitan_panggilan === "dalam_kota"
                                ? "Rp." +
                                  dataConfig.biaya_dalam_kota
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                : booking.tempat_khitan
                                    .tempat_khitan_panggilan === "luar_kota"
                                ? "Rp." +
                                  dataConfig.biaya_luar_kota
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                : "-"
                              : "-"}
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        TOTAL BAYAR
                      </th>
                      <td scope="col">
                        <h5>
                          <strong>
                            Rp.
                            {booking &&
                              booking.totalBayar &&
                              booking.totalBayar
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </strong>
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Kode Refferal
                      </th>
                      <td scope="col">
                        {booking &&
                        booking.data_affiliasi &&
                        booking.data_affiliasi.id_affiliasi
                          ? "#" + booking.data_affiliasi.id_affiliasi
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" width="200">
                        Nomor Telepon
                      </th>
                      <td scope="col">{booking ? booking.telepon : ""}</td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Tanggal Khitan
                      </th>
                      <td scope="col">
                        {booking ? booking.tanggal_khitan : ""}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Jam Khitan
                      </th>
                      <td scope="col">{booking ? booking.jam_khitan : ""}</td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        <ul className="list-unstyled mb-0">
                          <li>
                            Tempat Khitan
                            <ul>
                              <li>Tempat</li>
                              <li>Panggilan</li>
                            </ul>
                          </li>
                        </ul>
                      </th>
                      <td scope="col">
                        <ul className="list-unstyled mb-0">
                          <li>
                            &nbsp;
                            <ul className="list-unstyled mb-0">
                              <li>
                                {booking && booking.tempat_khitan
                                  ? booking?.tempat_khitan?.tempat_khitan || "-"
                                  : "-"}
                              </li>
                              <li>
                                {booking && booking.tempat_khitan
                                  ? booking?.tempat_khitan
                                      ?.tempat_khitan_panggilan || "-"
                                  : "-"}
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" width="200">
                        <ul className="list-unstyled mb-0">
                          <li>
                            Alamat
                            <ul>
                              <li>Jalan</li>
                              <li>Kecamatan</li>
                              <li>Kota / Kab</li>
                              <li>Provinsi</li>
                            </ul>
                          </li>
                        </ul>
                      </th>
                      <td scope="col">
                        <ul className="list-unstyled mb-0">
                          <li>
                            &nbsp;
                            <ul className="list-unstyled mb-0">
                              <li>
                                {booking && booking.alamat
                                  ? booking.alamat.jalan
                                  : ""}
                              </li>
                              <li>
                                {booking && booking.alamat
                                  ? booking.alamat.kecamatan
                                  : ""}
                              </li>
                              <li>
                                {booking && booking.alamat
                                  ? booking.alamat.kota
                                  : ""}
                              </li>
                              <li>
                                {booking && booking.alamat
                                  ? booking.alamat.provinsi
                                  : ""}
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        <ul className="list-unstyled mb-0">
                          <li>
                            Data Ortu
                            <ul>
                              <li>Nama Ortu</li>
                              <li>Agama</li>
                              <li>Refrensi</li>
                            </ul>
                          </li>
                        </ul>
                      </th>
                      <td scope="col">
                        <ul className="list-unstyled mb-0">
                          <li>
                            &nbsp;
                            <ul className="list-unstyled mb-0">
                              <li>
                                {booking && booking.data_ortu
                                  ? booking.data_ortu.nama_ortu
                                  : ""}
                              </li>
                              <li>
                                {booking && booking.data_ortu
                                  ? booking.data_ortu.agama
                                  : ""}
                              </li>
                              <li>
                                {booking && booking.data_ortu
                                  ? booking.data_ortu.ref_khitan
                                  : ""}
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        <ul className="list-unstyled mb-0">
                          <li>
                            Data Anak
                            <ul>
                              <li>Nama Anak</li>
                              <li>Tanggal Lahir Anak</li>
                              <li>Usia Anak</li>
                              <li>Penyakit Bawaan</li>
                              <li>Berat Badan</li>
                              <li>Tinggi Badan</li>
                              <li>Catatan</li>
                            </ul>
                          </li>
                        </ul>
                      </th>
                      <td scope="col">
                        <ul className="list-unstyled mb-0">
                          <li>
                            &nbsp;
                            <ul className="list-unstyled mb-0">
                              <li>
                                {booking && booking.data_anak
                                  ? booking.data_anak.nama_anak || "-"
                                  : "-"}
                              </li>

                              <li>
                                {booking && booking.data_anak
                                  ? booking.data_anak?.tanggal_lahir_anak || "-"
                                  : "-"}
                              </li>
                              <li>
                                {booking && booking.data_anak
                                  ? booking.data_anak.usia_anak || "-"
                                  : "-"}
                              </li>
                              <li>
                                {booking && booking.data_anak
                                  ? booking.data_anak.penyakit_anak || "-"
                                  : "-"}
                              </li>
                              <li>
                                {booking && booking.data_anak
                                  ? booking.data_anak.berat_anak || "-"
                                  : "-"}{" "}
                                kg
                              </li>
                              <li>
                                {booking && booking.data_anak
                                  ? booking.data_anak.tinggi_anak || "-"
                                  : "-"}{" "}
                                cm
                              </li>
                              <li>
                                {booking && booking.data_anak
                                  ? booking.data_anak.catatan_anak || "-"
                                  : "-"}
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Jenis Obat
                      </th>
                      <td scope="col">
                        {booking && booking.data_obat
                          ? booking.data_obat.obat
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Dokter Operator
                      </th>
                      <td scope="col">
                        {booking && booking.data_dokter
                          ? booking.data_dokter.nama_dokter
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Status Booking
                      </th>
                      <td scope="col">
                        <Badge
                          className={
                            "font-size-12 badge bg-" +
                            (booking?.status === "verifikasi"
                              ? "warning"
                              : booking?.status === "aktif"
                              ? "primary"
                              : booking?.status === "selesai"
                              ? "success"
                              : booking?.status === "rejadwal"
                              ? "info"
                              : "danger")

                            // enable transfer
                            // (booking?.status === "belum_bayar"
                            //   ? "warning"
                            //   : booking?.status === "lunas"
                            //   ? "primary"
                            //   : booking?.status === "selesai"
                            //   ? "success"
                            //   : "danger")
                          }
                          pill
                        >
                          {booking.status === "verifikasi"
                            ? "diproses"
                            : booking.status === "aktif"
                            ? "terverifikasi"
                            : booking.status}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Metode Pembayaran
                      </th>
                      <td scope="col">
                        {booking && booking.data_pembayaran
                          ? booking?.data_pembayaran?.metode_pembayaran
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" width="200">
                        Bukti Pembayaran
                      </th>
                      <td scope="col">
                        {imageBuktiPembayaran.map((f, i) => {
                          return f ? (
                            <img
                              key={i}
                              height="400"
                              className="bg-light"
                              alt={
                                f.name ||
                                imageBuktiPembayaran.toString().split("-")[1]
                              }
                              src={
                                f.preview || env.IMG_URL + imageBuktiPembayaran
                              }
                            />
                          ) : (
                            "-"
                          )
                        })}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* <ReactToPrint trigger={linkToPrint} content={() => componentRefPdf.current} /> */}

              <Row>
                <Col>
                  <div className="text-end">
                    <br />
                    <br />
                    <button
                      className="btn btn-outline-warning save-booking"
                      onClick={() =>
                        handleInvoicePdf(
                          booking._id,
                          booking.kode_booking_plain,
                        )
                      }
                    >
                      Download Invoice
                    </button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

Booking.propTypes = {
  dataBooking: PropTypes.array,
  dataBookingFilter: PropTypes.array,
  dataBookingExport: PropTypes.any,
  dataSertifikatExport: PropTypes.any,
  dataPaketKhitan: PropTypes.array,
  dataAffiliasi: PropTypes.array,
  dataKaryawan: PropTypes.array,
  dataUserSearch: PropTypes.array,
  loadingUserSearch: PropTypes.any,
  dataConfig: PropTypes.object,
  checkVoucher: PropTypes.object,
  globalLoading: PropTypes.any,
  onGetDataBooking: PropTypes.func,
  onGetDataBookingFilter: PropTypes.func,
  onAddNewBooking: PropTypes.func,
  onUpdateBooking: PropTypes.func,
  onExportBooking: PropTypes.func,
  onExportSertifikat: PropTypes.func,
  onDeleteBooking: PropTypes.func,
  onGetDataPaketKhitan: PropTypes.func,
  onGetDataAffiliasi: PropTypes.func,
  onGetDataKaryawan: PropTypes.func,
  onSearchUser: PropTypes.func,
  onGetDataConfig: PropTypes.func,
  onCheckVoucher: PropTypes.func,
}

const mapStateToProps = ({
  dataBookingReducer,
  dataPaketKhitanReducer,
  dataAffiliasiReducer,
  dataUserReducer,
  dataConfigReducer,
  dataVoucherReducer,
  loadingReducer,
}) => ({
  dataBooking: dataBookingReducer.dataBooking,
  dataBookingFilter: dataBookingReducer.dataBookingFilter,
  dataBookingExport: dataBookingReducer.dataBookingExport,
  dataSertifikatExport: dataBookingReducer.dataSertifikatExport,
  dataPaketKhitan: dataPaketKhitanReducer.dataPaketKhitan,
  dataAffiliasi: dataAffiliasiReducer.dataAffiliasi,
  dataKaryawan: dataUserReducer.dataKaryawan,
  dataUserSearch: dataUserReducer.dataUserSearch,
  loadingUserSearch: dataUserReducer.loadingUserSearch,
  dataConfig: dataConfigReducer.dataConfig,
  checkVoucher: dataVoucherReducer.checkVoucher,
  globalLoading: loadingReducer.globalLoading,
})

const mapDispatchToProps = dispatch => ({
  onGetDataBooking: () => dispatch(getDataBooking()),
  onGetDataBookingFilter: booking => dispatch(getDataBookingFilter(booking)),
  onGetDataPaketKhitan: () => dispatch(getDataPaketKhitan()),
  onGetDataAffiliasi: () => dispatch(getDataAffiliasi()),
  onGetDataKaryawan: () => dispatch(getDataKaryawan()),
  onAddNewBooking: booking => dispatch(addNewBooking(booking)),
  onSearchUser: user => dispatch(searchUser(user)),
  onUpdateBooking: booking => dispatch(updateBooking(booking)),
  onExportBooking: booking => dispatch(exportBooking(booking)),
  onExportSertifikat: booking => dispatch(exportSertifikat(booking)),
  onGetDataConfig: config => dispatch(getDataConfig(config)),
  onCheckVoucher: voucher => dispatch(checkVoucher(voucher)),
  onDeleteBooking: booking => dispatch(deleteBooking(booking)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Booking)
