import {
  GET_DATA_DASHBOARD,
  GET_DATA_DASHBOARD_FAIL,
  GET_DATA_DASHBOARD_SUCCESS,
  FILTER_DATA_DASHBOARD,
  FILTER_DATA_DASHBOARD_FAIL,
  FILTER_DATA_DASHBOARD_SUCCESS,
} from "./actionTypes"

export const getDataDashboard = () => ({
  type: GET_DATA_DASHBOARD,
})

export const getDataDashboardSuccess = dataDashboard => ({
  type: GET_DATA_DASHBOARD_SUCCESS,
  payload: dataDashboard,
})

export const getDataDashboardFail = error => ({
  type: GET_DATA_DASHBOARD_FAIL,
  payload: error,
})

export const filterDataDashboard = data_filter => ({
  type: FILTER_DATA_DASHBOARD,
  payload: data_filter,
})

export const filterDataDashboardSuccess = dataDashboard => ({
  type: FILTER_DATA_DASHBOARD_SUCCESS,
  payload: dataDashboard,
})

export const filterDataDashboardFail = error => ({
  type: FILTER_DATA_DASHBOARD_FAIL,
  payload: error,
})
