import {
  GET_DATA_TEMUKAN_METODE,
  GET_DATA_TEMUKAN_METODE_FAIL,
  GET_DATA_TEMUKAN_METODE_SUCCESS,
  ADD_NEW_TEMUKAN_METODE,
  ADD_TEMUKAN_METODE_SUCCESS,
  ADD_TEMUKAN_METODE_FAIL,
  UPDATE_TEMUKAN_METODE,
  UPDATE_TEMUKAN_METODE_SUCCESS,
  UPDATE_TEMUKAN_METODE_FAIL,
  DELETE_TEMUKAN_METODE,
  DELETE_TEMUKAN_METODE_SUCCESS,
  DELETE_TEMUKAN_METODE_FAIL,
  // GET_CATEGORIES,
  // GET_CATEGORIES_SUCCESS,
  // GET_CATEGORIES_FAIL,
} from "./actionTypes"

export const getDataTemukanMetode = () => ({
  type: GET_DATA_TEMUKAN_METODE,
})

export const getDataTemukanMetodeSuccess = dataTemukanMetode => ({
  type: GET_DATA_TEMUKAN_METODE_SUCCESS,
  payload: dataTemukanMetode,
})

export const getDataTemukanMetodeFail = error => ({
  type: GET_DATA_TEMUKAN_METODE_FAIL,
  payload: error,
})

export const addNewTemukanMetode = temukanMetode => ({
  type: ADD_NEW_TEMUKAN_METODE,
  payload: temukanMetode,
})

export const addTemukanMetodeSuccess = temukanMetode => ({
  type: ADD_TEMUKAN_METODE_SUCCESS,
  payload: temukanMetode,
})

export const addTemukanMetodeFail = error => ({
  type: ADD_TEMUKAN_METODE_FAIL,
  payload: error,
})

export const updateTemukanMetode = temukanMetode => ({
  type: UPDATE_TEMUKAN_METODE,
  payload: temukanMetode,
})

export const updateTemukanMetodeSuccess = temukanMetode => ({
  type: UPDATE_TEMUKAN_METODE_SUCCESS,
  payload: temukanMetode,
})

export const updateTemukanMetodeFail = error => ({
  type: UPDATE_TEMUKAN_METODE_FAIL,
  payload: error,
})

export const deleteTemukanMetode = temukanMetode => ({
  type: DELETE_TEMUKAN_METODE,
  payload: temukanMetode,
})

export const deleteTemukanMetodeSuccess = temukanMetode => ({
  type: DELETE_TEMUKAN_METODE_SUCCESS,
  payload: temukanMetode,
})

export const deleteTemukanMetodeFail = error => ({
  type: DELETE_TEMUKAN_METODE_FAIL,
  payload: error,
})

// export const getCategories = () => ({
//   type: GET_CATEGORIES,
// })

// export const getCategoriesSuccess = categories => ({
//   type: GET_CATEGORIES_SUCCESS,
//   payload: categories,
// })

// export const getCategoriesFail = error => ({
//   type: GET_CATEGORIES_FAIL,
//   payload: error,
// })
