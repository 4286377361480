import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_CAROUSEL,
  DELETE_CAROUSEL,
  GET_DATA_CAROUSEL,
  UPDATE_CAROUSEL,
} from "./actionTypes"
import {
  getDataCarouselSuccess,
  getDataCarouselFail,
  addCarouselFail,
  addCarouselSuccess,
  updateCarouselSuccess,
  updateCarouselFail,
  deleteCarouselSuccess,
  deleteCarouselFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataCarousel,
  addNewCarousel,
  updateCarousel,
  deleteCarousel,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"


function* fetchDataCarousel() {
  yield put(startLoading());
  try {
    const response = yield call(getDataCarousel)
    yield put(getDataCarouselSuccess(response))
  } catch (error) {
    yield put(getDataCarouselFail(error))
  }
  yield put(stopLoading());
}

function* onAddNewCarousel({ payload: carousel }) {
  yield put(startLoading());
  try {
    const response = yield call(addNewCarousel, carousel)
    yield put(addCarouselSuccess(response))
  } catch (error) {
    yield put(addCarouselFail(error))
  }
  yield put(stopLoading());
}

function* onUpdateCarousel({ payload: carousel }) {
  yield put(startLoading());
  try {
    // console.log(carousel)
    const response = yield call(updateCarousel, carousel)
    yield put(updateCarouselSuccess(response))
  } catch (error) {
    yield put(updateCarouselFail(error))
  }
  yield put(stopLoading());
}

function* onDeleteCarousel({ payload: carousel }) {
  yield put(startLoading());
  try {
    const response = yield call(deleteCarousel, carousel)
    yield put(deleteCarouselSuccess(response))
  } catch (error) {
    yield put(deleteCarouselFail(error))
  }
  yield put(stopLoading());
}

function* DataCarouselSaga() {
  yield takeEvery(GET_DATA_CAROUSEL, fetchDataCarousel)
  yield takeEvery(ADD_NEW_CAROUSEL, onAddNewCarousel)
  yield takeEvery(UPDATE_CAROUSEL, onUpdateCarousel)
  yield takeEvery(DELETE_CAROUSEL, onDeleteCarousel)
}

export default DataCarouselSaga
