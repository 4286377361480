import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import DataBarangSaga from "./barang/saga"
import DataPaketKhitanSaga from "./paketKhitan/saga"
import DataArtikelSaga from "./artikel/saga"
import DataBookingSaga from "./booking/saga"
import DataUserSaga from "./user/saga"
import DataNotifSaga from "./notif/saga"
import ioNotifSaga from "./ioNotif/saga"
import dataDashboardSaga from "./dashboard/saga"
import dataCarouselSaga from "./carousel/saga"
import konsultasiSaga from "./konsultasi/saga"
import rekeningSaga from "./rekening/saga"
import DataPengeluaranSaga from "./pengeluaran/saga"
import DataAffiliasiSaga from "./affiliasi/saga"
import DataLevelAffiliasiSaga from "./levelAffiliasi/saga"
import DataTarikDanaSaga from "./tarikDana/saga"
import DataPresensiSaga from "./presensi/saga"
import DataVideoCallSaga from "./videoCall/saga"
import DataListTaskSaga from "./listTask/saga"
import DataTemukanMetodeSaga from "./temukanMetode/saga"
import DataNeracaSaga from "./neraca/saga"
import DataGajiSaga from "./gaji/saga"
import DataConfigSaga from "./additionalConfig/saga"
import DataVoucherSaga from "./voucher/saga"
import DataReviewSaga from "./review/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    fork(DataBarangSaga),
    fork(DataPaketKhitanSaga),
    fork(DataArtikelSaga),
    fork(DataBookingSaga),
    fork(DataUserSaga),
    fork(DataNotifSaga),
    ioNotifSaga(),
    fork(dataDashboardSaga),
    fork(dataCarouselSaga),
    konsultasiSaga(),
    fork(rekeningSaga),
    fork(DataPengeluaranSaga),
    fork(DataAffiliasiSaga),
    fork(DataLevelAffiliasiSaga),
    fork(DataTarikDanaSaga),
    fork(DataPresensiSaga),
    fork(DataVideoCallSaga),
    fork(DataListTaskSaga),
    fork(DataTemukanMetodeSaga),
    fork(DataNeracaSaga),
    fork(DataGajiSaga),
    fork(DataConfigSaga),
    fork(DataVoucherSaga),
    fork(DataReviewSaga),
  ])
}
