/* DATA_PAKET_KHITAN */
export const GET_DATA_PAKET_KHITAN = "GET_DATA_PAKET_KHITAN"
export const GET_DATA_PAKET_KHITAN_SUCCESS = "GET_DATA_PAKET_KHITAN_SUCCESS"
export const GET_DATA_PAKET_KHITAN_FAIL = "GET_DATA_PAKET_KHITAN_FAIL"

export const ADD_NEW_PAKET_KHITAN = "ADD_NEW_PAKET_KHITAN"
export const ADD_PAKET_KHITAN_SUCCESS = "ADD_PAKET_KHITAN_SUCCESS"
export const ADD_PAKET_KHITAN_FAIL = "ADD_PAKET_KHITAN_FAIL"

export const UPDATE_PAKET_KHITAN = "UPDATE_PAKET_KHITAN"
export const UPDATE_PAKET_KHITAN_SUCCESS = "UPDATE_PAKET_KHITAN_SUCCESS"
export const UPDATE_PAKET_KHITAN_FAIL = "UPDATE_PAKET_KHITAN_FAIL"

export const DELETE_PAKET_KHITAN = "DELETE_PAKET_KHITAN"
export const DELETE_PAKET_KHITAN_SUCCESS = "DELETE_PAKET_KHITAN_SUCCESS"
export const DELETE_PAKET_KHITAN_FAIL = "DELETE_PAKET_KHITAN_FAIL"

// export const GET_CATEGORIES = "GET_CATEGORIES"
// export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
// export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"
