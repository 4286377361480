/* DATA_LIST_TASK */
export const GET_DATA_LIST_TASK = "GET_DATA_LIST_TASK"
export const GET_DATA_LIST_TASK_SUCCESS = "GET_DATA_LIST_TASK_SUCCESS"
export const GET_DATA_LIST_TASK_FAIL = "GET_DATA_LIST_TASK_FAIL"

export const ADD_NEW_LIST_TASK = "ADD_NEW_LIST_TASK"
export const ADD_LIST_TASK_SUCCESS = "ADD_LIST_TASK_SUCCESS"
export const ADD_LIST_TASK_FAIL = "ADD_LIST_TASK_FAIL"

export const UPDATE_LIST_TASK = "UPDATE_LIST_TASK"
export const UPDATE_LIST_TASK_SUCCESS = "UPDATE_LIST_TASK_SUCCESS"
export const UPDATE_LIST_TASK_FAIL = "UPDATE_LIST_TASK_FAIL"

export const DELETE_LIST_TASK = "DELETE_LIST_TASK"
export const DELETE_LIST_TASK_SUCCESS = "DELETE_LIST_TASK_SUCCESS"
export const DELETE_LIST_TASK_FAIL = "DELETE_LIST_TASK_FAIL"

// export const GET_CATEGORIES = "GET_CATEGORIES"
// export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
// export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"
