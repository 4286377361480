import {
  GET_DATA_PAKET_KHITAN_SUCCESS,
  GET_DATA_PAKET_KHITAN_FAIL,
  ADD_PAKET_KHITAN_SUCCESS,
  ADD_PAKET_KHITAN_FAIL,
  UPDATE_PAKET_KHITAN_SUCCESS,
  UPDATE_PAKET_KHITAN_FAIL,
  DELETE_PAKET_KHITAN_SUCCESS,
  DELETE_PAKET_KHITAN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataPaketKhitan: [],
  error: {},
}

const DataPaketKhitanReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_PAKET_KHITAN_SUCCESS:
      return {
        ...state,
        dataPaketKhitan: action.payload,
      }

    case GET_DATA_PAKET_KHITAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PAKET_KHITAN_SUCCESS:
      return {
        ...state,
        dataPaketKhitan: [...state.dataPaketKhitan, action.payload],
      }

    case ADD_PAKET_KHITAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PAKET_KHITAN_SUCCESS:
      return {
        ...state,
        dataPaketKhitan: state.dataPaketKhitan.map(paket =>
          paket._id === action.payload._id
            ? { paket, ...action.payload }
            : paket
        ),
      }

    case UPDATE_PAKET_KHITAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PAKET_KHITAN_SUCCESS:
      return {
        ...state,
        dataPaketKhitan: state.dataPaketKhitan.filter(
          paket => paket._id !== action.payload._id
        ),
      }

    case DELETE_PAKET_KHITAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default DataPaketKhitanReducer
