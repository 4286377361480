import {
  GET_DATA_BARANG_SUCCESS,
  GET_DATA_BARANG_FAIL,
  ADD_BARANG_SUCCESS,
  ADD_BARANG_FAIL,
  UPDATE_BARANG_SUCCESS,
  UPDATE_BARANG_FAIL,
  DELETE_BARANG_SUCCESS,
  DELETE_BARANG_FAIL,
  GET_KATEGORI_BARANG_SUCCESS,
  GET_KATEGORI_BARANG_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataBarang: [],
  kategoriBarang: [],
  error: {},
}

const DataBarangReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_BARANG_SUCCESS:
      return {
        ...state,
        dataBarang: action.payload,
      }

    case GET_DATA_BARANG_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_BARANG_SUCCESS:
      return {
        ...state,
        dataBarang: [...state.dataBarang, action.payload],
      }

    case ADD_BARANG_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_BARANG_SUCCESS:
      return {
        ...state,
        dataBarang: state.dataBarang.map(barang =>
          barang._id === action.payload._id
            ? { barang, ...action.payload }
            : barang
        ),
      }

    case UPDATE_BARANG_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_BARANG_SUCCESS:
      return {
        ...state,
        dataBarang: state.dataBarang.filter(
          barang => barang._id !== action.payload._id
        ),
      }

    case DELETE_BARANG_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_KATEGORI_BARANG_SUCCESS:
      return {
        ...state,
        kategoriBarang: action.payload,
      }

    case GET_KATEGORI_BARANG_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default DataBarangReducer
