import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_LEVEL_AFFILIASI,
  DELETE_LEVEL_AFFILIASI,
  // GET_CATEGORIES,
  GET_DATA_LEVEL_AFFILIASI,
  UPDATE_LEVEL_AFFILIASI,
} from "./actionTypes"
import {
  getDataLevelAffiliasiSuccess,
  getDataLevelAffiliasiFail,
  addLevelAffiliasiFail,
  addLevelAffiliasiSuccess,
  updateLevelAffiliasiSuccess,
  updateLevelAffiliasiFail,
  deleteLevelAffiliasiSuccess,
  deleteLevelAffiliasiFail,
  // getCategoriesSuccess,
  // getCategoriesFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataLevelAffiliasi,
  addNewLevelAffiliasi,
  updateLevelAffiliasi,
  deleteLevelAffiliasi,
  // getCategories,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"

function* fetchDataLevelAffiliasi() {
  yield put(startLoading());
  try {
    const response = yield call(getDataLevelAffiliasi)
    yield put(getDataLevelAffiliasiSuccess(response))
  } catch (error) {
    yield put(getDataLevelAffiliasiFail(error))
  }
  yield put(stopLoading());
}

function* onAddNewLevelAffiliasi({ payload: levelaffiliasi }) {
  yield put(startLoading());
  try {
    const response = yield call(addNewLevelAffiliasi, levelaffiliasi)
    yield put(addLevelAffiliasiSuccess(response))
  } catch (error) {
    yield put(addLevelAffiliasiFail(error))
  }
  yield put(stopLoading());
}

function* onUpdateLevelAffiliasi({ payload: levelaffiliasi }) {
  yield put(startLoading());
  try {
    // console.log(levelaffiliasi)
    const response = yield call(updateLevelAffiliasi, levelaffiliasi)
    yield put(updateLevelAffiliasiSuccess(response))
  } catch (error) {
    yield put(updateLevelAffiliasiFail(error))
  }
  yield put(stopLoading());
}

function* onDeleteLevelAffiliasi({ payload: levelaffiliasi }) {
  yield put(startLoading());
  try {
    const response = yield call(deleteLevelAffiliasi, levelaffiliasi)
    yield put(deleteLevelAffiliasiSuccess(response))
  } catch (error) {
    yield put(deleteLevelAffiliasiFail(error))
  }
  yield put(stopLoading());
}

// function* onGetCategories() {
//   try {
//     const response = yield call(getCategories)
//     yield put(getCategoriesSuccess(response))
//   } catch (error) {
//     yield put(getCategoriesFail(error))
//   }
// }

function* DataLevelAffiliasiSaga() {
  yield takeEvery(GET_DATA_LEVEL_AFFILIASI, fetchDataLevelAffiliasi)
  yield takeEvery(ADD_NEW_LEVEL_AFFILIASI, onAddNewLevelAffiliasi)
  yield takeEvery(UPDATE_LEVEL_AFFILIASI, onUpdateLevelAffiliasi)
  yield takeEvery(DELETE_LEVEL_AFFILIASI, onDeleteLevelAffiliasi)
  // yield takeEvery(GET_CATEGORIES, onGetCategories)
}

export default DataLevelAffiliasiSaga
