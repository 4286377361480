import {
  GET_DATA_REVIEW,
  GET_DATA_REVIEW_FAIL,
  GET_DATA_REVIEW_SUCCESS,
} from "./actionTypes"

export const getDataReview = keyword => ({
  type: GET_DATA_REVIEW,
  payload: keyword,
})

export const getDataReviewSuccess = dataReview => ({
  type: GET_DATA_REVIEW_SUCCESS,
  payload: dataReview,
})

export const getDataReviewFail = error => ({
  type: GET_DATA_REVIEW_FAIL,
  payload: error,
})
