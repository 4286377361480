import {
  GET_DATA_LEVEL_AFFILIASI,
  GET_DATA_LEVEL_AFFILIASI_FAIL,
  GET_DATA_LEVEL_AFFILIASI_SUCCESS,
  ADD_NEW_LEVEL_AFFILIASI,
  ADD_LEVEL_AFFILIASI_SUCCESS,
  ADD_LEVEL_AFFILIASI_FAIL,
  UPDATE_LEVEL_AFFILIASI,
  UPDATE_LEVEL_AFFILIASI_SUCCESS,
  UPDATE_LEVEL_AFFILIASI_FAIL,
  DELETE_LEVEL_AFFILIASI,
  DELETE_LEVEL_AFFILIASI_SUCCESS,
  DELETE_LEVEL_AFFILIASI_FAIL,
  // GET_CATEGORIES,
  // GET_CATEGORIES_SUCCESS,
  // GET_CATEGORIES_FAIL,
} from "./actionTypes"

export const getDataLevelAffiliasi = () => ({
  type: GET_DATA_LEVEL_AFFILIASI,
})

export const getDataLevelAffiliasiSuccess = dataLevelAffiliasi => ({
  type: GET_DATA_LEVEL_AFFILIASI_SUCCESS,
  payload: dataLevelAffiliasi,
})

export const getDataLevelAffiliasiFail = error => ({
  type: GET_DATA_LEVEL_AFFILIASI_FAIL,
  payload: error,
})

export const addNewLevelAffiliasi = levelaffiliasi => ({
  type: ADD_NEW_LEVEL_AFFILIASI,
  payload: levelaffiliasi,
})

export const addLevelAffiliasiSuccess = levelaffiliasi => ({
  type: ADD_LEVEL_AFFILIASI_SUCCESS,
  payload: levelaffiliasi,
})

export const addLevelAffiliasiFail = error => ({
  type: ADD_LEVEL_AFFILIASI_FAIL,
  payload: error,
})

export const updateLevelAffiliasi = levelaffiliasi => ({
  type: UPDATE_LEVEL_AFFILIASI,
  payload: levelaffiliasi,
})

export const updateLevelAffiliasiSuccess = levelaffiliasi => ({
  type: UPDATE_LEVEL_AFFILIASI_SUCCESS,
  payload: levelaffiliasi,
})

export const updateLevelAffiliasiFail = error => ({
  type: UPDATE_LEVEL_AFFILIASI_FAIL,
  payload: error,
})

export const deleteLevelAffiliasi = levelaffiliasi => ({
  type: DELETE_LEVEL_AFFILIASI,
  payload: levelaffiliasi,
})

export const deleteLevelAffiliasiSuccess = levelaffiliasi => ({
  type: DELETE_LEVEL_AFFILIASI_SUCCESS,
  payload: levelaffiliasi,
})

export const deleteLevelAffiliasiFail = error => ({
  type: DELETE_LEVEL_AFFILIASI_FAIL,
  payload: error,
})

// export const getCategories = () => ({
//   type: GET_CATEGORIES,
// })

// export const getCategoriesSuccess = categories => ({
//   type: GET_CATEGORIES_SUCCESS,
//   payload: categories,
// })

// export const getCategoriesFail = error => ({
//   type: GET_CATEGORIES_FAIL,
//   payload: error,
// })
