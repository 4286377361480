import {
  GET_DATA_GAJI,
  GET_DATA_GAJI_FAIL,
  GET_DATA_GAJI_SUCCESS,
  ADD_NEW_GAJI,
  ADD_GAJI_SUCCESS,
  ADD_GAJI_FAIL,
  UPDATE_GAJI,
  UPDATE_GAJI_SUCCESS,
  UPDATE_GAJI_FAIL,
  DELETE_GAJI,
  DELETE_GAJI_SUCCESS,
  DELETE_GAJI_FAIL,
} from "./actionTypes"

export const getDataGaji = () => ({
  type: GET_DATA_GAJI,
})

export const getDataGajiSuccess = dataGaji => ({
  type: GET_DATA_GAJI_SUCCESS,
  payload: dataGaji,
})

export const getDataGajiFail = error => ({
  type: GET_DATA_GAJI_FAIL,
  payload: error,
})

export const addNewGaji = gaji => ({
  type: ADD_NEW_GAJI,
  payload: gaji,
})

export const addGajiSuccess = gaji => ({
  type: ADD_GAJI_SUCCESS,
  payload: gaji,
})

export const addGajiFail = error => ({
  type: ADD_GAJI_FAIL,
  payload: error,
})

export const updateGaji = gaji => ({
  type: UPDATE_GAJI,
  payload: gaji,
})

export const updateGajiSuccess = gaji => ({
  type: UPDATE_GAJI_SUCCESS,
  payload: gaji,
})

export const updateGajiFail = error => ({
  type: UPDATE_GAJI_FAIL,
  payload: error,
})

export const deleteGaji = gaji => ({
  type: DELETE_GAJI,
  payload: gaji,
})

export const deleteGajiSuccess = gaji => ({
  type: DELETE_GAJI_SUCCESS,
  payload: gaji,
})

export const deleteGajiFail = error => ({
  type: DELETE_GAJI_FAIL,
  payload: error,
})
