import { takeEvery, put, call } from "redux-saga/effects"

import { GET_DATA_NERACA, FILTER_DATA_NERACA } from "./actionTypes"
import {
  getDataNeracaSuccess,
  getDataNeracaFail,
  filterDataNeracaSuccess,
  filterDataNeracaFail,
} from "./actions"
import { getDataNeraca, filterDataNeraca } from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"


function* fetchDataNeraca() {
  yield put(startLoading());
  try {
    const response = yield call(getDataNeraca)
    yield put(getDataNeracaSuccess(response))
  } catch (error) {
    yield put(getDataNeracaFail(error))
  }
  yield put(stopLoading());
}

function* onFilterDataNeraca({ payload: data }) {
  yield put(startLoading());
  try {
    const response = yield call(filterDataNeraca, data)
    yield put(filterDataNeracaSuccess(response))
  } catch (error) {
    yield put(filterDataNeracaFail(error))
  }
  yield put(stopLoading());
}

function* DataNeracaSaga() {
  yield takeEvery(GET_DATA_NERACA, fetchDataNeraca)
  yield takeEvery(FILTER_DATA_NERACA, onFilterDataNeraca)
}

export default DataNeracaSaga
