import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Container, Row, Col, Card, CardBody, Badge } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { isEmpty } from "lodash"

import {
  getDataDashboard,
  filterDataDashboard,
  getDataConfig,
} from "../../store/actions"

import { Link } from "react-router-dom"

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"
// // Charts
import PlatformChart from "../Charts/platformChart"
import MetodeChart from "../Charts/metodeChart"
import UsiaChart from "../Charts/usiaChart"
// import DonutBookingType from "../AllCharts/apex/DonutBookingType"

import "chartist/dist/scss/chartist.scss"

const Dashboard = props => {
  const {
    onGetDataDashboard,
    onFilterDataDashboard,
    dataDashboard,
    onGetDataConfig,
    dataConfig,
  } = props
  const [menu, setMenu] = useState(false)
  const [formatTotal, setFormatTotal] = useState(0)
  const [apexOptions, setApexOptions] = useState({
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
        },
      },
    },
    colors: ["#626ed4", "#f8b425"],
  })
  const [dataChartPlatform, setDataChartPlatform] = useState([0, 0])
  const [dataChartMetode, setDataChartMetode] = useState([])
  const [dataChartUsia, setDataChartUsia] = useState([])
  const toggle = () => {
    setMenu(!menu)
  }
  const [dataFilter, setDataFilter] = useState("")
  const [dataUpcoming, setDataUpcoming] = useState([])

  useEffect(() => {
    if (!isEmpty(dataDashboard) && !isEmpty(dataConfig)) {
      let total_pendapatan = 0

      if (!isEmpty(dataDashboard.pendapatan_booking)) {
        dataDashboard?.pendapatan_booking.map(item => {
          let ongkos =
            item.tempat_khitan.tempat_khitan === "panggilan_rumah" && dataConfig
              ? item.tempat_khitan.tempat_khitan_panggilan === "dalam_kota"
                ? parseInt(dataConfig.biaya_dalam_kota)
                : item.tempat_khitan.tempat_khitan_panggilan === "luar_kota"
                ? parseInt(dataConfig.biaya_luar_kota)
                : 0
              : 0

          let diskon =
            item.paket_khitan.tipePotongan === "persen"
              ? (parseInt(item.paket_khitan.harga) *
                  parseInt(item.paket_khitan.potongan)) /
                100
              : item.paket_khitan.tipePotongan === "nominal"
              ? parseInt(item.paket_khitan.potongan)
              : 0

          let voucher =
            item.paket_khitan.kode_voucher &&
            !isEmpty(item.paket_khitan.kode_voucher)
              ? item.paket_khitan.isVoucherPersen
                ? (parseInt(item.paket_khitan.harga) *
                    parseInt(item.paket_khitan.nominal_voucher)) /
                  100
                : !item.paket_khitan.isVoucherPersen
                ? parseInt(item.paket_khitan.nominal_voucher)
                : 0
              : 0

          let total =
            parseInt(item.paket_khitan.harga) + ongkos - diskon - voucher
          let totalcek = total > 0 ? total : 0

          total_pendapatan += totalcek
        })
      }

      // if (total_pendapatan >= 1e6 && total_pendapatan < 1e9) total_pendapatan = (total_pendapatan / 1e6).toFixed(0) + " jt"
      // if (total_pendapatan >= 1e9 && total_pendapatan < 1e12) total_pendapatan = (total_pendapatan / 1e9).toFixed(1) + " Mi"
      setFormatTotal(total_pendapatan)

      dataDashboard?.latest_booking.map((item, index) => {
        item.statusBadge = (
          <span
            className={
              "badge bg-" +
              (item.status === "verifikasi"
                ? "warning"
                : item.status === "aktif"
                ? "primary"
                : item.status === "selesai"
                ? "success"
                : item.status === "rejadwal"
                ? "info"
                : "danger")

              //   enable transfer
              // (item.status === "belum_bayar"
              //   ? "warning"
              //   : item.status === "lunas"
              //   ? "primary"
              //   : item.status === "selesai"
              //   ? "success"
              //   : "danger")
            }
          >
            {item.status === "verifikasi"
              ? "diproses"
              : item.status === "aktif"
              ? "terverifikasi"
              : item.status}
          </span>
        )

        item.statusBadge = (
          <>
            <Badge
              className={
                "font-size-12 badge bg-" +
                (item.status === "verifikasi"
                  ? "warning"
                  : item.status === "aktif"
                  ? "primary"
                  : item.status === "selesai"
                  ? "success"
                  : item.status === "rejadwal"
                  ? "info"
                  : "danger")
              }
              pill
            >
              {item.status === "verifikasi"
                ? "diproses"
                : item.status === "aktif"
                ? "terverifikasi"
                : item.status}
            </Badge>
          </>
        )
        item.display_tempat_khitan = (
          <Badge
            className={
              "font-size-12 badge bg-" +
              (item.tempat_khitan?.tempat_khitan === "klinik_singosari"
                ? "primary"
                : item.tempat_khitan?.tempat_khitan === "klinik_joyoagung"
                ? "success"
                : item.tempat_khitan?.tempat_khitan === "panggilan_rumah"
                ? "warning"
                : "danger")
            }
            pill
          >
            {item.tempat_khitan?.tempat_khitan || "belum_dipilih"}
          </Badge>
        )
        item.nama_anak = item.data_anak.nama_anak
        item.usia = item.data_anak.usia_anak
        item.paket_khitan_display = item.paket_khitan.nama
        item.nama_dokter = item?.data_dokter?.nama_dokter || ""
      })

      setDataChartPlatform([dataDashboard.bookingWeb, dataDashboard.bookingApp])
      const metodeChartData = dataDashboard.metode.map(({ _id, total }) => {
        return {
          value: total,
          name: _id,
        }
      })
      setDataChartMetode(metodeChartData)

      const usiaChartData = dataDashboard.usia.map(({ _id, total }) => {
        return {
          value: total,
          name: _id,
        }
      })
      setDataChartUsia(usiaChartData)

      setDataUpcoming(dataDashboard.latest_booking)
    }
    // console.log(dataDashboard)
  }, [dataDashboard, dataConfig])

  // useEffect(() => {
  //   onGetDataDashboard()
  // }, [])

  useEffect(() => {
    onFilterDataDashboard({ data_filter: dataFilter })
    onGetDataConfig(["biaya_dalam_kota", "biaya_luar_kota"])
  }, [dataFilter])

  const filterDashboard = val => {
    setDataFilter(val)
  }

  const data = {
    columns: [
      {
        label: "Nama Anak",
        field: "nama_anak",
        width: 150,
      },
      {
        label: "Tanggal Khitan",
        field: "tanggal_khitan",
        width: 270,
      },
      {
        label: "Jam Khitan",
        field: "jam_khitan",
        width: 100,
      },
      {
        label: "Tempat Khitan",
        field: "display_tempat_khitan",
        width: 150,
      },
      {
        label: "Paket",
        field: "paket_khitan_display",
        width: 150,
      },
      {
        label: "Usia",
        field: "usia",
        width: 100,
      },
      {
        label: "Nama Dokter",
        field: "nama_dokter",
        width: 100,
      },
      {
        label: "Status",
        field: "statusBadge",
        width: 200,
      },
    ],
    rows: dataUpcoming,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | KhitanSultan Backoffice</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col className="col-6">
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Selamat datang di Dashboard Khitan Sultan
                  </li>
                </ol>
              </Col>

              <Col className="col-6">
                <div className="float-end d-block">
                  <label
                    htmlFor="example-month-input"
                    className="col-form-label"
                  >
                    Filter Bulan
                  </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="month"
                      // defaultValue="2022-05"
                      id="example-month-input"
                      onChange={val => filterDashboard(val.target.value)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <Link to="/user">
                    <div className="mb-2">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon1} alt="" />
                      </div>
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                        User
                      </h5>
                      <h4 className="fw-medium font-size-24 text-white">
                        {dataDashboard?.jumlah_user || 0}
                      </h4>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <Link to="/barang">
                    <div className="mb-2">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon2} alt="" />
                      </div>
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                        Barang
                      </h5>
                      <h4 className="fw-medium font-size-24 text-white">
                        {dataDashboard?.jumlah_barang || 0}
                      </h4>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <Link to="/booking">
                    <div className="mb-2">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon3} alt="" />
                      </div>
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                        Booking
                      </h5>
                      <h4 className="fw-medium font-size-24 text-white">
                        {dataDashboard?.jumlah_booking || 0}
                      </h4>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <Link to="/Neraca">
                    <div className="mb-2">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon4} alt="" />
                      </div>
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                        Pendapatan
                      </h5>
                      <h6 className="fw-medium font-size-14 text-white">
                        Rp.{" "}
                        {formatTotal
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                      </h6>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Jadwal Khitan Mendatang</h4>

                  {/* <MDBDataTable responsive striped bordered data={data} /> */}

                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Nama Anak</th>
                          <th scope="col">Tanggal Khitan</th>
                          <th scope="col">Jam Khitan</th>
                          <th scope="col">Tempat Khitan</th>
                          <th scope="col">Paket</th>
                          <th scope="col">Usia</th>
                          <th scope="col">Nama Dokter</th>
                          <th scope="col">Status"</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isEmpty(dataUpcoming) ? (
                          dataUpcoming?.map((item, index) => (
                            <tr key={index}>
                              <td>{item.nama_anak}</td>
                              <td>{item.tanggal_khitan}</td>
                              <td>{item.jam_khitan}</td>
                              <td>{item.display_tempat_khitan}</td>
                              <td>{item.paket_khitan_display}</td>
                              <td>{item.usia}</td>
                              <td>{item.nama_dokter}</td>
                              <td>{item.statusBadge}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <th scope="row" colSpan={8} className="text-center">
                              Belum ada transaksi
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="4">
              <Card>
                <CardBody>
                  <h4 className="mt-0 header-title mb-4">Metode Digunakan</h4>
                  <div id="pie-chart" className="e-chart">
                    <MetodeChart
                      data={dataChartMetode}
                      notMerge={true}
                      lazyUpdate={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <CardBody>
                  <h4 className="mt-0 header-title mb-4">Platform Booking</h4>

                  <div id="doughnut-chart" className="e-chart">
                    <PlatformChart
                      data={dataChartPlatform}
                      notMerge={true}
                      lazyUpdate={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <CardBody>
                  <h4 className="mt-0 header-title mb-4">Usia Pasien</h4>

                  <div id="doughnut-chart" className="e-chart">
                    <UsiaChart
                      data={dataChartUsia}
                      notMerge={true}
                      lazyUpdate={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  dataDashboard: PropTypes.object,
  dataConfig: PropTypes.object,
  onGetDataDashboard: PropTypes.func,
  onFilterDataDashboard: PropTypes.func,
  onGetDataConfig: PropTypes.func,
}

const mapStateToProps = ({ dataDashboardReducer, dataConfigReducer }) => ({
  dataDashboard: dataDashboardReducer.dataDashboard,
  dataConfig: dataConfigReducer.dataConfig,
})

const mapDispatchToProps = dispatch => ({
  onGetDataDashboard: () => dispatch(getDataDashboard()),
  onFilterDataDashboard: data => dispatch(filterDataDashboard(data)),
  onGetDataConfig: config => dispatch(getDataConfig(config)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
