import {
  GET_KONSULTASI_SUCCESS,
  GET_KONSULTASI_FAIL,
  GET_UNREAD_KONSULTASI,
  GET_UNREAD_KONSULTASI_SUCCESS,
  GET_UNREAD_KONSULTASI_FAIL,
  GET_LIST_KONSULTASI_SUCCESS,
  GET_LIST_KONSULTASI_FAIL,
  UPDATE_LIST_KONSULTASI_SUCCESS,
  UPDATE_LIST_KONSULTASI_FAIL,
  ADD_KONSULTASI_SUCCESS,
  ADD_KONSULTASI_FAIL,
  READ_KONSULTASI_SUCCESS,
  READ_KONSULTASI_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataListKonsultasi: {},
  dataKonsultasi: {},
  error: {},
  unread: 0,
}

const konsultasiReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_KONSULTASI_SUCCESS:
      // return {
      //   // ...state,
      //   // dataKonsultasi: [action.payload, ...state.dataKonsultasi],

      //   dataListKonsultasi: state.dataListKonsultasi.map(konsultasi =>
      //     konsultasi._id === action.payload._id
      //       ? { konsultasi, ...action.payload }
      //       : konsultasi
      //   ),
      // }
      return {
        ...state,
        dataKonsultasi: action.payload,
      }

    case GET_KONSULTASI_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // case GET_UNREAD_KONSULTASI:
    //   return {
    //     ...state,
    //   }

    case GET_UNREAD_KONSULTASI_SUCCESS:
      return {
        ...state,
        unread: action.payload,
      }

    case GET_UNREAD_KONSULTASI_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LIST_KONSULTASI_SUCCESS:
      return {
        ...state,
        dataListKonsultasi: action.payload,
      }

    case GET_LIST_KONSULTASI_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_LIST_KONSULTASI_SUCCESS:
      if (
        state.dataListKonsultasi.data.find(
          item => item._id === action.payload._id
        )
      ) {
      } else {
        return {
          ...state,
          dataListKonsultasi: {
            users: [ ...state.dataListKonsultasi.users ],
            data: [...state.dataListKonsultasi.data, action.payload],
          },
        }

        // dataListKonsultasi: state.dataListKonsultasi.data.map(konsultasi =>
        //   konsultasi._id === action.payload._id
        //     ? { konsultasi, ...action.payload }
        //     : konsultasi
        // ),
      }

    case UPDATE_LIST_KONSULTASI_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_KONSULTASI_SUCCESS:
      return {
        // ...state,
        // dataListKonsultasi: [action.payload, ...state.dataListKonsultasi],

        dataListKonsultasi: state.dataListKonsultasi.map(konsultasi =>
          konsultasi._id === action.payload._id
            ? { ...action.payload }
            : konsultasi
        ),
      }
    // return {
    //   ...state,
    //   dataListKonsultasi: {
    //     users: { ...state.dataListKonsultasi.users },
    //     data: [...state.dataListKonsultasi.data, action.payload],
    //   },
    // }

    case ADD_KONSULTASI_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case READ_KONSULTASI_SUCCESS:
      return {
        ...state,
        dataListKonsultasi: [action.payload, ...state.dataListKonsultasi],

        // dataListKonsultasi: state.dataListKonsultasi.map(konsultasi =>
        //   konsultasi._id === action.payload._id
        //     ? { konsultasi, ...action.payload }
        //     : konsultasi
        // ),
      }

    case READ_KONSULTASI_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default konsultasiReducer
