import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { env } from "../../helpers/config"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

import {
  addNewPaketKhitan,
  deletePaketKhitan,
  getDataPaketKhitan,
  updatePaketKhitan,
} from "../../store/actions"

const PaketKhitan = props => {
  const { onGetDataPaketKhitan, dataPaketKhitan } = props
  const [modal, setModal] = useState(false)
  const [paket, setPaketKhitan] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [imagePaket, setImagePaket] = useState([])
  const [imageAlat, setImageAlat] = useState([])
  const [imageHadiah, setImageHadiah] = useState([])
  const [errImage, setErrImage] = useState({})
  const [hargaPaket, setHargaPaket] = useState(0)
  const [tipePotongan, setTipePotongan] = useState("")
  const [potongan, setPotongan] = useState(0)

  const [biayaDalamKota, setBiayaDalamKota] = useState(0)
  const [biayaLuarKota, setBiayaLuarKota] = useState(0)

  useEffect(() => {
    if (dataPaketKhitan) {
      dataPaketKhitan.map((item, index) => {
        item.action = (
          <Button
            color="primary"
            outline
            className="font-16 btn-block btn-sm"
            onClick={e => handleOpenModal(item)}
          >
            {/* <i className="mdi mdi-grease-pencil"></i> */}
            Ubah
          </Button>
        )
        item.image1 = item.image.map((img, i) => [
          <CardImg
            key={i}
            src={env.IMG_URL + img}
            alt="image-paket"
            className="avatar-lg img-thumbnail"
          />,
        ])
        item.image_alat2 = item.image_alat.map((img, i) => [
          <CardImg
            key={i}
            src={env.IMG_URL + img}
            alt="image-alat"
            className="avatar-lg img-thumbnail"
          />,
        ])
        item.image_hadiah3 = item.image_hadiah.map((img, i) => [
          <CardImg
            key={i}
            src={env.IMG_URL + img}
            alt="image-hadiah"
            className="avatar-lg img-thumbnail"
          />,
        ])
        item.harga_formatted =
          "Rp." + item.harga.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        item.diskon = !isEmpty(item.data_potongan)
          ? item.data_potongan.tipePotongan === "persen"
            ? item.data_potongan.potongan + "%"
            : item.data_potongan.tipePotongan === "nominal" &&
              "Rp." +
                item.data_potongan.potongan
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          : "-"
      })
      // console.log(dataPaketKhitan)
    }

    if (!modal && !isEmpty(paket) && !!isEdit) {
      // setTimeout(() => {
      setPaketKhitan({})
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, paket, dataPaketKhitan])

  const toggle = close => {
    if (close) {
      setImagePaket([])
      setImageAlat([])
      setImageHadiah([])
      setErrImage({})
      setHargaPaket(0)
      setTipePotongan("")
      setPotongan(0)
    }
    setModal(!modal)
  }

  const handleOpenModal = async data => {
    // console.log("data", data)
    if (data) {
      // console.log("data exist")
      setPaketKhitan({
        _id: data._id,
        nama: data.nama,
        metode: data.metode,
        harga: data.harga,
        image: data.image,
        image_alat: data.image_alat,
        image_hadiah: data.image_hadiah,
        fasilitas: data.fasilitas,
        deskripsi: data.deskripsi,
        data_potongan: data.data_potongan,
      })
      setImagePaket(data.image)
      setImageAlat(data.image_alat)
      setImageHadiah(data.image_hadiah)
      setHargaPaket(data.harga)
      await setTipePotongan(
        data && data.data_potongan ? data.data_potongan.tipePotongan : ""
      )
      setPotongan(data && data.data_potongan ? data.data_potongan.potongan : 0)
      setIsEdit(true)
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  useEffect(() => {
    onGetDataPaketKhitan()
  }, [])

  const handleValidPaketKhitanSubmit = (e, values) => {
    const { onAddNewPaketKhitan, onUpdatePaketKhitan } = props

    const formData = new FormData()
    formData.append("nama", values.nama)
    formData.append("metode", values.metode)
    formData.append("harga", values.harga)
    imagePaket.map(img => {
      formData.append("image_paket", img)
    })
    imageAlat.map(img => {
      formData.append("image_alat", img)
    })
    imageHadiah.map(img => {
      formData.append("image_hadiah", img)
    })
    formData.append("fasilitas", values.fasilitas)
    formData.append("deskripsi", values.deskripsi)
    formData.append("tipePotongan", values.tipePotongan)
    formData.append("potongan", values.potongan)

    if (Object.keys(imagePaket).length === 0 && !isEdit) {
      setErrImage({ ...errImage, paket: "Gambar Paket harus di upload.." })
    } else if (Object.keys(imageAlat).length === 0 && !isEdit) {
      setErrImage({ ...errImage, alat: "Gambar Alat harus di upload.." })
    } else if (Object.keys(imageHadiah).length === 0 && !isEdit) {
      setErrImage({ ...errImage, hadiah: "Gambar Hadiah harus di upload.." })
    } else {
      if (isEdit) {
        formData.append("_id", paket._id)
        onUpdatePaketKhitan(formData)
      } else {
        onAddNewPaketKhitan(formData)
      }
      setImagePaket([])
      setImageAlat([])
      setImageHadiah([])
      setHargaPaket(0)
      setTipePotongan("")
      setPotongan(0)
      toggle()
    }
  }

  const handleDeletePaketKhitan = () => {
    const { onDeletePaketKhitan } = props
    onDeletePaketKhitan(paket._id)
    setDeleteModal(false)
    setImagePaket([])
    setImageAlat([])
    setImageHadiah([])
    setHargaPaket(0)
    setTipePotongan("")
    setPotongan(0)
    toggle()
  }

  function handleImagePaket(files) {
    if (files.length > 5) {
      setErrImage({ paket: "Maksimal upload 5 gambar" })
    } else {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setImagePaket(files)
      setErrImage(delete errImage.paket)
    }
  }

  function handleImageAlat(files) {
    if (files.length > 5) {
      setErrImage({ alat: "Maksimal upload 5 gambar" })
    } else {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setImageAlat(files)
      setErrImage(delete errImage.alat)
    }
  }

  function handleImageHadiah(files) {
    if (files.length > 5) {
      setErrImage({ hadiah: "Maksimal upload 5 gambar" })
    } else {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setImageHadiah(files)
      setErrImage(delete errImage.hadiah)
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  useEffect(() => {
    // alert(tipePotongan)
    setPotongan(0)
  }, [tipePotongan])

  const data = {
    columns: [
      {
        label: "Nama",
        field: "nama",
        sort: "asc",
        width: 150,
      },
      {
        label: "Metode",
        field: "metode",
        sort: "asc",
        width: 150,
      },
      {
        label: "Harga",
        field: "harga_formatted",
        sort: "asc",
        width: 270,
      },
      {
        label: "Diskon",
        field: "diskon",
        sort: "asc",
        width: 270,
      },
      {
        label: "Image",
        field: "image1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Image Alat",
        field: "image_alat2",
        sort: "asc",
        width: 100,
      },
      {
        label: "Image Hadiah",
        field: "image_hadiah3",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Fasilitas",
      //   field: "fasilitas",
      //   sort: "asc",
      //   width: 100,
      // },
      // {
      //   label: "Deskripsi",
      //   field: "deskripsi",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: dataPaketKhitan,
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePaketKhitan}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Paket Khitan | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs
                    breadcrumbItem="Paket Khitan"
                    handleOpenModal={handleOpenModal}
                    buttonModal="Paket Khitan"
                  />
                  {/* <CardTitle className="h4">Data Paket Khitan</CardTitle> */}
                  {/* <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable modal-lg"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Paket Khitan" : "Tambah Paket Khitan"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidPaketKhitanSubmit}>
                <Row form>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="nama"
                      label="Nama Paket Khitan"
                      type="text"
                      errorMessage="Invalid nama paket"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={paket ? paket.nama : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="metode"
                      label="Metode Khitan"
                      type="text"
                      errorMessage="Invalid metode paket"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={paket ? paket.metode : ""}
                    />
                  </Col>
                  {/* <Col className="col-12 mb-3">
                    <AvField
                      name="harga"
                      label="Harga Paket Khitan"
                      type="number"
                      errorMessage="Invalid harga paket"
                      validate={{
                        required: { value: true },
                        number: true,
                      }}
                      min={1}
                      value={paket ? paket.harga : ""}
                    />
                  </Col> */}
                  <Col className="col-12 mb-3">
                    <AvField
                      name="harga"
                      label="Harga Paket Khitan"
                      type="number"
                      errorMessage="Invalid harga paket"
                      onChange={e => setHargaPaket(e.target.value)}
                      validate={{
                        required: { value: true },
                        number: true,
                      }}
                      min={1}
                      value={paket ? paket.harga : ""}
                    />
                  </Col>

                  <Col className="col-12 d-flex mb-3">
                    <Col className="col-4">
                      <AvField
                        name="tipePotongan"
                        label="Tipe Diskon / Potongan"
                        type="select"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={e => setTipePotongan(e.target.value)}
                        value={
                          paket && paket.data_potongan
                            ? paket.data_potongan.tipePotongan
                            : ""
                        }
                      >
                        <option hidden value="">
                          -- Pilih Tipe Diskon --
                        </option>
                        <option value="tanpaDiskon">Tanpa Diskon</option>
                        <option value="persen">Persentasi</option>
                        <option value="nominal">Nominal</option>
                      </AvField>
                    </Col>
                    <Col className="col-4 ps-4 ">
                      <AvField
                        name="potongan"
                        label="Jumlah Diskon"
                        type="number"
                        errorMessage={
                          potongan
                            ? tipePotongan === "persen"
                              ? "Maksimal Potongan 100%"
                              : "Diskon Melebihi Harga Paket"
                            : "Invalid Potongan"
                        }
                        validate={{
                          number: true,
                          required: {
                            value:
                              tipePotongan === "persen" ||
                              tipePotongan === "nominal"
                                ? true
                                : false,
                          },
                        }}
                        onChange={e => setPotongan(e.target.value)}
                        min={1}
                        max={tipePotongan === "persen" ? 100 : hargaPaket}
                        value={potongan}
                      />
                    </Col>
                    <Col className="col-4 ps-4 ">
                      <AvField
                        name="hasilPotongan"
                        label="Harga Setelah Diskon"
                        type="number"
                        disabled={true}
                        value={
                          tipePotongan === "persen"
                            ? hargaPaket - (hargaPaket * potongan) / 100
                            : tipePotongan === "nominal"
                            ? hargaPaket - potongan
                            : hargaPaket
                        }
                      />
                    </Col>
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      name="fasilitas"
                      label="Fasilitas Paket Khitan (Pisahkan tiap list dengan tanda @ , contoh: satu @ dua )"
                      type="textarea"
                      errorMessage="Invalid fasilitas"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={paket ? paket.fasilitas : ""}
                    />
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      name="deskripsi"
                      label="Deskripsi Paket Khitan"
                      type="textarea"
                      errorMessage="Invalid name"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={paket ? paket.deskripsi : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3 form-row d-flex">
                    <Col className="col-4 mb-3">
                      <Dropzone
                        accept="image/jpeg, image/png"
                        onDrop={img => {
                          handleImagePaket(img)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                              </div>
                              <h6>Seret Gambar Paket atau Klik untuk Upload</h6>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {imagePaket.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name || f.toString().split("-")[1]}
                                      src={f.preview || env.IMG_URL + f}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name || f.toString().split("-")[1]}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )
                        })}
                      </div>
                      <div className=" is-invalid"></div>
                      <div className="invalid-feedback">{errImage.paket}</div>
                    </Col>
                    <Col className="col-4 mb-3">
                      <Dropzone
                        accept="image/jpeg, image/png"
                        onDrop={img => {
                          handleImageAlat(img)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                              </div>
                              <h6>Seret Gambar Alat atau Klik untuk Upload</h6>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {imageAlat.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name || f.toString().split("-")[1]}
                                      src={f.preview || env.IMG_URL + f}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name || f.toString().split("-")[1]}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )
                        })}
                      </div>
                      <div className=" is-invalid"></div>
                      <div className="invalid-feedback">{errImage.alat}</div>
                    </Col>
                    <Col className="col-4 mb-3">
                      <Dropzone
                        accept="image/jpeg, image/png"
                        onDrop={img => {
                          handleImageHadiah(img)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                              </div>
                              <h6>
                                Seret Gambar Hadiah atau Klik untuk Upload
                              </h6>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {imageHadiah.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name || f.toString().split("-")[1]}
                                      src={f.preview || env.IMG_URL + f}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name || f.toString().split("-")[1]}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )
                        })}
                      </div>
                      <div className=" is-invalid"></div>
                      <div className="invalid-feedback">{errImage.hadiah}</div>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={() => toggle("close")}
                      >
                        Tutup
                      </button>
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-outline-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        type="submit"
                        className="btn btn-outline-success save-paket"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

PaketKhitan.propTypes = {
  dataPaketKhitan: PropTypes.array,
  onGetDataPaketKhitan: PropTypes.func,
  onAddNewPaketKhitan: PropTypes.func,
  onUpdatePaketKhitan: PropTypes.func,
  onDeletePaketKhitan: PropTypes.func,
}

const mapStateToProps = ({ dataPaketKhitanReducer }) => ({
  dataPaketKhitan: dataPaketKhitanReducer.dataPaketKhitan,
  // dataPaketKhitan: dataPaketKhitan,
})

const mapDispatchToProps = dispatch => ({
  onGetDataPaketKhitan: () => dispatch(getDataPaketKhitan()),
  onAddNewPaketKhitan: paket => dispatch(addNewPaketKhitan(paket)),
  onUpdatePaketKhitan: paket => dispatch(updatePaketKhitan(paket)),
  onDeletePaketKhitan: paket => dispatch(deletePaketKhitan(paket)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaketKhitan)
