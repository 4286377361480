/* DATA_VIDEO_CALL */
export const GET_DATA_VIDEO_CALL = "GET_DATA_VIDEO_CALL"
export const GET_DATA_VIDEO_CALL_SUCCESS = "GET_DATA_VIDEO_CALL_SUCCESS"
export const GET_DATA_VIDEO_CALL_FAIL = "GET_DATA_VIDEO_CALL_FAIL"

export const ADD_NEW_VIDEO_CALL = "ADD_NEW_VIDEO_CALL"
export const ADD_NEW_VIDEO_CALL_SUCCESS = "ADD_NEW_VIDEO_CALL_SUCCESS"
export const ADD_NEW_VIDEO_CALL_FAIL = "ADD_NEW_VIDEO_CALL_FAIL"

export const UPDATE_VIDEO_CALL = "UPDATE_VIDEO_CALL"
export const UPDATE_VIDEO_CALL_SUCCESS = "UPDATE_VIDEO_CALL_SUCCESS"
export const UPDATE_VIDEO_CALL_FAIL = "UPDATE_VIDEO_CALL_FAIL"
