import { takeEvery, put, call } from "redux-saga/effects"

import {
  // DELETE_NOTIF,
  GET_DATA_NOTIF,
  UPDATE_NOTIF,
} from "./actionTypes"
import {
  getDataNotifSuccess,
  getDataNotifFail,
  updateNotifSuccess,
  updateNotifFail,
  // deleteNotifSuccess,
  // deleteNotifFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataNotif,
  updateNotif,
  // deleteNotif,
} from "../../helpers/backend_helper"

function* fetchDataNotif() {
  try {
    const response = yield call(getDataNotif)
    yield put(getDataNotifSuccess(response))
  } catch (error) {
    yield put(getDataNotifFail(error))
  }
}

function* onUpdateNotif({ payload: notif }) {
  try {
    const response = yield call(updateNotif, notif)
    yield put(updateNotifSuccess(response))
  } catch (error) {
    yield put(updateNotifFail(error))
  }
}

// function* onDeleteNotif({ payload: notif }) {
//   try {
//     const response = yield call(deleteNotif, notif)
//     yield put(deleteNotifSuccess(response))
//   } catch (error) {
//     yield put(deleteNotifFail(error))
//   }
// }

function* DataNotifSaga() {
  yield takeEvery(GET_DATA_NOTIF, fetchDataNotif)
  yield takeEvery(UPDATE_NOTIF, onUpdateNotif)
  // yield takeEvery(DELETE_NOTIF, onDeleteNotif)
}

export default DataNotifSaga
