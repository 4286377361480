import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { env } from "../../helpers/config"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

import { getDataVideoCall, updateVideoCall } from "../../store/actions"

const VideoCall = props => {
  const { onGetDataVideoCall, dataVideoCall } = props
  const [modal, setModal] = useState(false)
  const [videoCall, setVideoCall] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [status, setStatus] = useState("")

  useEffect(() => {
    if (dataVideoCall) {
      dataVideoCall.map((item, index) => {
        item.action = (
          <Button
            color="primary"
            className="font-16 btn-block btn-sm"
            outline
            onClick={e => handleOpenModal(item)}
          >
            Ubah
          </Button>
        )

        item.statusBadge = (
          <>
            <Badge
              className={
                "font-size-12 badge bg-" +
                (item.status === "aktif"
                  ? "primary"
                  : item.status === "selesai"
                  ? "success"
                  : item.status === "rejadwal"
                  ? "info"
                  : item.status === "verifikasi"
                  ? "warning"
                  : "danger")
              }
              pill
            >
              {item.status === "verifikasi" ? "diproses" : item.status}
            </Badge>
          </>
        )
      })
    }

    if (!modal && !isEmpty(videoCall) && !!isEdit) {
      // setTimeout(() => {
      setVideoCall({})
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, videoCall, dataVideoCall])

  const toggle = close => {
    if (close) {
      setStatus("")
    }
    setModal(!modal)
  }

  const handleOpenModal = data => {
    // console.log("data", data)
    if (data) {
      // console.log("data exist")
      setVideoCall({
        _id: data._id,
        nama: data.nama,
        telepon: data.telepon,
        tanggal: data.tanggal,
        jam: data.jam,
        perihal: data.perihal,
        status: data.status,
      })
      setStatus(data.status)

      setIsEdit(true)
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  useEffect(() => {
    onGetDataVideoCall()
  }, [])

  const handleValidVideoCallSubmit = (e, values) => {
    const { onAddNewVideoCall, onUpdateVideoCall } = props

    const formData = new FormData()
    formData.append("status", values.status)

    if (isEdit) {
      formData.append("id", videoCall._id)
      onUpdateVideoCall(formData)
    } else {
      // onAddNewVideoCall(formData)
    }

    setStatus("")
    toggle()
  }

  const handleDeleteVideoCall = () => {
    const { onDeleteVideoCall } = props
    onDeleteVideoCall(videoCall._id)
    setDeleteModal(false)
    setStatus("")
    toggle()
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const data = {
    columns: [
      {
        label: "Nama",
        field: "nama",
        sort: "asc",
        width: 200,
      },
      {
        label: "Telepon",
        field: "telepon",
        sort: "asc",
        width: 200,
      },
      {
        label: "Tanggal",
        field: "tanggal",
        sort: "asc",
        width: 200,
      },
      {
        label: "Jam",
        field: "jam",
        sort: "asc",
        width: 200,
      },
      {
        label: "Perihal",
        field: "perihal",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "statusBadge",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: dataVideoCall,
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteVideoCall}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>VideoCall | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs breadcrumbItem="Janji Video Call" />
                  {/* <CardTitle className="h4">Data VideoCall</CardTitle> */}
                  {/* <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable modal-lg"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Janji Video Call" : "Tambah Janji Video Call"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidVideoCallSubmit}>
                <Row form>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="nama"
                      label="Nama"
                      type="text"
                      disabled={true}
                      value={videoCall ? videoCall.nama : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="telepon"
                      label="Telepon"
                      type="text"
                      disabled={true}
                      value={videoCall ? videoCall.telepon : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="tanggal"
                      label="Tanggal"
                      type="text"
                      disabled={true}
                      value={videoCall ? videoCall.tanggal : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="jam"
                      label="Jam"
                      type="text"
                      disabled={true}
                      value={videoCall ? videoCall.jam : ""}
                    />
                  </Col>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="perihal"
                      label="Perihal"
                      type="text"
                      disabled={true}
                      value={videoCall ? videoCall.perihal : ""}
                    />
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      type="select"
                      name="status"
                      label="Status Janji"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={e => setStatus(e.target.value)}
                      value={videoCall ? videoCall.status : ""}
                    >
                      <option hidden value="">
                        -- Pilih Status --
                      </option>
                      <option value="verifikasi">Diproses</option>
                      <option value="aktif">Terverifikasi</option>
                      <option value="selesai">Selesai</option>
                      <option value="batal">Batal</option>
                    </AvField>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={() => toggle("close")}
                      >
                        Tutup
                      </button>
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-outline-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        type="submit"
                        className="btn btn-outline-success save-videoCall"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

VideoCall.propTypes = {
  dataVideoCall: PropTypes.array,
  onGetDataVideoCall: PropTypes.func,
  onUpdateVideoCall: PropTypes.func,
}

const mapStateToProps = ({ dataVideoCallReducer }) => ({
  dataVideoCall: dataVideoCallReducer.dataVideoCall,
})

const mapDispatchToProps = dispatch => ({
  onGetDataVideoCall: () => dispatch(getDataVideoCall()),
  onUpdateVideoCall: videoCall => dispatch(updateVideoCall(videoCall)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VideoCall)
