import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { env } from "../../helpers/config"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, convertToRaw, convertFromRaw } from "draft-js"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

import {
  addNewArtikel,
  deleteArtikel,
  getDataArtikel,
  getKategoriArtikel,
  updateArtikel,
} from "../../store/actions"

const Artikel = props => {
  const {
    onGetDataArtikel,
    onGetKategoriArtikel,
    dataArtikel,
    kategoriArtikel,
  } = props
  const [modal, setModal] = useState(false)
  const [artikel, setArtikel] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [imageArtikel, setImageArtikel] = useState([])
  const [katLainnya, setKatLainnya] = useState(false)
  const [editorState, setEditorState] = useState({
    editorState: EditorState.createEmpty(),
  })
  const [errImage, setErrImage] = useState("")

  useEffect(() => {
    if (dataArtikel) {
      dataArtikel.map((item, index) => {
        item.action = (
          <Button
            color="primary"
            outline
            className="font-16 btn-block btn-sm"
            onClick={e => handleOpenModal(item)}
          >
            {/* <i className="mdi mdi-grease-pencil"></i>  */}
            Ubah
          </Button>
        )
        item.image1 = (
          <CardImg
            src={env.IMG_URL + item.image}
            alt="image-artikel"
            className="avatar-lg img-thumbnail img-fluid"
          />
        )
      })
    }

    if (!modal && !isEmpty(artikel) && !!isEdit) {
      // setTimeout(() => {
      setArtikel({})
      setIsEdit(false)
      // }, 500)
    }
  }, [modal, artikel, dataArtikel, kategoriArtikel])

  const toggle = close => {
    if (close) {
      setImageArtikel([])
      setEditorState({
        editorState: EditorState.createEmpty(),
      })
      setErrImage("")
      setKatLainnya("")
    }
    setModal(!modal)
  }

  const handleOpenModal = data => {
    // console.log("data", data)
    if (data) {
      // console.log("data exist")
      setArtikel({
        _id: data._id,
        judul: data.judul,
        body: data.body,
        kategori: data.kategori,
        image: data.image,
      })
      setImageArtikel([data.image])

      setEditorState({
        editorState: EditorState.createWithContent(
          convertFromRaw(JSON.parse(data.body))
        ),
      })

      setIsEdit(true)
    } else {
      // console.log("data not exist")
      setIsEdit(false)
    }
    toggle()
  }

  useEffect(() => {
    onGetDataArtikel()
  }, [])

  useEffect(() => {
    onGetKategoriArtikel()
  }, [dataArtikel])

  const handleValidArtikelSubmit = (e, values) => {
    const { onAddNewArtikel, onUpdateArtikel } = props

    let kategori
    if (values.kategori === "lainnya") {
      kategori = values.kat_artikel_lainnya
    } else {
      kategori = values.kategori
    }

    if (typeof imageArtikel[0] === "undefined" && !isEdit) {
      setErrImage("Gambar artikel harus di upload..")
    } else {
      const formData = new FormData()
      formData.append("judul", values.judul)
      // formData.append("body", values.body)
      formData.append(
        "body",
        JSON.stringify(
          convertToRaw(editorState.editorState.getCurrentContent())
        )
      )
      formData.append("kategori", kategori)
      formData.append("image", imageArtikel[0])

      if (isEdit) {
        formData.append("_id", artikel._id)
        onUpdateArtikel(formData)
      } else {
        setErrImage("")
        onAddNewArtikel(formData)
      }

      setImageArtikel([])
      setKatLainnya(false)
      setEditorState({
        editorState: EditorState.createEmpty(),
      })
      toggle()
    }
  }

  const handleDeleteArtikel = () => {
    const { onDeleteArtikel } = props
    onDeleteArtikel(artikel._id)
    setDeleteModal(false)
    setImageArtikel([])
    toggle()
  }

  function handleImageArtikel(files) {
    if (files.length > 1) {
      setErrImage("Maksimal upload 1 gambar")
    } else {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setImageArtikel(files)
      setErrImage("")
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleKatArtikel = val => {
    if (val.target.value === "lainnya") {
      setKatLainnya(true)
    } else {
      setKatLainnya(false)
    }
  }

  const handleEditorChange = editorState => {
    setEditorState({ editorState })
  }

  const data = {
    columns: [
      {
        label: "Judul",
        field: "judul",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Isi Artikel",
      //   field: "body",
      //   sort: "asc",
      //   width: 270,
      // },
      {
        label: "Kategori",
        field: "kategori",
        sort: "asc",
        width: 100,
      },
      {
        label: "Image",
        field: "image1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: dataArtikel,
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteArtikel}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Artikel | KhitanSultan Backoffice</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <Breadcrumbs
                    breadcrumbItem="Artikel"
                    handleOpenModal={handleOpenModal}
                    buttonModal="Artikel"
                  />
                  {/* <CardTitle className="h4">Data Artikel</CardTitle> */}
                  {/* <p className="card-nama-desc">
                    Working Actions : <code>add, update, delete</code>.
                  </p> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            className="modal-dialog modal-dialog-scrollable modal-lg"
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Artikel" : "Tambah Artikel"}
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidArtikelSubmit}>
                <Row form>
                  <Col className="col-12 mb-3">
                    <AvField
                      name="judul"
                      label="Judul Artikel"
                      type="text"
                      errorMessage="Invalid judul artikel"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={artikel ? artikel.judul : ""}
                    />
                  </Col>

                  {/* <Col className="col-12 mb-3">
                    <AvField
                      name="body"
                      label="Isi Artikel"
                      type="textarea"
                      errorMessage="Invalid isi artikel"
                      validate={{
                        required: { value: true },
                        minLength: { value: 4 },
                      }}
                      value={artikel ? artikel.body : ""}
                    />
                  </Col> */}
                  <Col className="col-12 mb-3">
                    <Editor
                      editorState={editorState.editorState}
                      onEditorStateChange={handleEditorChange}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                    />
                  </Col>

                  <Col className="col-12 mb-3">
                    <AvField
                      type="select"
                      name="kategori"
                      label="Kategori Artikel"
                      validate={{
                        required: { value: true },
                      }}
                      value={artikel ? artikel.kategori : ""}
                      onChange={value => handleKatArtikel(value)}
                    >
                      <option hidden value="">
                        -- Pilih Kategori --
                      </option>
                      {kategoriArtikel &&
                        kategoriArtikel.map((item, i) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      <option value="lainnya">Lainnya</option>
                    </AvField>
                    {katLainnya && (
                      <div className="ps-1 pt-3">
                        <AvField
                          name="kat_artikel_lainnya"
                          type="textarea"
                          placeholder="Lainnya.."
                          validate={{
                            required: { value: katLainnya },
                          }}
                        ></AvField>
                      </div>
                    )}
                  </Col>
                  <Col className="col-12 mb-3">
                    <Dropzone
                      accept="image/jpeg, image/png"
                      onDrop={img => {
                        handleImageArtikel(img)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                            </div>
                            <h6>Seret Gambar Artikel atau Klik untuk Upload</h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {imageArtikel.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={
                                      f.name ||
                                      imageArtikel.toString().split("-")[1]
                                    }
                                    src={
                                      f.preview || env.IMG_URL + imageArtikel
                                    }
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name ||
                                      imageArtikel.toString().split("-")[1]}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                    <div className=" is-invalid"></div>
                    <div className="invalid-feedback">{errImage}</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={() => toggle("close")}
                      >
                        Tutup
                      </button>
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-outline-danger me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          Hapus
                        </button>
                      )}
                      <button
                        type="submit"
                        className="btn btn-outline-success save-artikel"
                      >
                        Simpan
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

Artikel.propTypes = {
  dataArtikel: PropTypes.array,
  kategoriArtikel: PropTypes.array,
  onGetDataArtikel: PropTypes.func,
  onAddNewArtikel: PropTypes.func,
  onUpdateArtikel: PropTypes.func,
  onDeleteArtikel: PropTypes.func,
  onGetKategoriArtikel: PropTypes.func,
}

const mapStateToProps = ({ dataArtikelReducer }) => ({
  dataArtikel: dataArtikelReducer.dataArtikel,
  kategoriArtikel: dataArtikelReducer.kategoriArtikel,
})

const mapDispatchToProps = dispatch => ({
  onGetDataArtikel: () => dispatch(getDataArtikel()),
  onGetKategoriArtikel: () => dispatch(getKategoriArtikel()),
  onAddNewArtikel: artikel => dispatch(addNewArtikel(artikel)),
  onUpdateArtikel: artikel => dispatch(updateArtikel(artikel)),
  onDeleteArtikel: artikel => dispatch(deleteArtikel(artikel)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Artikel)
