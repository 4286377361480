import {
  GET_DATA_BOOKING,
  GET_DATA_BOOKING_FAIL,
  GET_DATA_BOOKING_SUCCESS,
  GET_DATA_BOOKING_FILTER,
  GET_DATA_BOOKING_FILTER_FAIL,
  GET_DATA_BOOKING_FILTER_SUCCESS,
  ADD_NEW_BOOKING,
  ADD_BOOKING_SUCCESS,
  ADD_BOOKING_FAIL,
  UPDATE_BOOKING,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAIL,
  EXPORT_BOOKING,
  EXPORT_BOOKING_SUCCESS,
  EXPORT_BOOKING_FAIL,
  EXPORT_SERTIFIKAT,
  EXPORT_SERTIFIKAT_SUCCESS,
  EXPORT_SERTIFIKAT_FAIL,
  DELETE_BOOKING,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_FAIL,
} from "./actionTypes"

export const getDataBooking = () => ({
  type: GET_DATA_BOOKING,
})

export const getDataBookingSuccess = dataBooking => ({
  type: GET_DATA_BOOKING_SUCCESS,
  payload: dataBooking,
})

export const getDataBookingFail = error => ({
  type: GET_DATA_BOOKING_FAIL,
  payload: error,
})

export const getDataBookingFilter = booking => ({
  type: GET_DATA_BOOKING_FILTER,
  payload: booking,
})

export const getDataBookingFilterSuccess = dataBooking => ({
  type: GET_DATA_BOOKING_FILTER_SUCCESS,
  payload: dataBooking,
})

export const getDataBookingFilterFail = error => ({
  type: GET_DATA_BOOKING_FILTER_FAIL,
  payload: error,
})

export const addNewBooking = booking => ({
  type: ADD_NEW_BOOKING,
  payload: booking,
})

export const addBookingSuccess = booking => ({
  type: ADD_BOOKING_SUCCESS,
  payload: booking,
})

export const addBookingFail = error => ({
  type: ADD_BOOKING_FAIL,
  payload: error,
})

export const updateBooking = booking => ({
  type: UPDATE_BOOKING,
  payload: booking,
})

export const updateBookingSuccess = booking => ({
  type: UPDATE_BOOKING_SUCCESS,
  payload: booking,
})

export const updateBookingFail = error => ({
  type: UPDATE_BOOKING_FAIL,
  payload: error,
})

export const exportBooking = booking => ({
  type: EXPORT_BOOKING,
  payload: booking,
})

export const exportBookingSuccess = booking => ({
  type: EXPORT_BOOKING_SUCCESS,
  payload: booking,
})

export const exportBookingFail = error => ({
  type: EXPORT_BOOKING_FAIL,
  payload: error,
})

export const exportSertifikat = booking => ({
  type: EXPORT_SERTIFIKAT,
  payload: booking,
})

export const exportSertifikatSuccess = booking => ({
  type: EXPORT_SERTIFIKAT_SUCCESS,
  payload: booking,
})

export const exportSertifikatFail = error => ({
  type: EXPORT_SERTIFIKAT_FAIL,
  payload: error,
})

export const deleteBooking = booking => ({
  type: DELETE_BOOKING,
  payload: booking,
})

export const deleteBookingSuccess = booking => ({
  type: DELETE_BOOKING_SUCCESS,
  payload: booking,
})

export const deleteBookingFail = error => ({
  type: DELETE_BOOKING_FAIL,
  payload: error,
})
