import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  GET_DATA_VIDEO_CALL,
  ADD_NEW_VIDEO_CALL,
  UPDATE_VIDEO_CALL,
} from "./actionTypes"
import {
  getDataVideoCallSuccess,
  getDataVideoCallFail,
  addNewVideoCallSuccess,
  addNewVideoCallFail,
  updateVideoCallSuccess,
  updateVideoCallFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDataVideoCall,
  addNewVideoCall,
  updateVideoCall,
} from "../../helpers/backend_helper"

import {  
  startLoading, 
  stopLoading,
} from "../actions"

function* fetchDataVideoCall() {
  yield put(startLoading());
  try {
    const response = yield call(getDataVideoCall)
    yield put(getDataVideoCallSuccess(response))
  } catch (error) {
    yield put(getDataVideoCallFail(error))
  }
  yield put(stopLoading());
}
function* onaddNewVideoCall({ payload: videoCall }) {
  yield put(startLoading());
  try {
    const response = yield call(addNewVideoCall, videoCall)
    yield put(addNewVideoCallSuccess(response))
  } catch (error) {
    // alert(error.message);
    yield put(addNewVideoCallFail(error))
  }
  yield put(stopLoading());
}

function* onUpdateVideoCall({ payload: videoCall }) {
  yield put(startLoading());
  try {
    const response = yield call(updateVideoCall, videoCall)
    yield put(updateVideoCallSuccess(response))
  } catch (error) {
    // alert(error.message);
    yield put(updateVideoCallFail(error))
  }
  yield put(stopLoading());
}

function* DataVideoCallSaga() {
  yield takeEvery(GET_DATA_VIDEO_CALL, fetchDataVideoCall)
  yield takeEvery(ADD_NEW_VIDEO_CALL, onaddNewVideoCall)
  yield takeEvery(UPDATE_VIDEO_CALL, onUpdateVideoCall)
}

export default DataVideoCallSaga
