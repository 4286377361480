import {
  takeEvery,
  put,
  call,
  all,
  apply,
  fork,
  take,
} from "redux-saga/effects"
import { eventChannel } from "redux-saga"
import io from "socket.io-client"
import { env } from "../../helpers/config"

import { GET_IO_NOTIF } from "./actionTypes"
import { getIoNotifSuccess, getIoNotifFail } from "./actions"
import { addBookingSuccess } from "../booking/actions"
import { updateListKonsultasiSuccess } from "../konsultasi/actions"

function createSocketConnection(url) {
  return io(url)
}

function createSocketChannel(socket) {
  return eventChannel(emit => {
    const eventHandler = event => {
      emit(event)
    }

    const errorHandler = errorEvent => {
      emit(new Error(errorEvent.reason))
    }
    // listen ke semua notif, konsul, booking, dll
    socket.on("notif-admin", eventHandler)

    // socket.on("booking-notif", result => {
    //   if (result) {
    //     return result
    //   }
    // })

    socket.on("error", errorHandler)

    const unsubscribe = () => {
      socket.off("notif-admin", eventHandler)
    }

    return unsubscribe
  })
}

function* fetchIoNotif() {
  const socket = yield call(createSocketConnection, env.SOCKET_URL)
  const socketChannel = yield call(createSocketChannel, socket)

  while (true) {
    try {
      const response = yield take(socketChannel)

      if (response && response?.tipe_notif) {
        if (response?.tipe_notif === "konsultasi") {
          yield put(updateListKonsultasiSuccess(response))
          yield put(getIoNotifSuccess(response))
        }
        if (response?.tipe_notif === "booking") {
          yield put(getIoNotifSuccess(response))
          yield put(addBookingSuccess(response))
        }
        if (
          response?.tipe_notif === "barang_habis" ||
          response?.tipe_notif === "video-call" ||
          response?.tipe_notif === "tarik-dana"
        ) {
          yield put(getIoNotifSuccess(response))
        }
      }
    } catch (err) {
      // console.log("socket error: ", err)
    }
  }
}

// export default function* rootSaga() {
//   yield fork(fetchIoNotif);
// }

export function* watchSocket() {
  yield takeEvery(GET_IO_NOTIF, fetchIoNotif)
}

function* ioNotifSaga() {
  yield all([fork(watchSocket)])
}

export default ioNotifSaga
