import {
  GET_DATA_ARTIKEL,
  GET_DATA_ARTIKEL_FAIL,
  GET_DATA_ARTIKEL_SUCCESS,
  ADD_NEW_ARTIKEL,
  ADD_ARTIKEL_SUCCESS,
  ADD_ARTIKEL_FAIL,
  UPDATE_ARTIKEL,
  UPDATE_ARTIKEL_SUCCESS,
  UPDATE_ARTIKEL_FAIL,
  DELETE_ARTIKEL,
  DELETE_ARTIKEL_SUCCESS,
  DELETE_ARTIKEL_FAIL,
  GET_KATEGORI_ARTIKEL,
  GET_KATEGORI_ARTIKEL_SUCCESS,
  GET_KATEGORI_ARTIKEL_FAIL,
} from "./actionTypes"

export const getDataArtikel = () => ({
  type: GET_DATA_ARTIKEL,
})

export const getDataArtikelSuccess = dataArtikel => ({
  type: GET_DATA_ARTIKEL_SUCCESS,
  payload: dataArtikel,
})

export const getDataArtikelFail = error => ({
  type: GET_DATA_ARTIKEL_FAIL,
  payload: error,
})

export const addNewArtikel = artikel => ({
  type: ADD_NEW_ARTIKEL,
  payload: artikel,
})

export const addArtikelSuccess = artikel => ({
  type: ADD_ARTIKEL_SUCCESS,
  payload: artikel,
})

export const addArtikelFail = error => ({
  type: ADD_ARTIKEL_FAIL,
  payload: error,
})

export const updateArtikel = artikel => ({
  type: UPDATE_ARTIKEL,
  payload: artikel,
})

export const updateArtikelSuccess = artikel => ({
  type: UPDATE_ARTIKEL_SUCCESS,
  payload: artikel,
})

export const updateArtikelFail = error => ({
  type: UPDATE_ARTIKEL_FAIL,
  payload: error,
})

export const deleteArtikel = artikel => ({
  type: DELETE_ARTIKEL,
  payload: artikel,
})

export const deleteArtikelSuccess = artikel => ({
  type: DELETE_ARTIKEL_SUCCESS,
  payload: artikel,
})

export const deleteArtikelFail = error => ({
  type: DELETE_ARTIKEL_FAIL,
  payload: error,
})

export const getKategoriArtikel = () => ({
  type: GET_KATEGORI_ARTIKEL,
})

export const getKategoriArtikelSuccess = data => ({
  type: GET_KATEGORI_ARTIKEL_SUCCESS,
  payload: data,
})

export const getKategoriArtikelFail = error => ({
  type: GET_KATEGORI_ARTIKEL_FAIL,
  payload: error,
})
