import {
  GET_DATA_DASHBOARD_SUCCESS,
  GET_DATA_DASHBOARD_FAIL,
  FILTER_DATA_DASHBOARD_SUCCESS,
  FILTER_DATA_DASHBOARD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataDashboard: {},
  error: {},
}

const DataDashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_DASHBOARD_SUCCESS:
      return {
        ...state,
        dataDashboard: action.payload,
      }

    case GET_DATA_DASHBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case FILTER_DATA_DASHBOARD_SUCCESS:
      return {
        ...state,
        dataDashboard: action.payload,
      }

    case FILTER_DATA_DASHBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default DataDashboardReducer
