import {
  GET_DATA_NERACA,
  GET_DATA_NERACA_FAIL,
  GET_DATA_NERACA_SUCCESS,
  FILTER_DATA_NERACA,
  FILTER_DATA_NERACA_FAIL,
  FILTER_DATA_NERACA_SUCCESS,
} from "./actionTypes"

export const getDataNeraca = () => ({
  type: GET_DATA_NERACA,
})

export const getDataNeracaSuccess = dataNeraca => ({
  type: GET_DATA_NERACA_SUCCESS,
  payload: dataNeraca,
})

export const getDataNeracaFail = error => ({
  type: GET_DATA_NERACA_FAIL,
  payload: error,
})

export const filterDataNeraca = data_filter => ({
  type: FILTER_DATA_NERACA,
  payload: data_filter,
})

export const filterDataNeracaSuccess = dataNeraca => ({
  type: FILTER_DATA_NERACA_SUCCESS,
  payload: dataNeraca,
})

export const filterDataNeracaFail = error => ({
  type: FILTER_DATA_NERACA_FAIL,
  payload: error,
})
